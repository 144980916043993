const INITIAL_DRIVER = {
  id: "",
};
const INITIAL_STATE = {
  showForm: false,
  showFormReport: false,
  report: INITIAL_DRIVER,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(INITIAL_DRIVER)
  // console.log(state)
  // console.log(action)
  switch (action.type) {
    case "PRODUCT_RETURNS_BY_DRIVER_SHOW_FORM":
      return {
        ...state,
        showForm: true,
        showFormReport: false,
        report: action.payload === undefined ? INITIAL_DRIVER : action.payload,
      };
    case "PRODUCT_RETURNS_BY_DRIVER_HIDE_FORM":
      return {
        ...state,
        showForm: false,
        showFormReport: false,
        report: INITIAL_DRIVER,
      };
    case "PRODUCT_RETURNS_BY_DRIVER_SHOW_FORM_REPORT":
      return {
        ...state,
        showForm: false,
        showFormReport: true,
        report: INITIAL_DRIVER,
      };
    case "PRODUCT_RETURNS_BY_DRIVER_HIDE_FORM_REPORT":
      return {
        ...state,
        showForm: false,
        showFormReport: false,
        report: INITIAL_DRIVER,
      };
    case "PRODUCT_RETURNS_BY_DRIVER_GET":
      return { ...state, list: action.payload };
    case "PRODUCT_RETURNS_BY_DRIVER_GET_CLIENT":
      return { ...state, cliente: action.payload };
    case "PRODUCT_RETURNS_BY_DRIVER_GET_PRODUCT_RETURN":
      return {
        ...state,
        report: {
          ...state.report,
          name: action.payload.NOME,
          vendedor: action.payload.VENDEDOR,
        },
      };
    default:
      return state;
  }
};
