import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { Redirect, Link } from "react-router-dom";
import axios from "axios";
import consts from "../../consts";

import If from "../../common/operators/If";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Slide from "@material-ui/core/Slide";

import CustomInput from "components/CustomInput/CustomInput.jsx";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ShowForm,
  ShowProducts,
  HideProducts,
  save,
  showNotification,
  // get
} from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import CategoryIcon from "@material-ui/icons/Category";
import ArrowRightIcon from "@material-ui/icons/ChevronRight";
import ArrowLeftIcon from "@material-ui/icons/ChevronLeft";
import AssignmentIcon from "@material-ui/icons/Assignment";

// import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import { dataTable } from "variables/general.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class ReactTables extends React.Component {
  // history = useHistory();

  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      products: [],
      data: [],
      originalData: [],
      dataRelationship: [],
      originalDataRelationship: [],
      pages: -1,
      loading: true,
      alert: null,
      show: false,
      replicate: true,
      classicModal: false,
      ListProductsModal: {
        qtd: "",
        price: "",
      },
      product: {
        name: "",
        sku: "",
      },
      model: {
        id: null,
        planogram_id: props.planogram.id,
        product_id: "",
        qtd: "",
        channel: "",
        price: 0,
        alert: "",
      },
    };

    // this.get();
  }

  // componentWillMount(){
  // }

  componentDidMount() {
    this.get();
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  save() {
    // console.log(this.state.model);
    const that = this;
    if (this.state.model.id) {
      axios
        .put(
          `${consts.API_URL}/planograms/${this.props.planogram.id}/products/${
            this.state.model.id
          }/${this.state.replicate === true ? "replicate" : ""}`,
          this.state.model
        )
        .then((resp) => {
          // console.log(resp)
          that.setState({
            model: {
              id: null,
              planogram_id: this.props.planogram.id,
              product_id: "",
              qtd: "",
              channel: "",
              price: 0,
              alert: "",
            },
            product: {
              name: "",
              sku: "",
            },
            // classicModal: false,
            // ListProductsModal: {
            //   qtd: '',
            //   price: ''
            // }
          });
          that.get();
        })
        .catch((e) => {
          // console.log(e)
          // console.log(e.response)
          let configNotification = {
            message: "Erro inesperado.",
            color: "danger",
          };
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors > 0
          ) {
            e.response.data.errors.forEach((error) => {
              // toastr.error('Erro', error))
              configNotification = {
                message: error,
                color: "danger",
              };
            });
          } else if (e.response && e.response.data && e.response.data.message) {
            configNotification = {
              message: e.response.data.message,
              color: "danger",
            };
          } else {
            // toastr.error('Erro', 'Erro inesperado.')
            configNotification = {
              message: "Erro inesperado.",
              color: "danger",
            };
          }

          this.props.showNotification(configNotification);
        });
    } else {
      axios
        .post(
          `${consts.API_URL}/planograms/${this.props.planogram.id}/products/${
            this.state.replicate === true ? "replicate" : ""
          }`,
          this.state.model
        )
        .then((resp) => {
          // console.log(resp)
          that.setState({
            model: {
              id: null,
              planogram_id: this.props.planogram.id,
              product_id: "",
              qtd: "",
              channel: "",
              price: 0,
              alert: "",
            },
            product: {
              name: "",
              sku: "",
            },
            // classicModal: false,
            // ListProductsModal: {
            //   qtd: '',
            //   price: ''
            // }
          });
          that.get();
        })
        .catch((e) => {
          // console.log(e)
          // console.log(e.response)
          let configNotification = {
            message: "Erro inesperado.",
            color: "danger",
          };
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors > 0
          ) {
            e.response.data.errors.forEach((error) => {
              // toastr.error('Erro', error))
              configNotification = {
                message: error,
                color: "danger",
              };
            });
          } else if (e.response && e.response.data && e.response.data.message) {
            configNotification = {
              message: e.response.data.message,
              color: "danger",
            };
          } else {
            // toastr.error('Erro', 'Erro inesperado.')
            configNotification = {
              message: "Erro inesperado.",
              color: "danger",
            };
          }

          this.props.showNotification(configNotification);
        });
    }
  }

  // save(){
  //   console.log(this.state.model)
  //   axios.post(`${consts.API_URL}/planograms/${this.props.planogram.id}/products`, this.state.model)
  //   .then(resp => {
  //     console.log(resp)
  //     this.get()
  //   })
  // }

  // removeProduct(item){
  //   // console.log(item)
  //   axios.delete(`${consts.API_URL}/planograms/${this.props.planogram.id}/products/${item.id}`)
  //   .then(resp => {
  //     this.get()
  //   })
  // }

  delete(item) {
    const that = this;
    const thisItem = item;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Tem certeza?"
          onConfirm={() => {
            console.log(item);
            console.log(this.state);
            axios
              .delete(
                `${consts.API_URL}/planograms/${that.props.planogram.id}/products/${thisItem.id}/${
                  this.state.replicate === true ? "replicate" : ""
                }`
              )
              .then((resp) => {
                var data = that.state.data;
                data.find((o, i) => {
                  if (o.id === thisItem.id) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    data.splice(i, 1);
                    return true;
                  }
                  return false;
                });
                that.get();
                // this.setState({ data: data });
              });
            that.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={that.props.classes.button + " " + that.props.classes.success}
          cancelBtnCssClass={that.props.classes.button + " " + that.props.classes.danger}
          confirmBtnText="Sim, excluir!"
          cancelBtnText="Cancelar"
          showCancel
        >
          Não tem como recuperar o registro ao excluir!
        </SweetAlert>
      ),
    });
  }

  // showForm(item){
  //   // console.log(item)
  //   this.setState({
  //     classicModal: !this.state.classicModal,
  //     ListProductsModal: {
  //       ...item,
  //       qtd: item.qtd,
  //       price: item.price
  //     }
  //   })
  // }
  //
  // save(){
  //   // console.log(this.state.ListProductsModal)
  //   const that = this
  //   axios.put(`${consts.API_URL}/planograms/${this.props.planogram.id}/products/${this.state.ListProductsModal.id}`, {
  //     qtd: this.state.ListProductsModal.qtd,
  //     price: this.state.ListProductsModal.price,
  //   })
  //   .then(resp => {
  //     // console.log(resp)
  //     that.setState({
  //       classicModal: false,
  //       ListProductsModal: {
  //         qtd: '',
  //         price: ''
  //       }
  //     })
  //     that.get()
  //   })
  //   .catch(e => {
  //     // console.log(e)
  //     // console.log(e.response)
  //     let configNotification = {
  //           message: 'Erro inesperado.',
  //           color: 'danger'
  //         }
  //     if(e.response && e.response.data && e.response.data.errors && e.response.data.errors  > 0){
  //         e.response.data.errors.forEach(
  //             error => {
  //               // toastr.error('Erro', error))
  //               configNotification = {
  //                     message: error,
  //                     color: 'danger'
  //                   }
  //         })
  //     }else if(e.response && e.response.data && e.response.data.message){
  //         configNotification = {
  //               message: e.response.data.message,
  //               color: 'danger'
  //             }
  //     }else{
  //         // toastr.error('Erro', 'Erro inesperado.')
  //         configNotification = {
  //               message: 'Erro inesperado.',
  //               color: 'danger'
  //             }
  //     }
  //
  //     this.props.showNotification(configNotification)
  //   })
  // }

  // setData(result){
  //   var data = result.map(item => {
  //
  //     return {
  //       id: item.id,
  //       name: item.name,
  //       actions: (
  //         // we've added some custom button actions
  //         <div className="actions-right">
  //           <Button
  //             justIcon
  //             round
  //             simple
  //             onClick={() => this.save(item)}
  //             color="primary"
  //             className="edit"
  //             title="Produtos"
  //             >
  //               <ArrowRightIcon />
  //           </Button>
  //           {" "}
  //         </div>
  //       )
  //     };
  //   })
  //
  //   this.setState({
  //     data,
  //     originalData: result,
  //     loading: false
  //   })
  //
  // }

  cancelButtonClick() {
    this.setState({
      model: {
        id: null,
        planogram_id: this.props.planogram.id,
        product_id: "",
        qtd: "",
        channel: "",
        price: 0,
        alert: "",
      },
      product: {
        name: "",
        sku: "",
      },
    });
  }

  edit(item) {
    const id = item.id;
    const product = item.product;
    const product_id = item.product_id;
    const channel = item.channel;
    const qtd = item.qtd;
    const alert = item.alert;

    const model = {
      ...this.state.model,
      id,
      product_id,
      channel,
      qtd,
      alert,
    };
    console.log(model);

    this.setState({
      model,
      product,
    });
  }

  setDataRelationship(result) {
    var dataRelationship = result.map((item) => {
      return {
        id: item.id,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => this.edit(item)}
              color="primary"
              className="warning"
              title="Editar"
            >
              <EditIcon />
            </Button>{" "}
            <Button
              justIcon
              round
              simple
              onClick={() => this.delete(item)}
              color="danger"
              className="remove"
              title="Excluir"
            >
              <Close />
            </Button>{" "}
          </div>
        ),
        name: item.product.name,
        qtd: item.qtd,
        channel: item.channel,
        alert: item.alert,
        price: <div className="actions-left">{item.price}</div>,
      };
    });

    this.setState({
      dataRelationship,
      originalDataRelationship: result,
      loading: false,
    });
  }

  async get() {
    this.setState({
      loading: true,
    });

    const productsResponse = await axios.get(
      `${consts.API_URL}/products?qtd_per_page=9999999999999`
    );
    // console.log(productsResponse.data.result)
    this.setState({ products: productsResponse.data.result });
    const planogramProductsResponse = await axios.get(
      `${consts.API_URL}/planograms/${this.props.planogram.id}/products?qtd_per_page=9999999999999`
    );
    // console.log(planogramProductsResponse)

    let products = productsResponse.data.result
      .map((product) => {
        // console.log(product)
        // console.log(planogramProductsResponse.data.result)
        let remove = false;
        planogramProductsResponse.data.result.map((planogramProduct) => {
          if (planogramProduct.product_id === product.id) {
            remove = true;
          }
        });
        if (!remove) {
          return product;
        } else {
          return;
        }
      })
      .filter((product) => product != undefined);
    // console.log(products)
    // products = products
    // console.log(products)

    const planogramProducts = planogramProductsResponse.data.result.map((planogramProduct) => {
      let toReturn = { ...planogramProduct };
      toReturn.product = productsResponse.data.result.filter(
        (productResponse) => productResponse.id == planogramProduct.product_id
      )[0];
      return toReturn;
    });

    // console.log(planogramProducts)

    // this.setData(products)
    this.setDataRelationship(planogramProducts);
  }

  changeListProduct(event, stateName) {
    // console.log(event.target.value)
    this.setState({
      ListProductsModal: {
        ...this.state.ListProductsModal,
        [stateName]: event.target.value,
      },
    });
  }

  changeProduct(event) {
    // console.log("changeProduct: ", event);
    const product = event.target.innerText
      ? this.state.products.filter(
          (product) => event.target.innerText === `${product.sku} - ${product.name}`
        )[0]
      : false;
    // console.log(product);
    const product_id = product ? product.id : "";
    // console.log(product_id);
    this.setState({
      model: { ...this.state.model, product_id },
      product,
    });
  }

  changeModel(event, stateName) {
    this.setState({
      model: {
        ...this.state.model,
        [stateName]: event.target.value,
      },
    });
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const { classes } = this.props;
    // console.log("render state: ", this.state);
    // console.log(this.state.originalData.length)
    // console.log(this.state.originalData)
    return (
      <div>
        {this.state.alert}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <MaterialIconList />
                </CardIcon>
                <Button
                  color="primary"
                  className={classes.updateProfileButton}
                  onClick={() => this.props.HideProducts()}
                >
                  Voltar
                </Button>
                {"&nbsp;"}
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.replicate}
                      onChange={this.handleChange("replicate")}
                      value="replicate"
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        icon: classes.switchIcon,
                        iconChecked: classes.switchIconChecked,
                        bar: classes.switchBar,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                  }}
                  label="Replicar para as machines que já tem este planograma."
                />
                {/* <h4 className={classes.cardIconTitle}>Lista de membros</h4> */}
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <AssignmentIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Cadastrar novo produto</h4>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Autocomplete
                        options={this.state.products}
                        getOptionLabel={(option) =>
                          option.sku && option.sku !== "" && option.name && option.name !== ""
                            ? `${option.sku} - ${option.name}`
                            : ""
                        }
                        onChange={this.changeProduct.bind(this)}
                        value={this.state.product}
                        renderInput={(params) => (
                          <TextField {...params} label="Produto" fullWidth />
                        )}
                      />
                    </GridItem>
                    <GridItem sm={4}>
                      <CustomInput
                        success={this.state.nameState === "success"}
                        error={this.state.nameState === "error"}
                        labelText={<span>Canaleta</span>}
                        id="canaleta"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) => this.changeModel(event, "channel"),
                          value: this.state.model.channel,
                          type: "text",
                          placeholder: "",
                        }}
                      />
                    </GridItem>
                    <GridItem sm={4}>
                      <CustomInput
                        success={this.state.qtdState === "success"}
                        error={this.state.qtdState === "error"}
                        labelText={<span>Capacidade</span>}
                        id="qtd"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) => this.changeModel(event, "qtd"),
                          value: this.state.model.qtd,
                          type: "text",
                          placeholder: "",
                        }}
                      />
                    </GridItem>
                    <GridItem sm={4}>
                      <CustomInput
                        success={this.state.alertState === "success"}
                        error={this.state.alertState === "error"}
                        labelText={<span>Nível de alerta</span>}
                        id="alert"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) => this.changeModel(event, "alert"),
                          value: this.state.model.alert,
                          type: "text",
                          placeholder: "",
                        }}
                      />
                    </GridItem>
                    {1 == 2 ? (
                      <GridItem sm={3}>
                        <CustomInput
                          success={this.state.priceState === "success"}
                          error={this.state.priceState === "error"}
                          labelText={<span>Preço</span>}
                          id="price"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => this.changeModel(event, "price"),
                            value: this.state.model.price,
                            type: "text",
                            placeholder: "",
                          }}
                        />
                      </GridItem>
                    ) : null}
                  </GridContainer>
                </form>
              </CardBody>
              <CardFooter>
                <Button onClick={() => this.cancelButtonClick()}>Cancelar</Button>
                <Button color="primary" onClick={() => this.save()}>
                  Salvar
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <ReactTable
                  data={this.state.dataRelationship}
                  pages={this.state.pages}
                  loading={this.state.loading}
                  filterable={false}
                  columns={[
                    {
                      Header: "Canaleta",
                      accessor: "channel",
                      sortable: true,
                      filterable: true,
                    },
                    {
                      Header: "Nome",
                      accessor: "name",
                      sortable: true,
                      filterable: true,
                    },
                    {
                      Header: "Capacidade",
                      accessor: "qtd",
                      sortable: true,
                      filterable: true,
                    },
                    {
                      Header: "Alerta",
                      accessor: "alert",
                      sortable: true,
                      filterable: true,
                    },
                    {
                      Header: "Ações",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                  pageSize={this.state.originalDataRelationship.length}
                  showPaginationTop={false}
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  previousText="Anterior"
                  nextText="Próxima"
                  loadingText="Carregando"
                  noDataText="Nenhum registro encontrado"
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log(state)
//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = (state) => ({
  ShowForm: state.ShowForm,
  planogram: state.planograms.planogram,
  // ShowProducts: state.ShowProducts
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      ShowProducts,
      HideProducts,
      save,
      showNotification,
      // get
    },
    dispatch
  );
const MemberList = withStyles(sweetAlertStyle)(ReactTables);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberList);
