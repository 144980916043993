import React from "react";
import moment from "moment";
import ptbr from "moment/locale/pt-br";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import consts from "../../consts";
import { HideForm, save, showNotification } from "./Actions";

import Datetime from "react-datetime";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInputCep from "components/CustomInput/CustomInputCep.jsx";

// material ui icons
// import MailOutline from "@material-ui/icons/MailOutline";
// import Contacts from "@material-ui/icons/Contacts";
// import Check from "@material-ui/icons/Check";
// import Close from "@material-ui/icons/Close";
// import CategoryIcon from "@material-ui/icons/Category"
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomInputCPF from "components/CustomInput/CustomInputCPF.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import If from "../../common/operators/If";

// style for this view
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";

class ValidationForms extends React.Component {
  constructor(props) {
    super(props);
    // console.log('props: ',props)
    this.state = {
      tokenSent: false,
      pass: "",
      token: "",
    };
    this.registerClick = this.registerClick.bind(this);
    this.loginClick = this.loginClick.bind(this);
    this.typeClick = this.typeClick.bind(this);
    this.rangeClick = this.rangeClick.bind(this);
  }

  componentDidMount() {
    // this.getPlanograms();
  }

  // getPlanograms() {
  //   // console.log(this.state)
  //   const that = this;
  //   axios.get(`${consts.API_URL}/planograms`).then(resp => {
  //     // console.log(resp)
  //     that.setState({
  //       planograms: resp.data.result
  //     });
  //   });
  // }

  handleSimple = (event) => {
    // console.log(event)
    this.setState({ [event.target.name]: event.target.value });
  };

  cancelButtonClick() {
    this.props.HideForm();
  }

  // searchButtonClick() {
  //   // console.log('searchButtonClick(){')
  //   // console.log(this.state)
  //   var values = {
  //     cpf: this.state.registerCPF
  //   };
  //   // console.log(values)
  //   // this.props.getUser(values)
  // }

  sendToken() {
    // console.log(this.props);
    const that = this;
    axios
      .post(
        `${consts.API_URL}/earnings/${this.props.earning.id}/send-token`,
        {}
      )
      .then((resp) => {
        // console.log(resp);
        const configNotification = {
          message: resp.data.message,
          color: "success",
        };
        this.props.showNotification(configNotification);
        that.setState({
          tokenSent: true,
        });
      })
      .catch((e) => {
        console.log(e);
        console.log(e.response);
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors > 0
        ) {
          e.response.data.errors.forEach((error) => {
            // toastr.error('Erro', error))
            const configNotification = {
              message: error,
              color: "danger",
            };
            this.props.showNotification(configNotification);
          });
        } else if (e.response && e.response.data && e.response.data.message) {
          // console.log('e.response.data.message: ', e.response.data.message)
          // toastr.error('Erro', e.response.data.message)

          const configNotification = {
            message: e.response.data.message,
            color: "danger",
          };
          this.props.showNotification(configNotification);
        } else {
          // toastr.error('Erro', 'Erro inesperado.')
          const configNotification = {
            message: "Erro inesperado.",
            color: "danger",
          };
          this.props.showNotification(configNotification);
        }
      });
  }

  saveButtonClick() {
    const that = this;
    let data = { ...this.props.earning };
    data.pass = this.state.pass;
    data.token = this.state.token;

    axios
      .put(`${consts.API_URL}/earnings/${this.props.earning.id}`, data)
      .then((resp) => {
        // console.log(resp);
        const configNotification = {
          message: "Recibo assinado com sucesso",
          color: "success",
        };
        this.props.showNotification(configNotification);
        that.props.HideForm();
      })
      .catch((e) => {
        console.log(e);
        console.log(e.response);
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors > 0
        ) {
          e.response.data.errors.forEach((error) => {
            // toastr.error('Erro', error))
            const configNotification = {
              message: error,
              color: "danger",
            };
            this.props.showNotification(configNotification);
          });
        } else if (e.response && e.response.data && e.response.data.message) {
          // console.log('e.response.data.message: ', e.response.data.message)
          // toastr.error('Erro', e.response.data.message)

          const configNotification = {
            message: e.response.data.message,
            color: "danger",
          };
          this.props.showNotification(configNotification);
        } else {
          // toastr.error('Erro', 'Erro inesperado.')
          const configNotification = {
            message: "Erro inesperado.",
            color: "danger",
          };
          this.props.showNotification(configNotification);
        }
      });

    // this.props.report(data);
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  // function that verifies if two strings are equal
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  // function that verifies if value contains only numbers
  verifyNumber(value) {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }
  // verifies if value is a valid URL
  verifyUrl(value) {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  }
  change(event, stateName, type, stateNameEqualTo, maxValue) {
    // console.log(event)
    // console.log(this.state)
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "checkbox":
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-length":
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "min-value":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-value":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value <= stateNameEqualTo
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "range":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }
  registerClick() {
    if (this.state.registerEmailState === "") {
      this.setState({ registerEmailState: "error" });
    }
    if (this.state.registerPasswordState === "") {
      this.setState({ registerPasswordState: "error" });
    }
    if (this.state.registerConfirmPasswordState === "") {
      this.setState({ registerConfirmPasswordState: "error" });
    }
    if (this.state.registerCheckboxState === "") {
      this.setState({ registerCheckboxState: "error" });
    }
  }
  loginClick() {
    if (this.state.loginEmailState === "") {
      this.setState({ loginEmailState: "error" });
    }
    if (this.state.loginPasswordState === "") {
      this.setState({ loginPasswordState: "error" });
    }
  }
  typeClick() {
    if (this.state.requiredState === "") {
      this.setState({ requiredState: "error" });
    }
    if (this.state.typeEmailState === "") {
      this.setState({ typeEmailState: "error" });
    }
    if (this.state.numberState === "") {
      this.setState({ numberState: "error" });
    }
    if (this.state.urlState === "") {
      this.setState({ urlState: "error" });
    }
    if (this.state.equalToState === "") {
      this.setState({ equalToState: "error" });
    }
  }
  rangeClick() {
    if (this.state.minLengthState === "") {
      this.setState({ minLengthState: "error" });
    }
    if (this.state.maxLengthState === "") {
      this.setState({ maxLengthState: "error" });
    }
    if (this.state.rangeState === "") {
      this.setState({ rangeState: "error" });
    }
    if (this.state.minValueState === "") {
      this.setState({ minValueState: "error" });
    }
    if (this.state.maxValueState === "") {
      this.setState({ maxValueState: "error" });
    }
  }

  search() {
    const cep = this.state.cep
      .split("_")
      .join("")
      .split(".")
      .join("")
      .split("-")
      .join("");
    if (cep.length === 8) {
      // console.log(cep)
      this.setState({
        bairro: "Carregando",
        complemento: "Carregando",
        localidade: "Carregando",
        logradouro: "Carregando",
        uf: "Carregando",
        numero: "Carregando",
      });
      const url = `https://viacep.com.br/ws/${cep}/json/unicode`;
      axios
        .get(url)
        .then((resp) => {
          // console.log(resp)
          this.setState({
            bairro: resp.data.bairro,
            bairroState: resp.data.bairro !== "" ? "success" : "error",
            complemento: resp.data.complemento,
            complementoState:
              resp.data.complemento !== "" ? "success" : "error",
            localidade: resp.data.localidade,
            localidadeState: resp.data.localidade !== "" ? "success" : "error",
            logradouro: resp.data.logradouro,
            logradouroState: resp.data.logradouro !== "" ? "success" : "error",
            uf: resp.data.uf,
            ufState: resp.data.uf !== "" ? "success" : "error",
            numero: resp.data.unidade,
            numeroState: resp.data.unidade !== "" ? "success" : "error",
          });
          // this.setState({loading: false})
          // const me = JSON.parse(localStorage.getItem(UserKey))
          // axios.defaults.headers.common['Authorization'] = me.token

          // const condo = { ...this.state.condo }
          // condo.bairro = resp.data.bairro
          // condo.complemento = resp.data.complemento
          // condo.localidade = resp.data.localidade
          // condo.logradouro = resp.data.logradouro
          // condo.uf = resp.data.uf
          // condo.unidade = resp.data.unidade
          // this.setState({condo})
        })
        .catch((e) => {
          this.setState({
            bairro: "",
            bairroState: "",
            complemento: "",
            complementoState: "",
            localidade: "",
            localidadeState: "",
            logradouro: "",
            logradouroState: "",
            uf: "",
            ufState: "",
            numero: "",
            numeroState: "",
          });
          // this.setState({
          //   bairro: '',
          //   complemento: '',
          //   localidade: '',
          //   logradouro: '',
          //   uf: '',
          //   numero: '',
          // })
          // this.setState({loading: false})
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors > 0
          ) {
            // e.response.data.errors.forEach(
            // error => toastr.error('Erro', error))
          } else if (e.response && e.response.data && e.response.data.message) {
            // toastr.error('Erro', e.response.data.message)
          } else {
            // toastr.error('Erro', 'Erro inesperado.')
          }
        });
    }
  }

  render() {
    const { classes } = this.props;
    // console.log('render(): ', this.props)

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Aceite eletrônico do recibo
              </h4>
            </CardHeader>
            <CardBody>
              <form>
                <br />
                {this.state.tokenSent ? (
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        success={this.state.passState === "success"}
                        error={this.state.passState === "error"}
                        labelText={<span>Senha</span>}
                        id="pass"
                        value={this.state.pass}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) =>
                            this.change(event, "pass", "length", 1),
                          value: this.state.pass,
                          type: "password",
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        success={this.state.tokenState === "success"}
                        error={this.state.tokenState === "error"}
                        labelText={<span>Token</span>}
                        id="token"
                        value={this.state.token}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) =>
                            this.change(event, "token", "length", 1),
                          value: this.state.token,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                ) : null}
              </form>
            </CardBody>
            <CardFooter>
              <Button onClick={() => this.cancelButtonClick()}>Cancelar</Button>
              {this.state.tokenSent ? (
                <Button
                  color="primary"
                  className={classes.updateProfileButton}
                  onClick={() => this.saveButtonClick()}
                >
                  Assinar eletronicamente
                </Button>
              ) : null}

              <Button color="primary" onClick={() => this.sendToken()}>
                {this.state.reportUrl ? "Reenviar TOKEN" : "Enviar TOKEN"}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('mapStateToProps: ', state)
  return {
    HideForm: state.earnings.HideForm,
    earning: state.earnings.earning,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      HideForm,
      save,
      showNotification,
    },
    dispatch
  );
const ReturnClassDecorated = withStyles(validationFormsStyle)(ValidationForms);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnClassDecorated);
