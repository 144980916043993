import React from "react";

import moment from "moment";
import "moment/locale/pt-br";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import consts from "../../consts";
import socketIOClient from "socket.io-client";
import { HideForm, save, showNotification } from "./Actions";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInputCep from "components/CustomInput/CustomInputCep.jsx";

import PictureUpload from "components/CustomUpload/PictureUpload.jsx";

import Loader from "components/Loader/Loader.jsx";

// material ui icons
// import MailOutline from "@material-ui/icons/MailOutline";
// import Contacts from "@material-ui/icons/Contacts";
// import Check from "@material-ui/icons/Check";
// import Close from "@material-ui/icons/Close";
// import CategoryIcon from "@material-ui/icons/Category"
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomInputCPF from "components/CustomInput/CustomInputCPF.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Table from "components/Table/Table.jsx";

import If from "../../common/operators/If";

// style for this view
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";

const styles = {
  ...validationFormsStyle,
  ...sweetAlertStyle,
};

class ValidationForms extends React.Component {
  constructor(props) {
    super(props);
    // console.log({ props });
    // console.log("props.product_return: ", props.product_return);
    this.me = JSON.parse(localStorage.getItem(consts.UserKey)).user;
    // console.log("me: ", this.me);
    this.state = {
      loading: false,
      action: "",
      alert: null,
      report: props.report ? props.report : null,
      driver: props.report && props.report.driver ? props.report.driver : null,
      sapLogs: [],
      data: null,
      fridges: [],
      products: [],
    };
  }

  componentDidMount() {
    this.socketConnect();
    this.getData();
  }

  cancelButtonClick() {
    this.props.HideForm();
  }

  socketConnect() {
    const that = this;

    const socket = socketIOClient(consts.SOCKET_URL, {
      query: { app: "admin", user: that.me.id },
    });

    socket.on("ReportFinishedCreated", (resp) => {
      // console.log("resp: ", resp);
      const { message, status } = resp;
      const configNotification = {
        message,
        color: status === 200 ? "success" : "danger",
      };
      that.props.showNotification(configNotification);
      that.getData();
    });
  }

  getData() {
    const that = this;

    that.setState({
      loading: true,
    });

    axios
      .get(`${consts.API_URL}/product-return-grouped/${this.props.report.id}`)
      .then((resp) => {
        // console.log({ resp });
        const { data } = resp;
        const products = data.productReturnGroupedItems;
        const fridges = data.productReturns;
        this.setState({
          loading: false,
          data: data.productReturnGrouped,
          products,
          fridges,
        });
      })
      .catch((err) => {
        const message =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Erro ao conectar.";
        let configNotification = {
          message,
          color: "danger",
        };
        that.props.showNotification(configNotification);
        that.setState({
          loading: false,
        });
      });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  add(item) {
    // console.log({ item });
    let products = this.state.products.map((product) => {
      let toReturn = Object.assign({}, product);
      if (product.id === item.id) {
        if (toReturn.qtd_have === null) {
          toReturn.qtd_have = toReturn.qtd_must_have;
        }
        const qtd = toReturn.qtd_have + 1;
        toReturn.qtd_have = qtd;
        axios.put(`${consts.API_URL}/product-return-grouped/item/${item.id}`, {
          qtd,
        });
      }
      return toReturn;
    });
    this.setState({ products });
  }

  remove(item) {
    // console.log({ item });
    let products = this.state.products.map((product) => {
      let toReturn = Object.assign({}, product);
      if (product.id === item.id) {
        if (toReturn.qtd_have === null) {
          toReturn.qtd_have = toReturn.qtd_must_have;
        }
        const qtd = toReturn.qtd_have > 0 ? toReturn.qtd_have - 1 : 0;
        toReturn.qtd_have = qtd;
        axios.put(`${consts.API_URL}/product-return-grouped/item/${item.id}`, {
          qtd,
        });
      }
      return toReturn;
    });
    this.setState({ products });
  }

  start() {
    const that = this;
    that.setState({
      loading: true,
    });
    axios
      .put(`${consts.API_URL}/product-return-grouped/${this.state.data.id}/start`, {})
      .then((resp) => that.getData())
      .catch((err) => {
        const message =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Erro ao conectar.";
        let configNotification = {
          message,
          color: "danger",
        };
        that.props.showNotification(configNotification);
        that.setState({
          loading: false,
        });
      });
  }

  finish() {
    const that = this;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Tem certeza?"
          onConfirm={() => {
            // console.log(item)
            that.setState({
              loading: true,
            });
            axios
              .put(`${consts.API_URL}/product-return-grouped/${this.state.data.id}/finish`, {})
              .then((resp) => that.getData())
              .catch((err) => {
                const message =
                  err.response && err.response.data && err.response.data.message
                    ? err.response.data.message
                    : "Erro ao conectar.";
                let configNotification = {
                  message,
                  color: "danger",
                };
                that.props.showNotification(configNotification);
                that.setState({
                  loading: false,
                });
              });
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
          cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
          confirmBtnText="Sim, finalizar!"
          cancelBtnText="Desistir"
          showCancel
        >
          Não tem como voltar ao status atual após finalizar!
        </SweetAlert>
      ),
    });
  }

  createReport() {
    const that = this;
    that.setState({
      loading: true,
    });
    axios
      .post(`${consts.API_URL}/product-return-grouped/${this.state.data.id}/report`, {})
      .then((resp) => {
        const { message } = resp.data;
        let configNotification = {
          message,
          color: "info",
        };
        that.props.showNotification(configNotification);
        that.setState({
          loading: false,
        });
        // that.getData();
      })
      .catch((err) => {
        const message =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Erro ao conectar.";
        let configNotification = {
          message,
          color: "danger",
        };
        that.props.showNotification(configNotification);

        that.setState({
          loading: false,
        });
      });
  }

  renderHeader() {
    const { classes } = this.props;
    return (
      <Card>
        <CardFooter>
          <Button onClick={() => this.cancelButtonClick()}>Voltar</Button>
          {this.state.data &&
            this.state.data.started === false &&
            this.state.data.finished === false && (
              <div>
                <Button color="success" onClick={() => this.start()}>
                  Iniciar conferência
                </Button>
              </div>
            )}

          {this.state.data &&
            this.state.data.started === true && (
              <div>
                {!this.state.data.Report && (
                  <Button color="info" onClick={() => this.createReport()}>
                    Gerar relatório para impressão
                  </Button>
                )}
                {this.state.data.Report &&
                  this.state.data.Report.id && (
                    <>
                      <Button onClick={() => this.createReport()}>
                        Atualizar relatório para impressão
                      </Button>
                      <a
                        href={this.state.data.Report.link}
                        target="_blank"
                        className={classes.actionButton}
                      >
                        <Button color="info" onClick={() => {}}>
                          Baixar relatório
                        </Button>
                      </a>
                    </>
                  )}
                {this.state.data.finished === false && (
                  <Button color="primary" onClick={() => this.finish()}>
                    Finalizar
                  </Button>
                )}
              </div>
            )}
        </CardFooter>
      </Card>
    );
  }

  renderDetails() {
    if (!this.state.data || !this.state.data.id) {
      return;
    }
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>Relatório #{this.state.data.id}</h4>
        </CardHeader>
        <CardBody>
          <p>
            <strong>Motorista:</strong> {this.state.driver.name}
          </p>
          {this.state.data.creator &&
            this.state.data.creator.name && (
              <p>
                <strong>Iniciado por:</strong> {this.state.data.creator.name} em{" "}
                {this.state.data.started_at ? moment(this.state.data.started_at).format("LLL") : ""}
              </p>
            )}
          {this.state.data.finisher &&
            this.state.data.finisher.name && (
              <p>
                <strong>Finalizado por:</strong> {this.state.data.finisher.name} em{" "}
                {this.state.data.finished_at
                  ? moment(this.state.data.finished_at).format("LLL")
                  : ""}
              </p>
            )}
        </CardBody>
      </Card>
    );
  }

  renderFridges() {
    if (!this.state.fridges || this.state.fridges.length == 0) {
      return;
    }
    const { classes } = this.props;

    const tableData = this.state.fridges.map((fridge) => {
      return [
        fridge.Fridge.gebra,
        fridge.Fridge.local,
        moment(fridge.created_at).format("DD/MM/YYYY HH:mm:ss"),
      ];
    });

    // // tableData.push({ total: true, amount, colspan: 3 });
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>GELADEIRAS COM RETORNO DE PRODUTOS</h4>
        </CardHeader>
        <CardBody>
          <Table
            tableHeaderColor="primary"
            tableHead={["Gebra", "Condomínio", "Criado em"]}
            tableData={tableData}
            coloredColls={[4]}
            colorsColls={["primary"]}
          />
        </CardBody>
      </Card>
    );
  }

  renderProducts() {
    if (!this.state.products || this.state.products.length == 0) {
      return null;
    }
    const { classes } = this.props;
    const that = this;
    // console.log(classes);
    const tableData = this.state.products.map((product) => {
      const simpleButtons = [
        {
          color: "danger",
          icon: Remove,
          func: () => {
            that.remove(product);
            // console.log(product);
          },
        },
        {
          color: "success",
          icon: Add,
          func: () => {
            that.add(product);
            // console.log(product);
            // alert("add");
          },
        },
      ].map((prop, key) => {
        return (
          <Button
            color={prop.color}
            simple
            className={classes.actionButton}
            key={key}
            onClick={prop.func}
          >
            <prop.icon className={classes.icon} />
          </Button>
        );
      });
      if (this.state.data.started === true && this.state.data.finished === false) {
        return [
          product.Product.name,
          product.Product.sku,
          product.qtd_must_have,
          product.qtd_have !== null ? product.qtd_have : product.qtd_must_have,
          simpleButtons,
        ];
      } else {
        return [
          product.Product.name,
          product.Product.sku,
          product.qtd_must_have,
          this.state.data.started === true
            ? product.qtd_have !== null
              ? product.qtd_have
              : product.qtd_must_have
            : "",
        ];
      }
    });
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>LISTA DE PRODUTOS RETORNADOS PARA BRASAL</h4>
        </CardHeader>
        <CardBody>
          {this.state.data.started === true && this.state.data.finished === false ? (
            <Table
              tableHeaderColor="primary"
              tableHead={["Produto", "SKU", "Qtd Motorista", "Qtd Conferida", "Ações"]}
              tableData={tableData}
              customCellClasses={[
                classes.center,
                classes.right,
                classes.right,
                classes.right,
                classes.right,
              ]}
              coloredColls={[2]}
              colorsColls={["info"]}
            />
          ) : (
            <Table
              tableHeaderColor="primary"
              tableHead={["Produto", "SKU", "Qtd Motorista", "Qtd Conferida"]}
              tableData={tableData}
              customCellClasses={[classes.center, classes.right, classes.right, classes.right]}
              coloredColls={[2]}
              colorsColls={["info"]}
            />
          )}
        </CardBody>
      </Card>
    );
  }

  render() {
    const { classes } = this.props;
    // console.log('render(): ', this.props)
    return (
      <div>
        {this.state.alert}

        <GridContainer justify="center">
          {this.state.loading ? <Loader /> : null}
          <GridItem xs={12} sm={12} md={12}>
            {this.renderHeader()}
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {this.renderDetails()}
            {this.renderFridges()}
            {this.renderProducts()}
          </GridItem>
          {/* <GridItem xs={12} sm={12} md={4}>
          {this.renderActions()}
          {this.renderNotes()}
        </GridItem> */}
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('mapStateToProps: ', state)
  return {
    HideForm: state.product_returns_by_driver.HideForm,
    report: state.product_returns_by_driver.report,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      HideForm,
      save,
      showNotification,
    },
    dispatch
  );
const ReturnClassDecorated = withStyles(styles)(ValidationForms);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnClassDecorated);
