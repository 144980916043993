import React from "react";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import consts from "../../consts";
import { HideForm, save, showNotification } from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInputCep from "components/CustomInput/CustomInputCep.jsx";

import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";

// material ui icons
// import MailOutline from "@material-ui/icons/MailOutline";
// import Contacts from "@material-ui/icons/Contacts";
// import Check from "@material-ui/icons/Check";
// import Close from "@material-ui/icons/Close";
// import CategoryIcon from "@material-ui/icons/Category"
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomInputCPF from "components/CustomInput/CustomInputCPF.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import If from "../../common/operators/If";

// style for this view
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";

class ValidationForms extends React.Component {
  constructor(props) {
    super(props);
    // console.log("props: ", props);
    this.state = {
      id: props.equipment ? props.equipment.id : "",
      equipment_id: props.equipment ? props.equipment.equipment_id : "",
      status_id: props.equipment ? props.equipment.status_id : "",
      serial_number: props.equipment ? props.equipment.serial_number : "",

      statuses: [],
    };
    this.registerClick = this.registerClick.bind(this);
    this.loginClick = this.loginClick.bind(this);
    this.typeClick = this.typeClick.bind(this);
    this.rangeClick = this.rangeClick.bind(this);
  }

  componentDidMount() {
    console.log("componentDidMount");
    // const configNotification = {
    //   message: "Erro inesperado.",
    //   color: "danger",
    // };
    // this.props.showNotification(configNotification);
    this.getStatuses();
  }

  getStatuses() {
    axios
      .get(`${consts.API_URL}/equipment-statuses/select`)
      .then((res) => {
        // console.log(res)
        // console.log(state.sorted)
        // console.log(state.filtered)
        // Update react-table
        this.setState({
          statuses: res.data.result,
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        console.log(e.response);
        if (e.response && e.response.data && e.response.data.errors && e.response.data.errors > 0) {
          e.response.data.errors.forEach((error) => {
            // toastr.error('Erro', error))
            const configNotification = {
              message: error,
              color: "danger",
            };
            this.props.showNotification(configNotification);
          });
        } else if (e.response && e.response.data && e.response.data.message) {
          // console.log('e.response.data.message: ', e.response.data.message)
          // toastr.error('Erro', e.response.data.message)

          const configNotification = {
            message: e.response.data.message,
            color: "danger",
          };
          this.props.showNotification(configNotification);
        } else {
          // toastr.error('Erro', 'Erro inesperado.')
          const configNotification = {
            message: "Erro inesperado.",
            color: "danger",
          };
          this.props.showNotification(configNotification);
        }
      });
  }

  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  cancelButtonClick() {
    this.props.HideForm();
  }

  saveButtonClick() {
    const { equipment_id, serial_number } = this.state;
    console.log({
      equipment_id,
      serial_number,
    });
    if (!serial_number || serial_number === "") {
      const configNotification = {
        message: "Favor preencher o número de série",
        color: "danger",
      };
      this.props.showNotification(configNotification);
      return;
    }
    if (!equipment_id || equipment_id === "") {
      const configNotification = {
        message: "Favor preencher a descrição do produto",
        color: "danger",
      };
      this.props.showNotification(configNotification);
      return;
    }
    // console.log(this.props.product)
    // var product = { ...this.props.product };

    // product.name = this.state.name;
    // product.sku = this.state.sku;
    // product.image = this.state.image;

    this.props.save(this.state);
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  // function that verifies if two strings are equal
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  // function that verifies if value contains only numbers
  verifyNumber(value) {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }
  // verifies if value is a valid URL
  verifyUrl(value) {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  }
  change(event, stateName, type, stateNameEqualTo, maxValue) {
    // console.log(event)
    // console.log(this.state)
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "checkbox":
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-length":
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "min-value":
        if (this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-value":
        if (this.verifyNumber(event.target.value) && event.target.value <= stateNameEqualTo) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "range":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }
  registerClick() {
    if (this.state.registerEmailState === "") {
      this.setState({ registerEmailState: "error" });
    }
    if (this.state.registerPasswordState === "") {
      this.setState({ registerPasswordState: "error" });
    }
    if (this.state.registerConfirmPasswordState === "") {
      this.setState({ registerConfirmPasswordState: "error" });
    }
    if (this.state.registerCheckboxState === "") {
      this.setState({ registerCheckboxState: "error" });
    }
  }
  loginClick() {
    if (this.state.loginEmailState === "") {
      this.setState({ loginEmailState: "error" });
    }
    if (this.state.loginPasswordState === "") {
      this.setState({ loginPasswordState: "error" });
    }
  }
  typeClick() {
    if (this.state.requiredState === "") {
      this.setState({ requiredState: "error" });
    }
    if (this.state.typeEmailState === "") {
      this.setState({ typeEmailState: "error" });
    }
    if (this.state.numberState === "") {
      this.setState({ numberState: "error" });
    }
    if (this.state.urlState === "") {
      this.setState({ urlState: "error" });
    }
    if (this.state.equalToState === "") {
      this.setState({ equalToState: "error" });
    }
  }
  rangeClick() {
    if (this.state.minLengthState === "") {
      this.setState({ minLengthState: "error" });
    }
    if (this.state.maxLengthState === "") {
      this.setState({ maxLengthState: "error" });
    }
    if (this.state.rangeState === "") {
      this.setState({ rangeState: "error" });
    }
    if (this.state.minValueState === "") {
      this.setState({ minValueState: "error" });
    }
    if (this.state.maxValueState === "") {
      this.setState({ maxValueState: "error" });
    }
  }

  // getPicture(image){
  //   // console.log(image)
  //   if(image !== ''){
  //     this.setState({
  //       image,
  //       imageState: 'success'
  //     })
  //   }else{
  //     this.setState({
  //       image,
  //       imageState: 'error'
  //     })
  //   }
  //
  // }

  getPicture(image) {
    // console.log("getPicture(image): ", image);
    this.setState({ image });
  }

  render() {
    const { classes } = this.props;
    // console.log('render(): ', this.props)
    // console.log("render(): ", this.state);
    // console.log(this.state.statuses);

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.equipmentdIconTitle}>Cadastrar novo produto</h4>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={12}>
                    <GridItem xs={12}>
                      <CustomInput
                        success={this.state.equipment_idState === "success"}
                        error={this.state.equipment_idState === "error"}
                        labelText={<span>Número de série</span>}
                        id="serial_number"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) => this.change(event, "serial_number", "length", 3),
                          value: this.state.serial_number,
                          type: "text",
                          placeholder: "Número de série",
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        success={this.state.equipment_idState === "success"}
                        error={this.state.equipment_idState === "error"}
                        labelText={<span>Descrição (Número lógico)</span>}
                        id="equipment_id"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) => this.change(event, "equipment_id", "length", 3),
                          value: this.state.equipment_id,
                          type: "text",
                          placeholder: "Descrição",
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <FormControl fullWidth className={classes.selectFormControl}>
                        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                          Status
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.status_id}
                          onChange={(event) => this.setState({ status_id: event.target.value })}
                          inputProps={{
                            name: "status_id",
                            id: "simple-select",
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                          >
                            Selecione
                          </MenuItem>
                          {this.state.statuses.map((status) => {
                            return (
                              <MenuItem
                                key={status.id}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={status.id}
                              >
                                {status.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
            <CardFooter>
              <Button onClick={() => this.cancelButtonClick()}>Cancelar</Button>
              <Button color="primary" onClick={() => this.saveButtonClick()}>
                Salvar
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log("mapStateToProps: ", state);
  return {
    HideForm: state.equipments.HideForm,
    equipment: state.equipments.equipment,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      HideForm,
      save,
      showNotification,
    },
    dispatch
  );
const ReturnClassDecorated = withStyles(validationFormsStyle)(ValidationForms);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnClassDecorated);
