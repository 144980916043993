import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import consts from "../../consts";
// react plugin for creating charts
import ChartistGraph from "react-chartist";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Icon from "@material-ui/core/Icon";

import Warning from "@material-ui/icons/Warning";
import Store from "@material-ui/icons/Store";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import People from "@material-ui/icons/People";
import Map from "@material-ui/icons/Map";
import PinDrop from "@material-ui/icons/PinDrop";
import SentimentSatisfied from "@material-ui/icons/SentimentSatisfied";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ExposureNeg1Icon from "@material-ui/icons/ExposureNeg1";
import PlusOneIcon from "@material-ui/icons/PlusOne";

// core components
import Heading from "components/Heading/Heading.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import Danger from "components/Typography/Danger.jsx";

import {
  totalRegistersByDate,
  usuariosQMaisTwitam,
  localizacao,
  sentimentos,
  roundedLineChart,
  straightLinesChart,
  simpleBarChart,
  colouredLineChart,
  multipleBarsChart,
  colouredLinesChart,
  pieChart,
} from "./charts0.jsx";

import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";

class Charts extends React.Component {
  constructor(props) {
    super(props);
    const me = JSON.parse(localStorage.getItem(consts.UserKey)).user;
    this.state = {
      me,
      totalAcumulado: 0,
      totalRepasse: 0,
      totalPerdas: 0,
      totalInversoes: 0,
      acumuladoGraph: { labels: [], series: [] },
    };
    this.getAll();
  }

  getAll() {
    const me = JSON.parse(localStorage.getItem(consts.UserKey)).user;
    switch (me.profile) {
      case "syndic":
        this.getSyndic();
        break;
      case "manager":
        this.getManager();
        break;
    }
    // this.getTotalPdvs();
    // this.getTotalPdvsAtivos();
    // this.getTotalRegistersByDate();
    // this.getTotalUsersTransfers();
    // this.getTotalPdvsTransfers();

    // this.getTotalReweeteds()
    // this.getTotalUsersTalkingAbout()
    // this.getLocations()
  }

  getSyndic() {
    axios.get(`${consts.API_URL}/dashboards/syndic`).then((resp) => {
      // console.log(resp.data);
      const totalAcumulado = resp.data.acumuladoFormated;
      const totalRepasse = resp.data.repasseFormated;
      const totalPerdas = resp.data.perdasFormated;
      const totalInversoes = resp.data.inversoesFormated;
      const labels = resp.data.sales.map((data) => {
        return data.day < 10 ? `0${data.day}` : data.day;
      });
      const series = [
        resp.data.sales.map((data) => {
          return data.total;
        }),
      ];
      const acumuladoGraph = { labels, series };
      this.setState({
        totalAcumulado,
        totalRepasse,
        totalPerdas,
        totalInversoes,
        acumuladoGraph,
      });
    });
  }

  getManager() {
    axios.get(`${consts.API_URL}/dashboards/manager`).then((resp) => {
      // console.log(resp.data);
      const totalAcumulado = resp.data.acumuladoFormated;
      const totalRepasse = resp.data.repasseFormated;
      const totalPerdas = resp.data.perdasFormated;
      const totalInversoes = resp.data.inversoesFormated;
      const labels = resp.data.sales.map((data) => {
        return data.day < 10 ? `0${data.day}` : data.day;
      });
      const series = [
        resp.data.sales.map((data) => {
          return data.total;
        }),
      ];
      const acumuladoGraph = { labels, series };
      this.setState({
        totalAcumulado,
        totalRepasse,
        totalPerdas,
        totalInversoes,
        acumuladoGraph,
      });
    });
  }

  // getTotalPdvs() {
  //   axios.get(`${consts.API_URL}/dashboard/total-pdvs`).then((resp) => {
  //     const totalPdvs = resp.data;
  //     this.setState({ totalPdvs });
  //   });
  // }

  // getTotalPdvsAtivos() {
  //   axios.get(`${consts.API_URL}/dashboard/total-pdvs-ativos`).then((resp) => {
  //     const totalPdvsAtivos = resp.data;
  //     this.setState({ totalPdvsAtivos });
  //   });
  // }

  // getTotalRegistersByDate() {
  //   axios
  //     .get(`${consts.API_URL}/dashboard/total-registers-by-date`)
  //     .then((resp) => {
  //       const labels = resp.data.map((data) => {
  //         return data.date;
  //       });
  //       const series = [
  //         resp.data.map((data) => {
  //           return data.total;
  //         }),
  //       ];
  //       const totalRegistersByDate = { labels, series };
  //       this.setState({ totalRegistersByDate });
  //     });
  // }

  // getTotalUsersTransfers() {
  //   axios
  //     .get(`${consts.API_URL}/dashboard/total-customers-transfers`)
  //     .then((resp) => {
  //       // console.log(resp)
  //       const labels = resp.data.map((data) => {
  //         return data.user_screen_name;
  //       });
  //       const series = [
  //         resp.data.map((data) => {
  //           return data.total;
  //         }),
  //       ];
  //       const totalUsersTransfers = { labels, series };
  //       this.setState({ totalUsersTransfers });
  //     });
  // }

  // getTotalPdvsTransfers() {
  //   axios
  //     .get(`${consts.API_URL}/dashboard/total-pdvs-transfers`)
  //     .then((resp) => {
  //       console.log(resp);
  //       const labels = resp.data.map((data) => {
  //         return data.user_screen_name;
  //       });
  //       const series = [
  //         resp.data.map((data) => {
  //           return data.total;
  //         }),
  //       ];
  //       const totalPdvsTransfers = { labels, series };
  //       this.setState({ totalPdvsTransfers });
  //     });
  // }

  // getTotalReweeteds(){
  //   axios.get(`${consts.API_URL}/dashboard/total-retweeteds`)
  //   .then(resp => {
  //       const totalReweeteds = resp.data
  //       this.setState({ totalReweeteds })
  //   })
  // }
  //
  // getTotalUsersTalkingAbout(){
  //   axios.get(`${consts.API_URL}/dashboard/total-users-talking-about`)
  //   .then(resp => {
  //       const totalUsersTalkingAbout = resp.data
  //       this.setState({ totalUsersTalkingAbout })
  //   })
  // }
  //
  // getLocations(){
  //   axios.get(`${consts.API_URL}/dashboard/locations`)
  //   .then(resp => {
  //       const labels = resp.data.map(data => { return `${data.place_full_name}` })
  //       const series = resp.data.map(data => { return data.total })
  //       const locations = { labels, series }
  //       // console.log(locations)
  //       this.setState({ locations })
  //   })
  // }

  renderSyndic() {
    const { classes } = this.props;
    // console.log(this.state.acumuladoGraph);
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="primary" stats icon>
                <CardIcon color="primary">
                  <AccountBalanceIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Acumulado</p>
                <h4 className={classes.cardTitle}>
                  {this.state.totalAcumulado}
                </h4>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <AttachMoneyIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Repasse</p>
                <h4 className={classes.cardTitle}>{this.state.totalRepasse}</h4>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <ExposureNeg1Icon />
                </CardIcon>
                <p className={classes.cardCategory}>Perdas</p>
                <h4 className={classes.cardTitle}>{this.state.totalPerdas}</h4>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <PlusOneIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Inversões</p>
                <h4 className={classes.cardTitle}>
                  {this.state.totalInversoes}
                </h4>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <DateRange />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Total de vendas por dia
                </h4>
              </CardHeader>
              <CardBody>
                <ChartistGraph
                  data={this.state.acumuladoGraph}
                  type="Bar"
                  options={simpleBarChart.options}
                  responsiveOptions={simpleBarChart.responsiveOptions}
                  listener={simpleBarChart.animation}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  renderManager() {
    const { classes } = this.props;
    // console.log(this.state.acumuladoGraph);
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="primary" stats icon>
                <CardIcon color="primary">
                  <AccountBalanceIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Acumulado</p>
                <h4 className={classes.cardTitle}>
                  {this.state.totalAcumulado}
                </h4>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <AttachMoneyIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Repasse</p>
                <h4 className={classes.cardTitle}>{this.state.totalRepasse}</h4>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <ExposureNeg1Icon />
                </CardIcon>
                <p className={classes.cardCategory}>Perdas</p>
                <h4 className={classes.cardTitle}>{this.state.totalPerdas}</h4>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <PlusOneIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Inversões</p>
                <h4 className={classes.cardTitle}>
                  {this.state.totalInversoes}
                </h4>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <DateRange />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Total de vendas por dia
                </h4>
              </CardHeader>
              <CardBody>
                <ChartistGraph
                  data={this.state.acumuladoGraph}
                  type="Bar"
                  options={simpleBarChart.options}
                  responsiveOptions={simpleBarChart.responsiveOptions}
                  listener={simpleBarChart.animation}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  render() {
    switch (this.state.me.profile) {
      case "syndic":
        return this.renderSyndic();
      case "manager":
        return this.renderManager();
      case "marketing":
        return <Redirect to="/orders" />;

      default:
        return null;
    }
  }
}

export default withStyles(chartsStyle)(Charts);
