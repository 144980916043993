const INITIAL_OBJ = {};
const INITIAL_STATE = {
  showFormReport: false,
  route: INITIAL_OBJ,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(INITIAL_OBJ)
  // console.log(state);
  // console.log(action);
  switch (action.type) {
    case "AUDITS_SHOW_FORM_REPORT":
      return {
        ...state,
        showFormReport: true,
      };
    case "AUDITS_HIDE_FORM_REPORT":
      return {
        ...state,
        showFormReport: false,
      };
    default:
      return state;
  }
};
