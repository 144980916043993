import React, { useState, useEffect } from "react";
import axios from "axios";
import consts from "../../../consts";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/pt-br";

// core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "components/Table/Table.jsx";

import Loader from "components/Loader/Loader.jsx";

import AddAlert from "@material-ui/icons/AddAlert";
import Close from "@material-ui/icons/Close";

import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";

let timeout = null;

function EquipmentTransactions(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const [loading, set_loading] = useState(true);
  const [equipment, set_equipment] = useState(null);
  const [tr, setTR] = useState(false);
  const [message, setMessage] = useState("teste");
  const [color, setColor] = useState("success");
  const [technicians, set_technicians] = useState([]);
  const [technician_id, set_technician_id] = useState(null);

  useEffect(() => {
    getTechnician();
  }, []);

  useEffect(
    () => {
      if (props.match.params.equipment_id) {
        getData();
      }
    },
    [props]
  );

  const showNotification = (message, color) => {
    clearTimeout(timeout);
    setTR(true);
    setMessage(message);
    setColor(color);
    timeout = setTimeout(() => {
      setTR(false);
    }, 6000);
  };

  function getData() {
    set_loading(true);
    axios
      .get(`${consts.API_URL}/equipments/${props.match.params.equipment_id}`)
      .then((resp) => {
        set_equipment(resp.data);
        set_loading(false);
      })
      .catch((error) => {
        set_loading(false);
        showNotification("Erro ao carregar dados", "danger");
        console.log({ error });
      });
  }

  function getTechnician() {
    set_loading(true);
    axios
      .get(`${consts.API_URL}/users/maintenanceSelect`)
      .then((resp) => {
        set_technicians(resp.data.result);
        set_loading(false);
      })
      .catch((error) => {
        set_loading(false);
        showNotification("Erro ao carregar técnicos", "danger");
        console.log({ error });
      });
  }

  function changeResponsible() {
    set_loading(true);
    axios
      .put(`${consts.API_URL}/equipments/${props.match.params.equipment_id}/change-responsible`, {
        responsible_id: technician_id,
      })
      .then((resp) => {
        showNotification("Equipamento atribuído com sucesso", "success");
        set_loading(false);
        set_technician_id(null);
        getData();
      })
      .catch((error) => {
        set_loading(false);
        showNotification("Erro ao carregar técnicos", "danger");
        console.log({ error });
      });
  }

  function releaseToInstall() {
    set_loading(true);
    axios
      .put(`${consts.API_URL}/equipments/${props.match.params.equipment_id}`, {
        available_to_install: true,
      })
      .then((resp) => {
        showNotification("Equipamento liberado para instalação com sucesso", "success");
        set_loading(false);
        set_technician_id(null);
        getData();
      })
      .catch((error) => {
        set_loading(false);
        showNotification("Erro ao liberar equipamento para instalação", "danger");
        console.log({ error });
      });
  }

  function deactivate() {
    set_loading(true);
    axios
      .put(`${consts.API_URL}/equipments/${props.match.params.equipment_id}`, {
        available_to_install: false,
        deactivated: true,
      })
      .then((resp) => {
        showNotification("Equipamento desativado com sucesso", "success");
        set_loading(false);
        set_technician_id(null);
        getData();
      })
      .catch((error) => {
        set_loading(false);
        showNotification("Erro ao desativar equipamento", "danger");
        console.log({ error });
      });
  }

  function reactivate() {
    set_loading(true);
    axios
      .put(`${consts.API_URL}/equipments/${props.match.params.equipment_id}`, {
        available_to_install: false,
        deactivated: false,
      })
      .then((resp) => {
        showNotification("Equipamento reativado com sucesso", "success");
        set_loading(false);
        set_technician_id(null);
        getData();
      })
      .catch((error) => {
        set_loading(false);
        showNotification("Erro ao reativar equipamento", "danger");
        console.log({ error });
      });
  }

  function renderHeader() {
    return (
      <Card>
        <CardHeader color="warning" icon>
          <Link to={`/equipments`}>
            <Button color="primary" className={classes.updateProfileButton} onClick={() => {}}>
              Voltar
            </Button>
          </Link>
        </CardHeader>
      </Card>
    );
  }

  function renderDescription() {
    return (
      equipment && (
        <Card>
          <CardHeader>
            <h4 className={classes.cardIconTitle}>Id do equipamento: {equipment.equipment_id}</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              {equipment.EquipmentFridge &&
                equipment.EquipmentFridge.Fridge &&
                equipment.EquipmentFridge.Fridge.local && (
                  <GridItem xs={12} sm={6} md={6}>
                    <strong>Instalado em:</strong>
                    <br />
                    {equipment.EquipmentFridge.Fridge.gebra} -{" "}
                    {equipment.EquipmentFridge.Fridge.local}
                  </GridItem>
                )}
              {equipment.User &&
                equipment.User.name && (
                  <GridItem xs={12} sm={6} md={4}>
                    <strong>Responsável:</strong>
                    <br />
                    {equipment.User.name}
                  </GridItem>
                )}

              <GridItem xs={12} sm={6} md={4}>
                <strong>Disponível para instalação:</strong>
                <br />
                {equipment.available_to_install === true ? "Sim" : "Não"}
              </GridItem>

              {!equipment.EquipmentFridge &&
                equipment.EquipmentStatus &&
                equipment.EquipmentStatus.description &&
                !equipment.available_to_install && (
                  <GridItem xs={12} sm={6} md={4}>
                    <strong>Status:</strong>
                    <br />
                    {equipment.EquipmentStatus.description}
                  </GridItem>
                )}
            </GridContainer>
          </CardBody>
        </Card>
      )
    );
  }

  function renderEquipmentRecord(equipmentRecord) {
    const date = moment(equipmentRecord.createdAt);
    const formatedDate = date.isValid() ? `${date.format("LLL")}` : "";
    let statusText = "";
    if (equipmentRecord.installation === true) {
      statusText = "Instalação";
    } else if (equipmentRecord.removal === true) {
      statusText = "Desinstalação";
    } else if (!equipmentRecord.from && !equipmentRecord.to) {
      statusText = equipmentRecord.description;
    } else {
      statusText = "Transferência";
    }

    return (
      equipmentRecord && (
        <Card>
          <CardHeader>
            <h4 className={classes.cardIconTitle}>{formatedDate}</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              {equipmentRecord.user &&
                equipmentRecord.user.name && (
                  <GridItem xs={12} sm={6} md={6}>
                    <p>
                      <strong>Responsável pela movimentação:</strong>
                      <br />
                      {equipmentRecord.user.name}
                    </p>
                  </GridItem>
                )}
              <GridItem xs={12} sm={6} md={6}>
                <p>
                  <strong>Movimentação:</strong>
                  <br />
                  {statusText}
                </p>
              </GridItem>
              {equipmentRecord.from &&
                equipmentRecord.from.name && (
                  <GridItem xs={12} sm={6} md={6}>
                    <p>
                      <strong>De:</strong>
                      <br />
                      {equipmentRecord.from.name}
                    </p>
                  </GridItem>
                )}
              {equipmentRecord.to &&
                equipmentRecord.to.name && (
                  <GridItem xs={12} sm={6} md={6}>
                    <p>
                      <strong>Para:</strong>
                      <br />
                      {equipmentRecord.to.name}
                    </p>
                  </GridItem>
                )}
              {equipmentRecord.Fridge &&
                equipmentRecord.Fridge.local && (
                  <GridItem xs={12} sm={6} md={6}>
                    <p>
                      <strong>Instalado em:</strong>
                      <br />
                      {equipmentRecord.Fridge.gebra} - {equipmentRecord.Fridge.local}
                    </p>
                  </GridItem>
                )}
              {equipmentRecord.removal === true &&
                equipmentRecord.description && (
                  <GridItem xs={12} sm={6} md={6}>
                    <p>
                      <strong>Motivo:</strong>
                      <br />
                      {equipmentRecord.description}
                    </p>
                  </GridItem>
                )}
            </GridContainer>
          </CardBody>
        </Card>
      )
    );
  }

  function renderEquipmentRecords() {
    if (!equipment || !equipment.EquipmentRecords) {
      return null;
    }

    return equipment.EquipmentRecords.map((equipmentRecord) => {
      return renderEquipmentRecord(equipmentRecord);
    });
  }

  // function renderEquipmentRecords() {
  //   if (!equipment || !equipment.EquipmentRecords) {
  //     return null;
  //   }
  //   const tableData = equipment.EquipmentRecords.map((equipmentRecord) => {
  //     const date = moment(equipmentRecord.createdAt);
  //     const formatedDate = date.isValid() ? `${date.format("L")} ${date.format("LT")}` : "";
  //     const user = equipmentRecord.user ? equipmentRecord.user.name : "";
  //     const from = equipmentRecord.from ? equipmentRecord.from.name : "";
  //     const to = equipmentRecord.to ? equipmentRecord.to.name : "";
  //     const fridge = equipmentRecord.Fridge ? equipmentRecord.Fridge.local : "";

  //     return [formatedDate, user, from, to, fridge];
  //   });

  //   // tableData.push({ total: true, amount, colspan: 3 });
  //   return (
  //     <Card>
  //       <CardBody>
  //         <Table
  //           tableHeaderColor="primary"
  //           tableHead={["Data", "Usuário", "De", "Para", "Geladeira"]}
  //           tableData={tableData}
  //         />
  //       </CardBody>
  //     </Card>
  //   );
  // }

  return (
    <GridContainer justify="center">
      {loading && <Loader />}
      <Snackbar
        place="tr"
        color={color}
        icon={AddAlert}
        message={message}
        open={tr}
        closeNotification={() => setTR(false)}
        close
      />
      <GridItem xs={12} sm={12} md={12}>
        {renderHeader()}
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        {renderDescription()}
      </GridItem>
      {/* <GridItem xs={12} sm={12} md={12}>
        {technicians.map((technician) => (
          <p>{technician.name}</p>
        ))}
      </GridItem> */}
      {equipment &&
        !equipment.EquipmentFridge &&
        equipment.available_to_install && (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={6}>
                    <FormControl fullWidth className={classes.selectFormControl}>
                      <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                        Atribuir responsável
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={technician_id}
                        onChange={(event) => set_technician_id(event.target.value)}
                        inputProps={{
                          name: "technician_id",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                        >
                          Selecione
                        </MenuItem>
                        {technicians.map((technician) => {
                          return (
                            <MenuItem
                              key={technician.id}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={technician.id}
                            >
                              {technician.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Button
                      color="primary"
                      className={classes.updateProfileButton}
                      onClick={changeResponsible}
                    >
                      Salvar
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        )}

      {equipment &&
        !equipment.EquipmentFridge &&
        !equipment.available_to_install &&
        !equipment.deactivated &&
        !equipment.confirm_transfer &&
        equipment.User &&
        equipment.User.profile === "maintenance_manager" && (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <Button
                  color="primary"
                  className={classes.updateProfileButton}
                  onClick={releaseToInstall}
                >
                  Liberar para instalação
                </Button>
                <Button className={classes.updateProfileButton} onClick={deactivate}>
                  Desativar equipamento
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        )}
      {equipment &&
        !equipment.EquipmentFridge &&
        !equipment.available_to_install &&
        equipment.deactivated && (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <Button
                  color="primary"
                  className={classes.updateProfileButton}
                  onClick={reactivate}
                >
                  Reativar equipamento
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        )}
      {equipment &&
        equipment.EquipmentRecords &&
        equipment.EquipmentRecords.length > 0 && (
          <GridItem xs={12} sm={12} md={12}>
            <h2>Histórico</h2>
            {renderEquipmentRecords()}
          </GridItem>
        )}
    </GridContainer>
  );
}

export default EquipmentTransactions;
