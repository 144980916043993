import React from "react";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import consts from "../../consts";
import socketIOClient from "socket.io-client";
import { HideForm, save, showNotification } from "./Actions";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInputCep from "components/CustomInput/CustomInputCep.jsx";

import PictureUpload from "components/CustomUpload/PictureUpload.jsx";

import Loader from "components/Loader/Loader.jsx";

// material ui icons
// import MailOutline from "@material-ui/icons/MailOutline";
// import Contacts from "@material-ui/icons/Contacts";
// import Check from "@material-ui/icons/Check";
// import Close from "@material-ui/icons/Close";
// import CategoryIcon from "@material-ui/icons/Category"
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomInputCPF from "components/CustomInput/CustomInputCPF.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Table from "components/Table/Table.jsx";

import moment from "moment";
import "moment/locale/pt-br";

import If from "../../common/operators/If";

// style for this view
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";

const styles = {
  ...validationFormsStyle,
  ...sweetAlertStyle,
};

class ValidationForms extends React.Component {
  constructor(props) {
    super(props);
    // console.log("props: ", props);
    // console.log("props.product_return: ", props.product_return);
    this.me = JSON.parse(localStorage.getItem(consts.UserKey)).user;
    // console.log("me: ", this.me);
    this.state = {
      loading: false,
      action: "",
      alert: null,
      product_return: props.product_return ? props.product_return : null,
      id: props.product_return ? props.product_return.id : "",
    };
  }

  cancelButtonClick() {
    this.props.HideForm();
  }

  renderDetails1() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="warning" icon>
          <CardIcon color="warning">
            <AssignmentIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Cadastrar novo produto</h4>
        </CardHeader>
        <CardBody>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <GridItem xs={12}>
                  <CustomInput
                    success={this.state.nameState === "success"}
                    error={this.state.nameState === "error"}
                    labelText={<span>Nome</span>}
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => this.change(event, "name", "length", 3),
                      value: this.state.name,
                      type: "text",
                      placeholder: "Nome",
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <CustomInput
                    success={this.state.skuState === "success"}
                    error={this.state.skuState === "error"}
                    labelText={<span>SKU</span>}
                    id="sku"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => this.change(event, "sku", "length", 3),
                      value: this.state.sku,
                      type: "text",
                      placeholder: "SKU",
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <CustomInput
                    success={this.state.imageState === "success"}
                    error={this.state.imageState === "error"}
                    labelText={<span>Link da imagem</span>}
                    id="image"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => this.change(event, "image", "length", 3),
                      value: this.state.image,
                      type: "text",
                      placeholder: "Link da imagem",
                    }}
                  />
                </GridItem>
              </GridItem>
            </GridContainer>
          </form>
        </CardBody>
        <CardFooter>
          <Button onClick={() => this.cancelButtonClick()}>Cancelar</Button>
          <Button color="primary" onClick={() => this.saveButtonClick()}>
            Salvar
          </Button>
        </CardFooter>
      </Card>
    );
  }

  renderHeader() {
    const { classes } = this.props;
    return (
      <Card>
        <CardFooter>
          <Button onClick={() => this.cancelButtonClick()}>Voltar</Button>
          {this.state.product_return && this.state.product_return.status === 0 && false ? (
            <div>
              <Button color="danger" onClick={() => this.cancel()}>
                Cancelar
              </Button>
              <Button color="success" onClick={() => this.confirm()}>
                Concluir
              </Button>
            </div>
          ) : null}
        </CardFooter>
      </Card>
    );
  }

  renderDetails() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>Detalhes do abastecimentoo</h4>
        </CardHeader>
        <CardBody>
          <p>
            <strong>Local:</strong>{" "}
            {this.props.product_return && this.props.product_return.Fridge
              ? this.props.product_return.Fridge.local
              : ""}
          </p>
          <p>
            <strong>Geladeira:</strong>{" "}
            {this.props.product_return && this.props.product_return.Fridge
              ? this.props.product_return.Fridge.gebra
              : ""}
          </p>
          <p>
            <strong>Motorista:</strong>{" "}
            {this.props.product_return && this.props.product_return.driver
              ? this.props.product_return.driver.name
              : ""}
          </p>
          <p>
            <strong>Data:</strong>{" "}
            {this.props.product_return.createdAt
              ? moment(this.props.product_return.createdAt).format("L")
              : ""}
          </p>

          <p>
            <strong>NF Abastecimento:</strong>{" "}
            {this.props.product_return.Piclist && this.props.product_return.Piclist.nf
              ? this.props.product_return.Piclist.nf
              : ""}
          </p>

          <br />
          {this.props.product_return &&
            this.props.product_return.ordem && (
              <p>
                <strong>Ordem:</strong> {this.props.product_return.ordem}
              </p>
            )}
          {this.props.product_return &&
            this.props.product_return.fornecimento && (
              <p>
                <strong>Fornecimento:</strong> {this.props.product_return.fornecimento}
              </p>
            )}
          {this.props.product_return &&
            this.props.product_return.fatura && (
              <p>
                <strong>Fatura:</strong> {this.props.product_return.fatura}
              </p>
            )}
        </CardBody>
      </Card>
    );
  }

  renderProducts() {
    if (!this.props.product_return.inventories) return null;

    const { classes } = this.props;
    const tableData = this.props.product_return.inventories.map((product) => {
      return [
        product.sku,
        product.name,
        product.stock_pre,
        product.product_return,
        product.piclist,
        product.stock_pos,
      ];
    });

    // tableData.push({ total: true, amount, colspan: 3 });
    return (
      <Card>
        <CardBody>
          <Table
            tableHeaderColor="primary"
            tableHead={[
              "SKU",
              "Produto",
              "Estoque antes",
              "Retornos",
              "Abastecido",
              "Novo Estoque",
            ]}
            tableData={tableData}
            coloredColls={[4]}
            colorsColls={["primary"]}
          />
        </CardBody>
      </Card>
    );
  }

  render_images_nf_products() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>Nota fiscal</h4>
        </CardHeader>
        <CardBody>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={6}>
              Produtos
              <br />
              <img width="100%" src={this.props.product_return.images_nf_products} />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              Assinatura
              <br />
              <img width="100%" src={this.props.product_return.images_nf_signature} />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }

  render_images_abastecimentos() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>Abastecimento</h4>
        </CardHeader>
        <CardBody>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={6}>
              Pré abastecimento
              <br />
              <img width="100%" src={this.props.product_return.images_pre_refill} />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              Pós Abastecimento
              <br />
              <img width="100%" src={this.props.product_return.images_pos_refill} />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }

  render() {
    const { classes } = this.props;
    // console.log('render(): ', this.props)
    return (
      <div>
        {this.state.alert}

        <GridContainer justify="center">
          {this.state.loading ? <Loader /> : null}
          <GridItem xs={12} sm={12} md={12}>
            {this.renderHeader()}
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {this.renderDetails()}
            {this.renderProducts()}
            {this.render_images_nf_products()}
            {this.render_images_abastecimentos()}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('mapStateToProps: ', state)
  return {
    HideForm: state.product_returns.HideForm,
    product_return: state.product_returns.product_return,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      HideForm,
      save,
      showNotification,
    },
    dispatch
  );
const ReturnClassDecorated = withStyles(styles)(ValidationForms);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnClassDecorated);
