export default [
  {
    label: "Administrador",
    value: "manager",
  },
  {
    label: "Auditor",
    value: "controller",
  },
  {
    label: "Conferente",
    value: "conferente",
  },
  {
    label: "Entregador",
    value: "deliveryman",
  },
  {
    label: "Equipamentos",
    value: "maintenance_manager",
  },
  {
    label: "Funcionário de Condomínio",
    value: "syndic_staff",
  },
  {
    label: "Loja",
    value: "store",
  },
  {
    label: "Manutenção",
    value: "maintenance",
  },
  {
    label: "Marketing",
    value: "marketing",
  },
  {
    label: "Roteirização",
    value: "scripting",
  },
  {
    label: "Síndico",
    value: "syndic",
  },
];
