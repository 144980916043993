import React from "react";
import List from "./List";
// import PicList from './PicList'

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ShowForm,
  HideForm,
  // ShowPicList,
  // HidePicList,
} from "./Actions";

class Main extends React.Component {
  constructor(props) {
    super(props);
    props.HideForm();
    // props.HidePicList()
    // console.log(props)
  }

  render() {
    return (
      <div>
        <List driver_cod={this.props.match.params.driver_cod} date={this.props.match.params.date} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state)
  return {
    showForm: state.piclists.showForm,
    // showPicLists: state.piclists.showPicLists,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      HideForm,
      // ShowPicList,
      // HidePicList,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
