const INITIAL_OBJ = {
  name: "",
  date: "",
  run_at: "",
  active: true,
};
const INITIAL_STATE = {
  showForm: false,
  route: INITIAL_OBJ,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(INITIAL_OBJ)
  // console.log(state);
  // console.log(action);
  switch (action.type) {
    case "EXTRAORDINARY_ROUTE_SHOW_FORM":
      return {
        ...state,
        showForm: true,
        route: action.payload === undefined ? INITIAL_OBJ : action.payload,
      };
    case "EXTRAORDINARY_ROUTE_HIDE_FORM":
      return { ...state, showForm: false, route: INITIAL_OBJ };
    default:
      return state;
  }
};
