import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// import { Link } from "react-router-dom";
import axios from "axios";
import consts from "../../consts";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ShowForm,
  save,
  showNotification,
  // get
} from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";
import EditIcon from "@material-ui/icons/Edit";
import { Favorite } from "@material-ui/icons";

// import Assignment from "@material-ui/icons/Assignment";
// import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

// import { dataTable } from "variables/general.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { Link } from "react-router-dom";

import EquipmentMenu from "../equipements/Menu";

// import ReactExport from "react-export-excel";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    // console.log("props: ", props);
    this.state = {
      data: [],
      pages: -1,
      loading: true,
      alert: null,
      show: false,
    };
  }

  // componentWillMount(){
  // this.get();
  // }

  // componentDidMount(){
  // this.getReportData();
  // }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  delete(item) {
    const that = this;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Tem certeza?"
          onConfirm={() => {
            // console.log(item)
            axios
              .delete(`${consts.API_URL}/fridge-techs/${item.id}`)
              .then((resp) => {
                var data = this.state.data;
                data.find((o, i) => {
                  if (o.id === item.id) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    data.splice(i, 1);
                    return true;
                  }
                  return false;
                });
                let configNotification = {
                  message: "Tecnologia excluída com sucesso.",
                  color: "success",
                };
                that.props.showNotification(configNotification);
                this.setState({ data: data });
              })
              .catch((err) => {
                let configNotification = {
                  message: "Erro ao excluir produto.",
                  color: "error",
                };
                that.props.showNotification(configNotification);
              });
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
          cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
          confirmBtnText="Sim, excluir!"
          cancelBtnText="Cancelar"
          showCancel
        >
          Não tem como recuperar o registro ao excluir!
        </SweetAlert>
      ),
    });
  }

  setData(result) {
    var data = result.map((item) => {
      return {
        id: item.id,
        description: item.description,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => this.props.ShowForm(item)}
              color="warning"
              className="edit"
            >
              <EditIcon />
            </Button>{" "}
            {/* use this button to remove the data row */}
            <Button
              justIcon
              round
              simple
              onClick={() => this.delete(item)}
              color="danger"
              className="remove"
            >
              <Close />
            </Button>{" "}
          </div>
        ),
      };
    });

    this.setState({ data });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.alert}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <MaterialIconList />
                </CardIcon>
                <Button
                  color="primary"
                  className={classes.updateProfileButton}
                  onClick={() => this.props.ShowForm()}
                >
                  Adicionar
                </Button>
                {/* <h4 className={classes.cardIconTitle}>Lista de membros</h4> */}
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={this.state.data}
                  pages={this.state.pages}
                  loading={this.state.loading}
                  filterable={true}
                  columns={[
                    {
                      Header: "Descrição",
                      accessor: "description",
                    },
                    {
                      Header: "Ações",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                  defaultPageSize={20}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  previousText="Anterior"
                  nextText="Próxima"
                  loadingText="Carregando"
                  noDataText="Nenhum registro encontrado"
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                  manual
                  onFetchData={(state, instance) => {
                    // show the loading overlay
                    this.setState({ loading: true }, () => {
                      // console.log(state.filtered)
                      const sorted = state.sorted.length === 0 ? "" : state.sorted[0].id;
                      const order =
                        state.sorted.length > 0 && state.sorted[0].desc ? "desc" : "asc";
                      const filtered = btoa(JSON.stringify(state.filtered));

                      // fetch your data
                      axios
                        .get(
                          `${consts.API_URL}/fridge-techs?page=${state.page + 1}&qtd_per_page=${
                            state.pageSize
                          }&sorted=${sorted}&order=${order}&filtered=${filtered}`,
                          {
                            page: state.page,
                            pageSize: state.pageSize,
                            sorted: state.sorted,
                            filtered: state.filtered,
                          }
                        )
                        .then((res) => {
                          // console.log(res)
                          // console.log(state.sorted)
                          // console.log(state.filtered)
                          // Update react-table
                          this.setState(
                            {
                              pages: res.data.pages,
                              loading: false,
                            },
                            () => {
                              this.setData(res.data.result);
                            }
                          );
                        });
                    });
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log(state)
//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = (state) => ({
  ShowForm: state.ShowForm,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      save,
      showNotification,
      // get
    },
    dispatch
  );
const MemberList = withStyles(sweetAlertStyle)(ReactTables);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberList);
