import React from "react";

// react component for creating dynamic tables
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import axios from "axios";
import socketIOClient from "socket.io-client";
import consts from "../../consts";

import moment from "moment";
import "moment/locale/pt-br";

// import Form from "./Form";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import Loader from "components/Loader/Loader.jsx";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ShowForm,
  showNotification,
  // get
} from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import CategoryIcon from "@material-ui/icons/Category";
import DownloadIcon from "@material-ui/icons/GetApp";
import WarningIcon from "@material-ui/icons/Warning";
import NotaFistalIcon from "@material-ui/icons/InsertDriveFile";
import ViewIcon from "@material-ui/icons/Visibility";
import Assignment from "@material-ui/icons/Assignment";

import CheckIcon from "@material-ui/icons/Check";
import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Table from "components/Table/Table.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      data: [],
      originalData: [],
      routine_data: [],
      pages: -1,
      loading: true,
      alert: null,
      show: false,
      piclist_id: null,
      showFilters: false,
    };
  }

  setData(result) {
    var data = result.map((item) => {
      // console.log(item);
      return {
        id: item.id,
        date: (
          <div>{item.createdAt ? new Date(item.createdAt).toLocaleString("pt-BR") : ""}</div>
          // <div>{item.createdAt ? moment(item.createdAt).fromNow() : ""}</div>
          // item.createdAt
        ),
        opened: item.data.filter((piclist) => piclist.status === 401).length,
        no_products: item.data.filter((piclist) => piclist.status === 200).length,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => this.setState({ routine_data: item.data })}
              color="primary"
              className="edit"
            >
              <ViewIcon />
            </Button>
          </div>
        ),
      };
    });

    this.setState({ data });
  }

  get() {
    const { sorted, order, filtered, page, pageSize } = this.state;
    // const sorted = (state.sorted.length === 0) ? '' : state.sorted[0].id
    // const order = (state.sorted.length > 0 && state.sorted[0].desc) ? 'desc' : 'asc'
    // const filtered = btoa(JSON.stringify(state.filtered))
    axios
      .get(
        `${consts.API_URL}/piclists-routines?page=${page +
          1}&qtd_per_page=${pageSize}&sorted=${sorted}&order=${order}&filtered=${filtered}`,
        {
          page,
          pageSize,
          sorted,
          filtered,
        }
      )
      .then((res) => {
        // console.log(res)
        // console.log(state.sorted)
        // console.log(state.filtered)
        // Update react-table
        this.setState(
          {
            pages: res.data.pages,
            loading: false,
            originalData: res.data.result,
          },
          () => {
            this.setData(res.data.result);
          }
        );
      });
  }

  renderHeader() {
    const { classes } = this.props;
    return (
      <Card>
        <CardFooter>
          <Link to={`/all-piclists`}>
            <Button color="primary" onClick={() => {}}>
              Voltar
            </Button>
          </Link>
        </CardFooter>
      </Card>
    );
  }

  render() {
    const { classes } = this.props;
    // console.log(this.state.originalData);
    return (
      <div>
        {this.state.loading ? <Loader /> : null}
        {this.state.alert}
        {this.renderHeader()}
        <GridContainer>
          {this.state.routine_data.length > 0 ? (
            <GridItem xs={12}>
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <ViewIcon />
                  </CardIcon>

                  <Button color="primary" onClick={() => this.setState({ routine_data: [] })}>
                    Fechar
                  </Button>
                </CardHeader>
                <CardBody className={classes.customCardContentClass}>
                  <Table
                    hover
                    tableHead={["Mensagem", "Visualizar"]}
                    tableData={this.state.routine_data.map((routine) => {
                      let color = "";
                      if (routine.status === 201) {
                        color = "success";
                      }
                      if (routine.status === 200) {
                        color = "info";
                      }
                      if (routine.status === 401) {
                        color = "danger";
                      }
                      return {
                        color,
                        data: [
                          routine.message,
                          <div className="actions-right">
                            <Link to={`/fridges/${routine.fridge_id}/piclists`}>
                              <Button
                                justIcon
                                round
                                simple
                                onClick={() => {}}
                                color="primary"
                                className="edit"
                              >
                                <ViewIcon />
                              </Button>
                            </Link>
                          </div>,
                        ],
                      };
                    })}
                  />
                </CardBody>
              </Card>
            </GridItem>
          ) : (
            <GridItem xs={12}>
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <MaterialIconList />
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.data}
                    pages={this.state.pages}
                    loading={this.state.loading}
                    filterable={false}
                    columns={[
                      {
                        Header: "Data",
                        accessor: "date",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Abertas",
                        accessor: "opened",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Sem produtos",
                        accessor: "no_products",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Ações",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                    defaultPageSize={20}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                    previousText="Anterior"
                    nextText="Próxima"
                    loadingText="Carregando"
                    noDataText="Nenhum registro encontrado"
                    pageText="Página"
                    ofText="de"
                    rowsText="registros"
                    manual
                    onFetchData={(state, instance) => {
                      // show the loading overlay
                      const sorted = state.sorted.length === 0 ? "" : state.sorted[0].id;
                      const order =
                        state.sorted.length > 0 && state.sorted[0].desc ? "desc" : "asc";
                      const filtered = btoa(JSON.stringify(state.filtered));
                      const page = state.page;
                      const pageSize = state.pageSize;
                      this.setState(
                        {
                          loading: true,
                          sorted,
                          order,
                          filtered,
                          page,
                          pageSize,
                        },
                        () => {
                          this.get();
                        }
                      );
                    }}
                  />
                </CardBody>
              </Card>
            </GridItem>
          )}
        </GridContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log(state)
//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = (state) => ({
  ShowForm: state.ShowForm,
  ShowPicList: state.ShowPicList,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      showNotification,
    },
    dispatch
  );
const MemberList = withStyles(sweetAlertStyle)(ReactTables);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberList);
