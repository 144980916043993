import React from "react";
// react component for creating dynamic tables
import axios from "axios";
import ReactTable from "react-table";
import consts from "../../consts";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ShowForm,
  save,
  // ShowPicList,
  showNotification,
} from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";

// import Assignment from "@material-ui/icons/Assignment";
// import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";

// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// import { dataTable } from "variables/general.jsx";

// import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

// import ReactExport from "react-export-excel";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// const styles = {
//   cardIconTitle: {
//     ...cardTitle,
//     marginTop: "15px",
//     marginBottom: "0px"
//   }
// };

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    this.me = JSON.parse(localStorage.getItem(consts.UserKey)).user;
    this.state = {
      data: [],
      originalData: [],
      openingReasons: [],
      openingReason_id: "",
      pages: -1,
      loading: true,
      alert: null,
      show: false,
      showTable: true,
    };
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  delete(item) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Tem certeza?"
          onConfirm={() => {
            // console.log(item)
            axios
              .delete(`${consts.API_URL}/contract-cancellations/${item.id}`)
              .then((resp) => {
                var data = this.state.data;
                data.find((o, i) => {
                  if (o.id === item.id) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    // data.splice(i, 1);
                    // console.log(data);

                    const configNotification = {
                      message: "Cancelamento de contrato cancelado com sucesso",
                      color: "success",
                    };
                    this.props.showNotification(configNotification);
                    data[i].status = "Cancelado";
                    return true;
                  }
                  return false;
                });
                this.setState({ data: data });
              })
              .catch((e) => {
                console.log(e);
                console.log(e.response);
                const message =
                  e.response && e.response.data && e.response.data.message
                    ? e.response.data.message
                    : "Falha ao excluir geladeira.";
                const configNotification = {
                  message,
                  color: "danger",
                };
                this.props.showNotification(configNotification);
              });
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
          cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
          confirmBtnText="Sim, excluir!"
          cancelBtnText="Cancelar"
          showCancel
        >
          Não tem como recuperar o registro ao excluir!
        </SweetAlert>
      ),
    });
  }

  setData(result) {
    var data = result.map((item) => {
      let actions = (
        // we've added some custom button actions
        <div className="actions-right">
          <Button
            justIcon
            round
            simple
            onClick={() => this.delete(item)}
            color="danger"
            className="remove"
          >
            <Close />
          </Button>{" "}
        </div>
      );

      if (this.me.profile === "marketing") {
        actions = <div className="actions-right" />;
      }

      if (this.me.profile === "maintenance_manager") {
        actions = <div className="actions-right" />;
      }

      let status = "Em andamento";
      switch (item.status) {
        case 0:
          status = "Em andamento";
          break;
        case 1:
          status = "Finalizado";
          break;
        case 2:
          status = "Cancelado";
          break;
        default:
          status = "Em andamento";
          break;
      }

      return {
        id: item.id,
        gebra: item.Fridge.gebra,
        status,
        actions,
      };
    });

    this.setState({ data });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.alert}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <MaterialIconList />
                </CardIcon>
              </CardHeader>
              <CardBody>
                {this.state.showTable && (
                  <ReactTable
                    data={this.state.data}
                    pages={this.state.pages}
                    loading={this.state.loading}
                    filterable={true}
                    columns={[
                      {
                        Header: "GEBRA",
                        accessor: "gebra",
                      },
                      {
                        Header: "Status",
                        accessor: "status",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Ações",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                    defaultPageSize={20}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                    previousText="Anterior"
                    nextText="Próxima"
                    loadingText="Carregando"
                    noDataText="Nenhum registro encontrado"
                    pageText="Página"
                    ofText="de"
                    rowsText="registros"
                    manual
                    onFetchData={(state, instance) => {
                      // show the loading overlay
                      this.setState({ loading: true }, () => {
                        const sorted = state.sorted.length === 0 ? "" : state.sorted[0].id;
                        const order =
                          state.sorted.length > 0 && state.sorted[0].desc ? "desc" : "asc";
                        const filtered = btoa(JSON.stringify(state.filtered));

                        // fetch your data
                        axios
                          .get(
                            `${consts.API_URL}/contract-cancellations?page=${state.page +
                              1}&qtd_per_page=${
                              state.pageSize
                            }&sorted=${sorted}&order=${order}&filtered=${filtered}`,
                            {
                              page: state.page,
                              pageSize: state.pageSize,
                              sorted: state.sorted,
                              filtered: state.filtered,
                            }
                          )
                          .then((res) => {
                            // console.log(res)
                            // console.log(state.sorted)
                            // console.log(state.filtered)
                            // Update react-table
                            this.setState(
                              {
                                pages: res.data.pages,
                                loading: false,
                              },
                              () => {
                                this.setState({ originalData: res.data.result });
                                this.setData(res.data.result);
                              }
                            );
                          });
                      });
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log(state)
//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = (state) => ({
  ShowForm: state.ShowForm,
  ShowPicList: state.ShowPicList,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      // ShowPicList,
      showNotification,
      save,
      // get
    },
    dispatch
  );
const MemberList = withStyles(sweetAlertStyle)(ReactTables);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberList);

const sql = `
  CREATE VIEW _v_inventory_report AS
  SELECT
    fridges.gebra,
    fridges.deposito,
    products.sku,
    fridge_products.qtd AS quantidade_inventario,
    (
      SELECT
        qtd
      FROM
        planogram_products
      WHERE
        planogram_id = planograms.id
      AND
        product_id = products.id
    ) AS quantidade_planograma,
    routes.name AS rota
  FROM
    fridges
  LEFT JOIN
    fridge_products
  ON
    fridges.id = fridge_products.fridge_id
  LEFT JOIN
    products
  ON
    products.id = fridge_products.product_id
  LEFT JOIN
    planograms
  ON
    fridges.planogram_id = planograms.id
  LEFT JOIN
    routes
  ON
    fridges.route_id = routes.id
`;
