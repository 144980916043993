import React from "react";
import List from "./List";
import ListProducts from "./ListProducts";
import Form from "./Form";
import If from "../../common/operators/If";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ShowForm, HideForm, ShowProducts, HideProducts } from "./Actions";

class Main extends React.Component {
  constructor(props) {
    super(props);
    props.HideForm();
    props.HideProducts();
    // console.log(props)
  }

  render() {
    return (
      <div>
        <If test={!this.props.showProducts}>
          <If test={!this.props.showForm}>
            <List />
          </If>
          <If test={this.props.showForm}>
            <Form />
          </If>
        </If>
        <If test={this.props.showProducts}>
          <ListProducts />
        </If>
      </div>
    );
  }
}

const mapStateToProps = state => {
  // console.log('state: ', state)
  return {
    showForm: state.planograms.showForm,
    showProducts: state.planograms.showProducts
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ShowForm,
      HideForm,
      HideProducts,
      ShowProducts
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Main);
