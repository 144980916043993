import React from "react";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import consts from "../../consts";
import profiles from "../../profiles";
import { HideForm, save } from "./Actions";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";

// material ui icons
// import MailOutline from "@material-ui/icons/MailOutline";
// import Contacts from "@material-ui/icons/Contacts";
// import Check from "@material-ui/icons/Check";
// import Close from "@material-ui/icons/Close";
// import CategoryIcon from "@material-ui/icons/Category"
import AssignmentIcon from "@material-ui/icons/Assignment";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomInputCPF from "components/CustomInput/CustomInputCPF.jsx";
import CustomInputCel from "components/CustomInput/CustomInputCel";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

// import If from '../../common/operators/If'

// style for this view
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

const styles = {
  ...validationFormsStyle,
  ...extendedFormsStyle,
  // MuiSwitch-colorSecondary:{

  // }. + .MuiSwitch-track": {

  // }
};
// f50057
// console.log(styles);

// const styles = {
//   cardIconTitle: {
//     ...cardTitle,
//     marginTop: "15px",
//     marginBottom: "0px"
//   }
// };

class ValidationForms extends React.Component {
  constructor(props) {
    super(props);
    // console.log('props: ',props)
    this.state = {
      // profile: props.user ? props.user.profile : "",
      // name: props.user ? props.user.name : "",
      // vendedor: props.user ? props.user.vendedor : "",
      // email: props.user ? props.user.email : "",
      // registerCPF: props.user ? props.user.cpf : "",
      // active: props.user ? props.user.active : true,

      name: props.user ? props.user.name : "",
      email: props.user ? props.user.email : "",
      profile: "syndic_staff",
      password: props.user ? props.user.password : "",
      cpf: props.user ? props.user.cpf : "",
      matricula: props.user ? props.user.matricula : "",
      celular: props.user ? props.user.celular : "",
      syndic_id: props.user ? props.user.syndic_id : "",
      active: props.user ? props.user.active : true,

      category: "",
      // register form
      emailState: "",
      registerPassword: "",
      registerPasswordState: "",
      registerConfirmPassword: "",
      registerConfirmPasswordState: "",
      registerCheckbox: false,
      registerCheckboxState: "",
      // login form
      loginEmail: "",
      loginEmailState: "",
      loginPassword: "",
      loginPasswordState: "",
      // type validation
      required: "",
      requiredState: "",
      typeEmail: "",
      typeEmailState: "",
      number: "",
      numberState: "",
      url: "",
      urlState: "",
      equalTo: "",
      whichEqualTo: "",
      equalToState: "",
      // range validation
      minLength: "",
      minLengthState: "",
      maxLength: "",
      maxLengthState: "",
      range: "",
      rangeState: "",
      minValue: "",
      minValueState: "",
      maxValue: "",
      maxValueState: "",
      syndics: [],
      syndic_name: "",
      syndic: "",
    };
    this.registerClick = this.registerClick.bind(this);
    this.loginClick = this.loginClick.bind(this);
    this.typeClick = this.typeClick.bind(this);
    this.rangeClick = this.rangeClick.bind(this);
  }

  componentDidMount() {
    this.getSyndics();
  }

  getSyndics() {
    // console.log(this.state)
    const that = this;
    axios.get(`${consts.API_URL}/users/syndicSelect`).then((resp) => {
      // console.log(resp);
      const syndic = this.state.syndic_id
        ? resp.data.result.filter((syndic) => this.state.syndic_id === syndic.id)[0]
        : false;
      that.setState({
        syndics: resp.data.result,
        syndic,
      });
    });
  }

  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  cancelButtonClick() {
    this.props.HideForm();
  }

  searchButtonClick() {
    // console.log('searchButtonClick(){')
    // console.log(this.state)
    var values = {
      cpf: this.state.registerCPF,
    };
    // console.log(values)
    // this.props.getUser(values)
  }
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  saveButtonClick() {
    const me = JSON.parse(localStorage.getItem(consts.UserKey)).user;
    // console.log(this.props.user)
    var user = { ...this.props.user };
    // user.name = this.state.name;
    // user.email = this.state.email;
    // user.profile = this.state.profile;
    // user.cpf = this.state.cpf;
    // user.password = this.state.password;
    // user.active = this.state.active;

    user.name = this.state.name;
    user.email = this.state.email;
    user.password = this.state.password;
    user.cpf = this.state.cpf;
    user.matricula = this.state.matricula;
    user.celular = this.state.celular;
    user.active = this.state.active;

    // user.vendedor = this.props.user.vendedor
    console.log(user);

    // var user = {
    //   name: this.state.name,
    // }
    this.props.save(user);
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  // function that verifies if two strings are equal
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  // function that verifies if value contains only numbers
  verifyNumber(value) {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }
  // verifies if value is a valid URL
  verifyUrl(value) {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  }
  change(event, stateName, type, stateNameEqualTo, maxValue) {
    // console.log(event)
    // console.log(this.state)
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "checkbox":
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-length":
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "min-value":
        if (this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-value":
        if (this.verifyNumber(event.target.value) && event.target.value <= stateNameEqualTo) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "range":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }
  registerClick() {
    if (this.state.emailState === "") {
      this.setState({ emailState: "error" });
    }
    if (this.state.registerPasswordState === "") {
      this.setState({ registerPasswordState: "error" });
    }
    if (this.state.registerConfirmPasswordState === "") {
      this.setState({ registerConfirmPasswordState: "error" });
    }
    if (this.state.registerCheckboxState === "") {
      this.setState({ registerCheckboxState: "error" });
    }
  }
  loginClick() {
    if (this.state.loginEmailState === "") {
      this.setState({ loginEmailState: "error" });
    }
    if (this.state.loginPasswordState === "") {
      this.setState({ loginPasswordState: "error" });
    }
  }
  typeClick() {
    if (this.state.requiredState === "") {
      this.setState({ requiredState: "error" });
    }
    if (this.state.typeEmailState === "") {
      this.setState({ typeEmailState: "error" });
    }
    if (this.state.numberState === "") {
      this.setState({ numberState: "error" });
    }
    if (this.state.urlState === "") {
      this.setState({ urlState: "error" });
    }
    if (this.state.equalToState === "") {
      this.setState({ equalToState: "error" });
    }
  }
  rangeClick() {
    if (this.state.minLengthState === "") {
      this.setState({ minLengthState: "error" });
    }
    if (this.state.maxLengthState === "") {
      this.setState({ maxLengthState: "error" });
    }
    if (this.state.rangeState === "") {
      this.setState({ rangeState: "error" });
    }
    if (this.state.minValueState === "") {
      this.setState({ minValueState: "error" });
    }
    if (this.state.maxValueState === "") {
      this.setState({ maxValueState: "error" });
    }
  }

  changeSyndic(event) {
    const syndic = event.target.innerText
      ? this.state.syndics.filter((syndic) => event.target.innerText === syndic.name)[0]
      : false;
    // console.log(syndic);
    const syndic_name = syndic ? syndic.name : "";
    const syndic_id = syndic ? syndic.id : "";
    this.setState({
      syndic_name,
      syndic_id,
      syndic,
    });
  }

  renderSyndics() {
    return (
      <Autocomplete
        options={this.state.syndics}
        getOptionLabel={(option) => option.name}
        onChange={this.changeSyndic.bind(this)}
        value={this.state.syndic}
        renderInput={(params) => <TextField {...params} label="Síndico" fullWidth />}
      />
    );
  }

  render() {
    const { classes } = this.props;
    // console.log(profiles);
    // console.log("render() props: ", this.props);
    // console.log("render() state: ", this.state);
    // console.log("render() classes: ", classes);
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Cadastrar novo usuário</h4>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12}>
                    <CustomInput
                      success={this.state.nameState === "success"}
                      error={this.state.nameState === "error"}
                      labelText={<span>Nome</span>}
                      id="name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) => this.change(event, "name", "length", 3),
                        value: this.state.name,
                        type: "text",
                        placeholder: "Nome",
                      }}
                    />
                    <CustomInput
                      success={this.state.emailState === "success"}
                      error={this.state.emailState === "error"}
                      labelText={
                        this.state.profile === "seller" ||
                        this.state.profile === "asset_manager" ? (
                          <span>
                            Usuário <small>(obrigatório para login no aplicativo)</small>
                          </span>
                        ) : (
                          <span>
                            E-mail <small>(obrigatório)</small>
                          </span>
                        )
                      }
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) => {
                          if (this.state.profile === "seller") {
                            this.change(event, "email", "length", 3);
                          } else {
                            this.change(event, "email", "email");
                          }
                        },
                        type: "email",
                        value: this.state.email,
                        placeholder: this.state.profile === "seller" ? "Usuário" : "E-mail",
                      }}
                    />

                    <CustomInputCPF
                      success={this.state.registerCPFState === "success"}
                      error={this.state.registerCPFState === "error"}
                      id="cpf"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        // (event, stateName, type, stateNameEqualTo, maxValue)
                        onChange: (event) => this.change(event, "cpf"),
                        value: this.state.cpf,
                        type: "text",
                        placeholder: "CPF",
                      }}
                    />
                    <CustomInput
                      success={this.state.nameState === "success"}
                      error={this.state.nameState === "error"}
                      labelText={<span>Matrícula</span>}
                      id="matricula"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) => this.change(event, "matricula"),
                        value: this.state.matricula,
                        type: "text",
                        placeholder: "Matrícula",
                      }}
                    />
                    <CustomInputCel
                      success={this.state.nameState === "success"}
                      error={this.state.nameState === "error"}
                      labelText={<span>Celular</span>}
                      id="celular"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) => this.change(event, "celular"),
                        value: this.state.celular,
                        type: "text",
                        placeholder: "Celular",
                      }}
                    />
                    <CustomInput
                      success={this.state.nameState === "success"}
                      error={this.state.nameState === "error"}
                      labelText={<span>Senha</span>}
                      id="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) => this.change(event, "password"),
                        value: this.state.password,
                        type: "text",
                        placeholder: "Senha",
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.active}
                            onChange={this.handleChange("active")}
                            value="active"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              bar: classes.switchBar,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                        }}
                        label="Ativo"
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
            <CardFooter>
              <Button onClick={() => this.cancelButtonClick()}>Cancelar</Button>
              <Button color="primary" onClick={() => this.saveButtonClick()}>
                Salvar
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('mapStateToProps: ', state)
  return {
    HideForm: state.users.HideForm,
    user: state.users.user,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      HideForm,
      save,
    },
    dispatch
  );

const ReturnClassDecorated = withStyles(styles)(ValidationForms);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnClassDecorated);
