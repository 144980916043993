import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Switch } from "react-router-dom";
// import * as Sentry from "@sentry/browser";

import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import promise from "redux-promise";
import multi from "redux-multi";
import thunk from "redux-thunk";

// import indexRoutes from "routes/index.jsx";
import Main from "./routes/index";
// import AuthOrApp from './main/authOrApp'
import reducers from "./reducers";

import "assets/scss/material-dashboard-pro-react.css?v=1.4.0";

const hist = createBrowserHistory();

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
const store = applyMiddleware(multi, thunk, promise)(createStore)(reducers, devTools);

// Sentry.init({
//   dsn: "https://b95d02170e3c4878a0ddc0752f7a6f2e@sentry.io/5180739"
// });
// Sentry.captureException(new Error("Something broke"));

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <Main />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
