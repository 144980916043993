import React from "react";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import consts from "../../consts";
import { HideForm, save, showNotification } from "./Actions";
import moment from "moment";
import "moment/locale/pt-br";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInputCep from "components/CustomInput/CustomInputCep.jsx";

import PictureUpload from "components/CustomUpload/PictureUpload.jsx";

import Loader from "components/Loader/Loader.jsx";

// material ui icons
// import MailOutline from "@material-ui/icons/MailOutline";
// import Contacts from "@material-ui/icons/Contacts";
// import Check from "@material-ui/icons/Check";
// import Close from "@material-ui/icons/Close";
// import CategoryIcon from "@material-ui/icons/Category"
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomInputCPF from "components/CustomInput/CustomInputCPF.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Table from "components/Table/Table.jsx";

import If from "../../common/operators/If";

// style for this view
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";

class ValidationForms extends React.Component {
  constructor(props) {
    super(props);
    // console.log('props: ',props)
    // console.log('props.order: ', props.order)
    this.state = {
      loading: true,
      action: "",
      id: props.order ? props.order.id : "",
      sapLogs: [],
      syndicEmailLogs: []
    };
    this.registerClick = this.registerClick.bind(this);
    this.loginClick = this.loginClick.bind(this);
    this.typeClick = this.typeClick.bind(this);
    this.rangeClick = this.rangeClick.bind(this);
    this.getSapLogs();
    this.getSyndicEmailsSent();
  }

  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  cancelButtonClick() {
    this.props.HideForm();
  }

  saveButtonClick() {
    // console.log(this.props.order)
    var order = { ...this.props.order };

    order.name = this.state.name;
    order.sku = this.state.sku;
    order.image = this.state.image;

    this.props.save(order);
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  // function that verifies if two strings are equal
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  // function that verifies if value contains only numbers
  verifyNumber(value) {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }
  // verifies if value is a valid URL
  verifyUrl(value) {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  }
  change(event, stateName, type, stateNameEqualTo, maxValue) {
    // console.log(event)
    // console.log(this.state)
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "checkbox":
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-length":
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "min-value":
        if (this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-value":
        if (this.verifyNumber(event.target.value) && event.target.value <= stateNameEqualTo) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "range":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }
  registerClick() {
    if (this.state.registerEmailState === "") {
      this.setState({ registerEmailState: "error" });
    }
    if (this.state.registerPasswordState === "") {
      this.setState({ registerPasswordState: "error" });
    }
    if (this.state.registerConfirmPasswordState === "") {
      this.setState({ registerConfirmPasswordState: "error" });
    }
    if (this.state.registerCheckboxState === "") {
      this.setState({ registerCheckboxState: "error" });
    }
  }
  loginClick() {
    if (this.state.loginEmailState === "") {
      this.setState({ loginEmailState: "error" });
    }
    if (this.state.loginPasswordState === "") {
      this.setState({ loginPasswordState: "error" });
    }
  }
  typeClick() {
    if (this.state.requiredState === "") {
      this.setState({ requiredState: "error" });
    }
    if (this.state.typeEmailState === "") {
      this.setState({ typeEmailState: "error" });
    }
    if (this.state.numberState === "") {
      this.setState({ numberState: "error" });
    }
    if (this.state.urlState === "") {
      this.setState({ urlState: "error" });
    }
    if (this.state.equalToState === "") {
      this.setState({ equalToState: "error" });
    }
  }
  rangeClick() {
    if (this.state.minLengthState === "") {
      this.setState({ minLengthState: "error" });
    }
    if (this.state.maxLengthState === "") {
      this.setState({ maxLengthState: "error" });
    }
    if (this.state.rangeState === "") {
      this.setState({ rangeState: "error" });
    }
    if (this.state.minValueState === "") {
      this.setState({ minValueState: "error" });
    }
    if (this.state.maxValueState === "") {
      this.setState({ maxValueState: "error" });
    }
  }

  // getPicture(image){
  //   // console.log(image)
  //   if(image !== ''){
  //     this.setState({
  //       image,
  //       imageState: 'success'
  //     })
  //   }else{
  //     this.setState({
  //       image,
  //       imageState: 'error'
  //     })
  //   }
  //
  // }

  getSapLogs() {
    this.setState({ loading: true });
    axios
      .get(`${consts.API_URL}/audits/${this.state.id}/sap`)
      .then((resp) => {
        // console.log("resp: ", resp);
        // const respFiltered = resp.data.result.filter((item => (item.status == 1)))
        // console.log('respFiltered: ', respFiltered)

        this.setState({ sapLogs: resp.data.result, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  }

  execSap() {
    // console.log("sap");
    // console.log('getReportData(){')
    this.setState({ loading: true });
    axios
      .post(`${consts.API_URL}/audits/${this.state.id}/sap`)
      .then((resp) => {
        console.log("resp: ", resp);
        const configNotification = {
          message: resp.data.message,
          color: "success",
        };
        this.props.showNotification(configNotification);
        this.getSapLogs();
        // const respFiltered = resp.data.result.filter((item => (item.status == 1)))
        // console.log('respFiltered: ', respFiltered)

        // this.setState({ reportData: resp.data });
      })
      .catch((err) => {
        console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        this.getSapLogs();
        console.log(err);
      });
  }

  // execEstorno() {
  //   // console.log("sap");
  //   // console.log('getReportData(){')
  //   this.setState({ loading: true });
  //   axios
  //     .put(`${consts.API_URL}/audits/${this.state.id}/reversal`)
  //     .then((resp) => {
  //       console.log("resp: ", resp);
  //       const configNotification = {
  //         message: "Pedido definido como estornado com sucesso",
  //         color: "success",
  //       };
  //       this.props.showNotification(configNotification);
  //       this.cancelButtonClick();
  //       // this.getSapLogs();
  //       // const respFiltered = resp.data.result.filter((item => (item.status == 1)))
  //       // console.log('respFiltered: ', respFiltered)

  //       // this.setState({ reportData: resp.data });
  //     })
  //     .catch((err) => {
  //       console.log(err.response);
  //       let configNotification = {
  //         message: "Erro inesperado.",
  //         color: "danger",
  //       };
  //       if (
  //         err.response &&
  //         err.response.data &&
  //         err.response.data.message &&
  //         err.response.data.message != ""
  //       ) {
  //         configNotification = {
  //           message: err.response.data.message,
  //           color: "danger",
  //         };
  //       }
  //       this.props.showNotification(configNotification);
  //       this.setState({ loading: false });
  //       this.getSapLogs();
  //       console.log(err);
  //     });
  // }

  getSyndicEmailsSent() {
    this.setState({ loading: true });
    axios
      .get(`${consts.API_URL}/audits/${this.state.id}/send-syndic-email`)
      .then((resp) => {
        console.log("getSyndicEmailsSent resp: ", resp.data);
        // const respFiltered = resp.data.result.filter((item => (item.status == 1)))
        // console.log('respFiltered: ', respFiltered)

        this.setState({ syndicEmailLogs: resp.data.scheduledAuditCondoReportLogs, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  }

  execSendSyndicEmail() {
    console.log("execSendSyndicEmail()");
    this.setState({ loading: true });
    axios
      .post(`${consts.API_URL}/audits/${this.state.id}/send-syndic-email`)
      .then((resp) => {
        console.log("resp: ", resp);
        const configNotification = {
          message: resp.data.message,
          color: "success",
        };
        this.props.showNotification(configNotification);
        // this.getSapLogs();
        // const respFiltered = resp.data.result.filter((item => (item.status == 1)))
        // console.log('respFiltered: ', respFiltered)

        // this.setState({ reportData: resp.data });
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        this.getSapLogs();
        console.log(err);
      });
  }

  execAction() {
    // console.log(this.state);
    switch (this.state.action) {
      case "sap":
        this.execSap();
        break;
      case "sendSyndicEmail":
        this.execSendSyndicEmail();
        break;
      // case "estorno":
      //   this.execEstorno();
      //   break;
    }
  }

  renderDetails1() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="warning" icon>
          <CardIcon color="warning">
            <AssignmentIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Cadastrar novo produto</h4>
        </CardHeader>
        <CardBody>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <GridItem xs={12}>
                  <CustomInput
                    success={this.state.nameState === "success"}
                    error={this.state.nameState === "error"}
                    labelText={<span>Nome</span>}
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => this.change(event, "name", "length", 3),
                      value: this.state.name,
                      type: "text",
                      placeholder: "Nome",
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <CustomInput
                    success={this.state.skuState === "success"}
                    error={this.state.skuState === "error"}
                    labelText={<span>SKU</span>}
                    id="sku"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => this.change(event, "sku", "length", 3),
                      value: this.state.sku,
                      type: "text",
                      placeholder: "SKU",
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <CustomInput
                    success={this.state.imageState === "success"}
                    error={this.state.imageState === "error"}
                    labelText={<span>Link da imagem</span>}
                    id="image"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => this.change(event, "image", "length", 3),
                      value: this.state.image,
                      type: "text",
                      placeholder: "Link da imagem",
                    }}
                  />
                </GridItem>
              </GridItem>
            </GridContainer>
          </form>
        </CardBody>
        <CardFooter>
          <Button onClick={() => this.cancelButtonClick()}>Cancelar</Button>
          <Button color="primary" onClick={() => this.saveButtonClick()}>
            Salvar
          </Button>
        </CardFooter>
      </Card>
    );
  }

  renderHeader() {
    const { classes } = this.props;
    return (
      <Card>
        <CardFooter>
          <Button color="primary" onClick={() => this.cancelButtonClick()}>
            Concluir
          </Button>
        </CardFooter>
      </Card>
    );
  }

  renderDetails() {
    const { classes } = this.props;

    console.log(this.props.order);

    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>
            {this.props.order.app_version && this.props.order.app_version != "" ? (
              <small>Versão do app: {this.props.order.app_version}</small>
            ) : null}
            {this.props.order.app_version && this.props.order.app_version != "" ? <br /> : null}
            Detalhes do pedido #{this.props.order.id}
          </h4>
        </CardHeader>
        <CardBody>
          <p>
            <strong>Status:</strong>{" "}
            {this.props.order && this.props.order.status === 0 ? "Pendente" : ""}
            {this.props.order && this.props.order.status === 1 ? "Concluído" : ""}
            {this.props.order && this.props.order.status === 2 ? "Cancelado" : ""}
          </p>
          <p>
            <strong>Data:</strong>{" "}
            {this.props.order.createdAt
              ? new Date(this.props.order.createdAt).toLocaleString("pt-BR")
              : ""}
          </p>
          <p>
            <strong>Geladeira:</strong>{" "}
            {this.props.order && this.props.order.Fridge ? this.props.order.Fridge.gebra : ""}
          </p>
          <p>
            <strong>Código ocasional:</strong>{" "}
            {this.props.order && this.props.order.Fridge ? this.props.order.Fridge.cod_cliente : ""}
          </p>
          <p>
            <strong>Depósito:</strong>{" "}
            {this.props.order && this.props.order.Fridge ? this.props.order.Fridge.deposito : ""}
          </p>
          <p>
            <strong>Endereço:</strong>
            <br />
            {this.props.order && this.props.order.Fridge ? this.props.order.Fridge.local : ""}
            <br />
            {this.props.order && this.props.order.Fridge
              ? this.props.order.Fridge.logradouro
              : ""}{" "}
            {this.props.order && this.props.order.Fridge ? this.props.order.Fridge.complemento : ""}{" "}
            {this.props.order && this.props.order.Fridge ? this.props.order.Fridge.numero : ""}
            <br />
            {this.props.order && this.props.order.Fridge ? this.props.order.Fridge.localidade : ""}
            <br />
            {this.props.order && this.props.order.Fridge ? this.props.order.Fridge.bairro : ""}
            <br />
            {this.props.order && this.props.order.Fridge ? this.props.order.Fridge.uf : ""}
            <br />
            {this.props.order && this.props.order.Fridge ? this.props.order.Fridge.cep : ""}
          </p>
          <p>
            <strong>Motorista:</strong>{" "}
            {this.props.order && this.props.order.User ? this.props.order.User.name : ""}
          </p>
          {this.props.order.reviewed === true && (
            <p>
              <strong>Aprovado por:</strong>{" "}
              {this.props.order && this.props.order.reviewer ? this.props.order.reviewer.name : ""}
            </p>
          )}
          {this.props.order.reviewed === true &&
            this.props.order.reviewed_at &&
            this.props.order.reviewed_at !== "" && (
              <p>
                <strong>Data da aprovação:</strong>{" "}
                {this.props.order && this.props.order.reviewed_at
                  ? moment(this.props.order.reviewed_at).format("LLL")
                  : ""}
              </p>
            )}
        </CardBody>
      </Card>
    );
  }

  renderProducts() {
    const { classes } = this.props;
    if (!this.props.order || !this.props.order.AuditProducts) {
      return;
    }
    const tableData = this.props.order.AuditProducts.map((product) => {
      return [
        product.Product.name,
        product.Product.sku,
        product.price.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        product.stock_diff * -1,
        (product.price * product.stock_diff * -1).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
      ];
    });
    const amount = this.props.order.AuditProducts.map(
      (product) => product.price * product.stock_diff * -1
    )
      .reduce((total, num) => total + num)
      .toLocaleString("pt-BR", { style: "currency", currency: "BRL" });

    tableData.push({ total: true, amount, colspan: 3 });
    return (
      <Card>
        <CardBody>
          <Table
            tableHeaderColor="primary"
            tableHead={["Produto", "SKU", "Preço", "Qtd", "Total"]}
            tableData={tableData}
            coloredColls={[4]}
            colorsColls={["primary"]}
          />
        </CardBody>
      </Card>
    );
  }

  renderTransaction() {
    const { classes } = this.props;
    let payment_data =
      this.props.order && this.props.order.payment_data ? this.props.order.payment_data : {};
    // console.log(payment_data);

    // console.log(this.props.order);

    const cpf = this.props.order ? this.props.order.cpf : "";
    const birthday = this.props.order
      ? moment(this.props.order.birthday, "YYYY-MM-DD").format("DD/MM/YYYY")
      : "";

    // let new_payment_data =
    //   this.props.order && this.props.order.payment_data
    //     ? this.props.order.payment_data
    //         .split("\n")
    //         .map((paragraph, index) => <p key={index}>{paragraph}</p>)
    //     : "";
    // console.log(new_payment_data);

    const clientName =
      payment_data &&
      payment_data.payments &&
      payment_data.payments.length > 0 &&
      payment_data.payments[0].paymentFields &&
      payment_data.payments[0].paymentFields.clientName
        ? payment_data.payments[0].paymentFields.clientName
        : this.props.order.customer_name
          ? this.props.order.customer_name
          : "";
    const mask =
      payment_data &&
      payment_data.payments &&
      payment_data.payments.length > 0 &&
      payment_data.payments[0].mask
        ? payment_data.payments[0].mask
        : this.props.order.payment_cardnumber
          ? this.props.order.payment_cardnumber
          : "";
    const brand =
      payment_data &&
      payment_data.payments &&
      payment_data.payments.length > 0 &&
      payment_data.payments[0].brand
        ? payment_data.payments[0].brand
        : this.props.order.payment_band
          ? this.props.order.payment_band
          : "";
    const typeName =
      payment_data &&
      payment_data.payments &&
      payment_data.payments.length > 0 &&
      payment_data.payments[0].paymentFields &&
      payment_data.payments[0].paymentFields.typeName
        ? payment_data.payments[0].paymentFields.typeName
        : this.props.order.payment_type
          ? this.props.order.payment_type
          : "";
    const authCode =
      payment_data &&
      payment_data.payments &&
      payment_data.payments.length > 0 &&
      payment_data.payments[0].authCode
        ? payment_data.payments[0].authCode
        : this.props.order.payment_aut
          ? this.props.order.payment_aut
          : "";
    const cieloCode =
      payment_data &&
      payment_data.payments &&
      payment_data.payments.length > 0 &&
      payment_data.payments[0].cieloCode
        ? payment_data.payments[0].cieloCode
        : this.props.order.payment_nsu
          ? this.props.order.payment_nsu
          : "";
    const updatedAt =
      payment_data && payment_data.createdAt
        ? new Date(payment_data.updatedAt).toLocaleString("pt-BR")
        : this.props.order.createdAt
          ? moment(this.props.order.createdAt).format("LLL")
          : "";
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>Dados da Nota Fiscal</h4>
        </CardHeader>
        <CardBody>
          <strong>CPF:</strong> {cpf}
          <br />
          <strong>Data de Nascimento:</strong> {birthday}
        </CardBody>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>Informações de Pagamento</h4>
        </CardHeader>
        <CardBody>
          <strong>Nome titular do Cartão:</strong> {clientName}
          <br />
          <strong>Número do Cartão:</strong> {mask}
          <br />
          <strong>Bandeira do Cartão:</strong> {brand}
          <br />
          <strong>Tipo de Pagamento:</strong> {typeName}
          <br />
          <strong>Código de Autorização:</strong> {authCode}
          <br />
          <strong>NSU:</strong> {cieloCode}
          <br />
          <strong>Data e Hora da Transação:</strong> {updatedAt}
        </CardBody>
        {/* "clientName":"CRISTIAN R ARAUJO C", (Nome titular do Cartão)

        "mask":"************3007", (Número do Cartão)

        "brand":"VISA", (Bandeira do Cartão)

        "typeName":"VENDA A DEBITO", (Tipo de Pagamento)

        "authCode":"160135", (Código de Autorização)

        "cieloCode":"165762", (NSU)

        "updatedAt":"Aug 19, 2020 4:42:23 PM"} (Data e Hora da Transação) */}
        {/* <CardBody>{new_payment_data}</CardBody> */}
      </Card>
    );
  }

  renderActions() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>Ações do pedido</h4>
        </CardHeader>
        <CardBody>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Ação
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={this.state.action}
              onChange={(event) => this.setState({ [event.target.name]: event.target.value })}
              inputProps={{
                name: "action",
                id: "simple-select",
              }}
            >
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
              >
                Selecione uma ação
              </MenuItem>
              {this.props.order.sap_200 === 0 && (
                <MenuItem
                  key="sap"
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="sap"
                >
                  Enviar para o SAP
                </MenuItem>
              )}
              <MenuItem
                key="sendSyndicEmail"
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value="sendSyndicEmail"
              >
                Enviar e-mail para o síndico
              </MenuItem>
              {/* {!this.props.order.reversal ? (
                <MenuItem
                  key="estorno"
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="estorno"
                >
                  Definir como estornado
                </MenuItem>
              ) : null} */}
            </Select>
          </FormControl>
        </CardBody>
        {this.state.action ? (
          <CardFooter>
            <Button color="primary" onClick={() => this.execAction()}>
              Executar
            </Button>
          </CardFooter>
        ) : null}
      </Card>
    );
  }

  renderSapLogs() {
    return this.state.sapLogs.map((log, index) => {
      // console.log(log);
      const {
        status,
        message,
        data,
        createdAt,
        sap_message,
        sap_order,
        sap_fornecimento,
        sap_fatura,
      } = log;
      const date = createdAt ? new Date(createdAt).toLocaleString("pt-BR") : null;
      return (
        <div key={index}>
          <strong>{date}</strong>
          <br />
          <strong>Mensagem: </strong>
          {message}
          <br />
          <strong>Status: </strong>
          {status}

          {sap_message && sap_message != "" ? <br /> : null}
          {sap_message && sap_message != "" ? <strong>Mensagem do SAP: </strong> : null}
          {sap_message && sap_message != "" ? sap_message : null}

          {sap_order && sap_order != "" ? <br /> : null}
          {sap_order && sap_order != "" ? <strong>Pedido do SAP: </strong> : null}
          {sap_order && sap_order != "" ? sap_order : null}

          {sap_fornecimento && sap_fornecimento != "" ? <br /> : null}
          {sap_fornecimento && sap_fornecimento != "" ? (
            <strong>Fornevimento do SAP: </strong>
          ) : null}
          {sap_fornecimento && sap_fornecimento != "" ? sap_fornecimento : null}

          {sap_fatura && sap_fatura != "" ? <br /> : null}
          {sap_fatura && sap_fatura != "" ? <strong>Fatura do SAP: </strong> : null}
          {sap_fatura && sap_fatura != "" ? sap_fatura : null}

          {data && data != "" ? <br /> : null}
          {data && data != "" ? <strong>XML: </strong> : null}
          {data && data != "" ? data : null}
          {data && data != "" ? <br /> : null}
          <hr />
        </div>
      );
    });
  }

  renderNotes() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>Notas do pedido</h4>
        </CardHeader>
        <CardBody>{this.renderSapLogs()}</CardBody>
      </Card>
    );
  }

  renderSyndicEmailsSentLogs() {
    return this.state.syndicEmailLogs.map((log, index) => {
      // console.log(log);
      const {
        status,
        message,
        createdAt,
      } = log;
      const date = createdAt ? new Date(createdAt).toLocaleString("pt-BR") : null;
      return (
        <div key={index}>
          <strong>{date}</strong>
          <br />
          <strong>Mensagem: </strong>
          {message}
          <br />
          <strong>Status: </strong>
          {status}
          <hr />
        </div>
      );
    });
  }

  renderSyndicEmailsSent() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>Envios de e-mail</h4>
        </CardHeader>
        <CardBody>{this.renderSyndicEmailsSentLogs()}</CardBody>
      </Card>
    );
  }

  render() {
    const { classes } = this.props;
    // console.log('render(): ', this.props)
    return (
      <GridContainer justify="center">
        {this.state.loading ? <Loader /> : null}
        <GridItem xs={12} sm={12} md={12}>
          {this.renderHeader()}
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          {this.renderDetails()}
          {this.renderProducts()}
          {/* {this.renderTransaction()} */}
          {this.renderNotes()}
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          {this.renderActions()}
          {this.renderSyndicEmailsSent()}
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('mapStateToProps: ', state)
  return {
    HideForm: state.orders.HideForm,
    order: state.orders.order,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      HideForm,
      save,
      showNotification,
    },
    dispatch
  );
const ReturnClassDecorated = withStyles(validationFormsStyle)(ValidationForms);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnClassDecorated);
