import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { Redirect, Link } from "react-router-dom";
import axios from "axios";
import consts from "../../consts";

import If from "../../common/operators/If";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ShowForm,
  ShowProducts,
  save,
  showNotification,
  // get
} from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import CategoryIcon from "@material-ui/icons/Category";

// import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import { dataTable } from "variables/general.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

class ReactTables extends React.Component {
  // history = useHistory();

  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      data: [],
      pages: -1,
      loading: true,
      alert: null,
      show: false,
    };
  }

  // componentWillMount(){
  // this.get();
  // }

  // componentDidMount(){
  // this.getReportData();
  // }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  delete(item) {
    const that = this;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Tem certeza?"
          onConfirm={() => {
            // console.log(item)
            axios
              .delete(`${consts.API_URL}/planograms/${item.id}`)
              .then((resp) => {
                var data = this.state.data;
                data.find((o, i) => {
                  if (o.id === item.id) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    data.splice(i, 1);
                    return true;
                  }
                  return false;
                });

                let configNotification = {
                  message: "Planograma excluído com sucesso.",
                  color: "success",
                };
                that.props.showNotification(configNotification);
                this.setState({ data: data });
              })
              .catch((err) => {
                let configNotification = {
                  message: "Erro ao excluir planograma.",
                  color: "error",
                };
                that.props.showNotification(configNotification);
              });
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Sim, excluir!"
          cancelBtnText="Cancelar"
          showCancel
        >
          Não tem como recuperar o registro ao excluir!
        </SweetAlert>
      ),
    });
  }

  setData(result) {
    var data = result.map((item) => {
      return {
        id: item.id,
        name: item.name,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => this.props.ShowProducts(item)}
              color="primary"
              className="edit"
              title="Produtos"
            >
              <CategoryIcon />
            </Button>{" "}
            <Button
              justIcon
              round
              simple
              onClick={() => this.props.ShowForm(item)}
              color="warning"
              className="edit"
              title="Editar"
            >
              <EditIcon />
            </Button>{" "}
            {/* use this button to remove the data row */}
            <Button
              justIcon
              round
              simple
              onClick={() => this.delete(item)}
              color="danger"
              className="remove"
              title="Excluir"
            >
              <Close />
            </Button>{" "}
          </div>
        ),
      };
    });

    this.setState({ data });
  }

  // get(){
  // axios.get(`${consts.API_URL}/planograms`)
  // .then(resp => {
  // console.log(resp)
  // this.setData(resp.data.result)
  // })
  // }

  // getReportData(){
  // axios.get(`${consts.API_URL}/planograms/report`)
  // .then(resp => {
  // console.log(resp)
  // this.setState({reportData: resp.data})
  // })
  // }

  // renderDownloadXLS(){
  //   const { classes } = this.props;
  //   // console.log('renderDownloadXLS')
  //   // return Promise.all([
  //   // ])
  //
  //   // console.log(report)
  //   return (
  //           <ExcelFile element={
  //               <Button
  //                 color="primary"
  //                 className={classes.updateProfileButton}
  //                 >
  //                 Download XLS
  //               </Button>
  //             }>
  //               <ExcelSheet data={this.state.reportData} name="Employees">
  //                   <ExcelColumn label="Nome" value="name"/>
  //                   <ExcelColumn label="E-mail" value="email"/>
  //                   <ExcelColumn label="CPF" value="cpf"/>
  //                   <ExcelColumn label="Telefone" value="tel"/>
  //                   <ExcelColumn label="Data de nascimento" value="birthday"/>
  //                   <ExcelColumn label="Cep" value="cep"/>
  //                   <ExcelColumn label="Status"
  //                                value={(col) => {
  //                                  var to_return = ""
  //                                  switch (col.active) {
  //                                    case 0:
  //                                      to_return = "Aguardando"
  //                                      break;
  //                                    case 1:
  //                                      to_return = "Aprovado"
  //                                      break;
  //                                    case 2:
  //                                      to_return = "Negado"
  //                                      break;
  //                                    default:
  //                                      to_return = "Aguardando"
  //                                      break;
  //                                  }
  //                                  return to_return
  //                                  // col.active ? "Married" : "Single"
  //                                }}
  //                   />
  //                   <ExcelColumn label="Validação do celular"
  //                                value={(col) => {
  //                                  var to_return = ""
  //                                  switch (col.validated_cel) {
  //                                    case 0:
  //                                      to_return = "Não Validado"
  //                                      break;
  //                                    case 1:
  //                                      to_return = "Validado"
  //                                      break;
  //                                    default:
  //                                      to_return = "Não Validado"
  //                                      break;
  //                                  }
  //                                  return to_return
  //                                  // col.active ? "Married" : "Single"
  //                                }}
  //                   />
  //                 <ExcelColumn label="Virtuais promocionais" value="virtuais_promocionais"/>
  //                 <ExcelColumn label="Físicas" value="fisicas"/>
  //                 <ExcelColumn label="Virtuais sem as promocionais" value="virtuais_exceto_promocionais"/>
  //                 <ExcelColumn label="Brindes" value="brindes"/>
  //               </ExcelSheet>
  //           </ExcelFile>
  //       );
  // }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.alert}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <MaterialIconList />
                </CardIcon>
                <Button
                  color="primary"
                  className={classes.updateProfileButton}
                  onClick={() => this.props.ShowForm()}
                >
                  Adicionar
                </Button>
                {/* <h4 className={classes.cardIconTitle}>Lista de membros</h4> */}
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={this.state.data}
                  pages={this.state.pages}
                  loading={this.state.loading}
                  filterable={true}
                  columns={[
                    {
                      Header: "Nome",
                      accessor: "name",
                    },
                    {
                      Header: "Ações",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                  defaultPageSize={20}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  previousText="Anterior"
                  nextText="Próxima"
                  loadingText="Carregando"
                  noDataText="Nenhum registro encontrado"
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                  manual
                  onFetchData={(state, instance) => {
                    // show the loading overlay
                    this.setState({ loading: true }, () => {
                      // console.log(state.filtered)
                      const sorted =
                        state.sorted.length === 0 ? "" : state.sorted[0].id;
                      const order =
                        state.sorted.length > 0 && state.sorted[0].desc
                          ? "desc"
                          : "asc";
                      const filtered = btoa(JSON.stringify(state.filtered));

                      // fetch your data
                      axios
                        .get(
                          `${consts.API_URL}/planograms?page=${state.page +
                            1}&qtd_per_page=${
                            state.pageSize
                          }&sorted=${sorted}&order=${order}&filtered=${filtered}`,
                          {
                            page: state.page,
                            pageSize: state.pageSize,
                            sorted: state.sorted,
                            filtered: state.filtered,
                          }
                        )
                        .then((res) => {
                          // console.log(res)
                          // console.log(state.sorted)
                          // console.log(state.filtered)
                          // Update react-table
                          this.setState(
                            {
                              pages: res.data.pages,
                              loading: false,
                            },
                            () => {
                              this.setData(res.data.result);
                            }
                          );
                        });
                    });
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log('state: ', state)
//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = (state) => ({
  ShowForm: state.ShowForm,
  ShowProducts: state.ShowProducts,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      ShowProducts,
      save,
      showNotification,
      // get
    },
    dispatch
  );
const MemberList = withStyles(sweetAlertStyle)(ReactTables);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberList);
