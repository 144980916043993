import React from "react";
import List from "./List";
import Form from "./Form";
import FormReport from "./FormReport";
import FormSyndic from "./FormSyndic";
import FormMarketing from "./FormMarketing";
import If from "../../common/operators/If";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ShowForm, HideForm, ShowFormReport, HideFormReport } from "./Actions";
import consts from "../../consts";

class Main extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    props.HideForm();
    props.HideFormReport();
    // console.log(this.me);
    // props.ShowForm();
  }

  render() {
    const me = JSON.parse(localStorage.getItem(consts.UserKey)).user;
    return (
      <div>
        <If test={!this.props.showForm}>
          <If test={!this.props.showFormReport}>
            <List parentProps={this.props} />
          </If>
          <If test={this.props.showFormReport}>
            <FormReport />
          </If>
        </If>
        <If test={this.props.showForm}>
          <If test={me.profile === "syndic"}>
            <FormSyndic />
          </If>
          <If test={me.profile === "marketing"}>
            <FormMarketing />
          </If>
          <If test={me.profile !== "syndic" && me.profile !== "marketing"}>
            <Form />
          </If>
        </If>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state)
  return {
    showForm: state.orders.showForm,
    showBookingForm: state.orders.showBookingForm,
    showFormReport: state.orders.showFormReport,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      HideForm,
      ShowFormReport,
      HideFormReport,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
