import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// import { Link } from "react-router-dom";
import axios from "axios";
import consts from "../../consts";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ShowForm,
  save,
  showNotification,
  // get
} from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInputCep from "components/CustomInput/CustomInputCep.jsx";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";
import EditIcon from "@material-ui/icons/Edit";
import { Favorite } from "@material-ui/icons";

// import Assignment from "@material-ui/icons/Assignment";
// import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Icon from "@material-ui/icons/Visibility";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

// import { dataTable } from "variables/general.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { Link } from "react-router-dom";
import Loader from "components/Loader/Loader.jsx";

import EquipmentMenu from "../equipements/Menu";

import moment from "moment";
import "moment/locale/pt-br";

// import ReactExport from "react-export-excel";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    // console.log("props: ", props);
    this.state = {
      data: [],
      pages: -1,
      loading: true,
      hideTable: false,
      alert: null,
      show: false,
      chargeback: null,
      statuses: [],
      status_id: "",
      filter_status_id: "",
      order: null,
    };
  }

  componentWillMount() {
    this.getStatus();
  }

  getStatus() {
    this.setState({
      loading: true,
    });

    axios
      .get(`${consts.API_URL}/chargeback-status/select`)
      .then((resp) => {
        this.setState({
          statuses: resp.data.result || [],
          loading: false,
        });
      })
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // console.log(err);
      });
  }

  // componentDidMount(){
  // this.getReportData();
  // }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  showChargeback(item) {
    // console.log(item);
    this.setState({
      show: true,
      chargeback: item,
      status_id: item.status_id || "",
    });
  }
  hideChargeback() {
    this.setState({
      show: false,
      chargeback: null,
      status_id: "",
    });
  }

  handleSimple = (event) => {
    // console.log(event)
    this.setState(
      {
        hideTable: true,
        [event.target.name]: event.target.value,
      },
      () => setTimeout(() => this.setState({ hideTable: false }), 500)
    );
  };

  save() {
    this.setState({
      loading: true,
    });

    const { status_id } = this.state;

    axios
      .put(`${consts.API_URL}/chargebacks/${this.state.chargeback.id}`, { status_id })
      .then((resp) => {
        let configNotification = {
          message: "Chargeback atualizado com sucesso.",
          color: "success",
        };

        this.props.showNotification(configNotification);
        this.setState({
          chargeback: resp.data,
          loading: false,
        });
      })
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // console.log(err);
      });
  }

  start() {
    this.setState({
      loading: true,
    });

    const { status_id } = this.state;

    axios
      .put(`${consts.API_URL}/chargebacks/${this.state.chargeback.id}/start`)
      .then((resp) => {
        let configNotification = {
          message: "Chargeback iniciada com sucesso.",
          color: "success",
        };

        this.props.showNotification(configNotification);
        this.setState({
          chargeback: resp.data,
          loading: false,
        });
      })
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // console.log(err);
      });
  }

  confirmFinish() {
    this.setState({
      loading: true,
    });

    axios
      .put(`${consts.API_URL}/chargebacks/${this.state.chargeback.id}/finish`)
      .then((resp) => {
        let configNotification = {
          message: "Chargeback finalizada com sucesso.",
          color: "success",
        };

        this.props.showNotification(configNotification);
        this.setState({
          chargeback: resp.data,
          loading: false,
        });
      })
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // console.log(err);
      });
  }

  finish() {
    const that = this;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Tem certeza?"
          onConfirm={() => {
            // console.log(item)
            this.confirmFinish();
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
          cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
          confirmBtnText="Sim, finalizar!"
          cancelBtnText="Cancelar"
          showCancel
        >
          Essa ação só poderá ser realizada após a confirmação do estorno pelo consumidor.
          <br />
          Não esqueça de encerrar a ficha no SAB.
        </SweetAlert>
      ),
    });
  }

  setData(result) {
    var data = result.map((item) => {
      return {
        id: item.id,
        order: item.order_id,
        sab_id_manif: item.sab_id_manif,
        status:
          item.ChargebackStatus && item.ChargebackStatus.description
            ? item.ChargebackStatus.description
            : "Enviado para a área",
        date: moment(item.created_at).format("DD/MM/YYYY HH:mm"),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => this.showChargeback(item)}
              color="primary"
              className="edit"
            >
              <Icon />
            </Button>{" "}
          </div>
        ),
      };
    });

    this.setState({ data });
  }

  renderList() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <MaterialIconList />
          </CardIcon>
          <Button
            color="primary"
            className={classes.updateProfileButton}
            onClick={() => this.props.ShowForm()}
          >
            Adicionar
          </Button>
          {/* <h4 className={classes.cardIconTitle}>Lista de membros</h4> */}
        </CardHeader>
        <CardBody>
          <GridItem xs={12} sm={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                Filtrar por Status
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={this.state.filter_status_id}
                onChange={this.handleSimple}
                inputProps={{
                  name: "filter_status_id",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="-1"
                >
                  Todos
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="0"
                >
                  Abertos
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="1"
                >
                  Fechados
                </MenuItem>
                {this.state.statuses.map((status) => {
                  return (
                    <MenuItem
                      key={status.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={status.id}
                    >
                      {status.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          <br />
          {!this.state.hideTable && (
            <ReactTable
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              filterable={true}
              columns={[
                {
                  Header: "Pedido",
                  accessor: "order",
                },
                {
                  Header: "SAB",
                  accessor: "sab_id_manif",
                },
                {
                  Header: "STATUS",
                  accessor: "status",
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: "data",
                  accessor: "date",
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: "Ações",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                },
              ]}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultPageSize={20}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
              previousText="Anterior"
              nextText="Próxima"
              loadingText="Carregando"
              noDataText="Nenhum registro encontrado"
              pageText="Página"
              ofText="de"
              rowsText="registros"
              manual
              onFetchData={(state, instance) => {
                // show the loading overlay
                this.setState({ loading: true }, () => {
                  // console.log(state.filtered)
                  const sorted = state.sorted.length === 0 ? "" : state.sorted[0].id;
                  const order = state.sorted.length > 0 && state.sorted[0].desc ? "desc" : "asc";
                  let filtered = [
                    ...state.filtered,
                    { id: "status_id", value: this.state.filter_status_id },
                  ];

                  filtered = btoa(JSON.stringify(filtered));

                  // fetch your data
                  axios
                    .get(
                      `${consts.API_URL}/chargebacks?page=${state.page + 1}&qtd_per_page=${
                        state.pageSize
                      }&sorted=${sorted}&order=${order}&filtered=${filtered}`,
                      {
                        page: state.page,
                        pageSize: state.pageSize,
                        sorted: state.sorted,
                        filtered: state.filtered,
                      }
                    )
                    .then((res) => {
                      // console.log(res)
                      // console.log(state.sorted)
                      // console.log(state.filtered)
                      // Update react-table
                      this.setState(
                        {
                          pages: res.data.pages,
                          loading: false,
                        },
                        () => {
                          this.setData(res.data.result);
                        }
                      );
                    });
                });
              }}
            />
          )}
        </CardBody>
      </Card>
    );
  }

  renderFilteredRegister() {
    if (!this.state.chargeback.Order || !this.state.chargeback.Order.id) {
      return;
    }
    const { classes } = this.props;
    const order = this.state.chargeback.Order;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="warning" icon>
              <h4
                className={classes.cardIconTitle}
                style={{
                  color: "black",
                }}
              >
                Pedido: {order.id}
              </h4>
            </CardHeader>
            <CardBody>
              <p>
                <strong>Geladeira:</strong> {order.Fridge.gebra}
                <br />
                <strong>Local:</strong> {order.Fridge.local}
                <br />
                <strong>Valor:</strong> R${" "}
                {order.OrderProducts.reduce((total, item) => total + item.qtd * item.price, 0)
                  .toString()
                  .split(".")
                  .join(",")}
                <br />
                <strong>Bandeira:</strong> {order.payment_band}
                <br />
                <strong>Número do cartão:</strong> {order.payment_cardnumber}
                <br />
                <strong>Forma de pagamento:</strong> {order.payment_type}
                <br />
                <strong>Nome no cartão:</strong> {order.customer_name}
                <br />
                <strong>Data e hora:</strong> {moment(order.createdAt).format("DD/MM/YYYY HH:mm")}
              </p>

              <p>
                <strong>PRODUTOS</strong>
              </p>
              <p>
                {order.OrderProducts.map(
                  (product) => `${product.product_name} - Qtd: ${product.qtd}`
                ).join("<br/>")}
              </p>
              <br />
              {this.state.chargeback.started_by && (
                <p>
                  <strong>Ficha SAB:</strong> {this.state.chargeback.sab_id_manif}
                  <br />
                  <strong>Nome do consumidor:</strong> {this.state.chargeback.contact_costumer}
                  <br />
                  <strong>WhatsApp:</strong> {this.state.chargeback.contact_tel}
                  <br />
                  <strong>Observação:</strong> {this.state.chargeback.contact_obs}
                </p>
              )}
              {this.state.chargeback.started_by && (
                <p>
                  <strong>Histórico</strong>
                </p>
              )}
              {this.state.chargeback.started_by &&
                this.state.chargeback.ChargebackEvents.map((event) => <p>{event.description}</p>)}
              <br />
              {this.state.chargeback.started_by &&
                !this.state.chargeback.finished_by && (
                  <GridItem xs={12} sm={12}>
                    <FormControl fullWidth className={classes.selectFormControl}>
                      <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                        Status
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={this.state.status_id}
                        onChange={this.handleSimple}
                        inputProps={{
                          name: "status_id",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                        >
                          Selecione
                        </MenuItem>
                        {this.state.statuses.map((status) => {
                          return (
                            <MenuItem
                              key={status.id}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={status.id}
                            >
                              {status.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                )}
            </CardBody>
            {!this.state.chargeback.started_by && (
              <CardFooter>
                <Button fullWidth color="primary" onClick={() => this.start()}>
                  Iniciar atendimento
                </Button>
              </CardFooter>
            )}
            {this.state.chargeback.started_by &&
              !this.state.chargeback.finished_by && (
                <CardFooter>
                  <Button onClick={() => this.hideChargeback()}>Voltar</Button>
                  <Button color="success" onClick={() => this.finish()}>
                    Finalizar
                  </Button>
                  <Button color="primary" onClick={() => this.save()}>
                    Salvar
                  </Button>
                </CardFooter>
              )}
            {this.state.chargeback.started_by &&
              this.state.chargeback.finished_by && (
                <CardFooter>
                  <Button onClick={() => this.hideChargeback()}>Voltar</Button>
                </CardFooter>
              )}
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  render() {
    return (
      <div>
        {this.state.alert}
        {this.state.loading && <Loader />}
        <GridContainer>
          <GridItem xs={12}>
            {!this.state.show && this.renderList()}
            {this.state.show && this.renderFilteredRegister()}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log(state)
//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = (state) => ({
  ShowForm: state.ShowForm,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      save,
      showNotification,
      // get
    },
    dispatch
  );
const MemberList = withStyles(sweetAlertStyle)(ReactTables);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberList);
