const INITIAL_OBJ = {
  description: "",
  plate: "",
  number: "",
};
const INITIAL_STATE = {
  showForm: false,
  car: INITIAL_OBJ,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(INITIAL_OBJ)
  // console.log(state);
  // console.log(action);
  switch (action.type) {
    case "CAR_SHOW_FORM":
      return {
        ...state,
        showForm: true,
        car: action.payload === undefined ? INITIAL_OBJ : action.payload,
      };
    case "CAR_HIDE_FORM":
      return { ...state, showForm: false, car: INITIAL_OBJ };
    default:
      return state;
  }
};
