const INITIAL_STATE = {
  showForm: false,
  showFormReport: false,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(INITIAL_OBJ)
  // console.log(state);
  // console.log(action);
  switch (action.type) {
    case "AUDIT_SHOW_FORM":
      return { ...state, showForm: true };
    case "AUDIT_HIDE_FORM":
      return { ...state, showForm: false };
    case "AUDIT_SHOW_FORM_REPORT":
      return { ...state, showFormReport: true };
    case "AUDIT_HIDE_FORM_REPORT":
      return { ...state, showFormReport: false };
    default:
      return state;
  }
};
