import React from "react";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import consts from "../../consts";
import socketIOClient from "socket.io-client";
import { HideForm, save, showNotification } from "./Actions";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInputCep from "components/CustomInput/CustomInputCep.jsx";

import PictureUpload from "components/CustomUpload/PictureUpload.jsx";

import Loader from "components/Loader/Loader.jsx";

// material ui icons
// import MailOutline from "@material-ui/icons/MailOutline";
// import Contacts from "@material-ui/icons/Contacts";
// import Check from "@material-ui/icons/Check";
// import Close from "@material-ui/icons/Close";
// import CategoryIcon from "@material-ui/icons/Category"
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomInputCPF from "components/CustomInput/CustomInputCPF.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Table from "components/Table/Table.jsx";

import If from "../../common/operators/If";

// style for this view
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";

const styles = {
  ...validationFormsStyle,
  ...sweetAlertStyle,
};

class ValidationForms extends React.Component {
  constructor(props) {
    super(props);
    // console.log("props: ", props);
    // console.log("props.product_return: ", props.product_return);
    this.me = JSON.parse(localStorage.getItem(consts.UserKey)).user;
    // console.log("me: ", this.me);
    this.state = {
      loading: false,
      action: "",
      alert: null,
      product_return: props.product_return ? props.product_return : null,
      id: props.product_return ? props.product_return.id : "",
      sapLogs: props.product_return ? props.product_return.ProductReturnSapLogs : [],
    };
    this.registerClick = this.registerClick.bind(this);
    this.loginClick = this.loginClick.bind(this);
    this.typeClick = this.typeClick.bind(this);
    this.rangeClick = this.rangeClick.bind(this);
    this.getSapLogs();
  }

  componentDidMount() {
    this.socketConnect();
  }

  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  cancelButtonClick() {
    this.props.HideForm();
  }

  socketConnect() {
    const that = this;

    const socket = socketIOClient(consts.SOCKET_URL, {
      query: { app: "admin" },
    });
    // socket = this.props.socket;
    // const socket = this.props.socket;
    // socket.on("connect", () => console.log("connected"));
    // socket.on("teste", (data) => console.log("data: ", data));
    // socket.on("statusChanged", (resp) => {
    //   console.log("resp: ", resp);
    // });

    socket.on("ProductReturnControllerCompletedAdmin", (resp) => {
      // console.log("resp: ", resp);
      const { message, status } = resp;
      const configNotification = {
        message,
        color: status === 200 ? "success" : "danger",
      };
      that.props.showNotification(configNotification);
      // console.log("state: ", that.state);
      // if (resp.fridge_id == that.state.fridge_id) {
      //   // const configNotification = {
      //   //   message: resp.message,
      //   //   color: resp.status == 200 ? "success" : "danger",
      //   // };
      //   // this.props.showNotification(configNotification);
      //   that.setState(
      //     {
      //       // reloading: false,
      //       fridge_id: null
      //     },
      //     that.get()
      //   );
      // }
    });

    // socket.on("PiclistSapControllerCreated", resp => {
    //   // console.log("resp: ", resp);
    //   // console.log("state: ", that.state);
    //   if (resp.piclist_id == that.state.piclist_id) {
    //     const configNotification = {
    //       message: resp.message,
    //       color: resp.status == 200 ? "success" : "danger"
    //     };
    //     this.props.showNotification(configNotification);
    //     that.setState(
    //       {
    //         // loading: false,
    //         piclist_id: null
    //       },
    //       that.get()
    //     );
    //   }
    // });
    // socket.on("DeliveryPiclistControllerChanged", resp => {
    //   console.log("DeliveryPiclistControllerChanged resp: ", resp);
    //   // console.log("resp: ", resp);
    //   // console.log("props: ", that.props);
    //   // if (resp.delivery_id == that.props.deliveryId) {
    //   that.get();
    //   // }
    // });
    // this.setState({ socket });
  }

  saveButtonClick() {
    // console.log(this.props.product_return)
    var product_return = { ...this.props.product_return };

    product_return.name = this.state.name;
    product_return.sku = this.state.sku;
    product_return.image = this.state.image;

    this.props.save(product_return);
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  confirm() {
    if (this.me.profile == "syndic" || this.me.profile == "marketing") {
      return false;
    }
    const that = this;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Tem certeza?"
          onConfirm={() => {
            // console.log(item)
            that.setState({
              loading: true,
            });
            axios
              .put(`${consts.API_URL}/product-returns/${this.state.product_return.id}/completed`)
              .then((resp) => {
                var data = this.state.data;
                const message =
                  resp && resp.data && resp.data.message
                    ? resp.data.message
                    : "Produtos retornados com sucesso.";
                let configNotification = {
                  message,
                  color: "info",
                };
                that.props.showNotification(configNotification);
                that.setState({
                  loading: false,
                  product_return: { ...this.state.product_return, status: 1 },
                });

                // this.setState({ data: data });
              })
              .catch((err) => {
                const message =
                  err.response && err.response.data && err.response.data.message
                    ? err.response.data.message
                    : "Erro ao conectar com SAP.";
                let configNotification = {
                  message,
                  color: "danger",
                };
                that.props.showNotification(configNotification);
                that.setState({
                  loading: false,
                });
              });
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
          cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
          confirmBtnText="Sim, concluir!"
          cancelBtnText="Desistir"
          showCancel
        >
          Não tem como voltar ao status atual após confirmar!
        </SweetAlert>
      ),
    });
  }

  cancel() {
    if (this.me.profile == "syndic" || this.me.profile == "marketing") {
      return false;
    }
    const that = this;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Tem certeza?"
          onConfirm={() => {
            // console.log(item)
            that.setState({
              loading: true,
            });
            axios
              .put(`${consts.API_URL}/product-returns/${this.state.product_return.id}/canceled`)
              .then((resp) => {
                var data = this.state.data;
                let configNotification = {
                  message: "Retorno cancelado com sucesso.",
                  color: "success",
                };
                that.props.showNotification(configNotification);
                that.setState({
                  loading: false,
                  product_return: { ...this.state.product_return, status: 2 },
                });
                // this.setState({ data: data });
              })
              .catch((err) => {
                let configNotification = {
                  message: "Erro ao excluir produto.",
                  color: "danger",
                };
                that.props.showNotification(configNotification);
                that.setState({
                  loading: false,
                });
              });
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
          cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
          confirmBtnText="Sim, cancelar!"
          cancelBtnText="Desistir"
          showCancel
        >
          Não tem como voltar ao status atual após cancelar!
        </SweetAlert>
      ),
    });
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  // function that verifies if two strings are equal
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  // function that verifies if value contains only numbers
  verifyNumber(value) {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }
  // verifies if value is a valid URL
  verifyUrl(value) {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  }
  change(event, stateName, type, stateNameEqualTo, maxValue) {
    // console.log(event)
    // console.log(this.state)
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "checkbox":
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-length":
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "min-value":
        if (this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-value":
        if (this.verifyNumber(event.target.value) && event.target.value <= stateNameEqualTo) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "range":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }
  registerClick() {
    if (this.state.registerEmailState === "") {
      this.setState({ registerEmailState: "error" });
    }
    if (this.state.registerPasswordState === "") {
      this.setState({ registerPasswordState: "error" });
    }
    if (this.state.registerConfirmPasswordState === "") {
      this.setState({ registerConfirmPasswordState: "error" });
    }
    if (this.state.registerCheckboxState === "") {
      this.setState({ registerCheckboxState: "error" });
    }
  }
  loginClick() {
    if (this.state.loginEmailState === "") {
      this.setState({ loginEmailState: "error" });
    }
    if (this.state.loginPasswordState === "") {
      this.setState({ loginPasswordState: "error" });
    }
  }
  typeClick() {
    if (this.state.requiredState === "") {
      this.setState({ requiredState: "error" });
    }
    if (this.state.typeEmailState === "") {
      this.setState({ typeEmailState: "error" });
    }
    if (this.state.numberState === "") {
      this.setState({ numberState: "error" });
    }
    if (this.state.urlState === "") {
      this.setState({ urlState: "error" });
    }
    if (this.state.equalToState === "") {
      this.setState({ equalToState: "error" });
    }
  }
  rangeClick() {
    if (this.state.minLengthState === "") {
      this.setState({ minLengthState: "error" });
    }
    if (this.state.maxLengthState === "") {
      this.setState({ maxLengthState: "error" });
    }
    if (this.state.rangeState === "") {
      this.setState({ rangeState: "error" });
    }
    if (this.state.minValueState === "") {
      this.setState({ minValueState: "error" });
    }
    if (this.state.maxValueState === "") {
      this.setState({ maxValueState: "error" });
    }
  }

  // getPicture(image){
  //   // console.log(image)
  //   if(image !== ''){
  //     this.setState({
  //       image,
  //       imageState: 'success'
  //     })
  //   }else{
  //     this.setState({
  //       image,
  //       imageState: 'error'
  //     })
  //   }
  //
  // }

  getSapLogs() {
    return true;
    this.setState({ loading: true });
    axios
      .get(`${consts.API_URL}/product_returns/${this.state.id}/sap`)
      .then((resp) => {
        // console.log("resp: ", resp);
        // const respFiltered = resp.data.result.filter((item => (item.status == 1)))
        // console.log('respFiltered: ', respFiltered)

        this.setState({ sapLogs: resp.data.result, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  }

  execSap() {
    // console.log("sap");
    // console.log('getReportData(){')
    this.setState({ loading: true });
    axios
      .put(`${consts.API_URL}/product-returns-canceled-piclists/${this.state.id}/sap`)
      .then((resp) => {
        // console.log("resp: ", resp);
        const configNotification = {
          message: resp.data.message,
          color: "success",
        };
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // this.getSapLogs();
        // const respFiltered = resp.data.result.filter((item => (item.status == 1)))
        // console.log('respFiltered: ', respFiltered)

        // this.setState({ reportData: resp.data });
      })
      .catch((err) => {
        console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        this.getSapLogs();
        console.log(err);
      });
  }

  execAction() {
    // console.log(this.state);
    switch (this.state.action) {
      case "sap":
        this.execSap();
        break;
    }
  }

  renderDetails1() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="warning" icon>
          <CardIcon color="warning">
            <AssignmentIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Cadastrar novo produto</h4>
        </CardHeader>
        <CardBody>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <GridItem xs={12}>
                  <CustomInput
                    success={this.state.nameState === "success"}
                    error={this.state.nameState === "error"}
                    labelText={<span>Nome</span>}
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => this.change(event, "name", "length", 3),
                      value: this.state.name,
                      type: "text",
                      placeholder: "Nome",
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <CustomInput
                    success={this.state.skuState === "success"}
                    error={this.state.skuState === "error"}
                    labelText={<span>SKU</span>}
                    id="sku"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => this.change(event, "sku", "length", 3),
                      value: this.state.sku,
                      type: "text",
                      placeholder: "SKU",
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <CustomInput
                    success={this.state.imageState === "success"}
                    error={this.state.imageState === "error"}
                    labelText={<span>Link da imagem</span>}
                    id="image"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => this.change(event, "image", "length", 3),
                      value: this.state.image,
                      type: "text",
                      placeholder: "Link da imagem",
                    }}
                  />
                </GridItem>
              </GridItem>
            </GridContainer>
          </form>
        </CardBody>
        <CardFooter>
          <Button onClick={() => this.cancelButtonClick()}>Cancelar</Button>
          <Button color="primary" onClick={() => this.saveButtonClick()}>
            Salvar
          </Button>
        </CardFooter>
      </Card>
    );
  }

  renderHeader() {
    const { classes } = this.props;
    return (
      <Card>
        <CardFooter>
          <Button onClick={() => this.cancelButtonClick()}>Voltar</Button>
          {this.state.product_return && this.state.product_return.status === 0 && false ? (
            <div>
              <Button color="danger" onClick={() => this.cancel()}>
                Cancelar
              </Button>
              <Button color="success" onClick={() => this.confirm()}>
                Concluir
              </Button>
            </div>
          ) : null}
        </CardFooter>
      </Card>
    );
  }

  renderDetails() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>
            Detalhes do retorno #{this.props.product_return.id}
          </h4>
        </CardHeader>
        <CardBody>
          <p>
            <strong>Status:</strong>{" "}
            {this.props.product_return && this.props.product_return.status === 0 ? "Pendente" : ""}
            {this.props.product_return && this.props.product_return.status === 1 ? "Concluído" : ""}
            {this.props.product_return && this.props.product_return.status === 2 ? "Cancelado" : ""}
          </p>
          <p>
            <strong>Data:</strong>{" "}
            {this.props.product_return.createdAt
              ? new Date(this.props.product_return.createdAt).toLocaleString("pt-BR")
              : ""}
          </p>
          <p>
            <strong>Geladeira:</strong>{" "}
            {this.props.product_return && this.props.product_return.Fridge
              ? this.props.product_return.Fridge.gebra
              : ""}
          </p>
          <p>
            <strong>Fatura:</strong>{" "}
            {this.props.product_return ? this.props.product_return.fatura : ""}
          </p>
          <p>
            <strong>Nota Fiscal:</strong>{" "}
            {this.props.product_return ? this.props.product_return.nf : ""}
          </p>
          <p>
            <strong>Endereço:</strong>
            <br />
            {this.props.product_return && this.props.product_return.Fridge
              ? this.props.product_return.Fridge.local
              : ""}
            <br />
            {this.props.product_return && this.props.product_return.Fridge
              ? this.props.product_return.Fridge.logradouro
              : ""}{" "}
            {this.props.product_return && this.props.product_return.Fridge
              ? this.props.product_return.Fridge.complemento
              : ""}{" "}
            {this.props.product_return && this.props.product_return.Fridge
              ? this.props.product_return.Fridge.numero
              : ""}
            <br />
            {this.props.product_return && this.props.product_return.Fridge
              ? this.props.product_return.Fridge.localidade
              : ""}
            <br />
            {this.props.product_return && this.props.product_return.Fridge
              ? this.props.product_return.Fridge.bairro
              : ""}
            <br />
            {this.props.product_return && this.props.product_return.Fridge
              ? this.props.product_return.Fridge.uf
              : ""}
            <br />
            {this.props.product_return && this.props.product_return.Fridge
              ? this.props.product_return.Fridge.cep
              : ""}
          </p>
          <br />
          {this.props.product_return &&
            this.props.product_return.ordem && (
              <p>
                <strong>Ordem:</strong> {this.props.product_return.ordem}
              </p>
            )}
          {this.props.product_return &&
            this.props.product_return.fornecimento && (
              <p>
                <strong>Fornecimento:</strong> {this.props.product_return.fornecimento}
              </p>
            )}
          {this.props.product_return &&
            this.props.product_return.fatura && (
              <p>
                <strong>Fatura:</strong> {this.props.product_return.fatura}
              </p>
            )}
        </CardBody>
      </Card>
    );
  }

  renderProducts() {
    const { classes } = this.props;
    const tableData = this.props.product_return.PiclistProducts.map((product) => {
      return [product.Product.name, product.Product.sku, product.qtd];
    });

    // tableData.push({ total: true, amount, colspan: 3 });
    return (
      <Card>
        <CardBody>
          <Table
            tableHeaderColor="primary"
            tableHead={["Produto", "SKU", "Qtd"]}
            tableData={tableData}
            coloredColls={[4]}
            colorsColls={["primary"]}
          />
        </CardBody>
      </Card>
    );
  }

  // renderTransaction() {
  //   const { classes } = this.props;
  //   let payment_data =
  //     this.props.product_return && this.props.product_return.payment_data
  //       ? JSON.parse(this.props.product_return.payment_data)
  //       : {};
  //   // console.log(payment_data);

  //   // console.log(this.props.product_return);

  //   const cpf = this.props.product_return ? this.props.product_return.cpf : "";
  //   const birthday = this.props.product_return
  //     ? new Date(this.props.product_return.birthday).toLocaleDateString("pt-BR")
  //     : "";

  //   // let new_payment_data =
  //   //   this.props.product_return && this.props.product_return.payment_data
  //   //     ? this.props.product_return.payment_data
  //   //         .split("\n")
  //   //         .map((paragraph, index) => <p key={index}>{paragraph}</p>)
  //   //     : "";
  //   // console.log(new_payment_data);

  //   const clientName =
  //     payment_data &&
  //     payment_data.payments &&
  //     payment_data.payments.length > 0 &&
  //     payment_data.payments[0].paymentFields &&
  //     payment_data.payments[0].paymentFields.clientName
  //       ? payment_data.payments[0].paymentFields.clientName
  //       : "";
  //   const mask =
  //     payment_data &&
  //     payment_data.payments &&
  //     payment_data.payments.length > 0 &&
  //     payment_data.payments[0].mask
  //       ? payment_data.payments[0].mask
  //       : "";
  //   const brand =
  //     payment_data &&
  //     payment_data.payments &&
  //     payment_data.payments.length > 0 &&
  //     payment_data.payments[0].brand
  //       ? payment_data.payments[0].brand
  //       : "";
  //   const typeName =
  //     payment_data &&
  //     payment_data.payments &&
  //     payment_data.payments.length > 0 &&
  //     payment_data.payments[0].paymentFields &&
  //     payment_data.payments[0].paymentFields.typeName
  //       ? payment_data.payments[0].paymentFields.typeName
  //       : "";
  //   const authCode =
  //     payment_data &&
  //     payment_data.payments &&
  //     payment_data.payments.length > 0 &&
  //     payment_data.payments[0].authCode
  //       ? payment_data.payments[0].authCode
  //       : "";
  //   const cieloCode =
  //     payment_data &&
  //     payment_data.payments &&
  //     payment_data.payments.length > 0 &&
  //     payment_data.payments[0].cieloCode
  //       ? payment_data.payments[0].cieloCode
  //       : "";
  //   const updatedAt =
  //     payment_data && payment_data.createdAt
  //       ? new Date(payment_data.updatedAt).toLocaleString("pt-BR")
  //       : "";
  //   return (
  //     <Card>
  //       <CardHeader>
  //         <h4 className={classes.cardIconTitle}>Dados da Nota Fiscal</h4>
  //       </CardHeader>
  //       <CardBody>
  //         <strong>CPF:</strong> {cpf}
  //         <br />
  //         <strong>Data de Nascimento:</strong> {birthday}
  //       </CardBody>
  //       <CardHeader>
  //         <h4 className={classes.cardIconTitle}>Informações de Pagamento</h4>
  //       </CardHeader>
  //       <CardBody>
  //         <strong>Nome titular do Cartão:</strong> {clientName}
  //         <br />
  //         <strong>Número do Cartão:</strong> {mask}
  //         <br />
  //         <strong>Bandeira do Cartão:</strong> {brand}
  //         <br />
  //         <strong>Tipo de Pagamento:</strong> {typeName}
  //         <br />
  //         <strong>Código de Autorização:</strong> {authCode}
  //         <br />
  //         <strong>NSU:</strong> {cieloCode}
  //         <br />
  //         <strong>Data e Hora da Transação:</strong> {updatedAt}
  //       </CardBody>
  //       {/* "clientName":"CRISTIAN R ARAUJO C", (Nome titular do Cartão)

  //       "mask":"************3007", (Número do Cartão)

  //       "brand":"VISA", (Bandeira do Cartão)

  //       "typeName":"VENDA A DEBITO", (Tipo de Pagamento)

  //       "authCode":"160135", (Código de Autorização)

  //       "cieloCode":"165762", (NSU)

  //       "updatedAt":"Aug 19, 2020 4:42:23 PM"} (Data e Hora da Transação) */}
  //       {/* <CardBody>{new_payment_data}</CardBody> */}
  //     </Card>
  //   );
  // }

  renderActions() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>Ações do pedido</h4>
        </CardHeader>
        <CardBody>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Ação
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={this.state.action}
              onChange={(event) => this.setState({ [event.target.name]: event.target.value })}
              inputProps={{
                name: "action",
                id: "simple-select",
              }}
            >
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
              >
                Selecione uma ação
              </MenuItem>
              <MenuItem
                key="sap"
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value="sap"
              >
                Enviar para o SAP
              </MenuItem>
            </Select>
          </FormControl>
        </CardBody>
        {this.state.action ? (
          <CardFooter>
            <Button color="primary" onClick={() => this.execAction()}>
              Executar
            </Button>
          </CardFooter>
        ) : null}
      </Card>
    );
  }

  renderSapLogs() {
    return this.props.product_return.PiclistSapLogs.map((log, index) => {
      // console.log(log);
      const {
        status,
        message,
        data,
        createdAt,
        sap_message,
        sap_product_return,
        sap_fornecimento,
        sap_fatura,
      } = log;
      const date = createdAt ? new Date(createdAt).toLocaleString("pt-BR") : null;
      return (
        <div key={index}>
          <strong>{date}</strong>
          <br />
          <strong>Mensagem: </strong>
          {message}
          <br />
          <strong>Status: </strong>
          {status}
          <br />
          {data && data != "" ? <strong>XML: </strong> : null}
          {data && data != "" ? data : null}
          {data && data != "" ? <br /> : null}
          <hr />
        </div>
      );
    });
  }

  renderNotes() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader>
          {this.props.product_return.PiclistSapLogs.filter((sap) => sap.status === 200).length ===
            0 && (
            <Button color="primary" onClick={() => this.execSap()}>
              Enviar para o SAP
            </Button>
          )}
          <h4 className={classes.cardIconTitle}>Notas do pedido</h4>
        </CardHeader>
        <CardBody>{this.renderSapLogs()}</CardBody>
      </Card>
    );
  }

  render() {
    const { classes } = this.props;
    // console.log('render(): ', this.props)
    return (
      <div>
        {this.state.alert}

        <GridContainer justify="center">
          {this.state.loading ? <Loader /> : null}
          <GridItem xs={12} sm={12} md={12}>
            {this.renderHeader()}
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {this.renderDetails()}
            {this.renderProducts()}
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {this.renderNotes()}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('mapStateToProps: ', state)
  return {
    HideForm: state.product_returns.HideForm,
    product_return: state.product_returns.product_return,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      HideForm,
      save,
      showNotification,
    },
    dispatch
  );
const ReturnClassDecorated = withStyles(styles)(ValidationForms);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnClassDecorated);
