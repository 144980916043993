import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// import { Link } from "react-router-dom";
import axios from "axios";
import consts from "../../consts";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Loader from "components/Loader/Loader.jsx";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ShowForm,
  save,
  showNotification,
  ShowFormReport,
  // get
} from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";
import EditIcon from "@material-ui/icons/Edit";
import DownloadIcon from "@material-ui/icons/GetApp";

// import Assignment from "@material-ui/icons/Assignment";
// import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

// import { dataTable } from "variables/general.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

// import ReactExport from "react-export-excel";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    // console.log("props: ", props);
    this.state = {
      data: [],
      originalData: [],
      pages: -1,
      loading: true,
      alert: null,
      show: false,
    };
  }

  setData(result) {
    // console.log(result);
    var data = result.map((item) => {
      // console.log(item);
      return {
        id: item.id,
        gebra: item.Fridge ? item.Fridge.gebra : "Gebra não disponível",
        local: item.Fridge ? item.Fridge.local : "Local não disponível",
        user: (
          <div className="actions-left">
            {item.User ? item.User.name : "Usuário não disponível"}
          </div>
        ),
        reason: (
          <div className="actions-left">
            {item.FridgeOpeningReason
              ? item.FridgeOpeningReason.description
              : "Motivo não disponível"}
          </div>
        ),
        empty: "",
        date: (
          <div>{item.createdAt ? new Date(item.createdAt).toLocaleString("pt-BR") : ""}</div>
          // item.createdAt
        ),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {item.file && item.file != "" ? (
              <a href={`${item.file}`} target="_blank">
                <Button justIcon round simple onClick={() => false} color="info" className="edit">
                  <DownloadIcon />
                </Button>
              </a>
            ) : (
              <Button
                justIcon
                round
                simple
                onClick={() => this.createReport(item)}
                color="warning"
                className="edit"
              >
                <EditIcon />
              </Button>
            )}{" "}
          </div>
        ),
      };
    });

    this.setState({ data });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  // createReport(item) {
  //   const that = this;
  //   // console.log(this.state.originalData);
  //   this.setState({
  //     alert: (
  //       <SweetAlert
  //         warning
  //         style={{ display: "block", marginTop: "-100px" }}
  //         title="Deseja criar o relatório para download?"
  //         onConfirm={() => {
  //           // console.log(item)
  //           that.setState({
  //             loading: true,
  //           });
  //           axios.defaults.timeout = 15000;
  //           axios
  //             .get(`${consts.API_URL}/audits/${item.id}`)
  //             .then((resp) => {
  //               // console.log(resp);
  //               if (resp.data.file == "") {
  //                 let configNotification = {
  //                   message: "Erro ao criar relatório.",
  //                   color: "danger",
  //                 };
  //                 return that.props.showNotification(configNotification);
  //               }

  //               const originalData = this.state.originalData.map((item) => {
  //                 let toReturn = { ...item };
  //                 if (item.id === resp.data.id) {
  //                   toReturn.file = resp.data.file;
  //                 }
  //                 return toReturn;
  //               });
  //               // data.find((o, i) => {
  //               //   return false;
  //               // });
  //               let configNotification = {
  //                 message: "Relatório criado com sucesso.",
  //                 color: "success",
  //               };
  //               that.props.showNotification(configNotification);
  //               // console.log("originalData: ", originalData);
  //               that.setData(originalData);
  //               that.setState({ originalData, loading: false });
  //             })
  //             .catch((err) => {
  //               let configNotification = {
  //                 message: "Erro ao criar relatório.",
  //                 color: "danger",
  //               };
  //               that.props.showNotification(configNotification);
  //               that.setState({
  //                 loading: false,
  //               });
  //             });
  //           this.hideAlert();
  //         }}
  //         onCancel={() => this.hideAlert()}
  //         confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
  //         cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
  //         confirmBtnText="Sim, criar!"
  //         cancelBtnText="Cancelar"
  //         showCancel
  //       >
  //         O processo pode demorar um pouco.
  //       </SweetAlert>
  //     ),
  //   });
  // }

  render() {
    const { classes } = this.props;
    const me = JSON.parse(localStorage.getItem(consts.UserKey)).user;
    return (
      <div>
        {this.state.alert}
        {this.state.loading ? <Loader /> : null}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <MaterialIconList />
                </CardIcon>

                {/* <Button
                  color="primary"
                  className={classes.updateProfileButton}
                  onClick={() => this.props.ShowFormReport()}
                >
                  Relatório
                </Button> */}

                {/* <h4 className={classes.cardIconTitle}>Lista de membros</h4> */}
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={this.state.data}
                  pages={this.state.pages}
                  loading={this.state.loading}
                  filterable={false}
                  columns={[
                    {
                      Header: "Gebra",
                      accessor: "gebra",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Data",
                      accessor: "date",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Liberado por",
                      accessor: "user",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Motivo",
                      accessor: "reason",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                  defaultPageSize={20}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  previousText="Anterior"
                  nextText="Próxima"
                  loadingText="Carregando"
                  noDataText="Nenhum registro encontrado"
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                  manual
                  onFetchData={(state, instance) => {
                    // show the loading overlay
                    this.setState({ loading: true }, () => {
                      // console.log(state.filtered)
                      const sorted = state.sorted.length === 0 ? "" : state.sorted[0].id;
                      const order =
                        state.sorted.length > 0 && state.sorted[0].desc ? "desc" : "asc";
                      const filtered = btoa(JSON.stringify(state.filtered));

                      // fetch your data
                      axios
                        .get(
                          `${consts.API_URL}/fridge-release-door-openings?page=${state.page +
                            1}&qtd_per_page=${
                            state.pageSize
                          }&sorted=id&order=DESC&filtered=${filtered}`,
                          {
                            page: state.page,
                            pageSize: state.pageSize,
                            sorted: state.sorted,
                            filtered: state.filtered,
                          }
                        )
                        .then((res) => {
                          // console.log(res)
                          // console.log(state.sorted)
                          // console.log(state.filtered)
                          // Update react-table
                          this.setState(
                            {
                              pages: res.data.pages,
                              originalData: res.data.result,
                              loading: false,
                            },
                            () => {
                              this.setData(res.data.result);
                            }
                          );
                        });
                    });
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log(state)
//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = (state) => ({
  ShowForm: state.ShowForm,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      save,
      showNotification,
      ShowFormReport,
      // get
    },
    dispatch
  );
const MemberList = withStyles(sweetAlertStyle)(ReactTables);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberList);
