const INITIAL_FRIDGE = {
  gebra: "",
  local: "",
  cep: "",
  logradouro: "",
  complemento: "",
  numero: "",
  bairro: "",
  localidade: "",
  uf: "",
  planogram_id: "",
  maintenance: false,
  lio: "",
  table: "",
  lat: "",
  lng: "",
  sap: "",
  route_id: "",
  cod_cliente: "",
  cod_comodato: "",
  cpf_required: false,
  birthday_required: false,
  cpf_validation: false,
  over_18: false,
  credit: true,
  debit: true,
  voucher: true,
  released_door_sound: false,
};
const INITIAL_STATE = {
  showForm: false,
  // showPicLists: false,
  fridge: INITIAL_FRIDGE,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(INITIAL_FRIDGE)
  // console.log(state)
  // console.log(action)
  switch (action.type) {
    case "FRIDGES_SHOW_FORM":
      return {
        ...state,
        showForm: true,
        fridge: action.payload === undefined ? INITIAL_FRIDGE : action.payload,
      };
    case "FRIDGES_HIDE_FORM":
      return { ...state, showForm: false, fridge: INITIAL_FRIDGE };
    default:
      return state;
  }
};
