import React from "react";
import List from "./List";
import Form from "./Form";
import If from "../../common/operators/If";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ShowForm, HideForm, ShowFormReport, HideFormReport } from "./Actions";
import consts from "../../consts";

class Main extends React.Component {
  constructor(props) {
    super(props);
    props.HideForm();
    props.HideFormReport();
    // console.log(this.me);
    // props.ShowForm();
    // console.log(props)
  }

  render() {
    const me = JSON.parse(localStorage.getItem(consts.UserKey)).user;
    return (
      <div>
        <If test={!this.props.showForm}>
          <List />
        </If>
        <If test={this.props.showForm}>
          <Form />
        </If>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state)
  return {
    showForm: state.product_returns_by_driver.showForm,
    showBookingForm: state.product_returns_by_driver.showBookingForm,
    showFormReport: state.product_returns_by_driver.showFormReport,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      HideForm,
      ShowFormReport,
      HideFormReport,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
