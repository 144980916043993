import React from "react";

import defaultImage from "assets/img/default-avatar.png";

class PictureUpload extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      file: null,
      imagePreviewUrl: defaultImage
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
      // console.log(reader.result)
      this.props.getPicture(reader.result)
    };
    reader.readAsDataURL(file);
  }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  render() {
    // console.log(this.props)
    let colorStatus = '#000';
    if(this.props.success){ colorStatus = '#4caf50' }
    if(this.props.error){ colorStatus = '#f44336' }
    return (
      <div className="picture-container">
        <div className="picture" style={{
            borderRadius: 0
          }}>
          <img
            src={this.state.imagePreviewUrl}
            className="picture-src"
            alt="..."
          />
          <input type="file" onChange={e => this.handleImageChange(e)} />
        </div>
        <h6 className="description" style={{color: colorStatus}}>Selecione uma imagem</h6>
      </div>
    );
  }
}

export default PictureUpload;
