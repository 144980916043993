// const SOCKET_URL = "http://127.0.0.1:3001";
// const API_URL = "http://127.0.0.1:3001";
// const API2_URL = "http://127.0.0.1:3001";

// const SOCKET_URL = "https://hom-icoke-api.herokuapp.com";
// const API_URL = "https://hom-icoke-api.herokuapp.com";
// const API2_URL = "https://hom-icoke-api.herokuapp.com";

const SOCKET_URL = "https://icoke-api.herokuapp.com";
const API_URL = "https://icoke-api.herokuapp.com";
const API2_URL = "https://icoke-api.herokuapp.com";

const OAPI_URL = API_URL;

export default {
  API_URL,
  API2_URL,
  OAPI_URL,
  SOCKET_URL,
  UserKey: "_icoke",
};

/**
 * 
SELECT
planogram_products.id,
products.name,
products.sku,
planogram_products.price
FROM
planogram_products
LEFT JOIN
products
ON
products.id = planogram_products.product_id
WHERE
planogram_products.planogram_id = 3
and
CAST(products.sku AS SIGNED)  > 1
ORDER BY
CAST(products.sku AS SIGNED) ASC
 */
