import React from "react";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import QRCode from "qrcode.react";
import { Link } from "react-router-dom";
import consts from "../../consts";
import { HideForm, save } from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInputCep from "components/CustomInput/CustomInputCep.jsx";

// material ui icons
// import MailOutline from "@material-ui/icons/MailOutline";
// import Contacts from "@material-ui/icons/Contacts";
// import Check from "@material-ui/icons/Check";
// import Close from "@material-ui/icons/Close";
// import CategoryIcon from "@material-ui/icons/Category"
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomInputCPF from "components/CustomInput/CustomInputCPF.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import If from "../../common/operators/If";

// style for this view
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";

// import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'row',
//     backgroundColor: '#E4E4E4'
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1
//   }
// });

class ValidationForms extends React.Component {
  constructor(props) {
    super(props);
    // console.log('props: ',props)
    this.state = {
      link: "",
      gebra: "",
      errorLink: false,
      // gebra: (props.fridge) ? props.fridge.gebra : "",
      // cod_pinpad: (props.fridge) ? props.fridge.cod_pinpad : "",
      // local: (props.fridge) ? props.fridge.local : "",
      // cep: (props.fridge) ? props.fridge.cep : "",
      // logradouro: (props.fridge) ? props.fridge.logradouro : "",
      // complemento: (props.fridge) ? props.fridge.complemento : "",
      // numero: (props.fridge) ? props.fridge.numero : "",
      // bairro: (props.fridge) ? props.fridge.bairro : "",
      // localidade: (props.fridge) ? props.fridge.localidade : "",
      // uf: (props.fridge) ? props.fridge.uf : "",
      // planogram: (props.fridge) ? props.fridge.planogram_id : "",
      // planograms: []
    };
    // this.registerClick = this.registerClick.bind(this);
    // this.loginClick = this.loginClick.bind(this);
    // this.typeClick = this.typeClick.bind(this);
    // this.rangeClick = this.rangeClick.bind(this);
  }

  componentDidMount() {
    this.get();
  }

  get() {
    // console.log(this.state)
    // console.log(this.props.match.params)
    const that = this;
    axios
      .get(
        `${consts.API_URL}/fridges/${this.props.match.params.fridge_id}/qrcode`
      )
      .then((resp) => {
        // console.log(resp)
        that.setState({
          gebra: resp.data.gebra,
          link: `${consts.API_URL}/${resp.data.qr_img}`,
        });
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        that.setState({
          gebra:
            err.response && err.response.data && err.response.data.gebra
              ? err.response.data.gebra
              : "",
          errorLink: true,
        });
      });
  }

  render() {
    const { classes } = this.props;
    // console.log('render(): ', this.props)
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader>
              <h4 className={classes.cardIconTitle}>
                Download do QR Code para impressão
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  {this.state.gebra != "" ? (
                    <QRCode
                      value={this.state.gebra}
                      renderAs="canvas"
                      size={300}
                    />
                  ) : null}
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Link to={`/fridges`}>
                <Button>Cancelar</Button>
              </Link>
              {this.state.link !== "" ? (
                <a href={this.state.link} target="_blank">
                  <Button color="primary">Baixar arquivo</Button>
                </a>
              ) : null}
              {this.state.errorLink ? (
                <Button color="danger">
                  Erro ao gerar arquivo para download
                </Button>
              ) : null}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('mapStateToProps: ', state)
  return {
    HideForm: state.fridges.HideForm,
    fridge: state.fridges.fridge,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      HideForm,
      save,
    },
    dispatch
  );
const ReturnClassDecorated = withStyles(validationFormsStyle)(ValidationForms);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnClassDecorated);
