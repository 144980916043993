import React from "react";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import consts from "../../consts";
import { HideForm, save, showNotification } from "./Actions";

import moment from "moment";
import "moment/locale/pt-br";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInputCep from "components/CustomInput/CustomInputCep.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";

// material ui icons
// import MailOutline from "@material-ui/icons/MailOutline";
// import Contacts from "@material-ui/icons/Contacts";
// import Check from "@material-ui/icons/Check";
// import Close from "@material-ui/icons/Close";
// import CategoryIcon from "@material-ui/icons/Category"
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomInputCel from "components/CustomInput/CustomInputCel.jsx";
import CustomInputDate from "components/CustomInput/CustomInputDate.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import If from "../../common/operators/If";
import Loader from "components/Loader/Loader.jsx";

// style for this view
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";

const payment_types = [
  {
    id: "CREDITO",
    label: "Crédito",
  },
  {
    id: "DEBITO",
    label: "Débito",
  },
  {
    id: "VOUCHER",
    label: "Voucher",
  },
];

class ValidationForms extends React.Component {
  constructor(props) {
    super(props);
    // console.log("props: ", props);
    this.me = JSON.parse(localStorage.getItem(consts.UserKey)).user;
    this.state = {
      loading: false,
      order_id: "",
      payment_cardnumber: "",
      local: "",
      gebra: "",
      date: "",
      payment_type: "",
      amount: "",
      registers: [],
      totalRegisters: 0,
      showRegisters: false,
      order: null,
      contact_costumer: "",
      contact_tel: "",
      contact_obs: "",
      chargeback: null,
    };
  }

  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  cancelButtonClick() {
    if (this.me.profile === "marketing") {
      this.setState({
        loading: false,
        order_id: "",
        payment_cardnumber: "",
        payment_type: "",
        local: "",
        gebra: "",
        date: "",
        registers: [],
        totalRegisters: 0,
        showRegisters: false,
        order: null,
        contact_costumer: "",
        contact_tel: "",
        contact_obs: "",
        chargeback: null,
      });
    } else {
      this.props.HideForm();
    }
  }

  showButtonClick() {
    this.setState({ showRegisters: true });
  }

  filterButtonClick() {
    const { order_id, payment_cardnumber, local, gebra, date, payment_type } = this.state;
    let { amount } = this.state;

    amount = parseInt(amount.replace(/\D/g, ""));

    let data = { payment_cardnumber, local, gebra, payment_type, amount };

    if (date && moment(date, "DD/MM/YYYY").isValid()) {
      data.date = moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    }
    if (order_id && order_id !== "") {
      data = { id: order_id };
    }

    this.setState({
      loading: true,
      totalRegisters: 0,
      showRegisters: false,
      order: null,
      contact_costumer: "",
      contact_tel: "",
      contact_obs: "",
    });
    axios
      .post(`${consts.API_URL}/chargebacks/orders`, data)
      .then((resp) => {
        if (resp.data.total === 0) {
          let configNotification = {
            message: "Nenhum pedido encontrado.",
            color: "danger",
          };
          this.props.showNotification(configNotification);
        }
        this.setState({
          registers: resp.data.orders || [],
          totalRegisters: resp.data.total,
          loading: false,
        });
      })
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // console.log(err);
      });
  }

  createChargeback() {
    const { order, contact_costumer, contact_tel, contact_email, contact_obs } = this.state;

    let configNotification;

    if (!contact_costumer || contact_costumer === "") {
      configNotification = {
        message: "O nome do consumidor é obrigatório.",
        color: "danger",
      };
      this.props.showNotification(configNotification);
      return;
    }

    if (!contact_tel || contact_tel === "") {
      configNotification = {
        message: "O telefone/Whatsapp do consumidor é obrigatório.",
        color: "danger",
      };
      this.props.showNotification(configNotification);
      return;
    }

    const data = {
      order_id: order.id,
      contact_costumer,
      contact_tel,
      contact_email,
      contact_obs,
    };

    this.setState({ loading: true });
    axios
      .post(`${consts.API_URL}/chargebacks`, data)
      .then((resp) => {
        let configNotification = {
          message: "Solicitação de estorno cadastrada com sucesso.",
          color: "success",
        };
        this.props.showNotification(configNotification);
        this.setState({
          loading: false,
          order_id: "",
          payment_cardnumber: "",
          local: "",
          gebra: "",
          date: "",
          registers: [],
          totalRegisters: 0,
          showRegisters: false,
          order: null,
          contact_costumer: "",
          contact_tel: "",
          contact_obs: "",
          chargeback: resp.data,
        });
      })
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // console.log(err);
      });
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  // function that verifies if two strings are equal
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  // function that verifies if value contains only numbers
  verifyNumber(value) {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }
  // verifies if value is a valid URL
  verifyUrl(value) {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  }
  change(event, stateName, type, stateNameEqualTo, maxValue) {
    // console.log(event)
    // console.log(this.state)
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "checkbox":
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-length":
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "min-value":
        if (this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-value":
        if (this.verifyNumber(event.target.value) && event.target.value <= stateNameEqualTo) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "range":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }
  registerClick() {
    if (this.state.registerEmailState === "") {
      this.setState({ registerEmailState: "error" });
    }
    if (this.state.registerPasswordState === "") {
      this.setState({ registerPasswordState: "error" });
    }
    if (this.state.registerConfirmPasswordState === "") {
      this.setState({ registerConfirmPasswordState: "error" });
    }
    if (this.state.registerCheckboxState === "") {
      this.setState({ registerCheckboxState: "error" });
    }
  }
  loginClick() {
    if (this.state.loginEmailState === "") {
      this.setState({ loginEmailState: "error" });
    }
    if (this.state.loginPasswordState === "") {
      this.setState({ loginPasswordState: "error" });
    }
  }
  typeClick() {
    if (this.state.requiredState === "") {
      this.setState({ requiredState: "error" });
    }
    if (this.state.typeEmailState === "") {
      this.setState({ typeEmailState: "error" });
    }
    if (this.state.numberState === "") {
      this.setState({ numberState: "error" });
    }
    if (this.state.urlState === "") {
      this.setState({ urlState: "error" });
    }
    if (this.state.equalToState === "") {
      this.setState({ equalToState: "error" });
    }
  }
  rangeClick() {
    if (this.state.minLengthState === "") {
      this.setState({ minLengthState: "error" });
    }
    if (this.state.maxLengthState === "") {
      this.setState({ maxLengthState: "error" });
    }
    if (this.state.rangeState === "") {
      this.setState({ rangeState: "error" });
    }
    if (this.state.minValueState === "") {
      this.setState({ minValueState: "error" });
    }
    if (this.state.maxValueState === "") {
      this.setState({ maxValueState: "error" });
    }
  }

  renderFilterForm() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Localizar transação</h4>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      success={this.state.descriptionState === "success"}
                      error={this.state.descriptionState === "error"}
                      labelText={<span>Número do pedido</span>}
                      id="order_id"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) => this.change(event, "order_id", "length", 3),
                        value: this.state.order_id,
                        type: "text",
                        placeholder: "Número do pedido",
                      }}
                    />
                  </GridItem>
                  {this.state.order_id === "" && (
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInputDate
                        success={this.state.dateState === "success"}
                        error={this.state.dateState === "error"}
                        id="date"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) => this.change(event, "date", "length", 3),
                          value: this.state.date,
                          type: "text",
                          placeholder: "Data da compra",
                        }}
                      />
                    </GridItem>
                  )}

                  {this.state.order_id === "" && (
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        success={this.state.payment_cardnumberState === "success"}
                        error={this.state.payment_cardnumberState === "error"}
                        labelText={<span>4 últimos dígitos do cartão</span>}
                        id="payment_cardnumber"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) =>
                            this.change(event, "payment_cardnumber", "length", 3),
                          value: this.state.payment_cardnumber,
                          type: "text",
                          placeholder: "4 últimos dígitos do cartão",
                        }}
                      />
                    </GridItem>
                  )}

                  {this.state.order_id === "" && (
                    <GridItem xs={12} sm={12} md={3}>
                      <FormControl fullWidth className={classes.selectFormControl}>
                        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                          Forma de pagamento
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.payment_type}
                          onChange={this.handleSimple}
                          inputProps={{
                            name: "payment_type",
                            id: "simple-select",
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value=""
                          >
                            Selecione
                          </MenuItem>

                          {payment_types.map((status) => {
                            return (
                              <MenuItem
                                key={status.id}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={status.id}
                              >
                                {status.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>
                  )}
                  {this.state.order_id === "" && (
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        success={this.state.localState === "success"}
                        error={this.state.localState === "error"}
                        labelText={<span>Nome do estabelecimento</span>}
                        id="local"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) => this.change(event, "local", "length", 3),
                          value: this.state.local,
                          type: "text",
                          placeholder: "Nome do estabelecimento",
                        }}
                      />
                    </GridItem>
                  )}
                  {this.state.order_id === "" && (
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        success={this.state.gebraState === "success"}
                        error={this.state.gebraState === "error"}
                        labelText={<span>GEBRA</span>}
                        id="gebra"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) => this.change(event, "gebra", "length", 3),
                          value: this.state.gebra,
                          type: "text",
                          placeholder: "GEBRA",
                        }}
                      />
                    </GridItem>
                  )}
                  {this.state.order_id === "" && (
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        success={this.state.amountState === "success"}
                        error={this.state.amountState === "error"}
                        labelText={<span>Valor da compra</span>}
                        id="amount"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) => this.change(event, "amount", "length", 3),
                          value: this.state.amount,
                          type: "text",
                          placeholder: "Valor da compra",
                        }}
                      />
                    </GridItem>
                  )}
                </GridContainer>
              </form>
            </CardBody>
            <CardFooter>
              {/* {this.me.profile === "marketing" && ( */}
              <div>
                {this.state.totalRegisters > 0 && <br />}
                <Button onClick={() => this.cancelButtonClick()}>Cancelar</Button>
              </div>
              {/* )} */}

              {this.state.totalRegisters > 0 && (
                <div>
                  {this.state.totalRegisters} registros encontrado
                  {this.state.totalRegisters !== 1 ? "s" : ""} <br />
                  <Button color="success" onClick={() => this.showButtonClick()}>
                    Mostrar {this.state.registers.length} registro
                    {this.state.registers.length !== 1 ? "s" : ""}
                  </Button>
                </div>
              )}
              <div>
                {this.state.totalRegisters > 0 && <br />}
                <Button color="primary" onClick={() => this.filterButtonClick()}>
                  Filtrar
                </Button>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  renderFilteredRegister() {
    const { classes } = this.props;
    return (
      <GridContainer>
        {this.state.registers.map((order) => (
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="warning" icon>
                {/* <CardIcon color="warning">
                <AssignmentIcon />
              </CardIcon> */}
                <h4 className={classes.cardIconTitle}>Pedido: {order.id}</h4>
              </CardHeader>
              <CardBody>
                <p>
                  <strong>Geladeira:</strong> {order.Fridge.gebra}
                  <br />
                  <strong>Local:</strong> {order.Fridge.local}
                  <br />
                  <strong>Valor:</strong> R${" "}
                  {order.OrderProducts.reduce((total, item) => total + item.qtd * item.price, 0)
                    .toString()
                    .split(".")
                    .join(",")}
                  <br />
                  <strong>Bandeira:</strong> {order.payment_band}
                  <br />
                  <strong>Número do cartão:</strong> {order.payment_cardnumber}
                  <br />
                  <strong>Forma de pagamento:</strong> {order.payment_type}
                  <br />
                  <strong>Nome no cartão:</strong> {order.customer_name}
                  <br />
                  <strong>Data e hora:</strong> {moment(order.createdAt).format("DD/MM/YYYY HH:mm")}
                </p>
                <p>
                  <strong>PRODUTOS</strong>
                </p>
                {order.OrderProducts.map((product) => (
                  <p>{`${product.product_name} - Qtd: ${product.qtd}`}</p>
                ))}
                {order.Chargeback &&
                  order.Chargeback.id && (
                    <Button color="info" fullWidth onClick={() => {}}>
                      {`SAB: ${order.Chargeback.sab_id_manif} - ${
                        order.Chargeback.ChargebackStatus &&
                        order.Chargeback.ChargebackStatus.description
                          ? order.Chargeback.ChargebackStatus.description
                          : "Enviado para a área"
                      }`}
                    </Button>
                  )}

                {this.state.order &&
                  this.state.order.id === order.id &&
                  (!order.Chargeback || !order.Chargeback.id) && (
                    <GridContainer>
                      <GridItem xs={12}>
                        <CustomInput
                          success={this.state.contact_costumerState === "success"}
                          error={this.state.contact_costumerState === "error"}
                          labelText={<span>Nome do consumidor</span>}
                          id="order_id"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) =>
                              this.change(event, "contact_costumer", "length", 3),
                            value: this.state.contact_costumer,
                            type: "text",
                            placeholder: "Nome do consumidor",
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <CustomInputCel
                          success={this.state.contact_telState === "success"}
                          error={this.state.contact_telState === "error"}
                          labelText={<span>WhatsApp</span>}
                          id="order_id"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => this.change(event, "contact_tel", "length", 3),
                            value: this.state.contact_tel,
                            type: "text",
                            placeholder: "WhatsApp",
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <CustomInput
                          success={this.state.contact_emailState === "success"}
                          error={this.state.contact_emailState === "error"}
                          labelText={<span>E-mail</span>}
                          id="order_id"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => this.change(event, "contact_email", "email"),
                            value: this.state.contact_email,
                            type: "text",
                            placeholder: "E-mail",
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <CustomInput
                          success={this.state.contact_obsState === "success"}
                          error={this.state.contact_obsState === "error"}
                          labelText={<span>Observação</span>}
                          id="order_id"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            multiline: true,
                            onChange: (event) => this.change(event, "contact_obs", "length", 3),
                            value: this.state.contact_obs,
                            type: "text",
                            placeholder: "Observação",
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  )}
              </CardBody>
              {(!order.Chargeback || !order.Chargeback.id) && (
                <CardFooter>
                  {this.state.order && this.state.order.id === order.id ? (
                    <Button color="primary" fullWidth onClick={() => this.createChargeback()}>
                      Confirmar solicitação de estorno
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      fullWidth
                      onClick={() =>
                        this.setState({
                          order,
                          contact_costumer: "",
                          contact_tel: "",
                          contact_obs: "",
                        })
                      }
                    >
                      Solicitar estorno
                    </Button>
                  )}
                </CardFooter>
              )}
            </Card>
          </GridItem>
        ))}
      </GridContainer>
    );
  }

  renderResult() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="warning" icon>
              {/* <CardIcon color="warning">
                <AssignmentIcon />
              </CardIcon> */}
              {this.state.chargeback &&
                this.state.chargeback.sab_id_manif &&
                this.state.chargeback.sab_id_manif !== "" && (
                  <h4 className={classes.cardIconTitle}>
                    <strong>Protocolo do atendimento:</strong> {this.state.chargeback.sab_id_manif}
                  </h4>
                )}
              <h4 className={classes.cardIconTitle}>
                <strong>Pedido:</strong> {this.state.chargeback.order_id}
              </h4>
            </CardHeader>
            <CardBody>
              <p>
                <br />O prazo do primeiro contato da área responsável é de até 24 horas úteis.
              </p>
              <p>
                Atenção operador(a): se a ficha for aberta no sábado, o consumidor deverá ser
                informado que o primeiro atendimento será realizado no próximo dia útil.
              </p>
            </CardBody>
            <CardFooter>
              <Button color="primary" fullWidth onClick={() => this.cancelButtonClick()}>
                Finalizar
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  render() {
    return (
      <div>
        {this.state.loading && <Loader />}

        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            {!this.state.chargeback && this.renderFilterForm()}
            {!this.state.chargeback && this.state.showRegisters && this.renderFilteredRegister()}
            {this.state.chargeback && this.state.chargeback.id && this.renderResult()}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log("mapStateToProps: ", state);
  return {
    HideForm: state.cars.HideForm,
    car: state.cars.car,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      HideForm,
      save,
      showNotification,
    },
    dispatch
  );
const ReturnClassDecorated = withStyles(validationFormsStyle)(ValidationForms);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnClassDecorated);
