import React from "react";
import socketIOClient from "socket.io-client";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import consts from "../../consts";
import { showNotification } from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInputCep from "components/CustomInput/CustomInputCep.jsx";

import PictureUpload from "components/CustomUpload/PictureUpload.jsx";

import Loader from "components/Loader/Loader.jsx";

// material ui icons
// import MailOutline from "@material-ui/icons/MailOutline";
// import Contacts from "@material-ui/icons/Contacts";
// import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
// import CategoryIcon from "@material-ui/icons/Category"
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomInputCPF from "components/CustomInput/CustomInputCPF.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Table from "components/Table/Table.jsx";

import If from "../../common/operators/If";

import SweetAlert from "react-bootstrap-sweetalert";
// style for this view
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";

const styles = {
  ...validationFormsStyle,
  ...extendedTablesStyle,
  ...sweetAlertStyle,
};

class ValidationForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      loading: true,
      fridge: null,
      products: [],
    };
  }

  componentDidMount() {
    this.get();
  }

  get() {
    const that = this;
    this.setState({ loading: true });
    axios
      .get(`${consts.API_URL}/fridges/${this.props.fridgeId}`)
      .then((resp) => {
        // console.log("resp: ", resp);
        const fridge = resp.data;
        that.getProducts();

        this.setState({
          loading: false,
          fridge,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  }

  getProducts() {
    this.setState({ loading: true });
    axios
      .get(`${consts.API_URL}/fridges/${this.props.fridgeId}/products`)
      .then((resp) => {
        // console.log("resp: ", resp);
        const products = resp.data.result;
        this.setState({
          loading: false,
          products,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  renderHeader() {
    return (
      <Card>
        <CardFooter>Local: {this.state.fridge ? this.state.fridge.local : ""}</CardFooter>
      </Card>
    );
  }

  addFromPlanograma(item) {
    const { product_id, channel } = item;
    axios
      .post(`${consts.API_URL}/piclists/${this.state.id}/products`, {
        product_id,
        channel,
      })
      .then((resp) => this.get());
  }

  add(item) {
    let products = this.state.products.map((product) => {
      let toReturn = { ...product };
      if (product.id === item.id) {
        const qtd = toReturn.qtd + 1;
        toReturn.qtd = qtd;
        axios.put(`${consts.API_URL}/fridges/${this.state.fridge.id}/products/${product.id}`, {
          qtd,
        });
      }
      return toReturn;
    });
    this.setState({ products });
  }

  remove(item) {
    console.log(item);
    if (item.qtd === 0 && (!item.planogram || !item.planogram.qtd || item.planogram.qtd === 0)) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Tem certeza?"
            onConfirm={() => {
              // console.log(item)
              axios
                .delete(`${consts.API_URL}/fridges/${item.fridge_id}/products/${item.id}`)
                .then((resp) => {
                  this.getProducts();
                })
                .catch((e) => {
                  console.log(e);
                  console.log(e.response);
                  const message =
                    e.response && e.response.data && e.response.data.message
                      ? e.response.data.message
                      : "Falha ao excluir produto.";
                  const configNotification = {
                    message,
                    color: "danger",
                  };
                  this.props.showNotification(configNotification);
                });
              this.hideAlert();
            }}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
            cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
            confirmBtnText="Sim, excluir!"
            cancelBtnText="Cancelar"
            showCancel
          >
            Não tem como recuperar o registro ao excluir!
          </SweetAlert>
        ),
      });
      return;
    }

    let products = this.state.products.map((product) => {
      let toReturn = { ...product };
      if (product.id === item.id && toReturn.qtd > 0) {
        const qtd = toReturn.qtd - 1;
        toReturn.qtd = qtd;
        axios.put(`${consts.API_URL}/fridges/${this.state.fridge.id}/products/${product.id}`, {
          qtd,
        });
      }
      return toReturn;
    });
    this.setState({ products });
  }

  renderProducts() {
    if (!this.state.products || this.state.products.length == 0) {
      return null;
    }
    const { classes } = this.props;
    // console.log(classes);
    const tableData = this.state.products.map((product) => {
      const simpleButtons = [
        {
          color: "danger",
          icon: Remove,
          func: () => {
            this.remove(product);
            // console.log(product);
          },
        },
        {
          color: "success",
          icon: Add,
          func: () => {
            this.add(product);
            // console.log(product);
            // alert("add");
          },
        },
      ].map((prop, key) => {
        return (
          <Button
            color={prop.color}
            simple
            className={classes.actionButton}
            key={key}
            onClick={prop.func}
          >
            <prop.icon className={classes.icon} />
          </Button>
        );
      });
      return [
        product.Product.name,
        product.Product.sku,
        product.planogram ? product.planogram.qtd : 0,
        product.qtd,
        simpleButtons,
      ];
    });
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>Produtos</h4>
        </CardHeader>
        <CardBody>
          <Table
            tableHeaderColor="primary"
            tableHead={["Produto", "SKU", "Planograma", "Estoque", "Ações"]}
            tableData={tableData}
            customCellClasses={[
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
            ]}
            coloredColls={[2]}
            colorsColls={["info"]}
          />
        </CardBody>
      </Card>
    );
  }

  render() {
    const { classes } = this.props;
    // console.log("render(): ", this.state);
    return (
      <>
        {this.state.alert}
        <GridContainer justify="center">
          {this.state.loading ? <Loader /> : null}
          <GridItem xs={12} sm={12} md={12}>
            {this.renderHeader()}
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {this.renderProducts()}
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('mapStateToProps: ', state)
  return {
    HideForm: state.piclists.HideForm,
    piclist: state.piclists.piclist,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // HideForm,
      // save,
      showNotification,
    },
    dispatch
  );
const ReturnClassDecorated = withStyles(styles)(ValidationForms);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnClassDecorated);
