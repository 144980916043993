const INITIAL_USER = {
  name: "",
  sku: "",
  image: "",
  volume: "",
  multi_serve: "",
  only_pack: "",
};
const INITIAL_STATE = {
  showForm: false,
  product: INITIAL_USER,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(INITIAL_USER)
  // console.log(state)
  // console.log(action)
  switch (action.type) {
    case "USERS_SHOW_FORM":
      return {
        ...state,
        showForm: true,
        product: action.payload === undefined ? INITIAL_USER : action.payload,
      };
    case "USERS_HIDE_FORM":
      return { ...state, showForm: false, product: INITIAL_USER };
    case "USERS_GET":
      return { ...state, list: action.payload };
    case "USERS_GET_CLIENT":
      return { ...state, cliente: action.payload };
    case "USERS_GET_USER":
      return {
        ...state,
        product: {
          ...state.product,
          name: action.payload.NOME,
          vendedor: action.payload.VENDEDOR,
        },
      };
    default:
      return state;
  }
};
