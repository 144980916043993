const INITIAL_OBJ = {
  id: "",
  car_id: "",
  status: "",
  message: "",
  date: "",
  user_id: "",
};
const INITIAL_STATE = {
  showForm: false,
  delivery: INITIAL_OBJ,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(INITIAL_OBJ)
  // console.log(state);
  // console.log(action);
  switch (action.type) {
    case "DELIVERY_SHOW_FORM":
      return {
        ...state,
        showForm: true,
        delivery: action.payload === undefined ? INITIAL_OBJ : action.payload,
      };
    case "DELIVERY_HIDE_FORM":
      return { ...state, showForm: false, delivery: INITIAL_OBJ };
    default:
      return state;
  }
};
