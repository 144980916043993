import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showNotification, hideNotification } from "./Actions";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";

// core components
// import GridContainer from "components/Grid/GridContainer.jsx";
// import GridItem from "components/Grid/GridItem.jsx";
// import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import { Route } from "react-router-dom";

import Pages from "layouts/Pages.jsx";
import Dashboard from "layouts/Dashboard.jsx";

import { validateToken } from "../modules/auth/authActions";

class Main extends Component {
  indexRoutes = [];

  constructor(props) {
    super(props);
    this.state = {
      tr: false,
    };
  }

  componentWillMount() {
    if (this.props.auth.user) {
      this.props.validateToken(this.props.auth.user.token);
    }
  }

  renderRoutes() {
    const { user, validToken } = this.props.auth;
    if (user && validToken) {
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
      this.indexRoutes = [{ path: "/", name: "Home", component: Dashboard }];
    } else if (!user && !validToken) {
      this.indexRoutes = [{ path: "/", name: "Pages", component: Pages }];
    } else {
      this.indexRoutes = [];
    }
    // console.log(this.indexRoutes);
    return this.indexRoutes.map((prop, key) => {
      return <Route path={prop.path} component={prop.component} key={key} />;
    });
  }

  showNotification() {
    this.props.showNotification({
      message: "teste",
      color: "success",
    });
    setTimeout(
      function() {
        this.props.hideNotification();
      }.bind(this),
      6000
    );
    // if (!this.state[place]) {
    // var x = [];
    // x[place] = true;
    // this.setState(x);
    // }
  }

  render() {
    return (
      <div>
        <Snackbar
          place="tr"
          color={this.props.main.configNotification.color}
          icon={AddAlert}
          message={this.props.main.configNotification.message}
          open={this.props.main.showNotification}
          closeNotification={() => this.props.hideNotification()}
          close
        />
        {this.renderRoutes()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  main: state.main,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      validateToken,
      showNotification,
      hideNotification,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
