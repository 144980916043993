import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// import { Link } from "react-router-dom";
import axios from "axios";
import consts from "../../consts";

import If from "../../common/operators/If";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import Loader from "components/Loader/Loader.jsx";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ShowForm,
  save,
  showNotification,
  ShowFormReport,
  // get
} from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/Visibility";
import NotaFistalIcon from "@material-ui/icons/InsertDriveFile";
import ShopStatusIcon from "@material-ui/icons/CreditCard";
import DoorIcon from "@material-ui/icons/LockOpen";

// import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import { dataTable } from "variables/general.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import Menu from "./Menu";
import ReportCanceledPiclist from "./ReportCanceledPiclist";

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    this.me = JSON.parse(localStorage.getItem(consts.UserKey)).user;
    this.state = {
      data: [],
      reportData: [],
      pages: -1,
      loading: true,
      alert: null,
      show: false,
    };
    // this.getReportData();
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  setLoading(value) {
    this.setState({
      loading: value,
    });
  }

  execSap(id) {
    // console.log("sap");
    // console.log('getReportData(){')
    this.setState({ loading: true });
    axios
      .put(`${consts.API_URL}/product-returns-canceled-piclists/${id}/sap`)
      .then((resp) => {
        const configNotification = {
          message: resp.data.message,
          color: "success",
        };
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // this.getSapLogs();
        // const respFiltered = resp.data.result.filter((item => (item.status == 1)))
        // console.log('respFiltered: ', respFiltered)

        // this.setState({ reportData: resp.data });
      })
      .catch((err) => {
        console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        this.getSapLogs();
        console.log(err);
      });
  }

  setData(result) {
    var data = result.map((item) => {
      // console.log(item);
      const gebra = item.Fridge && item.Fridge.gebra ? item.Fridge.gebra : "";
      const local = item.Fridge && item.Fridge.local ? item.Fridge.local : "";
      const user_name = item.User && item.User.name ? item.User.name : "";
      const nf = item.PiclistSapLogs.filter((sapLog) => sapLog.status === 200).length > 0;

      let status;
      switch (item.status) {
        case 1:
          status = "Concluído";
          break;
        case 2:
          status = "Cancelado";
          break;
        default:
          status = "Andamento";
          break;
      }
      // console.log(item);
      let statusColor = "";
      switch (item.status) {
        case 1:
          statusColor = "success";
          break;
        case 2:
          statusColor = "danger";
          break;
        default:
          statusColor = "info";
          break;
      }

      let statusDoorOpened = "danger";
      if (item.door_released === true) {
        statusDoorOpened = "warning";
        if (item.door_pulled === true) {
          statusDoorOpened = "success";
        }
      }

      return {
        id: item.id,
        gebra,
        local,
        user_name,
        status,
        date: (
          <div>{item.createdAt ? new Date(item.createdAt).toLocaleString("pt-BR") : ""}</div>
          // item.createdAt
        ),
        status: (
          <Button
            justIcon
            round
            simple
            onClick={() => {
              if (nf == false) {
                this.execSap(item.id);
              }
            }}
            color={nf == true ? "success" : "danger"}
            className="edit"
            title={nf == true ? "Nota fiscal emitida" : "Nota fiscal não emitida"}
          >
            <NotaFistalIcon />
          </Button>
        ),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => this.props.ShowForm(item)}
              color="primary"
              className="edit"
            >
              <ViewIcon />
            </Button>{" "}
          </div>
        ),
      };
    });

    this.setState({ data });
  }

  render() {
    // console.log(this.me);
    const { classes } = this.props;
    let timeout = null;
    return (
      <div>
        {this.state.loading ? <Loader /> : null}
        {this.state.alert}

        {/* {this.props.parentProps.location.pathname === "/all-product-returns" && (
          <ReportCanceledPiclist
            classes={classes}
            setLoading={this.setLoading.bind(this)}
            showNotification={this.props.showNotification.bind(this)}
          />
        )} */}

        <Menu
          classes={classes}
          currentMenu={this.props.menuPosition}
          setMenu={this.props.setMenu}
        />

        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <MaterialIconList />
                </CardIcon>
                {/* <Button
                  color="primary"
                  className={classes.updateProfileButton}
                  onClick={() => this.props.ShowFormReport()}
                >
                  Relatório
                </Button> */}
                {/* {this.renderDownloadXLS()} */}
                {/* <h4 className={classes.cardIconTitle}>Lista de membros</h4> */}
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={this.state.data}
                  pages={this.state.pages}
                  loading={this.state.loading}
                  filterable={true}
                  columns={
                    this.me.profile == "syndic"
                      ? [
                          {
                            Header: "#",
                            accessor: "id",
                          },
                          {
                            Header: "Local",
                            accessor: "local",
                            // filterable: this.me.profile != "syndic",
                          },
                          {
                            Header: "Data",
                            accessor: "date",
                            filterable: false,
                          },
                          {
                            Header: "Ações",
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                          },
                        ]
                      : [
                          {
                            Header: "#",
                            accessor: "id",
                          },
                          {
                            Header: "GEBRA",
                            accessor: "gebra",
                            filterable: this.me.profile != "syndic",
                          },
                          // {
                          //   Header: "Status",
                          //   accessor: "status",
                          //   filterable: false,
                          // },
                          // {
                          //   Header: "Responsável",
                          //   accessor: "user_name",
                          //   sortable: false,
                          //   filterable: false,
                          // },
                          {
                            Header: "Data",
                            accessor: "date",
                            filterable: false,
                          },
                          {
                            Header: "Status",
                            accessor: "status",
                            sortable: false,
                            filterable: false,
                          },
                          {
                            Header: "Ações",
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                          },
                        ]
                  }
                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                  defaultPageSize={20}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  previousText="Anterior"
                  nextText="Próxima"
                  loadingText="Carregando"
                  noDataText="Nenhum registro encontrado"
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                  manual
                  onFetchData={(state, instance) => {
                    // show the loading overlay
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                      this.setState({ loading: true }, () => {
                        // console.log(state.filtered);
                        const sorted = state.sorted.length === 0 ? "" : state.sorted[0].id;
                        const order =
                          state.sorted.length > 0 && state.sorted[0].desc ? "desc" : "asc";

                        // let toFilter = [...state.filtered];
                        // const filtraStatus = { id: "status", value: "1" };
                        // console.log(filtraStatus);
                        // toFilter.push(filtraStatus);
                        // console.log(toFilter);
                        // const filtered = btoa(
                        //   JSON.stringify([...state.filtered, { id: "status", value: "1" }])
                        // );

                        let filtered = [...state.filtered];

                        if (this.props.parentProps.match.path == "/product-returns-without-sap") {
                          filtered.push({ id: "sap_200", value: "0" });
                        }
                        filtered.push({ id: "status", value: "2" });
                        filtered.push({ id: "under_limit", value: "0" });
                        // console.log(filtered);
                        filtered = btoa(JSON.stringify(filtered));

                        // fetch your data
                        axios
                          .get(
                            `${consts.API_URL}/product-returns-canceled-piclists?page=${state.page +
                              1}&qtd_per_page=${
                              state.pageSize
                            }&sorted=${sorted}&order=${order}&filtered=${filtered}`,
                            {
                              page: state.page,
                              pageSize: state.pageSize,
                              sorted: state.sorted,
                              filtered: state.filtered,
                            }
                          )
                          .then((res) => {
                            // console.log(res)
                            // console.log(state.sorted)
                            // console.log(state.filtered)
                            // Update react-table
                            this.setState(
                              {
                                pages: res.data.pages,
                                loading: false,
                              },
                              () => {
                                this.setData(res.data.result);
                              }
                            );
                          })
                          .catch((err) => {
                            this.setState({
                              loading: false,
                            });
                          });
                      });
                    }, 500);
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log(state)
//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = (state) => ({
  ShowForm: state.ShowForm,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      save,
      showNotification,
      ShowFormReport,
      // get
    },
    dispatch
  );
const MemberList = withStyles(sweetAlertStyle)(ReactTables);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberList);
