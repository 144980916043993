import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";

import AuthReducer from "./modules/auth/authReducer";
import MainReducer from "./routes/Reducer";
import UserReducer from "./modules/users/Reducer";

import FridgeReducer from "./modules/fridges/Reducer";
import ProductReducer from "./modules/products/Reducer";
import PlanogramReducer from "./modules/planograms/Reducer";
import OrderReducer from "./modules/orders/Reducer";
import PiclistReducer from "./modules/piclists/Reducer";
import CarsReducer from "./modules/cars/Reducer";
import RoutesReducer from "./modules/routes/Reducer";
import ExtraordinaryRoutesReducer from "./modules/extraordinary-routes/Reducer";
import DeliveriesReducer from "./modules/deliveries/Reducer";
import AuditReducer from "./modules/audits/Reducer";
import EarningReducer from "./modules/earnings/Reducer";
import ProductReturnReducer from "./modules/product_returns/Reducer";
import ProductReturnByDriverReducer from "./modules/product_returns_by_driver/Reducer";
import FridgeFieldReducer from "./modules/fridges/fields/Reducer";
import EquipmentReducer from "./modules/equipements/Reducer";
import ScheduledAuditsReducer from "./modules/scheduled-audits/Reducer";

const rootReducer = combineReducers({
  form: formReducer,
  toastr: toastrReducer,
  users: UserReducer,
  auth: AuthReducer,
  main: MainReducer,

  fridges: FridgeReducer,
  products: ProductReducer,
  planograms: PlanogramReducer,
  orders: OrderReducer,
  piclists: PiclistReducer,
  cars: CarsReducer,
  routes: RoutesReducer,
  extraordinary_routes: ExtraordinaryRoutesReducer,
  deliveries: DeliveriesReducer,
  audits: AuditReducer,
  earnings: EarningReducer,
  earnings: EarningReducer,
  product_returns: ProductReturnReducer,
  product_returns_by_driver: ProductReturnByDriverReducer,
  fridge_field: FridgeFieldReducer,
  equipments: EquipmentReducer,
  scheduledAudits: ScheduledAuditsReducer,
});

export default rootReducer;
