const INITIAL_OBJ = {
  description: "",
  status_id: "",
};
const INITIAL_STATE = {
  showForm: false,
  equipment: INITIAL_OBJ,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(INITIAL_OBJ)
  // console.log(state);
  // console.log(action);
  switch (action.type) {
    case "EQUIPMENT_SHOW_FORM":
      return {
        ...state,
        showForm: true,
        equipment: action.payload === undefined ? INITIAL_OBJ : action.payload,
      };
    case "EQUIPMENT_HIDE_FORM":
      return { ...state, showForm: false, equipment: INITIAL_OBJ };
    default:
      return state;
  }
};
