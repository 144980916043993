import axios from "axios";
import consts from "../../consts";

export function ShowForm(item) {
  return { type: "ORDERS_SHOW_FORM", payload: item };
}

export function HideForm() {
  return { type: "ORDERS_HIDE_FORM" };
}

export function ShowFormReport() {
  return { type: "ORDERS_SHOW_FORM_REPORT" };
}

export function HideFormReport() {
  return { type: "ORDERS_HIDE_FORM_REPORT" };
}

// export function get(){
//   return dispatch => {
//       axios.get(`${consts.API_URL}/products`)
//       .then(resp => {
//         console.log(resp)
//           dispatch([
//             { type: 'ORDERS_GET', payload: resp.data }
//           ])
//       })
//     }
// }

export function save(product) {
  console.log(product);
  return dispatch => {
    if (product && product.id) {
      axios
        .put(`${consts.API_URL}/products/${product.id}`, product)
        .then(resp => {
          console.log("resp: ", resp);
          const configNotification = {
            message: "Produto atualizado com sucesso",
            color: "success"
          };
          dispatch([
            {
              type: "MAIN_SHOW_NOTIFICATION",
              showNotification: true,
              payload: configNotification
            },
            { type: "ORDERS_HIDE_FORM" }
          ]);
        })
        .catch(e => {
          console.log(e);
          console.log(e.response);
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors > 0
          ) {
            e.response.data.errors.forEach(error => {
              // toastr.error('Erro', error))
              const configNotification = {
                message: error,
                color: "danger"
              };
              dispatch([
                {
                  type: "MAIN_SHOW_NOTIFICATION",
                  showNotification: true,
                  payload: configNotification
                }
              ]);
            });
          } else if (e.response && e.response.data && e.response.data.message) {
            // console.log('e.response.data.message: ', e.response.data.message)
            // toastr.error('Erro', e.response.data.message)

            const configNotification = {
              message: e.response.data.message,
              color: "danger"
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification
              }
            ]);
          } else {
            // toastr.error('Erro', 'Erro inesperado.')
            const configNotification = {
              message: "Erro inesperado.",
              color: "danger"
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification
              }
            ]);
          }
        });
    } else {
      axios
        .post(`${consts.API_URL}/products`, product)
        .then(resp => {
          // console.log(resp)
          const configNotification = {
            message: "Produto cadastrado com sucesso",
            color: "success"
          };
          dispatch([
            {
              type: "MAIN_SHOW_NOTIFICATION",
              showNotification: true,
              payload: configNotification
            },
            { type: "ORDERS_HIDE_FORM" }
          ]);
        })
        .catch(e => {
          console.log(e);
          console.log(e.response);
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors > 0
          ) {
            e.response.data.errors.forEach(error => {
              // toastr.error('Erro', error))
              const configNotification = {
                message: error,
                color: "danger"
              };
              dispatch([
                {
                  type: "MAIN_SHOW_NOTIFICATION",
                  showNotification: true,
                  payload: configNotification
                }
              ]);
            });
          } else if (e.response && e.response.data && e.response.data.message) {
            // console.log('e.response.data.message: ', e.response.data.message)
            // toastr.error('Erro', e.response.data.message)

            const configNotification = {
              message: e.response.data.message,
              color: "danger"
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification
              }
            ]);
          } else {
            // toastr.error('Erro', 'Erro inesperado.')
            const configNotification = {
              message: "Erro inesperado.",
              color: "danger"
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification
              }
            ]);
          }
        });
    }
  };
}

export function showNotification(configNotification) {
  return {
    type: "MAIN_SHOW_NOTIFICATION",
    showNotification: true,
    payload: configNotification
  };
}
