import React, { useState } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// import { Link } from "react-router-dom";
import axios from "axios";
import consts from "../../consts";

import If from "../../common/operators/If";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import Loader from "components/Loader/Loader.jsx";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ShowForm,
  save,
  showNotification,
  ShowFormReport,
  // get
} from "./Actions";

import Datetime from "react-datetime";
import moment from "moment";
import ptbr from "moment/locale/pt-br";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/Visibility";
import NotaFistalIcon from "@material-ui/icons/InsertDriveFile";
import ShopStatusIcon from "@material-ui/icons/CreditCard";
import DoorIcon from "@material-ui/icons/LockOpen";
import AssignmentIcon from "@material-ui/icons/Assignment";

// import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import { dataTable } from "variables/general.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

export default function Menu({ classes, setLoading, showNotification }) {
  const [show, set_show] = useState(false);
  const [reportUrl, set_reportUrl] = useState(null);
  const [start_date, set_start_date] = useState(
    moment()
      .subtract(1, "months")
      .format("L")
  );
  const [end_date, set_end_date] = useState(moment().format("L"));

  async function saveButtonClick() {
    // console.log("saveButtonClick");
    setLoading(true);

    let data = {
      start_date: moment(start_date, "DD/MM/YYYY").format("YYYY-MM-DD 00:00:00"),
      end_date: end_date ? moment(end_date, "DD/MM/YYYY").format("YYYY-MM-DD 00:00:00") : null,
    };

    // console.log("data: ", data);

    axios
      .post(`${consts.API_URL}/product-returns-to-sap-items/report`, data)
      .then((resp) => {
        // console.log(resp);
        setLoading(false);
        set_reportUrl(resp.data.file);
        let configNotification = {
          message: "Relatório criado com sucesso",
          color: "success",
        };
        showNotification(configNotification);
      })
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        showNotification(configNotification);
        this.setState({ loading: false });
        // console.log(err);
      });
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        {!show && (
          <Card>
            <CardFooter color="warning" icon>
              <Button
                color={"primary"}
                className={classes.updateProfileButton}
                onClick={() => set_show(true)}
              >
                Relatório
              </Button>
            </CardFooter>
          </Card>
        )}
        {show && (
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Relatório de perdas e sobras</h4>
            </CardHeader>
            <CardBody>
              <form>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Datetime
                      timeFormat={false}
                      inputProps={{ placeholder: "" }}
                      locale="pt-br"
                      closeOnSelect
                      onChange={(event) => set_start_date(event._d)}
                      value={start_date}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Datetime
                      timeFormat={false}
                      inputProps={{ placeholder: "Fim" }}
                      isValidDate={(current) => {
                        return current.isBefore(moment());
                      }}
                      locale="pt-br"
                      closeOnSelect
                      onChange={(event) => set_end_date(event._d)}
                      value={end_date}
                    />
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
            <CardFooter>
              <Button onClick={() => set_show(false)}>Cancelar</Button>
              {reportUrl ? (
                <a href={`${reportUrl}`} target="_blank">
                  <Button
                    color="primary"
                    className={classes.updateProfileButton}
                    onClick={() => false}
                  >
                    Baixar XLS
                  </Button>
                </a>
              ) : null}

              <Button color="primary" onClick={() => saveButtonClick()}>
                {reportUrl ? "Criar novo relatório" : "Criar relatório"}
              </Button>
            </CardFooter>
          </Card>
        )}
      </GridItem>
    </GridContainer>
  );
}
