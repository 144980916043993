import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import axios from "axios";
import consts from "../../consts";

import If from "../../common/operators/If";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ShowForm,
  // ShowPicList,
  showNotification,
  save,
  // get
} from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import qrCodeIcom from "assets/img/ico/qrcode.png";
import MaterialIconList from "@material-ui/icons/List";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import CategoryIcon from "@material-ui/icons/Category";
import LocalPrintshopIcon from "@material-ui/icons/LocalPrintshop";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import CloudQueueIcon from "@material-ui/icons/CloudQueue";
import BuildIcon from "@material-ui/icons/Build";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import NoMeetingRoomIcon from "@material-ui/icons/NoMeetingRoom";
import WarningIcon from "@material-ui/icons/Warning";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

// import Assignment from "@material-ui/icons/Assignment";
// import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import { RiFileEditLine } from "react-icons/ri";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import FormControl from "@material-ui/core/FormControl";

// import { dataTable } from "variables/general.jsx";

// import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

// import ReactExport from "react-export-excel";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// const styles = {
//   cardIconTitle: {
//     ...cardTitle,
//     marginTop: "15px",
//     marginBottom: "0px"
//   }
// };

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    this.me = JSON.parse(localStorage.getItem(consts.UserKey)).user;
    this.state = {
      data: [],
      originalData: [],
      openingReasons: [],
      openingReason_id: "",
      pages: -1,
      loading: true,
      alert: null,
      show: false,
      showTable: true,
    };
  }

  componentDidMount() {
    this.getOpeningReasons();
  }

  // componentDidMount(){
  // this.getReportData();
  // }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  getOpeningReasons() {
    this.setState({
      loading: true,
    });
    axios
      .get(`${consts.API_URL}/fridge-opening-reasons/select`)
      .then((res) => {
        this.setState({
          openingReasons: res.data.result,
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        console.log(e.response);
        this.setState({
          loading: false,
        });
        if (e.response && e.response.data && e.response.data.errors && e.response.data.errors > 0) {
          e.response.data.errors.forEach((error) => {
            // toastr.error('Erro', error))
            const configNotification = {
              message: error,
              color: "danger",
            };
            this.props.showNotification(configNotification);
          });
        } else if (e.response && e.response.data && e.response.data.message) {
          // console.log('e.response.data.message: ', e.response.data.message)
          // toastr.error('Erro', e.response.data.message)

          const configNotification = {
            message: e.response.data.message,
            color: "danger",
          };
          this.props.showNotification(configNotification);
        } else {
          // toastr.error('Erro', 'Erro inesperado.')
          const configNotification = {
            message: "Erro inesperado.",
            color: "danger",
          };
          this.props.showNotification(configNotification);
        }
      });
  }

  delete(item) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Tem certeza?"
          onConfirm={() => {
            // console.log(item)
            axios
              .delete(`${consts.API_URL}/fridges/${item.id}`)
              .then((resp) => {
                var data = this.state.data;
                data.find((o, i) => {
                  if (o.id === item.id) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    data.splice(i, 1);
                    return true;
                  }
                  return false;
                });
                this.setState({ data: data });
              })
              .catch((e) => {
                console.log(e);
                console.log(e.response);
                const message =
                  e.response && e.response.data && e.response.data.message
                    ? e.response.data.message
                    : "Falha ao excluir geladeira.";
                const configNotification = {
                  message,
                  color: "danger",
                };
                this.props.showNotification(configNotification);
              });
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
          cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
          confirmBtnText="Sim, excluir!"
          cancelBtnText="Cancelar"
          showCancel
        >
          Não tem como recuperar o registro ao excluir!
        </SweetAlert>
      ),
    });
  }

  changeMaintenance(item) {
    this.setState({ loading: true });
    const update = {
      maintenance: !item.maintenance,
    };
    // console.log(this.state.originalData);
    axios
      .put(`${consts.API_URL}/fridges/${item.id}`, update)
      .then((resp) => {
        // console.log(resp);
        const data = this.state.originalData.map((data) => {
          let fridge = data;
          if (data.id == item.id) {
            fridge.maintenance = !data.maintenance;
          }
          return fridge;
        });
        // console.log(data);
        this.setState(data);
        this.setData(data);
        this.setState({ loading: false });

        // this.get();
      })
      .catch((e) => {
        console.log(e);
        console.log(e.response);
        const message =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "Falha ao alterar status da geladeira.";
        const configNotification = {
          message,
          color: "danger",
        };
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
      });
  }

  changeOnlineOffline(item) {
    if (item.offline) {
      this.setOnlineOffline(item);
      return;
    }

    console.log("changeOnlineOffline");
    const that = this;

    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Tem certeza que deseja colocar essa geladeira em modo offline?"
          onConfirm={() => {
            that.setOnlineOffline(item);
            that.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
          cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
          confirmBtnText="Sim!"
          cancelBtnText="Cancelar"
          showCancel
        >
          {/* Não tem como recuperar o registro ao excluir! */}
        </SweetAlert>
      ),
    });
  }

  setOnlineOffline(item) {
    this.setState({ loading: true });

    const url = item.offline
      ? `${consts.API_URL}/fridges/${item.id}/online`
      : `${consts.API_URL}/fridges/${item.id}/offline`;
    // console.log({ url });
    // return;
    axios
      .put(url, {})
      .then((resp) => {
        // console.log(resp);
        const data = this.state.originalData.map((data) => {
          let fridge = data;
          if (data.id == item.id) {
            fridge.offline = !data.offline;
          }
          return fridge;
        });
        // console.log(data);
        this.setState(data);
        this.setData(data);
        this.setState({ loading: false });

        // this.get();
      })
      .catch((e) => {
        console.log(e);
        console.log(e.response);
        const message =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "Falha ao alterar status da geladeira.";
        const configNotification = {
          message,
          color: "danger",
        };
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
      });
  }

  setData(result) {
    var data = result.map((item) => {
      let actions = (
        // we've added some custom button actions
        <div className="actions-right">
          {/* <Link to={`/fridges/${item.id}/qrcode`}>
              <Button justIcon round simple onClick={() => {}} color="primary" className="edit">
                <img src={qrCodeIcom} />
              </Button>
            </Link>{" "} */}
          <Button
            justIcon
            // round
            simple
            color={item.release_door_opening === true ? "success" : "primary"}
            // className="edit"
            title="Liberar abertura de porta ao motorista"
            onClick={() => {
              if (!item.release_door_opening) {
                this.showOpeningReason(item);
              }
            }}
          >
            {item.release_door_opening === true ? <LockOpenIcon /> : <LockIcon />}
          </Button>
          <Button
            justIcon
            // round
            simple
            color="primary"
            // className="edit"
            title={item.maintenance === true ? "Manutenção" : "Manutenção desativada"}
            onClick={() => this.changeMaintenance(item)}
          >
            {item.maintenance === true ? <MeetingRoomIcon /> : <NoMeetingRoomIcon />}
          </Button>
          <Button
            justIcon
            // round
            simple
            color="primary"
            // className="edit"
            title={item.offline === true ? "Offline" : "Online"}
            onClick={() => this.changeOnlineOffline(item)}
          >
            {item.offline === true ? <CloudOffIcon /> : <CloudQueueIcon />}
          </Button>
          <Link to={`/fridges/${item.id}/inventory`}>
            <Button justIcon round simple onClick={() => {}} color="primary" className="edit">
              <RiFileEditLine />
            </Button>
          </Link>{" "}
          <Link to={`/fridges/${item.id}/piclists`}>
            <Button justIcon round simple onClick={() => {}} color="primary" className="edit">
              <CategoryIcon />
            </Button>
          </Link>
          <Button
            justIcon
            round
            simple
            onClick={() => this.props.ShowForm(item)}
            color="warning"
            className="edit"
          >
            <EditIcon />
          </Button>{" "}
          <Button
            justIcon
            round
            simple
            onClick={() => this.delete(item)}
            color="danger"
            className="remove"
          >
            <Close />
          </Button>{" "}
        </div>
      );

      if (this.me.profile === "marketing") {
        actions = (
          // we've added some custom button actions
          <div className="actions-right">
            <Link to={`/fridges/${item.id}/inventory`}>
              <Button justIcon round simple onClick={() => {}} color="primary" className="edit">
                <RiFileEditLine />
              </Button>
            </Link>{" "}
          </div>
        );
      }

      if (this.me.profile === "maintenance_manager") {
        actions = (
          // we've added some custom button actions
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => this.props.ShowForm(item)}
              color="warning"
              className="edit"
            >
              <EditIcon />
            </Button>{" "}
          </div>
        );
      }

      return {
        id: item.id,
        gebra: item.gebra,
        local: item.local,
        maintenance: (
          <Button
            justIcon
            // round
            simple
            color="primary"
            // className="edit"
            onClick={() => this.changeMaintenance(item)}
          >
            {item.maintenance === true ? <ToggleOnIcon /> : <ToggleOffIcon />}
          </Button>
        ),
        offline: (
          <Button
            justIcon
            // round
            simple
            color="primary"
            // className="edit"
            onClick={() => this.changeOnlineOffline(item)}
          >
            {item.offline === true ? <ToggleOnIcon /> : <ToggleOffIcon />}
          </Button>
        ),
        actions,
      };
    });

    this.setState({ data });
  }

  renderOpeningForm(item) {
    const { classes } = this.props;
    const that = this;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        Motivo para cancelamento
        <br />
        <select
          value={that.state.openingReason_id}
          onChange={(event) => {
            that.setState({ openingReason_id: event.target.value }, () => that.showCancel(item));
          }}
          className={classes.selectMenu}
        >
          <option value="">Selecione</option>
          {that.state.openingReasons.map((openingReason) => {
            return (
              <option
                key={openingReason.id}
                className={classes.selectMenuItem}
                value={openingReason.id}
              >
                {openingReason.name}
              </option>
            );
          })}
        </select>
      </FormControl>
    );
  }

  showOpeningReason(item) {
    // console.log(item);
    const that = this;
    that.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Tem certeza?"
          onConfirm={() => {
            console.log(item);
            this.releaseDoorOppening(item);
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
          cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
          confirmBtnText="Sim, liberar!"
          cancelBtnText="Desistir"
          showCancel
        >
          {that.renderCancelForm(item)}
          {/* {JSON.stringify(this.state.cancellationReasons)} */}
          {/* {JSON.stringify(this.state.cancellationReason_id)} */}
        </SweetAlert>
      ),
    });
  }

  renderCancelForm(item) {
    const { classes } = this.props;
    const that = this;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        Motivo para liberação de abertura de porta
        <br />
        <select
          value={that.state.openingReason_id}
          onChange={(event) => {
            that.setState({ openingReason_id: event.target.value }, () =>
              that.showOpeningReason(item)
            );
          }}
          className={classes.selectMenu}
        >
          <option value="">Selecione</option>
          {that.state.openingReasons.map((cancellationReason) => {
            return (
              <option
                key={cancellationReason.id}
                className={classes.selectMenuItem}
                value={cancellationReason.id}
              >
                {cancellationReason.name}
              </option>
            );
          })}
        </select>
      </FormControl>
    );
  }

  releaseDoorOppening(item) {
    this.setState({ loading: true, showTable: false });

    axios
      .put(`${consts.API_URL}/fridges/${item.id}/release-door-oppening`, {
        fridge_opening_reason_id: this.state.openingReason_id,
      })
      .then(() => {
        const configNotification = {
          message: "Geladeira liberada para abertura.",
          color: "success",
        };
        this.props.showNotification(configNotification);
        this.setState({ loading: false, showTable: true, openingReason_id: "" });
      })
      .catch((e) => {
        console.log(e);
        console.log(e.response);
        const message =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "Falha ao alterar status da geladeira.";
        const configNotification = {
          message,
          color: "danger",
        };
        this.props.showNotification(configNotification);
        this.setState({ loading: false, showTable: true });
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.alert}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <MaterialIconList />
                </CardIcon>
                <Button
                  color="primary"
                  className={classes.updateProfileButton}
                  onClick={() => this.props.ShowForm()}
                >
                  Adicionar
                </Button>
                {/* <h4 className={classes.cardIconTitle}>Lista de membros</h4> */}
              </CardHeader>
              <CardBody>
                {this.state.showTable && (
                  <ReactTable
                    data={this.state.data}
                    pages={this.state.pages}
                    loading={this.state.loading}
                    filterable={true}
                    columns={[
                      {
                        Header: "GEBRA",
                        accessor: "gebra",
                      },
                      {
                        Header: "Local",
                        accessor: "local",
                      },
                      // {
                      //   Header: "Manutenção",
                      //   accessor: "maintenance",
                      //   filterable: false,
                      // },
                      // {
                      //   Header: "Offline",
                      //   accessor: "offline",
                      //   filterable: false,
                      // },
                      {
                        Header: "Ações",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                    defaultPageSize={20}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                    previousText="Anterior"
                    nextText="Próxima"
                    loadingText="Carregando"
                    noDataText="Nenhum registro encontrado"
                    pageText="Página"
                    ofText="de"
                    rowsText="registros"
                    manual
                    onFetchData={(state, instance) => {
                      // show the loading overlay
                      this.setState({ loading: true }, () => {
                        const sorted = state.sorted.length === 0 ? "" : state.sorted[0].id;
                        const order =
                          state.sorted.length > 0 && state.sorted[0].desc ? "desc" : "asc";
                        const filtered = btoa(JSON.stringify(state.filtered));

                        // fetch your data
                        axios
                          .get(
                            `${consts.API_URL}/fridges?page=${state.page + 1}&qtd_per_page=${
                              state.pageSize
                            }&sorted=${sorted}&order=${order}&filtered=${filtered}`,
                            {
                              page: state.page,
                              pageSize: state.pageSize,
                              sorted: state.sorted,
                              filtered: state.filtered,
                            }
                          )
                          .then((res) => {
                            // console.log(res)
                            // console.log(state.sorted)
                            // console.log(state.filtered)
                            // Update react-table
                            this.setState(
                              {
                                pages: res.data.pages,
                                loading: false,
                              },
                              () => {
                                this.setState({ originalData: res.data.result });
                                this.setData(res.data.result);
                              }
                            );
                          });
                      });
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log(state)
//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = (state) => ({
  ShowForm: state.ShowForm,
  ShowPicList: state.ShowPicList,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      // ShowPicList,
      showNotification,
      save,
      // get
    },
    dispatch
  );
const MemberList = withStyles(sweetAlertStyle)(ReactTables);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberList);
