import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// import { Link } from "react-router-dom";
import axios from "axios";
import consts from "../../consts";

import If from "../../common/operators/If";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import Loader from "components/Loader/Loader.jsx";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ShowForm,
  save,
  showNotification,
  ShowFormReport,
  // get
} from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/Visibility";
import NotaFistalIcon from "@material-ui/icons/InsertDriveFile";
import ShopStatusIcon from "@material-ui/icons/CreditCard";
import DoorIcon from "@material-ui/icons/LockOpen";

// import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import { dataTable } from "variables/general.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import moment from "moment";
import "moment/locale/pt-br";

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    this.me = JSON.parse(localStorage.getItem(consts.UserKey)).user;
    this.state = {
      data: [],
      reportData: [],
      pages: -1,
      loading: true,
      alert: null,
      show: false,
    };
    // this.getReportData();
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  setLoading(value) {
    this.setState({
      loading: value,
    });
  }

  setData(result) {
    var data = result.map((item) => {
      const columns = [
        {
          Header: "NF",
          accessor: "images_nf_products",
          sortable: false,
          filterable: false,
        },
        {
          Header: "Pré",
          accessor: "images_pre_refill",
          sortable: false,
          filterable: false,
        },
        {
          Header: "Pós",
          accessor: "images_pos_refill",
          sortable: false,
          filterable: false,
        },
        {
          Header: "Assinatura",
          accessor: "images_nf_signature",
          sortable: false,
          filterable: false,
        },
        {
          Header: "Ações",
          accessor: "actions",
          sortable: false,
          filterable: false,
        },
      ];
      // console.log(item);
      const local = item.Fridge && item.Fridge.local ? item.Fridge.local : "";
      const gebra = item.Fridge && item.Fridge.gebra ? item.Fridge.gebra : "";
      const user_name = item.driver && item.driver.name ? item.driver.name : "";
      const images_nf_products = item.images_nf_products ? (
        <img width="100" height="100" src={item.images_nf_products} />
      ) : (
        ""
      );
      const images_pre_refill = item.images_pre_refill ? (
        <img width="100" height="100" src={item.images_pre_refill} />
      ) : (
        ""
      );
      const images_pos_refill = item.images_pos_refill ? (
        <img width="100" height="100" src={item.images_pos_refill} />
      ) : (
        ""
      );
      const images_nf_signature = item.images_nf_signature ? (
        <img width="100" height="100" src={item.images_nf_signature} />
      ) : (
        ""
      );

      return {
        id: item.id,
        gebra,
        local,
        user_name,
        date: <div>{item.createdAt ? moment(item.createdAt).format("LLL") : ""}</div>,
        images_nf_products,
        images_pre_refill,
        images_pos_refill,
        images_nf_signature,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => this.props.ShowForm(item)}
              color="primary"
              className="edit"
            >
              <ViewIcon />
            </Button>{" "}
          </div>
        ),
      };
    });

    this.setState({ data });
  }

  render() {
    // console.log(this.me);
    const { classes } = this.props;
    let timeout = null;
    return (
      <div>
        {this.state.loading ? <Loader /> : null}
        {this.state.alert}

        {/* {this.props.parentProps.location.pathname === "/all-product-returns" && ( */}

        {/* )} */}

        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <MaterialIconList />
                </CardIcon>
                {/* <Button
                  color="primary"
                  className={classes.updateProfileButton}
                  onClick={() => this.props.ShowFormReport()}
                >
                  Relatório
                </Button> */}
                {/* {this.renderDownloadXLS()} */}
                {/* <h4 className={classes.cardIconTitle}>Lista de membros</h4> */}
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={this.state.data}
                  pages={this.state.pages}
                  loading={this.state.loading}
                  filterable={true}
                  columns={[
                    {
                      Header: "Local",
                      accessor: "local",
                      sortable: false,
                    },
                    {
                      Header: "GEBRA",
                      accessor: "gebra",
                      sortable: false,
                    },
                    {
                      Header: "Motorista",
                      accessor: "user_name",
                      sortable: false,
                      // filterable: false,
                    },
                    {
                      Header: "Data",
                      accessor: "date",
                      filterable: false,
                    },
                    {
                      Header: "NF",
                      accessor: "images_nf_products",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Pré",
                      accessor: "images_pre_refill",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Pós",
                      accessor: "images_pos_refill",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Assinatura",
                      accessor: "images_nf_signature",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Ações",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                  defaultPageSize={20}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  previousText="Anterior"
                  nextText="Próxima"
                  loadingText="Carregando"
                  noDataText="Nenhum registro encontrado"
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                  manual
                  onFetchData={(state, instance) => {
                    // show the loading overlay
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                      this.setState({ loading: true }, () => {
                        // console.log(state.filtered);
                        const sorted = state.sorted.length === 0 ? "" : state.sorted[0].id;
                        const order =
                          state.sorted.length > 0 && state.sorted[0].desc ? "desc" : "asc";

                        // let toFilter = [...state.filtered];
                        // const filtraStatus = { id: "status", value: "1" };
                        // console.log(filtraStatus);
                        // toFilter.push(filtraStatus);
                        // console.log(toFilter);
                        // const filtered = btoa(
                        //   JSON.stringify([...state.filtered, { id: "status", value: "1" }])
                        // );
                        const filtered = btoa(JSON.stringify([...state.filtered]));

                        // fetch your data
                        axios
                          .get(
                            `${consts.API_URL}/refills?page=${state.page + 1}&qtd_per_page=${
                              state.pageSize
                            }&sorted=${sorted}&order=${order}&filtered=${filtered}`,
                            {
                              page: state.page,
                              pageSize: state.pageSize,
                              sorted: state.sorted,
                              filtered: state.filtered,
                            }
                          )
                          .then((res) => {
                            // console.log(res)
                            // console.log(state.sorted)
                            // console.log(state.filtered)
                            // Update react-table
                            this.setState(
                              {
                                pages: res.data.pages,
                                loading: false,
                              },
                              () => {
                                this.setData(res.data.result);
                              }
                            );
                          })
                          .catch((err) => {
                            this.setState({
                              loading: false,
                            });
                          });
                      });
                    }, 500);
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log(state)
//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = (state) => ({
  ShowForm: state.ShowForm,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      save,
      showNotification,
      ShowFormReport,
      // get
    },
    dispatch
  );
const MemberList = withStyles(sweetAlertStyle)(ReactTables);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberList);
