import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// import { Link } from "react-router-dom";
import axios from "axios";
import consts from "../../consts";

import If from "../../common/operators/If";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import Loader from "components/Loader/Loader.jsx";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ShowForm,
  save,
  showNotification,
  ShowFormReport,
  // get
} from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/Visibility";
import NotaFistalIcon from "@material-ui/icons/InsertDriveFile";
import ShopStatusIcon from "@material-ui/icons/CreditCard";
import DoorIcon from "@material-ui/icons/LockOpen";

// import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import { GrRevert } from "react-icons/gr";

// import { dataTable } from "variables/general.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

// import ReactExport from "react-export-excel";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
// console.log(sweetAlertStyle);

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    // console.log("parentProps: ", props.parentProps);
    this.me = JSON.parse(localStorage.getItem(consts.UserKey)).user;
    this.state = {
      data: [],
      reportData: [],
      pages: -1,
      loading: true,
      alert: null,
      show: false,
    };
    // this.getReportData();
  }

  execSap(order_id) {
    // console.log(this.me);
    if (this.me.profile == "syndic" || this.me.profile == "marketing") {
      // console.log("nao pode");
      return false;
    }
    // return false;
    const that = this;
    // console.log("sap");
    // console.log("execSap(): ", order_id);
    // return false;
    this.setState({ loading: true });
    axios
      .post(`${consts.API_URL}/audits/${order_id}/sap`)
      .then((resp) => {
        // console.log("resp: ", resp);
        const configNotification = {
          message: resp.data.message,
          color: "success",
        };
        this.props.showNotification(configNotification);
        that.setState({ loading: false });
      })
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // console.log(err);
      });
  }

  // getReportData() {
  //   // console.log('getReportData(){')
  //   axios
  //     .get(`${consts.API_URL}/audits/report`)
  //     .then(resp => {
  //       // console.log('resp: ',resp)
  //       // const respFiltered = resp.data.result.filter((item => (item.status == 1)))
  //       // console.log('respFiltered: ', respFiltered)

  //       this.setState({ reportData: resp.data });
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  // }

  // renderDownloadXLS() {
  //   const { classes } = this.props;
  //   // console.log('renderDownloadXLS')
  //   // console.log(this.state)
  //   // return Promise.all([
  //   // ])

  //   // console.log(report)
  //   return (
  //     <ExcelFile
  //       element={
  //         <Button color="info" className={classes.updateProfileButton}>
  //           Download XLS
  //         </Button>
  //       }
  //     >
  //       <ExcelSheet data={this.state.reportData} name="Pedidos">
  //         <ExcelColumn label="#" value="id" />
  //         <ExcelColumn label="GEBRA" value="gebra" />
  //         <ExcelColumn label="DATA" value="created_at" />
  //         <ExcelColumn label="PRODUTO" value="product_name" />
  //         <ExcelColumn label="SKU" value="product_sku" />
  //         <ExcelColumn label="QTD" value="qtd" />
  //         <ExcelColumn label="PREÇO" value="price" />
  //         <ExcelColumn label="TOTAL" value="total" />
  //       </ExcelSheet>
  //     </ExcelFile>
  //   );
  // }

  // componentWillMount(){
  // this.get();
  // }

  // componentDidMount(){
  // this.getReportData();
  // }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  delete(item) {
    if (this.me.profile !== "manager") {
      return false;
    }
    const that = this;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Tem certeza?"
          onConfirm={() => {
            // console.log(item)
            axios
              .delete(`${consts.API_URL}/audits/${item.id}`)
              .then((resp) => {
                var data = this.state.data;
                data.find((o, i) => {
                  if (o.id === item.id) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    data.splice(i, 1);
                    return true;
                  }
                  return false;
                });
                let configNotification = {
                  message: "Produto excluído com sucesso.",
                  color: "success",
                };
                that.props.showNotification(configNotification);
                this.setState({ data: data });
              })
              .catch((err) => {
                let configNotification = {
                  message: "Erro ao excluir produto.",
                  color: "danger",
                };
                that.props.showNotification(configNotification);
              });
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
          cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
          confirmBtnText="Sim, excluir!"
          cancelBtnText="Cancelar"
          showCancel
        >
          Não tem como recuperar o registro ao excluir!
        </SweetAlert>
      ),
    });
  }

  setData(result) {
    var data = result.map((item) => {
      const total = item.total_losses.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });

      const gebra = item.Fridge && item.Fridge.gebra ? item.Fridge.gebra : "";
      const local = item.Fridge && item.Fridge.local ? item.Fridge.local : "";
      // let status;
      // switch (item.status) {
      //   case 1:
      //     status = "Concluído";
      //     break;
      //   case 2:
      //     status = "Cancelado";
      //     break;
      //   default:
      //     status = "Andamento";
      //     break;
      // }
      // console.log(item);
      let statusColor = "";
      switch (item.status) {
        case 1:
          statusColor = "success";
          break;
        case 2:
          statusColor = "danger";
          break;
        default:
          statusColor = "info";
          break;
      }

      let statusDoorOpened = "danger";
      if (item.door_released === true) {
        statusDoorOpened = "warning";
        if (item.door_pulled === true) {
          statusDoorOpened = "success";
        }
      }

      return {
        id: item.id,
        gebra,
        local,
        // status: item.statusText,
        date: (
          <div>{item.createdAt ? new Date(item.createdAt).toLocaleString("pt-BR") : ""}</div>
          // item.createdAt
        ),
        // total: (
        //   <div>
        //     { (item.createdAt) ? new Date(total).toLocaleString('pt-BR') : '' }
        //   </div>
        //   // item.createdAt
        // ),
        // total: (item.OrderProducts && item.OrderProducts.length > 0) : item.OrderProducts.reduce()
        total,
        status: (
          <div className="teste">
            <Button
              justIcon
              round
              simple
              onClick={() => {
                if (
                  item.sap_200 == 0 &&
                  this.me.profile !== "syndic" &&
                  this.me.profile !== "marketing"
                ) {
                  // console.log("execSap");
                  this.execSap(item.id);
                }
              }}
              color={item.sap_200 > 0 ? "success" : "danger"}
              className="edit"
              title={item.sap_200 > 0 ? "Nota fiscal emitida" : "Nota fiscal não emitida"}
            >
              <NotaFistalIcon />
            </Button>{" "}
            {item.reversal ? (
              <Button justIcon simple title={"Estornado"}>
                <GrRevert />
              </Button>
            ) : null}
          </div>
        ),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => this.props.ShowForm(item)}
              color="primary"
              className="edit"
            >
              <ViewIcon />
            </Button>{" "}
            <Button
              justIcon
              round
              simple
              onClick={() => this.delete(item)}
              color="danger"
              className="remove"
            >
              <Close />
            </Button>{" "}
          </div>
        ),
      };
    });

    this.setState({ data });
  }

  render() {
    // console.log(this.me);
    const { classes } = this.props;
    let timeout = null;
    return (
      <div>
        {this.state.loading ? <Loader /> : null}
        {this.state.alert}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <MaterialIconList />
                </CardIcon>
                {/* <Button
                  color="primary"
                  className={classes.updateProfileButton}
                  onClick={() => this.props.ShowFormReport()}
                >
                  Relatório
                </Button> */}
                {/* {this.renderDownloadXLS()} */}
                {/* <h4 className={classes.cardIconTitle}>Lista de membros</h4> */}
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={this.state.data}
                  pages={this.state.pages}
                  loading={this.state.loading}
                  filterable={true}
                  columns={
                    this.me.profile == "syndic"
                      ? [
                          {
                            Header: "#",
                            accessor: "id",
                          },
                          {
                            Header: "Local",
                            accessor: "local",
                            // filterable: this.me.profile != "syndic",
                          },
                          {
                            Header: "Data",
                            accessor: "date",
                            filterable: false,
                          },
                          {
                            Header: "Total",
                            accessor: "total",
                            filterable: false,
                          },
                          {
                            Header: "Ações",
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                          },
                        ]
                      : [
                          {
                            Header: "#",
                            accessor: "id",
                          },
                          {
                            Header: "GEBRA",
                            accessor: "gebra",
                            filterable: this.me.profile != "syndic",
                          },
                          {
                            Header: "STATUS",
                            accessor: "status",
                            sortable: false,
                            filterable: false,
                          },
                          {
                            Header: "Data",
                            accessor: "date",
                            filterable: false,
                          },
                          {
                            Header: "Total",
                            accessor: "total",
                            filterable: false,
                          },
                          {
                            Header: "Ações",
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                          },
                        ]
                  }
                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                  defaultPageSize={20}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  previousText="Anterior"
                  nextText="Próxima"
                  loadingText="Carregando"
                  noDataText="Nenhum registro encontrado"
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                  manual
                  onFetchData={(state, instance) => {
                    // show the loading overlay
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                      this.setState({ loading: true }, () => {
                        // console.log(state.filtered);
                        let sorted = state.sorted.length === 0 ? "" : state.sorted[0].id;
                        let order =
                          state.sorted.length > 0 && state.sorted[0].desc ? "desc" : "asc";

                        // let toFilter = [...state.filtered];
                        // const filtraStatus = { id: "status", value: "1" };
                        // console.log(filtraStatus);
                        // toFilter.push(filtraStatus);
                        // console.log(toFilter);
                        let filtered = [...state.filtered, { id: "losses", value: "1" }];

                        if (this.props.parentProps.match.path == "/losses-nf") {
                          filtered.push({ id: "sap_200", value: "0" });
                          filtered.push({ id: "only_reviewed", value: "1" });
                        }

                        if (this.props.parentProps.match.path == "/losses-review") {
                          filtered.push({ id: "sap_200", value: "0" });
                          filtered.push({ id: "review", value: "1" });
                          filtered.push({ id: "reviewed", value: "0" });

                          sorted = "id";
                          order = "asc";
                        }
                        // console.log(filtered);
                        filtered = btoa(JSON.stringify(filtered));

                        // fetch your data
                        axios
                          .get(
                            `${consts.API_URL}/audits?page=${state.page + 1}&qtd_per_page=${
                              state.pageSize
                            }&sorted=${sorted}&order=${order}&filtered=${filtered}`,
                            {
                              page: state.page,
                              pageSize: state.pageSize,
                              sorted: state.sorted,
                              filtered: state.filtered,
                            }
                          )
                          .then((res) => {
                            // console.log(res)
                            // console.log(state.sorted)
                            // console.log(state.filtered)
                            // Update react-table
                            this.setState(
                              {
                                pages: res.data.pages,
                                loading: false,
                              },
                              () => {
                                this.setData(res.data.result);
                              }
                            );
                          })
                          .catch((err) => {
                            this.setState({
                              loading: false,
                            });
                          });
                      });
                    }, 500);
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log(state)
//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = (state) => ({
  ShowForm: state.ShowForm,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      save,
      showNotification,
      ShowFormReport,
      // get
    },
    dispatch
  );
const MemberList = withStyles(sweetAlertStyle)(ReactTables);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberList);
