// import Dashboard from "views/Dashboard/Dashboard.jsx";
// import Buttons from "views/Components/Buttons.jsx";
// import GridSystem from "views/Components/GridSystem.jsx";
// import Panels from "views/Components/Panels.jsx";
// import SweetAlert from "views/Components/SweetAlert.jsx";
// import Notifications from "views/Components/Notifications.jsx";
// import Icons from "views/Components/Icons.jsx";
// import Typography from "views/Components/Typography.jsx";
// import RegularForms from "views/Forms/RegularForms.jsx";
// import ExtendedForms from "views/Forms/ExtendedForms.jsx";
// import ValidationForms from "views/Forms/ValidationForms.jsx";
// import Wizard from "views/Forms/Wizard.jsx";
// import RegularTables from "views/Tables/RegularTables.jsx";
// import ExtendedTables from "views/Tables/ExtendedTables.jsx";
// import ReactTables from "views/Tables/ReactTables.jsx";
// import GoogleMaps from "views/Maps/GoogleMaps.jsx";
// import FullScreenMap from "views/Maps/FullScreenMap.jsx";
// import VectorMap from "views/Maps/VectorMap.jsx";
// import Charts from "views/Charts/Charts.jsx";
// import Calendar from "views/Calendar/Calendar.jsx";
// import Widgets from "views/Widgets/Widgets.jsx";
// import UserProfile from "views/Pages/UserProfile.jsx";
// import TimelinePage from "views/Pages/Timeline.jsx";
// import RTLSupport from "views/Pages/RTLSupport.jsx";

// Modules
import DashboardModule from "../modules/dashboard/Index";
import DashboardPickingListsAnalyticalModule from "../modules/dashboard/picking-lists-analytical/Index";
import DashboardPickingListsAnalyticalDetailModule from "../modules/dashboard/picking-lists-analytical-detail/Index";
import DashboardPickingListsSyntheticalModule from "../modules/dashboard/picking-lists-synthetical/Index";

import MapModule from "../modules/map/Index";
import UsersModule from "../modules/users/Index";
import UsersMobileModule from "../modules/m_users/Index";
import StaffsModule from "../modules/staffs/Index";
import MeModule from "../modules/users/Me";
import PasswordModule from "../modules/users/Password";
import ProductsModule from "../modules/products/Index";
import PlanogramsModule from "../modules/planograms/Index";

import FridgesModule from "../modules/fridges/Index";
import ContractCancellationModule from "../modules/contract-cancellations//Index";
import ManageFridgeNotificationModule from "../modules/manage-fridge-notifications/Index";
import FridgeQrcodeModule from "../modules/fridges/Qrcode";
import FridgePiclistsModule from "../modules/fridges/piclists/Index";
import PiclistsModule from "../modules/piclists/Index";
import PiclistRoutinesModule from "../modules/piclist_routines/Index";
import InventoryModule from "../modules/inventory/Index";
import OrdersModule from "../modules/orders/Index";
import CarsModule from "../modules/cars/Index";
import EquipementsModule from "../modules/equipements/Index";
import EquipementStatusesModule from "../modules/equipement-statuses/Index";
import EquipementReturnReasonsModule from "../modules/equipement-return-reasons/Index";
import EquipementConfirmModule from "../modules/equipement-confirm/Index";
import EquipmentTransactionsModule from "../modules/equipements/transactions/Index";
import RoutesModule from "../modules/routes/Index";
import ExtraordinaryRoutesModule from "../modules/extraordinary-routes/Index";
// import DeliveriesModule from "../modules/deliveries/Index";
import AuditsModule from "../modules/audits/Index";
import EarningsModule from "../modules/earnings/Index";
import MaintenancesModule from "../modules/maintenances/Index";
import ProductReturnsModule from "../modules/product_returns/Index";
import RefillModule from "../modules/refills/Index";
import ProductReturnsByDriverModule from "../modules/product_returns_by_driver/Index";

import FridgeBrandsModule from "../modules/fridges/fields/brands/Index";
import FridgeTypesModule from "../modules/fridges/fields/types/Index";
import FridgeChannelsModule from "../modules/fridges/fields/channels/Index";
import FridgeSubchannelsModule from "../modules/fridges/fields/subchannels/Index";
import FridgeSegmentationsModule from "../modules/fridges/fields/segmentations/Index";
import FridgeBanksModule from "../modules/fridges/fields/banks/Index";
import FridgeAutonomousMarketsModule from "../modules/fridges/fields/autonomous-markets/Index";
import FridgeInstallationSitesModule from "../modules/fridges/fields/installation-sites/Index";

import PiclistCancellationReasonsModule from "../modules/piclists/fields/cancellation-reasons/Index";
import FridgeOpeningReasonsModule from "../modules/fridge-opening-reasons/Index";
import FridgeReleaseDoorOpeningsModule from "../modules/fridge_release_door_openings/Index";
import FridgeTechsModule from "../modules/fridge-techs/Index";

import OrdersCondosModule from "../modules/orders-condos/Index";
import ConferencePanelReturnModule from "../modules/conference-panel-return/Index";
import ConferencePanelCancelModule from "../modules/conference-panel-cancel/Index";
import ConferencePanelCancelSapModule from "../modules/conference-panel-cancel-sap/Index";
import ConferencePanelNoTransportModule from "../modules/conference-panel-no-transport/Index";
import ConferencePanelContractCancellationsModule from "../modules/conference-panel-contract-cancellations/Index";
import ChargebacksModule from "../modules/chargebacks/Index";
import ChargebackStatusModule from "../modules/chargeback-status/Index";
import ScheduledAuditUnproductivityReasonModule from "../modules/scheduled-audit-unproductivity-reasons/Index";
import ScheduledAuditModule from "../modules/scheduled-audits/Index";
import ConfigVarsModule from "../modules/config-vars/Index";
import SapTableModule from "../modules/sap-tables/Index";
// End Modules

// import pagesRoutes from "./pages.jsx";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import PinDropIcon from "@material-ui/icons/PinDrop";
import MapIcon from "@material-ui/icons/Map";
import SettingsIcon from "@material-ui/icons/Settings";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TransfersIcon from "@material-ui/icons/Loop";
// import EqualizerIcon from "@material-ui/icons/Equalizer";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
// import Apps from "@material-ui/icons/Apps";
import CategoryIcon from "@material-ui/icons/Category";
import GroupIcon from "@material-ui/icons/Group";
// import DeliveryIcon from "@material-ui/icons/ExitToApp";
// import DeliveryIcon from "@material-ui/icons/AvTimer";
import DeliveryIcon from "@material-ui/icons/MergeType";
import RoutesIcon from "@material-ui/icons/CallSplit";
import ExtraordinaryRoutesIcon from "@material-ui/icons/CalendarToday";
import RefillIcon from "@material-ui/icons/EmojiTransportation";
import CarIcon from "@material-ui/icons/LocalShipping";
import ViewIcon from "@material-ui/icons/Visibility";
import OpenIcon from "@material-ui/icons/LockOpen";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ListAltIcon from "@material-ui/icons/ListAlt";
import UndoIcon from "@material-ui/icons/Undo";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
// import ContentPaste from "@material-ui/icons/ContentPaste";
// import GridOn from "@material-ui/icons/GridOn";
// import Place from "@material-ui/icons/Place";
// import WidgetsIcon from "@material-ui/icons/Widgets";
// import Timeline from "@material-ui/icons/Timeline";
// import DateRange from "@material-ui/icons/DateRange";
// @material-ui/icons
// import Face from "@material-ui/icons/Face";
// import { AiOutlineAudit } from "react-icons/ai";

var dashRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: DashboardIcon,
  //   // component: DashboardModule
  //   component: DashboardModule,
  //   profiles: ["manager", "syndic"],
  // },
  {
    collapse: true,
    state: "dashboardCollapse",
    name: "Dashboard",
    icon: DashboardIcon,
    profiles: ["manager", "syndic", "conferente"],
    views: [
      {
        path: "/dashboards/sales",
        name: "Vendas",
        mini: "V",
        icon: DashboardIcon,
        component: DashboardModule,
        profiles: ["manager", "syndic"],
      },
      {
        path: "/dashboards/picking-lists-synthetical",
        name: "Piclists Sintético",
        mini: "S",
        icon: AttachMoneyIcon,
        component: DashboardPickingListsSyntheticalModule,
        profiles: ["manager", "conferente"],
      },
      {
        path: "/dashboards/picking-lists-analytical/:driver_cod/detail/:date",
        name: "Piclists Analítico",
        mini: "A",
        icon: AttachMoneyIcon,
        component: DashboardPickingListsAnalyticalDetailModule,
        profiles: ["manager", "conferente"],
        hidden: true,
      },
      {
        path: "/dashboards/picking-lists-analytical",
        name: "Piclists Analítico",
        mini: "A",
        icon: AttachMoneyIcon,
        component: DashboardPickingListsAnalyticalModule,
        profiles: ["manager", "conferente"],
      },
    ],
  },
  {
    path: "/maps",
    name: "Mapa",
    icon: MapIcon,
    component: MapModule,
    profiles: ["manager"],
  },
  {
    collapse: true,
    state: "orderCollapse",
    name: "Vendas - ZS59",
    icon: AttachMoneyIcon,
    profiles: ["manager", "syndic", "marketing"],
    views: [
      {
        path: "/orders",
        name: "Todas",
        mini: "T",
        icon: AttachMoneyIcon,
        component: OrdersModule,
        profiles: ["manager", "syndic", "marketing"],
      },
      {
        path: "/nf_orders",
        name: "Não faturadas",
        mini: "NF",
        icon: AttachMoneyIcon,
        component: OrdersModule,
        profiles: ["manager"],
      },
      {
        path: "/chargebacks",
        name: "Estornos",
        mini: "E",
        icon: AttachMoneyIcon,
        component: ChargebacksModule,
        profiles: ["manager", "marketing"],
      },
      {
        path: "/recovered_sales",
        name: "Recuperadas",
        mini: "R",
        icon: AttachMoneyIcon,
        component: OrdersModule,
        profiles: ["manager"],
      },
    ],
  },
  {
    collapse: true,
    state: "orderCondoCollapse",
    name: "Vendas parceiros - ZS59",
    icon: AttachMoneyIcon,
    profiles: ["manager"],
    views: [
      {
        path: "/audit-losses",
        name: "Todas",
        mini: "T",
        icon: AttachMoneyIcon,
        component: OrdersCondosModule,
        profiles: ["manager"],
      },
      {
        path: "/losses-nf",
        name: "Não faturadas",
        mini: "NF",
        icon: AttachMoneyIcon,
        component: OrdersCondosModule,
        profiles: ["manager"],
      },
      {
        path: "/losses-review",
        name: "Críticas",
        mini: "C",
        icon: AttachMoneyIcon,
        component: OrdersCondosModule,
        profiles: ["manager"],
      },
    ],
  },
  {
    collapse: true,
    state: "productReturnsCollapse",
    name: "Retorno de produtos - ZR61",
    icon: ThumbDownIcon,
    profiles: ["manager"],
    views: [
      {
        path: "/all-product-returns",
        name: "Todas",
        mini: "T",
        icon: UndoIcon,
        component: ProductReturnsModule,
        profiles: ["manager"],
      },
      {
        path: "/product-returns-without-sap",
        name: "Não faturadas",
        mini: "NF",
        icon: UndoIcon,
        component: ProductReturnsModule,
        profiles: ["manager"],
      },
    ],
  },
  {
    collapse: true,
    state: "conferencesCollapse",
    name: "Conferências",
    icon: UndoIcon,
    profiles: ["manager", "conferente"],
    views: [
      {
        path: "/all-conferences",
        name: "Todas",
        mini: "T",
        icon: ThumbDownIcon,
        component: ProductReturnsModule,
        profiles: ["manager", "conferente"],
      },
      // {
      //   path: "/conferences-by-driver",
      //   name: "Por motoristas",
      //   mini: "PM",
      //   icon: ThumbDownIcon,
      //   component: ProductReturnsByDriverModule,
      //   profiles: ["manager", "conferente"],
      // },
      {
        path: "/conference-panel-contract-cancellations",
        name: "Cancelamento de contrato",
        mini: "CC",
        icon: ThumbDownIcon,
        component: ConferencePanelContractCancellationsModule,
        profiles: ["manager", "conferente", "store"],
      },
      {
        path: "/conference-panel-no-transport",
        name: "Sem transporte",
        mini: "ST",
        icon: ThumbDownIcon,
        component: ConferencePanelNoTransportModule,
        profiles: ["manager", "conferente", "store"],
      },
      {
        path: "/conference-panel-return",
        name: "Painel (Retornos)",
        mini: "P",
        icon: ThumbDownIcon,
        component: ConferencePanelReturnModule,
        profiles: ["manager", "conferente", "store"],
      },
      {
        path: "/conference-panel-cancel",
        name: "Painel (Cancelamentos)",
        mini: "P",
        icon: ThumbDownIcon,
        component: ConferencePanelCancelModule,
        profiles: ["manager", "conferente"],
      },
      {
        path: "/retry-sap-conference-panel-cancel",
        name: "Painel (Erros Sap)",
        mini: "P",
        icon: ThumbDownIcon,
        component: ConferencePanelCancelSapModule,
        profiles: ["manager", "conferente"],
      },
    ],
  },
  {
    path: "/refills",
    name: "Abastecimentos",
    icon: CarIcon,
    component: RefillModule,
    profiles: ["manager"],
  },
  // {
  //   path: "/product-returns",
  //   name: "Retorno de produtos",
  //   icon: ThumbDownIcon,
  //   component: ProductReturnsModule,
  //   profiles: ["manager", "syndic", "conferente"],
  // },
  // {
  //   path: "/deliveries",
  //   name: "Entregas",
  //   icon: DeliveryIcon,
  //   component: DeliveriesModule,
  //   profiles: ["manager", "scripting", "syndic"],
  // },
  {
    path: "/routes",
    name: "Rotas",
    icon: RoutesIcon,
    component: RoutesModule,
    profiles: ["manager"],
  },
  {
    path: "/extraordinary-routes",
    name: "Rotas agendadas",
    icon: ExtraordinaryRoutesIcon,
    component: ExtraordinaryRoutesModule,
    profiles: ["manager"],
  },
  // {
  //   path: "/cars",
  //   name: "Carros",
  //   icon: CarIcon,
  //   component: CarsModule,
  //   profiles: ["manager"],
  // },
  {
    path: "/products",
    name: "Produtos",
    icon: CategoryIcon,
    component: ProductsModule,
    profiles: ["manager"],
  },
  {
    path: "/planograms",
    name: "Planogramas",
    icon: TransfersIcon,
    component: PlanogramsModule,
    profiles: ["manager"],
  },
  {
    path: "/fridges/:fridge_id/piclists",
    name: "Machines",
    icon: PinDropIcon,
    component: FridgePiclistsModule,
    hidden: true,
  },
  {
    path: "/fridges/:fridge_id/qrcode",
    name: "Machines",
    icon: PinDropIcon,
    component: FridgeQrcodeModule,
    hidden: true,
  },
  {
    path: "/fridges/:fridge_id/inventory",
    name: "Machines",
    icon: PinDropIcon,
    component: InventoryModule,
    hidden: true,
  },
  {
    path: "/all-piclists/routines",
    name: "Piclists",
    icon: DeliveryIcon,
    component: PiclistRoutinesModule,
    profiles: ["manager"],
    hidden: true,
  },
  {
    path: "/equipments/statuses",
    name: "Status de equipamentos",
    icon: DeliveryIcon,
    component: EquipementStatusesModule,
    profiles: ["manager"],
    hidden: true,
  },
  {
    path: "/equipments/return-reasons",
    name: "Motivos de retorno de equipamentos",
    icon: DeliveryIcon,
    component: EquipementReturnReasonsModule,
    profiles: ["manager"],
    hidden: true,
  },
  {
    path: "/equipments/confirm",
    name: "Confirmação de recebimento de equipamentos",
    icon: DeliveryIcon,
    component: EquipementConfirmModule,
    profiles: ["manager", "maintenance_manager"],
    hidden: true,
  },
  {
    path: "/equipments/:equipment_id/transactions",
    name: "Transaçoes",
    icon: DeliveryIcon,
    component: EquipmentTransactionsModule,
    profiles: ["manager", "maintenance_manager"],
    hidden: true,
  },

  {
    collapse: true,
    name: "Machines",
    icon: PinDropIcon,
    state: "fridgeCollapse",
    profiles: ["manager", "maintenance_manager", "marketing"],
    views: [
      {
        path: "/fridges",
        name: "Geladeiras",
        mini: "G",
        icon: DeliveryIcon,
        component: FridgesModule,
        profiles: ["manager", "marketing", "maintenance_manager"],
      },
      {
        path: "/contract-cancellations",
        name: "Cancelamento de contratos",
        mini: "CC",
        icon: DeliveryIcon,
        component: ContractCancellationModule,
        profiles: ["manager"],
      },
      {
        path: "/all-piclists",
        name: "Piclists - ZS58",
        mini: "P",
        icon: DeliveryIcon,
        component: PiclistsModule,
        profiles: ["manager"],
      },
      {
        path: "/equipments",
        name: "Equipamentos",
        mini: "E",
        icon: DeliveryIcon,
        component: EquipementsModule,
        profiles: ["manager", "maintenance_manager"],
      },
    ],
  },

  {
    path: "/audits",
    name: "Auditorias",
    icon: ViewIcon,
    component: AuditsModule,
    profiles: ["manager", "syndic"],
  },
  {
    path: "/scheduled-audits",
    name: "Auditorias Agendadas",
    icon: ViewIcon,
    component: ScheduledAuditModule,
    profiles: ["manager"],
  },
  {
    path: "/earnings",
    name: "Apurações",
    icon: TrendingUpIcon,
    component: EarningsModule,
    profiles: ["manager", "syndic"],
  },
  {
    path: "/maintenances",
    name: "Acessos",
    icon: OpenIcon,
    component: MaintenancesModule,
    profiles: ["manager", "syndic"],
  },
  {
    path: "/fridge-release-door-openings",
    name: "Liberação de abertura",
    icon: OpenIcon,
    component: FridgeReleaseDoorOpeningsModule,
    profiles: ["manager"],
  },
  {
    path: "/users",
    name: "Usuários",
    icon: GroupIcon,
    component: UsersModule,
    profiles: ["manager", "maintenance_manager"],
  },
  {
    path: "/mobile-users",
    name: "Usuários Mobile",
    icon: RemoveCircleIcon,
    component: UsersMobileModule,
    profiles: ["manager"],
  },
  {
    path: "/staffs",
    name: "Funcionários",
    icon: GroupIcon,
    component: StaffsModule,
    profiles: ["syndic"],
  },
  {
    path: "/me",
    name: "Meu perfil",
    icon: DirectionsCarIcon,
    component: MeModule,
    hidden: true,
  },
  {
    path: "/password",
    name: "Mudar senha",
    icon: DirectionsCarIcon,
    component: PasswordModule,
    hidden: true,
  },
  // {
  //   path: "/config-vars",
  //   name: "Configurações",
  //   icon: SettingsIcon,
  //   component: ConfigVarsModule,
  //   profiles: ["manager"],
  // },
  {
    collapse: true,
    name: "Configurações",
    icon: SettingsIcon,
    state: "configCollapse",
    profiles: ["manager", "syndic"],
    views: [
      {
        path: "/config-vars",
        name: "Variáveis (Heroku)",
        mini: "V",
        icon: DeliveryIcon,
        component: ConfigVarsModule,
        profiles: ["manager"],
      },
      {
        path: "/manage-fridge-notifications",
        name: "Notificações",
        mini: "N",
        icon: DeliveryIcon,
        component: ManageFridgeNotificationModule,
        profiles: ["manager", "syndic"],
      },
    ],
  },
  {
    collapse: true,
    name: "Config Machines",
    icon: ListAltIcon,
    state: "fridgeFieldsCollapse",
    profiles: ["manager"],
    views: [
      {
        path: "/fridge-banks",
        name: "Bancos",
        mini: "B",
        icon: DeliveryIcon,
        component: FridgeBanksModule,
        profiles: ["manager"],
      },
      {
        path: "/fridge-channels",
        name: "Canais",
        mini: "C",
        icon: DeliveryIcon,
        component: FridgeChannelsModule,
        profiles: ["manager"],
      },
      {
        path: "/fridge-installation-sites",
        name: "Locais de instalação",
        mini: "LI",
        icon: DeliveryIcon,
        component: FridgeInstallationSitesModule,
        profiles: ["manager"],
      },
      {
        path: "/fridge-brands",
        name: "Marcas",
        mini: "M",
        icon: DeliveryIcon,
        component: FridgeBrandsModule,
        profiles: ["manager"],
      },
      {
        path: "/fridge-autonomous-markets",
        name: "Mercados autônomos",
        mini: "MA",
        icon: DeliveryIcon,
        component: FridgeAutonomousMarketsModule,
        profiles: ["manager"],
      },
      {
        path: "/fridge-segmentations",
        name: "Segmentação",
        mini: "S",
        icon: DeliveryIcon,
        component: FridgeSegmentationsModule,
        profiles: ["manager"],
      },
      {
        path: "/fridge-subchannels",
        name: "SubCanais",
        mini: "SC",
        icon: DeliveryIcon,
        component: FridgeSubchannelsModule,
        profiles: ["manager"],
      },
      {
        path: "/fridge-types",
        name: "Tipos",
        mini: "T",
        icon: DeliveryIcon,
        component: FridgeTypesModule,
        profiles: ["manager"],
      },
      {
        path: "/piclist-cancellation-reasons",
        name: "Motivos de cancelamento",
        mini: "MC",
        icon: DeliveryIcon,
        component: PiclistCancellationReasonsModule,
        profiles: ["manager"],
      },
      {
        path: "/scheduled-audit-unproductivity-reasons",
        name: "Motivo de improdutividade",
        mini: "MI",
        icon: DeliveryIcon,
        component: ScheduledAuditUnproductivityReasonModule,
        profiles: ["manager"],
      },
      {
        path: "/fridge-opening-reasons",
        name: "Motivos de liberaçao",
        mini: "ML",
        icon: DeliveryIcon,
        component: FridgeOpeningReasonsModule,
        profiles: ["manager"],
      },
      {
        path: "/fridge-techs",
        name: "Tecnologias",
        mini: "Tk",
        icon: DeliveryIcon,
        component: FridgeTechsModule,
        profiles: ["manager"],
      },
      {
        path: "/chargeback-status",
        name: "Status de estorno",
        mini: "SE",
        icon: DeliveryIcon,
        component: ChargebackStatusModule,
        profiles: ["manager"],
      },
      {
        path: "/sap-table",
        name: "Tabelas do SAP",
        mini: "TS",
        icon: DeliveryIcon,
        component: SapTableModule,
        profiles: ["manager"],
      },
    ],
  },

  { redirect: true, path: "/", pathTo: "/dashboards/sales", name: "Dashboard" },
];
export default dashRoutes;
