import axios from "axios";
import consts from "../../consts";

export function ShowForm(item) {
  return { type: "USERS_SHOW_FORM", payload: item };
}

export function HideForm() {
  return { type: "USERS_HIDE_FORM" };
}

// export function get(){
//   return dispatch => {
//       axios.get(`${consts.API_URL}/users`)
//       .then(resp => {
//         console.log(resp)
//           dispatch([
//             { type: 'USERS_GET', payload: resp.data }
//           ])
//       })
//     }
// }

export function save(user) {
  return dispatch => {
    if (user.id) {
      axios
        .put(`${consts.API_URL}/users/${user.id}`, user)
        .then(resp => {
          var data = JSON.parse(localStorage.getItem(consts.UserKey));
          // console.log(data)

          if (data.user.id === resp.data.id) {
            var newData = { user: resp.data, token: data.token };
            localStorage.setItem(consts.UserKey, JSON.stringify(newData));
          }
          // console.log(resp.data)
          const configNotification = {
            message: "Usuário atualizado com sucesso",
            color: "success"
          };
          dispatch([
            {
              type: "MAIN_SHOW_NOTIFICATION",
              showNotification: true,
              payload: configNotification
            },
            { type: "USERS_HIDE_FORM" }
          ]);
        })
        .catch(e => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors > 0
          ) {
            e.response.data.errors.forEach(error => {
              // toastr.error('Erro', error))
              const configNotification = {
                message: error,
                color: "danger"
              };
              dispatch([
                {
                  type: "MAIN_SHOW_NOTIFICATION",
                  showNotification: true,
                  payload: configNotification
                }
              ]);
            });
          } else if (e.response && e.response.data && e.response.data.message) {
            // console.log('e.response.data.message: ', e.response.data.message)
            // toastr.error('Erro', e.response.data.message)

            const configNotification = {
              message: e.response.data.message,
              color: "danger"
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification
              }
            ]);
          } else {
            // toastr.error('Erro', 'Erro inesperado.')
            const configNotification = {
              message: "Erro inesperado.",
              color: "danger"
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification
              }
            ]);
          }
        });
    } else {
      axios
        .post(`${consts.API_URL}/users`, user)
        .then(resp => {
          // console.log(resp)
          const configNotification = {
            message: "Usuário cadastrado com sucesso",
            color: "success"
          };
          dispatch([
            {
              type: "MAIN_SHOW_NOTIFICATION",
              showNotification: true,
              payload: configNotification
            },
            { type: "USERS_HIDE_FORM" }
          ]);
        })
        .catch(e => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors > 0
          ) {
            e.response.data.errors.forEach(error => {
              // toastr.error('Erro', error))
              const configNotification = {
                message: error,
                color: "danger"
              };
              dispatch([
                {
                  type: "MAIN_SHOW_NOTIFICATION",
                  showNotification: true,
                  payload: configNotification
                }
              ]);
            });
          } else if (e.response && e.response.data && e.response.data.message) {
            // console.log('e.response.data.message: ', e.response.data.message)
            // toastr.error('Erro', e.response.data.message)

            const configNotification = {
              message: e.response.data.message,
              color: "danger"
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification
              }
            ]);
          } else {
            // toastr.error('Erro', 'Erro inesperado.')
            const configNotification = {
              message: "Erro inesperado.",
              color: "danger"
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification
              }
            ]);
          }
        });
    }
  };
}
