import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// import { Link } from "react-router-dom";
import axios from "axios";
import consts from "../../consts";

import If from "../../common/operators/If";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import Loader from "components/Loader/Loader.jsx";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ShowForm,
  save,
  showNotification,
  ShowFormReport,
  // get
} from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/Visibility";
import NotaFistalIcon from "@material-ui/icons/InsertDriveFile";
import ShopStatusIcon from "@material-ui/icons/CreditCard";
import DoorIcon from "@material-ui/icons/LockOpen";

// import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import { dataTable } from "variables/general.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

export default function Menu({ classes, currentMenu, setMenu }) {
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardFooter color="warning" icon>
            <Button
              color={currentMenu === 1 ? "primary" : ""}
              className={classes.updateProfileButton}
              onClick={() => setMenu(1)}
            >
              Retorno completo da NF
            </Button>
            <Button
              color={currentMenu === 2 ? "primary" : ""}
              className={classes.updateProfileButton}
              onClick={() => setMenu(2)}
            >
              Retorno pontual
            </Button>
            <Button
              color={currentMenu === 3 ? "primary" : ""}
              className={classes.updateProfileButton}
              onClick={() => setMenu(3)}
            >
              Picklists canceladas
            </Button>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
