import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// import { Link } from "react-router-dom";
import axios from "axios";
import consts from "../../consts";
import moment from "moment";
import "moment/locale/pt-br";

import If from "../../common/operators/If";

import SweetAlert from "react-bootstrap-sweetalert";

import Loader from "components/Loader/Loader.jsx";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ShowForm,
  save,
  showNotification,
  ShowFormReport,
  // get
} from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/Visibility";
import NotaFistalIcon from "@material-ui/icons/InsertDriveFile";
import ShopStatusIcon from "@material-ui/icons/CreditCard";
import DoorIcon from "@material-ui/icons/LockOpen";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomInputDate from "components/CustomInput/CustomInputDate.jsx";
import Switch from "@material-ui/core/Switch";

// import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import AssignmentIcon from "@material-ui/icons/Assignment";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import { dataTable } from "variables/general.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import { ContactsOutlined } from "@material-ui/icons";

const styles = Object.assign(notificationsStyle);

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    this.me = JSON.parse(localStorage.getItem(consts.UserKey)).user;
    this.state = {
      data: [],
      reportData: [],
      pages: -1,
      loading: true,
      creating: false,
      alert: null,
      show: false,
      createModal: false,
      drivers: [],
      driver: null,
      showFilters: false,
      filters: [],
      filterName: "",
      filterDateFrom: "",
      filterDateTo: "",
      filterStarted: "",
      filterFinished: "",
      filterPending: "",
      productReturnGroupedDate: "",
    };
    // this.getReportData();
  }

  create() {
    const that = this;

    this.setState({ creating: true });
    this.handleCreateModalClose();

    const date = moment(this.state.productReturnGroupedDate, "DD/MM/YYYY").format("YYYY-MM-DD");

    axios
      .post(`${consts.API_URL}/product-return-grouped`, { driver_id: that.state.driver, date })
      .then((resp) => {
        // console.log("resp: ", resp);
        const configNotification = {
          message: "Agrupamento criado com sucesso",
          color: "success",
        };
        this.props.showNotification(configNotification);
        that.setState({ loading: false, creating: false });
      })
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false, creating: false });
        // console.log(err);
      });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  setData(result) {
    var data = result.map((item) => {
      console.log(item);
      const name = item.driver.name;
      const date = moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss");
      const started = item.started === true ? "SIM" : "NÃO";
      const finished = item.finished === true ? "SIM" : "NÃO";
      const pending = item.pending === true ? "SIM" : "NÃO";

      return {
        name,
        date,
        started,
        finished,
        pending,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <Button
              justIcon
              round
              simple
              onClick={() => this.props.ShowForm(item)}
              color="primary"
              className="edit"
            >
              <ViewIcon />
            </Button>{" "}
          </div>
        ),
      };
    });

    this.setState({ data });
  }

  handleCreateModalOpen() {
    const that = this;

    that.setState({
      loading: true,
    });
    const sorted = "name";
    const order = "asc";
    const filtered = btoa(
      JSON.stringify([
        { id: "profile", value: "deliveryman" },
        // { id: "active", value: "0" },
        { id: "has_product_returns", value: "1" },
      ])
    );

    // fetch your data
    axios
      .get(
        `${
          consts.API_URL
        }/users?page=${1}&qtd_per_page=${200}&sorted=${sorted}&order=${order}&filtered=${filtered}`
      )
      .then((res) => {
        // console.log(res)
        // console.log(state.sorted)
        // console.log(state.filtered)
        // Update react-table
        this.setState({
          drivers: res.data.result,
          loading: false,
          createModal: true,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }

  handleCreateModalClose() {
    this.setState({
      createModal: false,
    });
  }

  change(event, stateName, type, stateNameEqualTo, maxValue) {
    // console.log(event)
    // console.log(this.state)
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "checkbox":
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-length":
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "min-value":
        if (this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-value":
        if (this.verifyNumber(event.target.value) && event.target.value <= stateNameEqualTo) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "range":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }

  renderCreateModal() {
    const that = this;
    const { classes } = this.props;
    const profiles = [{ value: "value", label: "label" }];
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={this.state.createModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleCreateModalClose()}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => this.handleCreateModalClose()}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Selecione o motorista</h4>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                  Motorista
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={this.state.driver}
                  onChange={(event) => that.setState({ driver: event.target.value })}
                  inputProps={{
                    name: "profile",
                    id: "simple-select",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Motorista
                  </MenuItem>
                  {this.state.drivers.map((driver) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={driver.id}
                    >
                      {driver.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12}>
              <CustomInputDate
                success={this.state.productReturnGroupedDateState === "success"}
                error={this.state.productReturnGroupedDateState === "error"}
                id="productReturnGroupedDate"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => this.change(event, "productReturnGroupedDate"),
                  value: this.state.productReturnGroupedDate,
                  type: "text",
                  placeholder: "Data",
                }}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => this.handleCreateModalClose()} color="transparent">
            Cancelar
          </Button>
          <Button onClick={() => this.create()} color="success" simple>
            Criar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  clearFilters() {
    this.setState({
      filters: [],
    });
  }

  setFilters() {
    const {
      filterName,
      filterDateFrom,
      filterDateTo,
      filterStarted,
      filterFinished,
      filterPending,
    } = this.state;

    const filters = [
      { id: "name", value: filterName },
      {
        id: "dateFrom",
        value: moment(filterDateFrom, "DD/MM/YYYY").isValid()
          ? moment(filterDateFrom, "DD/MM/YYYY").format()
          : "",
      },
      {
        id: "dateTo",
        value: moment(filterDateTo, "DD/MM/YYYY").isValid()
          ? moment(`${filterDateTo} 23:59:59`, "DD/MM/YYYY HH:ii:ss").format()
          : "",
      },
      { id: "started", value: filterStarted },
      { id: "finished", value: filterFinished },
      { id: "pending", value: filterPending },
    ];

    this.setState(
      {
        filters,
        creating: true,
      },
      () =>
        setTimeout(
          () =>
            this.setState({
              creating: false,
            }),
          50
        )
    );
  }

  renderFilters() {
    if (!this.state.showFilters) {
      return;
    }
    const that = this;
    const { classes } = this.props;
    return (
      <Card>
        <CardBody>
          <form>
            <GridContainer>
              <GridItem xs={12} md={4}>
                <CustomInput
                  success={this.state.filterNameState === "success"}
                  error={this.state.filterNameState === "error"}
                  labelText={<span>Nome do motorista</span>}
                  id="filterName"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => this.change(event, "filterName"),
                    value: this.state.filterName,
                    type: "text",
                    placeholder: "Nome do motorista",
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={4}>
                <CustomInputDate
                  success={this.state.filterDateFromState === "success"}
                  error={this.state.filterDateFromState === "error"}
                  id="filterDateFrom"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => this.change(event, "filterDateFrom"),
                    value: this.state.filterDateFrom,
                    type: "text",
                    placeholder: "De",
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={4}>
                <CustomInputDate
                  success={this.state.filterDateToState === "success"}
                  error={this.state.filterDateToState === "error"}
                  id="filterDateTo"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => this.change(event, "filterDateTo"),
                    value: this.state.filterDateTo,
                    type: "text",
                    placeholder: "Até",
                  }}
                />
              </GridItem>

              <GridItem xs={12} md={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                    Iniciados
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.filterStarted}
                    onChange={(event) => that.setState({ filterStarted: event.target.value })}
                    inputProps={{
                      name: "filterStarted",
                      id: "simple-select",
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value=""
                    >
                      Não filtrar
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value="1"
                    >
                      Iniciados
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value="0"
                    >
                      Não iniciados
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>

              <GridItem xs={12} md={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                    Finalizados
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.filterFinished}
                    onChange={(event) => that.setState({ filterFinished: event.target.value })}
                    inputProps={{
                      name: "filterFinished",
                      id: "simple-select",
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value=""
                    >
                      Não filtrar
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value="1"
                    >
                      Finalizados
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value="0"
                    >
                      Não finalizados
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>

              <GridItem xs={12} md={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                    Pendentes
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.filterPending}
                    onChange={(event) => that.setState({ filterPending: event.target.value })}
                    inputProps={{
                      name: "filterPending",
                      id: "simple-select",
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value=""
                    >
                      Não filtrar
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value="1"
                    >
                      Com diferenças na conferência
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value="0"
                    >
                      Sem diferenças na conferência
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
          </form>
        </CardBody>
        <CardFooter>
          <Button onClick={() => this.clearFilters()}>Limpar filtros</Button>
          <Button color="primary" onClick={() => this.setFilters()}>
            Aplicar filtros
          </Button>
        </CardFooter>
      </Card>
    );
  }

  render() {
    // console.log(this.me);
    const { classes } = this.props;
    let timeout = null;
    return (
      <div>
        {this.state.loading ? <Loader /> : null}
        {this.state.alert}
        <GridContainer>
          {this.renderCreateModal()}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <MaterialIconList />
                </CardIcon>
                <Button
                  color="primary"
                  className={classes.updateProfileButton}
                  onClick={() => this.handleCreateModalOpen()}
                >
                  Criar agrupamento
                </Button>
                <Button
                  className={classes.updateProfileButton}
                  onClick={() => this.setState({ showFilters: !this.state.showFilters })}
                >
                  {this.state.showFilters ? "Esconder filtros" : "Mostrar filtros"}
                </Button>
                {/* {this.renderDownloadXLS()} */}
                {/* <h4 className={classes.cardIconTitle}>Lista de membros</h4> */}
              </CardHeader>
              {this.renderFilters()}

              <CardBody>
                {!this.state.creating && (
                  <ReactTable
                    data={this.state.data}
                    pages={this.state.pages}
                    loading={this.state.loading}
                    filterable={true}
                    columns={[
                      {
                        Header: "Nome",
                        accessor: "name",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Data",
                        accessor: "date",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Iniciado",
                        accessor: "started",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Finalizado",
                        accessor: "finished",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Diferenças",
                        accessor: "pending",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Ações",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                    defaultPageSize={20}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                    previousText="Anterior"
                    nextText="Próxima"
                    loadingText="Carregando"
                    noDataText="Nenhum registro encontrado"
                    pageText="Página"
                    ofText="de"
                    rowsText="registros"
                    manual
                    onFetchData={(state, instance) => {
                      // show the loading overlay
                      clearTimeout(timeout);
                      timeout = setTimeout(() => {
                        this.setState({ loading: true }, () => {
                          // console.log(state.filtered);
                          const sorted = state.sorted.length === 0 ? "" : state.sorted[0].id;
                          const order =
                            state.sorted.length > 0 && state.sorted[0].desc ? "desc" : "asc";

                          // let toFilter = [...state.filtered];
                          // const filtraStatus = { id: "status", value: "1" };
                          // console.log(filtraStatus);
                          // toFilter.push(filtraStatus);
                          // console.log(toFilter);
                          // const filtered = btoa(
                          //   JSON.stringify([
                          //     ...state.filtered,
                          //     { id: "profile", value: "maintenance" },
                          //     // { id: "active", value: "0" },
                          //     { id: "has_product_returns", value: "1" },
                          //   ])
                          // );
                          const filtered = btoa(JSON.stringify([...this.state.filters]));

                          // fetch your data
                          axios
                            .get(
                              `${consts.API_URL}/product-return-grouped?page=${state.page +
                                1}&qtd_per_page=${
                                state.pageSize
                              }&sorted=${sorted}&order=${order}&filtered=${filtered}`,
                              {
                                page: state.page,
                                pageSize: state.pageSize,
                                sorted: state.sorted,
                                filtered: state.filtered,
                              }
                            )
                            .then((res) => {
                              // console.log(res)
                              // console.log(state.sorted)
                              // console.log(state.filtered)
                              // Update react-table
                              this.setState(
                                {
                                  pages: res.data.pages,
                                  loading: false,
                                },
                                () => {
                                  this.setData(res.data.result);
                                }
                              );
                            })
                            .catch((err) => {
                              this.setState({
                                loading: false,
                              });
                            });
                        });
                      }, 500);
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log(state)
//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = (state) => ({
  ShowForm: state.ShowForm,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      save,
      showNotification,
      ShowFormReport,
      // get
    },
    dispatch
  );
const MemberList = withStyles(styles)(ReactTables);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberList);
