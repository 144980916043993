import React from "react";
import List from "./List";
import Form from "./Form";
import If from "../../common/operators/If";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ShowForm, HideForm } from "./Actions";

class Main extends React.Component {
  constructor(props) {
    super(props);
    props.HideForm();
    // console.log(props)
  }

  render() {
    return (
      <div>
        <If test={!this.props.showForm}>
          <List />
        </If>
        <If test={this.props.showForm}>
          <Form />
        </If>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state)
  return {
    showForm: state.cars.showForm,
    showBookingForm: state.cars.showBookingForm,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      HideForm,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
