import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import axios from "axios";
import consts from "../../consts";

import If from "../../common/operators/If";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ShowForm,
  // ShowPicList,
  showNotification,
  save,
  // get
} from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import qrCodeIcom from "assets/img/ico/qrcode.png";
import MaterialIconList from "@material-ui/icons/List";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import CategoryIcon from "@material-ui/icons/Category";
import LocalPrintshopIcon from "@material-ui/icons/LocalPrintshop";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import CloudQueueIcon from "@material-ui/icons/CloudQueue";
import BuildIcon from "@material-ui/icons/Build";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import NoMeetingRoomIcon from "@material-ui/icons/NoMeetingRoom";
import WarningIcon from "@material-ui/icons/Warning";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import ToggleOn from "@material-ui/icons/ToggleOn";
import ToggleOff from "@material-ui/icons/ToggleOff";
// import MailIcon from "@material-ui/icons/ToggleOn";
// import UnsubscribeIcon from "@material-ui/icons/Unsubscribe";
// import UnsubscribeIcon from "@material-ui/icons/Check";

// import Assignment from "@material-ui/icons/Assignment";
// import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import { RiFileEditLine } from "react-icons/ri";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import FormControl from "@material-ui/core/FormControl";

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    this.me = JSON.parse(localStorage.getItem(consts.UserKey)).user;
    this.state = {
      data: [],
      originalData: [],
      openingReasons: [],
      openingReason_id: "",
      pages: -1,
      loading: true,
      alert: null,
      show: false,
      showTable: true,
    };
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  changeOptReportRefill(item) {
    // console.log("changeOptReportRefill", item);

    if (item.opt_report_refill === false) {
      this.setOptReportRefill(item);
      return;
    }

    const that = this;

    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title=" "
          onConfirm={() => {
            this.setOptReportRefill(item);
            that.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
          cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
          confirmBtnText="Sim!"
          cancelBtnText="Cancelar"
          showCancel
        >
          Tem certeza que deseja deixar de receber as notificações de abastecimento?
        </SweetAlert>
      ),
    });
  }

  setOptReportRefill(item) {
    // console.log("setOptReportRefill", item);

    this.setState({ loading: true });

    const url =
      item.opt_report_refill === true
        ? `${consts.API_URL}/fridges/${item.id}/opt-report-refill-off`
        : `${consts.API_URL}/fridges/${item.id}/opt-report-refill-on`;
    // console.log({ url });
    // return;
    axios
      .put(url, {})
      .then((resp) => {
        // console.log(resp);
        const data = this.state.originalData.map((data) => {
          let fridge = data;
          if (data.id == item.id) {
            fridge.opt_report_refill = !data.opt_report_refill;
          }
          return fridge;
        });
        // console.log(data);
        this.setState(data);
        this.setData(data);
        this.setState({ loading: false });

        // this.get();
      })
      .catch((e) => {
        console.log(e);
        console.log(e.response);
        const message =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "Falha ao alterar status da geladeira.";
        const configNotification = {
          message,
          color: "danger",
        };
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
      });
  }

  changeOptReportAudit(item) {
    // console.log("changeOptReportAudit", item);

    if (item.opt_report_audit === false) {
      this.setOptReportAudit(item);
      return;
    }

    const that = this;

    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title=" "
          onConfirm={() => {
            this.setOptReportAudit(item);
            that.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
          cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
          confirmBtnText="Sim!"
          cancelBtnText="Cancelar"
          showCancel
        >
          Tem certeza que deseja deixar de receber as notificações de inventário?
        </SweetAlert>
      ),
    });
  }

  setOptReportAudit(item) {
    // console.log("setOptReportAudit", item);

    this.setState({ loading: true });

    const url =
      item.opt_report_audit === true
        ? `${consts.API_URL}/fridges/${item.id}/opt-report-audit-off`
        : `${consts.API_URL}/fridges/${item.id}/opt-report-audit-on`;
    // console.log({ url });
    // return;
    axios
      .put(url, {})
      .then((resp) => {
        // console.log(resp);
        const data = this.state.originalData.map((data) => {
          let fridge = data;
          if (data.id == item.id) {
            fridge.opt_report_audit = !data.opt_report_audit;
          }
          return fridge;
        });
        // console.log(data);
        this.setState(data);
        this.setData(data);
        this.setState({ loading: false });

        // this.get();
      })
      .catch((e) => {
        console.log(e);
        console.log(e.response);
        const message =
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "Falha ao alterar status da geladeira.";
        const configNotification = {
          message,
          color: "danger",
        };
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
      });
  }

  setData(result) {
    var data = result.map((item) => {
      const refill = (
        // we've added some custom button actions
        <div className="actions-center">
          <Button
            justIcon
            simple
            color={item.opt_report_refill === true ? "success" : "primary"}
            title="Liberar abertura de porta ao motorista"
            onClick={() => this.changeOptReportRefill(item)}
          >
            {item.opt_report_refill === true ? <ToggleOn /> : <ToggleOff />}
          </Button>
        </div>
      );

      const audit = (
        // we've added some custom button actions
        <div className="actions-center">
          <Button
            justIcon
            simple
            color={item.opt_report_audit === true ? "success" : "primary"}
            title="Liberar abertura de porta ao motorista"
            onClick={() => this.changeOptReportAudit(item)}
          >
            {item.opt_report_audit === true ? <ToggleOn size={80} /> : <ToggleOff />}
          </Button>
        </div>
      );

      return {
        id: item.id,
        gebra: item.gebra,
        local: item.local,
        refill,
        audit,
      };
    });

    this.setState({ data });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.alert}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <MaterialIconList />
                </CardIcon>
              </CardHeader>
              <CardBody>
                {this.state.showTable && (
                  <ReactTable
                    data={this.state.data}
                    pages={this.state.pages}
                    loading={this.state.loading}
                    filterable={true}
                    columns={[
                      {
                        Header: "GEBRA",
                        accessor: "gebra",
                      },
                      {
                        Header: "Local",
                        accessor: "local",
                      },
                      {
                        Header: "Abastecimento",
                        accessor: "refill",
                        filterable: false,
                        sortable: false,
                        className: "actions-center",
                      },
                      {
                        Header: "Inventário",
                        accessor: "audit",
                        filterable: false,
                        sortable: false,
                        className: "actions-center",
                      },
                      {
                        Header: " ",
                        accessor: " ",
                        sortable: false,
                        filterable: false,
                        width: 1,
                        expander: false,
                      },
                    ]}
                    pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                    defaultPageSize={20}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                    previousText="Anterior"
                    nextText="Próxima"
                    loadingText="Carregando"
                    noDataText="Nenhum registro encontrado"
                    pageText="Página"
                    ofText="de"
                    rowsText="registros"
                    manual
                    onFetchData={(state, instance) => {
                      // show the loading overlay
                      this.setState({ loading: true }, () => {
                        const sorted = state.sorted.length === 0 ? "" : state.sorted[0].id;
                        const order =
                          state.sorted.length > 0 && state.sorted[0].desc ? "desc" : "asc";
                        const filtered = btoa(JSON.stringify(state.filtered));

                        // fetch your data
                        axios
                          .get(
                            `${consts.API_URL}/fridges?page=${state.page + 1}&qtd_per_page=${
                              state.pageSize
                            }&sorted=${sorted}&order=${order}&filtered=${filtered}`,
                            {
                              page: state.page,
                              pageSize: state.pageSize,
                              sorted: state.sorted,
                              filtered: state.filtered,
                            }
                          )
                          .then((res) => {
                            // console.log(res)
                            // console.log(state.sorted)
                            // console.log(state.filtered)
                            // Update react-table
                            this.setState(
                              {
                                pages: res.data.pages,
                                loading: false,
                              },
                              () => {
                                this.setState({ originalData: res.data.result });
                                this.setData(res.data.result);
                              }
                            );
                          });
                      });
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log(state)
//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = (state) => ({
  ShowForm: state.ShowForm,
  ShowPicList: state.ShowPicList,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      // ShowPicList,
      showNotification,
      save,
      // get
    },
    dispatch
  );
const MemberList = withStyles(sweetAlertStyle)(ReactTables);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberList);
