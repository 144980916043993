import React from "react";
import List from "./List";
// import PicList from './PicList'
import Form from "./Form";
import If from "../../common/operators/If";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import // ShowForm,
// HideForm,
// ShowPicList,
// HidePicList,
// "./Actions";

class Main extends React.Component {
  constructor(props) {
    super(props);
    // props.HideForm();
    // props.HidePicList()
    // console.log(props)
  }

  render() {
    return (
      <div>
        <Form fridgeId={this.props.match.params.fridge_id} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state)
  return {
    showForm: state.piclists.showForm,
    // showPicLists: state.piclists.showPicLists,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // ShowForm,
      // HideForm,
      // ShowPicList,
      // HidePicList,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
