import React from "react";

import List from "./List";

import Form from "./Form";

import If from "../../common/operators/If";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ShowForm, HideForm } from "./Actions";
import consts from "../../consts";

class Main extends React.Component {
  constructor(props) {
    super(props);
    props.HideForm();
    this.state = {
      menuPosition: 1,
    };
  }

  setMenu(menuPosition) {
    this.setState({ menuPosition });
  }

  render() {
    const me = JSON.parse(localStorage.getItem(consts.UserKey)).user;
    return (
      <div>
        <If test={!this.props.showForm}>
          <List
            parentProps={this.props}
            setMenu={this.setMenu.bind(this)}
            menuPosition={this.state.menuPosition}
          />
        </If>
        <If test={this.props.showForm}>
          <Form />
        </If>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state)
  return {
    showForm: state.product_returns.showForm,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      HideForm,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
