import React from "react";

// react component for creating dynamic tables
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import axios from "axios";
import socketIOClient from "socket.io-client";
import consts from "../../../consts";

// import Form from "./Form";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import Loader from "components/Loader/Loader.jsx";

import moment from "moment";
import "moment/locale/pt-br";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ShowForm,
  showNotification,
  // get
} from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import CategoryIcon from "@material-ui/icons/Category";
import DownloadIcon from "@material-ui/icons/GetApp";
import WarningIcon from "@material-ui/icons/Warning";
import NotaFistalIcon from "@material-ui/icons/InsertDriveFile";
import ViewIcon from "@material-ui/icons/Visibility";

import CheckIcon from "@material-ui/icons/Check";
import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInputDate from "components/CustomInput/CustomInputDate.jsx";

import { dataTable } from "variables/general.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ReactExport from "react-export-excel";

import FormReport from "./FormReport";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      data: [],
      originalData: [],
      contentData: null,
      contentData1: null,
      contentData2: null,
      date: moment().format("L"),
      date1: moment()
        .subtract(1, "days")
        .format("L"),
      date2: moment()
        .subtract(2, "days")
        .format("L"),
      deliveryDate: moment().format("L"),
      deliveryDate1: moment()
        .subtract(1, "days")
        .format("L"),
      deliveryDate2: moment()
        .subtract(2, "days")
        .format("L"),
      pages: -1,
      loading: true,
      alert: null,
      show: false,
      piclist_id: null,
      showFilters: false,
      showReport: false,
    };
  }

  componentDidMount() {
    this.get();
  }

  async get() {
    this.setState({
      loading: true,
    });
    const date = moment(this.state.date, "DD/MM/YYYY").format("YYYY-MM-DD");
    const date1 = moment(this.state.date1, "DD/MM/YYYY").format("YYYY-MM-DD");
    const date2 = moment(this.state.date2, "DD/MM/YYYY").format("YYYY-MM-DD");
    await axios.get(`${consts.API_URL}/dashboards/picking-lists/${date}`).then((res) => {
      return this.setState({
        contentData: res.data,
      });
    });
    await axios.get(`${consts.API_URL}/dashboards/picking-lists/${date1}`).then((res) => {
      return this.setState({
        contentData1: res.data,
      });
    });
    await axios.get(`${consts.API_URL}/dashboards/picking-lists/${date2}`).then((res) => {
      return this.setState({
        contentData2: res.data,
      });
    });
    this.setState({
      loading: false,
      deliveryDate: this.state.date,
      deliveryDate1: this.state.date1,
      deliveryDate2: this.state.date2,
    });
  }

  renderHeader() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="warning" icon>
          <CardIcon color="warning">
            <MaterialIconList />
          </CardIcon>
          <Button
            color="primary"
            className={classes.updateProfileButton}
            onClick={() => this.setShowReport(true)}
          >
            Relatório
          </Button>
          {/* {this.renderDownloadXLS()} */}
          {/* <h4 className={classes.cardIconTitle}>Lista de membros</h4> */}
        </CardHeader>
        <CardFooter>
          {/* <Button
            color="primary"
            onClick={() => this.setState({ showFilters: !this.state.showFilters })}
          >
            {this.state.showFilters ? "Fechar filtros" : "Mostrar filtros"}
          </Button> */}
          {/* <Link to={`/all-piclists/routines`}> */}
          <CustomInputDate
            success={this.state.dateState === "success"}
            error={this.state.dateState === "error"}
            id="date"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) =>
                this.setState({
                  date: event.target.value,
                  date1: moment(event.target.value, "DD/MM/YYYY")
                    .subtract(1, "days")
                    .format("DD/MM/YYYY"),
                  date2: moment(event.target.value, "DD/MM/YYYY")
                    .subtract(2, "days")
                    .format("DD/MM/YYYY"),
                }),
              value: this.state.date,
              type: "text",
              placeholder: "Data",
            }}
          />
          <Button color="primary" onClick={() => this.get()}>
            Filtrar
          </Button>
          {/* </Link> */}
        </CardFooter>
      </Card>
    );
  }

  renderCard(deliveryDate, contentData) {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>Entrega dia {deliveryDate}</h4>
        </CardHeader>
        <CardBody>
          <p>{contentData.geladeirasProgramadas} geladeiras programadas</p>
          <p>
            {contentData.geladeirasProgramadas - contentData.picklistGeradas} picklists não geradas
          </p>
          <p>{contentData.picklistGeradas} picklists geradas no iCoke</p>
          <p>{contentData.sapOrdersGeradas} ordens geradas no SAP</p>
          <p>{contentData.sapRecuperadasGeradas} ordens recuperadas no SAP</p>
          <p>{`${contentData.underLimit} picklist cancelada no iCoke < R$50,00`}</p>
          <p>
            <strong>Monitoramento em campo:</strong>
          </p>
          <p>{contentData.total_open} em aberto</p>
          <p>{contentData.total_closed} finalizadas</p>
          <p>{contentData.total_audits} auditorias enviadas</p>
          <p>{contentData.total_product_returns} retorno de produtos</p>
          <p>{contentData.total_cancelled} canceladas (manualmente)</p>
        </CardBody>
      </Card>
    );
  }

  setShowReport(value) {
    this.setState({ showReport: value });
  }

  render() {
    const { classes } = this.props;
    // console.log(this.state.originalData);
    return (
      <div>
        {this.state.loading ? <Loader /> : null}
        {this.state.alert}
        {this.state.showReport && (
          <FormReport setShowReport={(value) => this.setShowReport(value)} />
        )}
        {!this.state.showReport && this.renderHeader()}
        {!this.state.showReport && (
          <GridContainer>
            {this.state.contentData2 && (
              <GridItem xs={4}>
                {this.renderCard(this.state.deliveryDate2, this.state.contentData2)}
              </GridItem>
            )}
            {this.state.contentData1 && (
              <GridItem xs={4}>
                {this.renderCard(this.state.deliveryDate1, this.state.contentData1)}
              </GridItem>
            )}
            {this.state.contentData && (
              <GridItem xs={4}>
                {this.renderCard(this.state.deliveryDate, this.state.contentData)}
              </GridItem>
            )}
          </GridContainer>
        )}
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log(state)
//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = (state) => ({
  ShowForm: state.ShowForm,
  ShowPicList: state.ShowPicList,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      showNotification,
    },
    dispatch
  );
const MemberList = withStyles(sweetAlertStyle)(ReactTables);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberList);
