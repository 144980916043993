const INITIAL_OBJ = {};
const INITIAL_STATE = {
  showForm: false,
  earning: INITIAL_OBJ,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(INITIAL_OBJ)
  // console.log(state);
  // console.log(action);
  switch (action.type) {
    case "EARNINGS_SHOW_FORM":
      return {
        ...state,
        earning: action.payload === undefined ? INITIAL_OBJ : action.payload,
        showForm: true,
      };
    case "EARNINGS_HIDE_FORM":
      return {
        ...state,
        showForm: false,
      };
    case "EARNINGS_SHOW_FORM_REPORT":
      return {
        ...state,
        showForm: false,
        showFormReport: true,
        order: INITIAL_OBJ,
      };
    case "EARNINGS_HIDE_FORM_REPORT":
      return {
        ...state,
        showForm: false,
        showFormReport: false,
        order: INITIAL_OBJ,
      };
    default:
      return state;
  }
};
