import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
import consts from "../../consts";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ShowForm, save } from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";

// import Assignment from "@material-ui/icons/Assignment";
// import Favorite from "@material-ui/icons/Favorite";
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      result: [],
      pages: -1,
      loading: true,
      alert: null,
      show: false,
    };
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  ban(item) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Tem certeza?"
          onConfirm={() => {
            // console.log(item)
            axios.put(`${consts.API_URL}/m-users/${item.id}/ban`).then((resp) => {
              const result = this.state.result.map((user) => {
                if (user.id === item.id) {
                  user.banned = "1";
                }
                return user;
              });

              this.setState(
                {
                  result,
                  loading: false,
                },
                () => {
                  this.setData(result);
                }
              );
            });
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
          cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
          confirmBtnText="Sim, banir!"
          cancelBtnText="Cancelar"
          showCancel
        />
      ),
    });
  }

  unban(item) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Tem certeza?"
          onConfirm={() => {
            // console.log(item)
            axios.put(`${consts.API_URL}/m-users/${item.id}/unban`).then((resp) => {
              const result = this.state.result.map((user) => {
                if (user.id === item.id) {
                  user.banned = "0";
                }
                return user;
              });

              this.setState(
                {
                  result,
                  loading: false,
                },
                () => {
                  this.setData(result);
                }
              );
            });
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
          cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
          confirmBtnText="Sim, liberar acesso!"
          cancelBtnText="Cancelar"
          showCancel
        />
      ),
    });
  }

  setData(result) {
    var data = result.map((item) => {
      return {
        id: item.id,
        name: item.name,
        cpf: item.cpf,
        birthday: moment(item.birthday).format("DD/MM/YYYY"),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {item.banned == "1" ? (
              <Button
                justIcon
                round
                simple
                onClick={() => this.unban(item)}
                color="danger"
                className="edit"
              >
                <ToggleOffIcon />
              </Button>
            ) : (
              <Button
                justIcon
                round
                simple
                onClick={() => this.ban(item)}
                color="success"
                className="remove"
              >
                <ToggleOnIcon />
              </Button>
            )}
          </div>
        ),
      };
    });

    this.setState({ data });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.alert}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <MaterialIconList />
                </CardIcon>
                <a href="https://icoke-reports.herokuapp.com/m-users" target="_blank">
                  <Button color="primary" className={classes.updateProfileButton}>
                    Relatório
                  </Button>
                </a>
                {/* <h4 className={classes.cardIconTitle}>Lista de membros</h4> */}
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={this.state.data}
                  pages={this.state.pages}
                  loading={this.state.loading}
                  filterable={false}
                  columns={[
                    {
                      Header: "Nome",
                      accessor: "name",
                      filterable: true,
                    },
                    {
                      Header: "CPF",
                      accessor: "cpf",
                      filterable: true,
                    },
                    {
                      Header: "Data de nascimento",
                      accessor: "birthday",
                      filterable: false,
                    },

                    {
                      Header: "Ações",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                  defaultPageSize={20}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  previousText="Anterior"
                  nextText="Próxima"
                  loadingText="Carregando"
                  noDataText="Nenhum registro encontrado"
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                  manual
                  onFetchData={(state, instance) => {
                    // show the loading overlay
                    this.setState({ loading: true }, () => {
                      const sorted = state.sorted.length === 0 ? "" : state.sorted[0].id;
                      const order =
                        state.sorted.length > 0 && state.sorted[0].desc ? "desc" : "asc";
                      const filtered = btoa(JSON.stringify(state.filtered));

                      // fetch your data
                      axios
                        .get(
                          `${consts.API_URL}/m-users?page=${state.page + 1}&qtd_per_page=${
                            state.pageSize
                          }&sorted=${sorted}&order=${order}&filtered=${filtered}`,
                          {
                            page: state.page,
                            pageSize: state.pageSize,
                            sorted: state.sorted,
                            filtered: state.filtered,
                          }
                        )
                        .then((res) => {
                          // console.log(res)
                          // console.log(state.sorted)
                          // console.log(state.filtered)
                          // Update react-table
                          this.setState(
                            {
                              result: res.data.result,
                              pages: res.data.pages,
                              loading: false,
                            },
                            () => {
                              this.setData(res.data.result);
                            }
                          );
                        });
                    });
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log(state)
//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = (state) => ({
  ShowForm: state.ShowForm,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      save,
      // get
    },
    dispatch
  );
const MemberList = withStyles(sweetAlertStyle)(ReactTables);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberList);
