import React from "react";
import socketIOClient from "socket.io-client";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import consts from "../../../consts";
import { HideForm, save, showNotification } from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInputCep from "components/CustomInput/CustomInputCep.jsx";

import PictureUpload from "components/CustomUpload/PictureUpload.jsx";

import Loader from "components/Loader/Loader.jsx";

// material ui icons
// import MailOutline from "@material-ui/icons/MailOutline";
// import Contacts from "@material-ui/icons/Contacts";
// import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
// import CategoryIcon from "@material-ui/icons/Category"
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomInputCPF from "components/CustomInput/CustomInputCPF.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Table from "components/Table/Table.jsx";

import If from "../../../common/operators/If";

// style for this view
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";

const styles = {
  ...validationFormsStyle,
  ...extendedTablesStyle,
};

class ValidationForms extends React.Component {
  constructor(props) {
    super(props);
    // console.log("props: ", props);
    // console.log('props.piclist: ', props.piclist)
    // const products =
    //   props.piclist && props.piclist.piclistProducts
    //     ? props.piclist.piclistProducts
    //     : [];
    // const planogramProducts =
    //   props.piclist &&
    //   props.piclist.Fridge &&
    //   props.piclist.Fridge.Planogram &&
    //   props.piclist.Fridge.Planogram.PlanogramProducts
    //     ? props.piclist.Fridge.Planogram.PlanogramProducts.map(
    //         (planogramProduct) => {
    //           let hasOnProductList = false;
    //           products.map((product) => {
    //             if (product.Product.id === planogramProduct.Product.id) {
    //               hasOnProductList = true;
    //             }
    //           });
    //           return hasOnProductList === true ? null : planogramProduct;
    //         }
    //       ).filter((planogramProduct) => planogramProduct !== null)
    //     : [];
    // console.log("planogramProducts: ", planogramProducts);
    this.state = {
      loading: true,
      showPlanogramProducts: false,
      action: "",
      id: props.piclist ? props.piclist.id : "",
      sapLogs: [],
      products: [],
      planogramProducts: [],
    };
    // console.log(this.state);
    this.registerClick = this.registerClick.bind(this);
    this.loginClick = this.loginClick.bind(this);
    this.typeClick = this.typeClick.bind(this);
    this.rangeClick = this.rangeClick.bind(this);
    // this.getSapLogs();
  }

  componentDidMount() {
    this.get();
    this.getSapLogs();
    this.socketConnect();
  }

  socketConnect() {
    const that = this;
    const socket = socketIOClient(consts.SOCKET_URL, {
      query: { app: "admin" },
    });
    // socket.on("connect", () => console.log("connected"));
    // socket.on("teste", (data) => console.log("data: ", data));
    socket.on("PiclistSapControllerCreated", (resp) => {
      // console.log("resp: ", resp);
      // console.log("props: ", that.props);
      if (resp.piclist_id == that.props.piclist.id) {
        const configNotification = {
          message: resp.message,
          color: resp.status == 200 ? "success" : "danger",
        };
        this.props.showNotification(configNotification);
        that.getSapLogs();
      }
    });
    this.setState({ socket });
  }

  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  cancelButtonClick() {
    this.props.HideForm();
  }

  saveButtonClick() {
    // console.log(this.props.piclist)
    var piclist = { ...this.props.piclist };

    piclist.name = this.state.name;
    piclist.sku = this.state.sku;
    piclist.image = this.state.image;

    this.props.save(piclist);
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  // function that verifies if two strings are equal
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  // function that verifies if value contains only numbers
  verifyNumber(value) {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }
  // verifies if value is a valid URL
  verifyUrl(value) {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  }
  change(event, stateName, type, stateNameEqualTo, maxValue) {
    // console.log(event)
    // console.log(this.state)
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "checkbox":
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-length":
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "min-value":
        if (this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-value":
        if (this.verifyNumber(event.target.value) && event.target.value <= stateNameEqualTo) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "range":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }
  registerClick() {
    if (this.state.registerEmailState === "") {
      this.setState({ registerEmailState: "error" });
    }
    if (this.state.registerPasswordState === "") {
      this.setState({ registerPasswordState: "error" });
    }
    if (this.state.registerConfirmPasswordState === "") {
      this.setState({ registerConfirmPasswordState: "error" });
    }
    if (this.state.registerCheckboxState === "") {
      this.setState({ registerCheckboxState: "error" });
    }
  }
  loginClick() {
    if (this.state.loginEmailState === "") {
      this.setState({ loginEmailState: "error" });
    }
    if (this.state.loginPasswordState === "") {
      this.setState({ loginPasswordState: "error" });
    }
  }
  typeClick() {
    if (this.state.requiredState === "") {
      this.setState({ requiredState: "error" });
    }
    if (this.state.typeEmailState === "") {
      this.setState({ typeEmailState: "error" });
    }
    if (this.state.numberState === "") {
      this.setState({ numberState: "error" });
    }
    if (this.state.urlState === "") {
      this.setState({ urlState: "error" });
    }
    if (this.state.equalToState === "") {
      this.setState({ equalToState: "error" });
    }
  }
  rangeClick() {
    if (this.state.minLengthState === "") {
      this.setState({ minLengthState: "error" });
    }
    if (this.state.maxLengthState === "") {
      this.setState({ maxLengthState: "error" });
    }
    if (this.state.rangeState === "") {
      this.setState({ rangeState: "error" });
    }
    if (this.state.minValueState === "") {
      this.setState({ minValueState: "error" });
    }
    if (this.state.maxValueState === "") {
      this.setState({ maxValueState: "error" });
    }
  }

  // getPicture(image){
  //   // console.log(image)
  //   if(image !== ''){
  //     this.setState({
  //       image,
  //       imageState: 'success'
  //     })
  //   }else{
  //     this.setState({
  //       image,
  //       imageState: 'error'
  //     })
  //   }
  //
  // }

  get() {
    this.setState({ loading: true });
    axios
      .get(
        `${consts.API_URL}/fridges/${this.props.piclist.fridge_id}/piclists/${
          this.props.piclist.id
        }`
      )
      .then((resp) => {
        // console.log("resp: ", resp);
        // console.log("resp.data: ", resp.data);
        const products = resp.data && resp.data.piclistProducts ? resp.data.piclistProducts : [];
        const planogramProducts =
          resp.data &&
          resp.data.Fridge &&
          resp.data.Fridge.Planogram &&
          resp.data.Fridge.Planogram.PlanogramProducts
            ? resp.data.Fridge.Planogram.PlanogramProducts.map((planogramProduct) => {
                let hasOnProductList = false;
                products.map((product) => {
                  if (product.Product.id === planogramProduct.Product.id) {
                    hasOnProductList = true;
                  }
                });
                return hasOnProductList === true ? null : planogramProduct;
              }).filter((planogramProduct) => planogramProduct !== null)
            : [];
        // const respFiltered = resp.data.result.filter(
        //   (item) => item.status == 1
        // );
        // console.log('respFiltered: ', respFiltered)

        this.setState({ loading: false, products, planogramProducts });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  }

  getSapLogs() {
    this.setState({ loading: true });
    axios
      .get(`${consts.API_URL}/piclists/${this.state.id}/sap`)
      .then((resp) => {
        // console.log("resp: ", resp);
        // const respFiltered = resp.data.result.filter((item => (item.status == 1)))
        // console.log('respFiltered: ', respFiltered)

        this.setState({ sapLogs: resp.data.result, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        // console.log(err);
      });
  }

  execSap(route = false) {
    // console.log("sap");
    // console.log('getReportData(){')
    this.setState({ loading: true });
    axios
      .post(`${consts.API_URL}/piclists/${this.state.id}/${route ? "sap-route" : "sap"}`)
      .then((resp) => {
        // console.log("resp: ", resp);
        const configNotification = {
          message: resp.data.message,
          color: "info",
        };
        this.props.showNotification(configNotification);
        // this.getSapLogs();
        // const respFiltered = resp.data.result.filter((item => (item.status == 1)))
        // console.log('respFiltered: ', respFiltered)

        // this.setState({ reportData: resp.data });
      })
      .catch((err) => {
        console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        this.getSapLogs();
        console.log(err);
      });
  }

  execAction() {
    // console.log(this.state);
    switch (this.state.action) {
      case "sap":
        this.execSap();
        break;
      case "sapRoute":
        this.execSap(true);
        break;
    }
  }

  renderDetails1() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="warning" icon>
          <CardIcon color="warning">
            <AssignmentIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Cadastrar novo produto</h4>
        </CardHeader>
        <CardBody>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <GridItem xs={12}>
                  <CustomInput
                    success={this.state.nameState === "success"}
                    error={this.state.nameState === "error"}
                    labelText={<span>Nome</span>}
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => this.change(event, "name", "length", 3),
                      value: this.state.name,
                      type: "text",
                      placeholder: "Nome",
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <CustomInput
                    success={this.state.skuState === "success"}
                    error={this.state.skuState === "error"}
                    labelText={<span>SKU</span>}
                    id="sku"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => this.change(event, "sku", "length", 3),
                      value: this.state.sku,
                      type: "text",
                      placeholder: "SKU",
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <CustomInput
                    success={this.state.imageState === "success"}
                    error={this.state.imageState === "error"}
                    labelText={<span>Link da imagem</span>}
                    id="image"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (event) => this.change(event, "image", "length", 3),
                      value: this.state.image,
                      type: "text",
                      placeholder: "Link da imagem",
                    }}
                  />
                </GridItem>
              </GridItem>
            </GridContainer>
          </form>
        </CardBody>
        <CardFooter>
          <Button onClick={() => this.cancelButtonClick()}>Cancelar</Button>
          <Button color="primary" onClick={() => this.saveButtonClick()}>
            Salvar
          </Button>
        </CardFooter>
      </Card>
    );
  }

  renderHeader() {
    const { classes } = this.props;
    return (
      <Card>
        <CardFooter>
          <Button color="primary" onClick={() => this.cancelButtonClick()}>
            Concluir
          </Button>
          {this.state.planogramProducts && this.state.planogramProducts.length > 0 ? (
            <Button
              color="info"
              onClick={() =>
                this.setState({
                  showPlanogramProducts: !this.state.showPlanogramProducts,
                })
              }
            >
              {!this.state.showPlanogramProducts
                ? "Mostrar produtos do planograma"
                : "Ocultar produtos do planograma"}
            </Button>
          ) : null}
        </CardFooter>
      </Card>
    );
  }

  renderDetails() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>Detalhes do pedido #{this.props.piclist.id}</h4>
        </CardHeader>
        <CardBody>
          <p>
            <strong>Status:</strong>{" "}
            {this.props.piclist && this.props.piclist.status === 0 ? "Pendente" : ""}
            {this.props.piclist && this.props.piclist.status === 1 ? "Concluído" : ""}
            {this.props.piclist && this.props.piclist.status === 2 ? "Cancelado" : ""}
          </p>
          <p>
            <strong>Data:</strong>{" "}
            {this.props.piclist.createdAt
              ? new Date(this.props.piclist.createdAt).toLocaleString("pt-BR")
              : ""}
          </p>
          <p>
            <strong>Geladeira:</strong>{" "}
            {this.props.piclist && this.props.piclist.Fridge ? this.props.piclist.Fridge.gebra : ""}
          </p>
          <p>
            <strong>Endereço:</strong>
            <br />
            {this.props.piclist && this.props.piclist.Fridge ? this.props.piclist.Fridge.local : ""}
            <br />
            {this.props.piclist && this.props.piclist.Fridge
              ? this.props.piclist.Fridge.logradouro
              : ""}{" "}
            {this.props.piclist && this.props.piclist.Fridge
              ? this.props.piclist.Fridge.complemento
              : ""}{" "}
            {this.props.piclist && this.props.piclist.Fridge
              ? this.props.piclist.Fridge.numero
              : ""}
            <br />
            {this.props.piclist && this.props.piclist.Fridge
              ? this.props.piclist.Fridge.localidade
              : ""}
            <br />
            {this.props.piclist && this.props.piclist.Fridge
              ? this.props.piclist.Fridge.bairro
              : ""}
            <br />
            {this.props.piclist && this.props.piclist.Fridge ? this.props.piclist.Fridge.uf : ""}
            <br />
            {this.props.piclist && this.props.piclist.Fridge ? this.props.piclist.Fridge.cep : ""}
          </p>
        </CardBody>
      </Card>
    );
  }

  addFromPlanograma(item) {
    // console.log(item);
    // let products = this.state.products.map((product) => {
    //   let toReturn = { ...product };
    //   if (product.id === item.id) {
    //     const qtd = toReturn.qtd + 1;
    //     toReturn.qtd = qtd;
    const { product_id, channel } = item;
    axios
      .post(`${consts.API_URL}/piclists/${this.state.id}/products`, {
        product_id,
        channel,
      })
      .then((resp) => this.get());

    // let products = this.state.products;
    // products.push(item.Product);
    // //   }
    // //   return toReturn;
    // // });
    // this.setState({ products });
  }

  add(item) {
    let products = this.state.products.map((product) => {
      let toReturn = { ...product };
      if (product.id === item.id) {
        const qtd = toReturn.qtd + 1;
        toReturn.qtd = qtd;
        axios.put(`${consts.API_URL}/piclists/${this.state.id}/products/${product.id}`, { qtd });
      }
      return toReturn;
    });
    this.setState({ products });
  }

  remove(item) {
    let products = this.state.products.map((product) => {
      let toReturn = { ...product };
      if (product.id === item.id && toReturn.qtd > 0) {
        const qtd = toReturn.qtd - 1;
        toReturn.qtd = qtd;
        axios.put(`${consts.API_URL}/piclists/${this.state.id}/products/${product.id}`, { qtd });
      }
      return toReturn;
    });
    this.setState({ products });
  }

  renderPlanogramProducts() {
    if (
      this.state.showPlanogramProducts === false ||
      !this.state.planogramProducts ||
      this.state.planogramProducts.length == 0
    ) {
      return null;
    }
    const { classes } = this.props;
    // console.log(classes);
    const tableData = this.state.planogramProducts.map((product) => {
      const simpleButtons = [
        {
          color: "success",
          icon: Add,
          func: () => {
            this.addFromPlanograma(product);
            // console.log(product);
            // alert("add");
          },
        },
      ].map((prop, key) => {
        return (
          <Button
            color={prop.color}
            simple
            className={classes.actionButton}
            key={key}
            onClick={prop.func}
          >
            <prop.icon className={classes.icon} />
          </Button>
        );
      });
      return [product.Product.name, product.Product.sku, simpleButtons];
    });
    // .reduce((total, num) => total + num)
    // .toLocaleString("pt-BR", { style: "currency", currency: "BRL" });

    // tableData.push({ total: true, amount, colspan: 3 });
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>Produtos do planograma que não estão na piclist</h4>
        </CardHeader>
        <CardBody>
          <Table
            tableHeaderColor="primary"
            tableHead={["Produto", "SKU", "Ações"]}
            tableData={tableData}
            customCellClasses={[
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
            ]}
            coloredColls={[4]}
            colorsColls={["info"]}
          />
        </CardBody>
      </Card>
    );
  }

  renderProducts() {
    if (!this.state.products || this.state.products.length == 0) {
      return null;
    }
    const { classes } = this.props;
    // console.log(classes);
    const tableData = this.state.products.map((product) => {
      const simpleButtons = [
        {
          color: "danger",
          icon: Remove,
          func: () => {
            this.remove(product);
            // console.log(product);
          },
        },
        {
          color: "success",
          icon: Add,
          func: () => {
            this.add(product);
            // console.log(product);
            // alert("add");
          },
        },
      ].map((prop, key) => {
        return (
          <Button
            color={prop.color}
            simple
            className={classes.actionButton}
            key={key}
            onClick={prop.func}
          >
            <prop.icon className={classes.icon} />
          </Button>
        );
      });
      // console.log("product: ", product);
      // const price = product.sapProduct
      //   ? product.Product.only_pack === true
      //     ? product.sapProduct.final_price
      //     : product.sapProduct.unit_price
      //   : 0;
      const price = product.price ? product.price : 0;
      return [
        product.Product.name,
        price.toFixed(2),
        (price * product.qtd).toFixed(2),
        product.Product.sku,
        product.qtd,
        simpleButtons,
      ];
    });
    // .reduce((total, num) => total + num)
    // .toLocaleString("pt-BR", { style: "currency", currency: "BRL" });

    // tableData.push({ total: true, amount, colspan: 3 });
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>Produtos da piclist</h4>
        </CardHeader>
        <CardBody>
          <Table
            tableHeaderColor="primary"
            tableHead={["Produto", "Valor unitário", "Valor total", "SKU", "Qtd", "Ações"]}
            tableData={tableData}
            customCellClasses={[
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
            ]}
            coloredColls={[4]}
            colorsColls={["info"]}
          />
        </CardBody>
      </Card>
    );
  }

  renderTransaction() {
    const { classes } = this.props;
    let new_payment_data =
      this.props.piclist && this.props.piclist.payment_data
        ? this.props.piclist.payment_data
            .split("\n")
            .map((paragraph, index) => <p key={index}>{paragraph}</p>)
        : "";
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>Transação</h4>
        </CardHeader>
        <CardBody>{new_payment_data}</CardBody>
      </Card>
    );
  }

  renderActions() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>Ações do pedido</h4>
        </CardHeader>
        <CardBody>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Ação
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={this.state.action}
              onChange={(event) => this.setState({ [event.target.name]: event.target.value })}
              inputProps={{
                name: "action",
                id: "simple-select",
              }}
            >
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
              >
                Selecione uma ação
              </MenuItem>
              <MenuItem
                key="sap"
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value="sap"
              >
                Enviar para o SAP - Sem roteirização
              </MenuItem>
              <MenuItem
                key="sapRoute"
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value="sapRoute"
              >
                Enviar para o SAP - Com roteirização
              </MenuItem>
            </Select>
          </FormControl>
        </CardBody>
        {this.state.action ? (
          <CardFooter>
            <Button color="primary" onClick={() => this.execAction()}>
              Executar
            </Button>
          </CardFooter>
        ) : null}
      </Card>
    );
  }

  renderSapLogs() {
    return this.state.sapLogs.map((log, index) => {
      const { status, message, data, createdAt, creator } = log;
      const date = createdAt ? new Date(createdAt).toLocaleString("pt-BR") : null;
      return (
        <div key={index}>
          <strong>{date}</strong>
          <br />
          <strong>Mensagem: </strong>
          {message}
          <br />
          <strong>Status: </strong>
          {status}
          <br />
          <strong>Criado por: </strong>
          {creator && creator.name ? creator.name : "Rotina"}
          <br />
          {data && data != "" ? <strong>XML: </strong> : null}
          {data && data != "" ? data : null}
          {data && data != "" ? <br /> : null}
          <hr />
        </div>
      );
    });
  }

  renderNotes() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>Notas do pedido</h4>
        </CardHeader>
        <CardBody>{this.renderSapLogs()}</CardBody>
      </Card>
    );
  }

  renderInfo() {
    const { classes } = this.props;
    if (
      !this.props.piclist.creator ||
      !this.props.piclist.creator.name ||
      this.props.piclist.creator.name === ""
    ) {
      return;
    }
    return (
      <Card>
        <CardBody>
          <strong>Criado por</strong>: {this.props.piclist.creator.name}
        </CardBody>
      </Card>
    );
  }

  render() {
    const { classes } = this.props;
    // console.log("render(): ", this.state);
    return (
      <GridContainer justify="center">
        {this.state.loading ? <Loader /> : null}
        <GridItem xs={12} sm={12} md={12}>
          {this.renderHeader()}
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {this.renderInfo()}
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {/*this.renderDetails()*/}
          {this.renderPlanogramProducts()}
          {this.renderProducts()}
          {/*this.renderTransaction()*/}
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {this.renderActions()}
          {this.renderNotes()}
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('mapStateToProps: ', state)
  return {
    HideForm: state.piclists.HideForm,
    piclist: state.piclists.piclist,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      HideForm,
      save,
      showNotification,
    },
    dispatch
  );
const ReturnClassDecorated = withStyles(styles)(ValidationForms);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnClassDecorated);
