import axios from "axios";
import consts from "../../consts";

export function ShowForm() {
  return { type: "AUDIT_SHOW_FORM" };
}

export function HideForm() {
  return { type: "AUDIT_HIDE_FORM" };
}

export function ShowFormReport() {
  return { type: "AUDIT_SHOW_FORM_REPORT" };
}

export function HideFormReport() {
  return { type: "AUDIT_HIDE_FORM_REPORT" };
}

export function save(data) {
  // console.log(data);
  return (dispatch) => {
    axios
      .post(`${consts.API_URL}/scheduled-audits`, data)
      .then((resp) => {
        // console.log(resp)
        const configNotification = {
          message: "Agendamentos cadastrados com sucesso",
          color: "success",
        };
        dispatch([
          {
            type: "MAIN_SHOW_NOTIFICATION",
            showNotification: true,
            payload: configNotification,
          },
          { type: "CAR_HIDE_FORM" },
        ]);
      })
      .catch((e) => {
        console.log(e);
        console.log(e.response);
        if (e.response && e.response.data && e.response.data.errors && e.response.data.errors > 0) {
          e.response.data.errors.forEach((error) => {
            // toastr.error('Erro', error))
            const configNotification = {
              message: error,
              color: "danger",
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification,
              },
            ]);
          });
        } else if (e.response && e.response.data && e.response.data.message) {
          // console.log('e.response.data.message: ', e.response.data.message)
          // toastr.error('Erro', e.response.data.message)

          const configNotification = {
            message: e.response.data.message,
            color: "danger",
          };
          dispatch([
            {
              type: "MAIN_SHOW_NOTIFICATION",
              showNotification: true,
              payload: configNotification,
            },
          ]);
        } else {
          // toastr.error('Erro', 'Erro inesperado.')
          const configNotification = {
            message: "Erro inesperado.",
            color: "danger",
          };
          dispatch([
            {
              type: "MAIN_SHOW_NOTIFICATION",
              showNotification: true,
              payload: configNotification,
            },
          ]);
        }
      });
  };
}

export function showNotification(configNotification) {
  return {
    type: "MAIN_SHOW_NOTIFICATION",
    showNotification: true,
    payload: configNotification,
  };
}
