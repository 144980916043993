import React from "react";
import socketIOClient from "socket.io-client";
import moment from "moment";
import "moment/locale/pt-br";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import consts from "../../consts";
import { HideForm, save, showNotification, contractCancelation } from "./Actions";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
// import FormLabel from "@material-ui/core/FormLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInputCep from "components/CustomInput/CustomInputCep.jsx";
import ContractList from "@material-ui/icons/ListAlt";

import Loader from "components/Loader/Loader.jsx";

// material ui icons
import Settings from "@material-ui/icons/Settings";
import Dns from "@material-ui/icons/Dns";
import EditLocation from "@material-ui/icons/EditLocation";
import Group from "@material-ui/icons/Group";
import Payment from "@material-ui/icons/Payment";
// import Contacts from "@material-ui/icons/Contacts";
// import Check from "@material-ui/icons/Check";
// import Close from "@material-ui/icons/Close";
// import CategoryIcon from "@material-ui/icons/Category"
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomInputCPF from "components/CustomInput/CustomInputCPF.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
// import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import If from "../../common/operators/If";

// style for this view
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

const styles = {
  ...validationFormsStyle,
  ...extendedFormsStyle,
};

// console.log(validationFormsStyle);
// console.log(extendedFormsStyle);

class ValidationForms extends React.Component {
  constructor(props) {
    super(props);
    console.log("props: ", props);
    this.me = JSON.parse(localStorage.getItem(consts.UserKey)).user;
    this.state = {
      loading: true,
      action: "",
      tables: ["361", "786", "787", "788", "789", "790", "791", "884", "883"],
      id: props.fridge && props.fridge.id ? props.fridge.id : "",
      token:
        props.fridge && props.fridge.MVFridge && props.fridge.MVFridge.token
          ? props.fridge.MVFridge.token
          : "",
      gebra: props.fridge && props.fridge.gebra ? props.fridge.gebra : "",
      local: props.fridge && props.fridge.local ? props.fridge.local : "",
      cep: props.fridge && props.fridge.cep ? props.fridge.cep : "",
      logradouro: props.fridge && props.fridge.logradouro ? props.fridge.logradouro : "",
      complemento: props.fridge && props.fridge.complemento ? props.fridge.complemento : "",
      numero: props.fridge && props.fridge.numero ? props.fridge.numero : "",
      bairro: props.fridge && props.fridge.bairro ? props.fridge.bairro : "",
      localidade: props.fridge && props.fridge.localidade ? props.fridge.localidade : "",
      uf: props.fridge && props.fridge.uf ? props.fridge.uf : "",
      planogram_id: props.fridge && props.fridge.planogram_id ? props.fridge.planogram_id : "",
      maintenance: props.fridge && props.fridge.maintenance ? props.fridge.maintenance : "",
      lio: props.fridge && props.fridge.lio ? props.fridge.lio : "",
      table: props.fridge && props.fridge.table ? props.fridge.table : "",
      lat: props.fridge && props.fridge.lat ? props.fridge.lat : "",
      lng: props.fridge && props.fridge.lng ? props.fridge.lng : "",
      sap: props.fridge && props.fridge.sap ? props.fridge.sap : "",
      deposito: props.fridge && props.fridge.deposito ? props.fridge.deposito : "",
      route_id: props.fridge && props.fridge.route_id ? props.fridge.route_id : "",
      cod_cliente: props.fridge && props.fridge.cod_cliente ? props.fridge.cod_cliente : "",
      cod_comodato: props.fridge && props.fridge.cod_comodato ? props.fridge.cod_comodato : "",
      syndic_id: props.fridge && props.fridge.syndic_id ? props.fridge.syndic_id : "",
      condo_cnpj: props.fridge && props.fridge.condo_cnpj ? props.fridge.condo_cnpj : "",
      comissao: props.fridge && props.fridge.comissao ? props.fridge.comissao : "",

      cpf_required: props.fridge && props.fridge.cpf_required ? props.fridge.cpf_required : false,
      birthday_required:
        props.fridge && props.fridge.birthday_required ? props.fridge.birthday_required : false,
      cpf_validation:
        props.fridge && props.fridge.cpf_validation ? props.fridge.cpf_validation : false,
      over_18: props.fridge && props.fridge.over_18 ? props.fridge.over_18 : false,

      credit:
        props.fridge && props.fridge.credit !== undefined && props.fridge.credit !== null
          ? props.fridge.credit
          : true,
      debit:
        props.fridge && props.fridge.debit !== undefined && props.fridge.debit !== null
          ? props.fridge.debit
          : true,
      voucher:
        props.fridge && props.fridge.voucher !== undefined && props.fridge.voucher !== null
          ? props.fridge.voucher
          : true,

      razao_social: props.fridge && props.fridge.razao_social ? props.fridge.razao_social : "",
      brand_id: props.fridge && props.fridge.brand_id ? props.fridge.brand_id : "",
      type_id: props.fridge && props.fridge.type_id ? props.fridge.type_id : "",
      channel_id: props.fridge && props.fridge.channel_id ? props.fridge.channel_id : "",
      subchannel_id: props.fridge && props.fridge.subchannel_id ? props.fridge.subchannel_id : "",
      segmentation_id:
        props.fridge && props.fridge.segmentation_id ? props.fridge.segmentation_id : "",
      active_contract:
        props.fridge && props.fridge.active_contract ? props.fridge.active_contract : "",

      bank_id: props.fridge && props.fridge.bank_id ? props.fridge.bank_id : "",
      bank_agency: props.fridge && props.fridge.bank_agency ? props.fridge.bank_agency : "",
      bank_account: props.fridge && props.fridge.bank_account ? props.fridge.bank_account : "",
      bank_account_type:
        props.fridge && props.fridge.bank_account_type ? props.fridge.bank_account_type : "",
      installation_at:
        props.fridge && props.fridge.installation_at
          ? moment(props.fridge.installation_at).format("L")
          : "",
      contract_canceled_at:
        props.fridge && props.fridge.contract_canceled_at
          ? moment(props.fridge.contract_canceled_at).format("L")
          : "",

      apts_qty: props.fridge && props.fridge.apts_qty ? props.fridge.apts_qty : "",
      apts_blocks_qty:
        props.fridge && props.fridge.apts_blocks_qty ? props.fridge.apts_blocks_qty : "",
      residents_qty: props.fridge && props.fridge.residents_qty ? props.fridge.residents_qty : "",
      installation_site_id:
        props.fridge && props.fridge.installation_site_id ? props.fridge.installation_site_id : "",
      autonomous_market_id:
        props.fridge && props.fridge.autonomous_market_id ? props.fridge.autonomous_market_id : "",
      ambev: props.fridge && props.fridge.ambev ? props.fridge.ambev : "",
      picole: props.fridge && props.fridge.picole ? props.fridge.picole : "",
      released_door_sound:
        props.fridge && props.fridge.released_door_sound ? props.fridge.released_door_sound : "",
      tech_id: props.fridge && props.fridge.tech_id ? props.fridge.tech_id : "",

      planograms: [],
      sapLogs: [],
      routes: [],
      syndics: [],
      syndic_name: "",
      syndic: "",

      brands: [],
      types: [],
      channels: [],
      subchannels: [],
      segmentations: [],
      banks: [],
      autonomousMarkets: [],
      installationSites: [],
      techs: [],
    };
    this.registerClick = this.registerClick.bind(this);
    this.loginClick = this.loginClick.bind(this);
    this.typeClick = this.typeClick.bind(this);
    this.rangeClick = this.rangeClick.bind(this);
    this.getSapLogs();
  }

  componentDidMount() {
    this.getPlanograms();
    this.socketConnect();
    this.getRoutes2Select();
    this.getSyndics();
    this.getBrands();
    this.getTypes();
    this.getChannels();
    this.getSubchannels();
    this.getSegmentations();
    this.getBanks();
    this.getAutonomousMarkets();
    this.getInstallationSites();
    this.getTechs();
    this.getSapTables();
  }

  socketConnect() {
    const that = this;
    const socket = socketIOClient(consts.SOCKET_URL, {
      query: { app: "admin" },
    });
    // socket.on("connect", () => console.log("connected"));
    // socket.on("teste", (data) => console.log("data: ", data));
    socket.on("FridgeSapControllerCreated", (resp) => {
      // console.log("resp: ", resp);
      // console.log("props: ", that.props);
      if (resp.fridge_id == that.props.fridge.id) {
        const configNotification = {
          message: resp.message,
          color: resp.status == 200 ? "success" : "danger",
        };
        this.props.showNotification(configNotification);
        that.getSapLogs();
      }
    });
    this.setState({ socket });
  }

  getRoutes2Select() {
    const that = this;
    axios.get(`${consts.API_URL}/routes/select`).then((resp) => {
      // console.log(resp);
      that.setState({
        routes: resp.data.result,
      });
    });
  }

  getPlanograms() {
    // console.log(this.state)
    const that = this;
    axios.get(`${consts.API_URL}/planograms`).then((resp) => {
      // console.log(resp)
      that.setState({
        planograms: resp.data.result,
      });
    });
  }

  getSyndics() {
    // console.log(this.state)
    const that = this;
    axios.get(`${consts.API_URL}/users/syndicSelect`).then((resp) => {
      // console.log(resp);
      const syndic = this.state.syndic_id
        ? resp.data.result.filter((syndic) => this.state.syndic_id === syndic.id)[0]
        : false;
      that.setState({
        syndics: resp.data.result,
        syndic,
      });
    });
  }

  getSapLogs() {
    const id = this.props.fridge && this.props.fridge.id ? this.props.fridge.id : "";
    if(!id || id === "") return

    this.setState({ loading: true });
    axios
      .get(`${consts.API_URL}/fridges/${id}/sap`)
      .then((resp) => {
        // console.log("resp: ", resp);
        // const respFiltered = resp.data.result.filter((item => (item.status == 1)))
        // console.log('respFiltered: ', respFiltered)

        this.setState({ sapLogs: resp.data.result, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  }

  getBrands() {
    this.setState({ loading: true });
    axios
      .get(`${consts.API_URL}/fridge-brands/select`)
      .then((resp) => this.setState({ brands: resp.data.result, loading: false }))
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  }
  getTypes() {
    this.setState({ loading: true });
    axios
      .get(`${consts.API_URL}/fridge-types/select`)
      .then((resp) => this.setState({ types: resp.data.result, loading: false }))
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  }
  getChannels() {
    this.setState({ loading: true });
    axios
      .get(`${consts.API_URL}/fridge-channels/select`)
      .then((resp) => this.setState({ channels: resp.data.result, loading: false }))
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  }
  getSubchannels() {
    this.setState({ loading: true });
    axios
      .get(`${consts.API_URL}/fridge-subchannels/select`)
      .then((resp) => this.setState({ subchannels: resp.data.result, loading: false }))
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  }
  getSegmentations() {
    this.setState({ loading: true });
    axios
      .get(`${consts.API_URL}/fridge-segmentations/select`)
      .then((resp) => this.setState({ segmentations: resp.data.result, loading: false }))
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  }
  getBanks() {
    this.setState({ loading: true });
    axios
      .get(`${consts.API_URL}/fridge-banks/select`)
      .then((resp) => this.setState({ banks: resp.data.result, loading: false }))
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  }
  getAutonomousMarkets() {
    this.setState({ loading: true });
    axios
      .get(`${consts.API_URL}/fridge-autonomous-markets/select`)
      .then((resp) => this.setState({ autonomousMarkets: resp.data.result, loading: false }))
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  }
  getInstallationSites() {
    this.setState({ loading: true });
    axios
      .get(`${consts.API_URL}/fridge-installation-sites/select`)
      .then((resp) => this.setState({ installationSites: resp.data.result, loading: false }))
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  }
  getTechs() {
    this.setState({ loading: true });
    axios
      .get(`${consts.API_URL}/fridge-techs/select`)
      .then((resp) => this.setState({ techs: resp.data.result, loading: false }))
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  }
  getSapTables() {
    this.setState({ loading: true });
    axios
      .get(`${consts.API_URL}/sap-tables/select`)
      .then((resp) =>
        this.setState({ tables: resp.data.result.map((table) => table.name), loading: false })
      )
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  }

  // execSap() {
  //   // console.log("sap");
  //   // console.log('getReportData(){')
  //   this.setState({ loading: true });
  //   // axios.defaults.timeout = 120 * 1000;
  //   const config = {
  //     timeout: 120000,
  //   };
  //   axios
  //     .post(`${consts.API_URL}/fridges/${this.state.id}/sap`, {}, config)
  //     .then((resp) => {
  //       // console.log("resp: ", resp);
  //       const configNotification = {
  //         message: resp.data.message,
  //         color: "info",
  //       };
  //       this.props.showNotification(configNotification);
  //       // this.getSapLogs();
  //       // const respFiltered = resp.data.result.filter((item => (item.status == 1)))
  //       // console.log('respFiltered: ', respFiltered)

  //       // this.setState({ reportData: resp.data });
  //     })
  //     .catch((err) => {
  //       console.log(err.response);
  //       let configNotification = {
  //         message: "Erro inesperado.",
  //         color: "danger",
  //       };
  //       if (
  //         err.response &&
  //         err.response.data &&
  //         err.response.data.message &&
  //         err.response.data.message != ""
  //       ) {
  //         configNotification = {
  //           message: err.response.data.message,
  //           color: "danger",
  //         };
  //       }
  //       this.props.showNotification(configNotification);
  //       this.setState({ loading: false });
  //       this.getSapLogs();
  //       console.log(err);
  //     });
  // }

  // execAction() {
  //   // console.log(this.state);
  //   switch (this.state.action) {
  //     case "sap":
  //       this.execSap();
  //       break;
  //   }
  // }

  handleSimple = (event) => {
    // console.log(event)
    this.setState({ [event.target.name]: event.target.value });
  };

  cancelButtonClick() {
    this.props.HideForm();
  }

  searchButtonClick() {
    // console.log('searchButtonClick(){')
    // console.log(this.state)
    var values = {
      cpf: this.state.registerCPF,
    };
    // console.log(values)
    // this.props.getUser(values)
  }

  saveButtonClick() {
    // console.log(this.props.fridge)
    let state = { ...this.state };
    delete state.socket;
    // console.log(state);
    this.props.save(state);
  }

  contractCancelationButtonClick() {
    let state = { ...this.state };
    delete state.socket;
    this.props.contractCancelation(state);
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  // function that verifies if two strings are equal
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  // function that verifies if value contains only numbers
  verifyNumber(value) {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }
  // verifies if value is a valid URL
  verifyUrl(value) {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  }
  change(event, stateName, type, stateNameEqualTo, maxValue) {
    // console.log(event)
    // console.log(this.state)
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "checkbox":
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-length":
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "min-value":
        if (this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-value":
        if (this.verifyNumber(event.target.value) && event.target.value <= stateNameEqualTo) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "range":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }
  registerClick() {
    if (this.state.registerEmailState === "") {
      this.setState({ registerEmailState: "error" });
    }
    if (this.state.registerPasswordState === "") {
      this.setState({ registerPasswordState: "error" });
    }
    if (this.state.registerConfirmPasswordState === "") {
      this.setState({ registerConfirmPasswordState: "error" });
    }
    if (this.state.registerCheckboxState === "") {
      this.setState({ registerCheckboxState: "error" });
    }
  }
  loginClick() {
    if (this.state.loginEmailState === "") {
      this.setState({ loginEmailState: "error" });
    }
    if (this.state.loginPasswordState === "") {
      this.setState({ loginPasswordState: "error" });
    }
  }
  typeClick() {
    if (this.state.requiredState === "") {
      this.setState({ requiredState: "error" });
    }
    if (this.state.typeEmailState === "") {
      this.setState({ typeEmailState: "error" });
    }
    if (this.state.numberState === "") {
      this.setState({ numberState: "error" });
    }
    if (this.state.urlState === "") {
      this.setState({ urlState: "error" });
    }
    if (this.state.equalToState === "") {
      this.setState({ equalToState: "error" });
    }
  }
  rangeClick() {
    if (this.state.minLengthState === "") {
      this.setState({ minLengthState: "error" });
    }
    if (this.state.maxLengthState === "") {
      this.setState({ maxLengthState: "error" });
    }
    if (this.state.rangeState === "") {
      this.setState({ rangeState: "error" });
    }
    if (this.state.minValueState === "") {
      this.setState({ minValueState: "error" });
    }
    if (this.state.maxValueState === "") {
      this.setState({ maxValueState: "error" });
    }
  }

  search() {
    const cep = this.state.cep
      .split("_")
      .join("")
      .split(".")
      .join("")
      .split("-")
      .join("");
    if (cep.length === 8) {
      // console.log(cep)
      this.setState({
        bairro: "Carregando",
        complemento: "Carregando",
        localidade: "Carregando",
        logradouro: "Carregando",
        uf: "Carregando",
        numero: "Carregando",
      });
      const url = `https://viacep.com.br/ws/${cep}/json/unicode`;
      axios
        .get(url, {
          transformRequest: [
            (data, headers) => {
              delete headers.common.Authorization;
              return data;
            },
          ],
        })
        .then((resp) => {
          // console.log(resp)
          this.setState({
            bairro: resp.data.bairro,
            bairroState: resp.data.bairro !== "" ? "success" : "error",
            complemento: resp.data.complemento,
            complementoState: resp.data.complemento !== "" ? "success" : "error",
            localidade: resp.data.localidade,
            localidadeState: resp.data.localidade !== "" ? "success" : "error",
            logradouro: resp.data.logradouro,
            logradouroState: resp.data.logradouro !== "" ? "success" : "error",
            uf: resp.data.uf,
            ufState: resp.data.uf !== "" ? "success" : "error",
            numero: resp.data.unidade || "",
            numeroState: resp.data.unidade !== "" ? "success" : "error",
          });
          // this.setState({loading: false})
          // const me = JSON.parse(localStorage.getItem(UserKey))
          // axios.defaults.headers.common['Authorization'] = me.token

          // const condo = { ...this.state.condo }
          // condo.bairro = resp.data.bairro
          // condo.complemento = resp.data.complemento
          // condo.localidade = resp.data.localidade
          // condo.logradouro = resp.data.logradouro
          // condo.uf = resp.data.uf
          // condo.unidade = resp.data.unidade
          // this.setState({condo})
        })
        .catch((e) => {
          this.setState({
            bairro: "",
            bairroState: "",
            complemento: "",
            complementoState: "",
            localidade: "",
            localidadeState: "",
            logradouro: "",
            logradouroState: "",
            uf: "",
            ufState: "",
            numero: "",
            numeroState: "",
          });
          // this.setState({
          //   bairro: '',
          //   complemento: '',
          //   localidade: '',
          //   logradouro: '',
          //   uf: '',
          //   numero: '',
          // })
          // this.setState({loading: false})
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors > 0
          ) {
            // e.response.data.errors.forEach(
            // error => toastr.error('Erro', error))
          } else if (e.response && e.response.data && e.response.data.message) {
            // toastr.error('Erro', e.response.data.message)
          } else {
            // toastr.error('Erro', 'Erro inesperado.')
          }
        });
    }
  }

  changeSyndic(event) {
    const syndic = event.target.innerText
      ? this.state.syndics.filter((syndic) => event.target.innerText === syndic.name)[0]
      : false;
    // console.log(syndic);
    const syndic_name = syndic ? syndic.name : "";
    const syndic_id = syndic ? syndic.id : "";
    this.setState({
      syndic_name,
      syndic_id,
      syndic,
    });
  }

  renderSyndics() {
    return (
      <Autocomplete
        options={this.state.syndics}
        getOptionLabel={(option) => option.name}
        onChange={this.changeSyndic.bind(this)}
        value={this.state.syndic}
        renderInput={(params) => <TextField {...params} label="Responsável legal" fullWidth />}
      />
    );
  }

  renderGeneralFields() {
    const { classes } = this.props;
    return (
      <form>
        <GridContainer>
          <GridItem xs={12} sm={3}>
            <CustomInput
              success={this.state.gebraState === "success"}
              error={this.state.gebraState === "error"}
              labelText={<span>Razão Social</span>}
              id="razao_social"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "razao_social", "length", 3),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.razao_social,
                type: "text",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={3}>
            <CustomInput
              success={this.state.condo_cnpjState === "success"}
              error={this.state.condo_cnpjState === "error"}
              labelText={<span>CNPJ do condomínio</span>}
              id="condo_cnpj"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "condo_cnpj", "length", 3),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.condo_cnpj,
                type: "text",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={3}>
            <CustomInput
              success={this.state.gebraState === "success"}
              error={this.state.gebraState === "error"}
              labelText={<span>GEBRA</span>}
              id="gebra"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "gebra", "length", 3),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.gebra,
                type: "text",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={3}>
            <CustomInput
              success={this.state.depositoState === "success"}
              error={this.state.depositoState === "error"}
              labelText={<span>Depósito SAP</span>}
              id="deposito"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "deposito", "length", 3),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.deposito,
                type: "text",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={3}>
            <CustomInput
              success={this.state.lioState === "success"}
              error={this.state.lioState === "error"}
              labelText={<span>Número lógico da Lio</span>}
              id="lio"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "lio", "length", 3),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.lio,
                type: "text",
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={3}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                Planograma <small>*</small>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={this.state.planogram_id}
                onChange={this.handleSimple}
                inputProps={{
                  readOnly: this.me.profile === "maintenance_manager",
                  name: "planogram_id",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                >
                  Selecione o planograma
                </MenuItem>
                {this.state.planograms.map((planogram) => {
                  return (
                    <MenuItem
                      key={planogram.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={planogram.id}
                    >
                      {planogram.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={3}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                Tabela SAP <small>*</small>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={this.state.table}
                onChange={this.handleSimple}
                inputProps={{
                  readOnly: this.me.profile === "maintenance_manager",
                  name: "table",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Tabela SAP
                </MenuItem>
                {this.state.tables.map((table, index) => {
                  return (
                    <MenuItem
                      key={index}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={table}
                    >
                      {table}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={3}>
            {this.renderSyndics()}
          </GridItem>

          <GridItem xs={12} sm={3}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                Marca da geladeira
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={this.state.brand_id}
                onChange={this.handleSimple}
                inputProps={{
                  readOnly: this.me.profile === "maintenance_manager",
                  name: "brand_id",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                >
                  Selecione
                </MenuItem>
                {this.state.brands.map((brand) => {
                  return (
                    <MenuItem
                      key={brand.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={brand.id}
                    >
                      {brand.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={3}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                Tipo
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={this.state.type_id}
                onChange={this.handleSimple}
                inputProps={{
                  readOnly: this.me.profile === "maintenance_manager",
                  name: "type_id",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                >
                  Selecione
                </MenuItem>
                {this.state.types.map((type) => {
                  return (
                    <MenuItem
                      key={type.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={type.id}
                    >
                      {type.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={3}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                Canal
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={this.state.channel_id}
                onChange={this.handleSimple}
                inputProps={{
                  readOnly: this.me.profile === "maintenance_manager",
                  name: "channel_id",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                >
                  Selecione
                </MenuItem>
                {this.state.channels.map((channel) => {
                  return (
                    <MenuItem
                      key={channel.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={channel.id}
                    >
                      {channel.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={3}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                Subcanal
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={this.state.subchannel_id}
                onChange={this.handleSimple}
                inputProps={{
                  readOnly: this.me.profile === "maintenance_manager",
                  name: "subchannel_id",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                >
                  Selecione
                </MenuItem>
                {this.state.subchannels.map((subchannel) => {
                  return (
                    <MenuItem
                      key={subchannel.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={subchannel.id}
                    >
                      {subchannel.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={3}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                Segmentação
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={this.state.segmentation_id}
                onChange={this.handleSimple}
                inputProps={{
                  readOnly: this.me.profile === "maintenance_manager",
                  name: "segmentation_id",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                >
                  Selecione
                </MenuItem>
                {this.state.segmentations.map((segmentation) => {
                  return (
                    <MenuItem
                      key={segmentation.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={segmentation.id}
                    >
                      {segmentation.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>

          <GridItem xs={12} sm={3}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                Tecnologia
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={this.state.tech_id}
                onChange={this.handleSimple}
                inputProps={{
                  readOnly: this.me.profile === "maintenance_manager",
                  name: "tech_id",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                >
                  Selecione
                </MenuItem>
                {this.state.techs.map((tech) => {
                  return (
                    <MenuItem
                      key={tech.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={tech.id}
                    >
                      {tech.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>

          <GridItem xs={12} sm={6}>
            <CustomInput
              success={this.state.lioState === "success"}
              error={this.state.lioState === "error"}
              labelText={<span>Token para acesso externo</span>}
              id="token"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "token", "length", 3),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.token,
                type: "text",
                endAdornment: (
                  <InputAdornment position="end" className={classes.inputAdornment}>
                    <Button justIcon simple onClick={() => this.copyCodeToClipboard()}>
                      <FileCopyIcon className={classes.inputAdornmentIcon} />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
        </GridContainer>
      </form>
    );
  }

  copyCodeToClipboard = async () => {
    console.log("copyCodeToClipboard");
    const text = this.state.token;

    try {
      if ("clipboard" in navigator) {
        await navigator.clipboard.writeText(text);
      } else {
        document.execCommand("copy", true, text);
      }
      const configNotification = {
        message: "Token copiado com sucesso",
        color: "success",
      };
      this.props.showNotification(configNotification);
    } catch (error) {
      const configNotification = {
        message: "Erro ao copiar o token",
        color: "danger",
      };
      this.props.showNotification(configNotification);
    }
  };

  renderFormButtons() {
    // if (this.me.profile === "maintenance_manager") {
    //   return (
    //     <div
    //       style={{
    //         width: "100%",
    //         marginTop: "20px",
    //       }}
    //     >
    //       <Button onClick={() => this.cancelButtonClick()}>Cancelar</Button>
    //     </div>
    //   );
    // }

    return (
      <div
        style={{
          width: "100%",
          marginTop: "20px",
        }}
      >
        <Button onClick={() => this.cancelButtonClick()}>Cancelar</Button>
        <Button
          style={{
            float: "right",
          }}
          color="primary"
          onClick={() => this.saveButtonClick()}
        >
          Salvar
        </Button>
      </div>
    );
  }
  renderContractCancelationButtons() {
    if(!this.props.fridge.ContractCancellations) return null;
    const contract_cancelations = this.props.fridge.ContractCancellations.filter(
      (cancellation) => cancellation.status !== 2
    );
    const open_contract_cancelations = this.props.fridge.ContractCancellations.filter(
      (cancellation) => cancellation.status === 0
    );
    const finished_contract_cancelations = this.props.fridge.ContractCancellations.filter(
      (cancellation) => cancellation.status === 1
    );
    return (
      <div
        style={{
          width: "100%",
          marginTop: "20px",
        }}
      >
        <Button onClick={() => this.cancelButtonClick()}>Cancelar</Button>

        {contract_cancelations.length === 0 && (
          <Button
            style={{
              float: "right",
            }}
            color="primary"
            onClick={() => this.contractCancelationButtonClick()}
          >
            Cancelar contrato
          </Button>
        )}
        {open_contract_cancelations.length !== 0 && (
          <Button
            style={{
              float: "right",
            }}
            color=" "
            onClick={() => {}}
          >
            Cancelamento de contrato em andamento
          </Button>
        )}
        {finished_contract_cancelations.length !== 0 && (
          <Button
            style={{
              float: "right",
            }}
            color=" "
            onClick={() => {}}
          >
            Cancelamento de contrato finalizado
          </Button>
        )}
      </div>
    );
  }

  renderRoutes() {
    const { classes } = this.props;
    return (
      <div>
        <GridItem xs={12} sm={12}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Rota <small>*</small>
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={this.state.route_id}
              onChange={this.handleSimple}
              inputProps={{
                readOnly: this.me.profile === "maintenance_manager",
                name: "route_id",
                id: "simple-select",
              }}
            >
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
              >
                Selecione a rota
              </MenuItem>
              {this.state.routes.map((route) => {
                return (
                  <MenuItem
                    key={route.id}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={route.id}
                  >
                    {route.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>
      </div>
    );
  }

  renderSapFields() {
    const { classes } = this.props;
    return (
      <form>
        <GridContainer>
          <GridItem xs={12} sm={4}>
            <CustomInput
              success={this.state.cod_comodatoState === "success"}
              error={this.state.cod_comodatoState === "error"}
              labelText={<span>CÓDIGO COMODATO (SAC/0800)</span>}
              id="cod_comodato"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "cod_comodato", "length", 3),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.cod_comodato,
                type: "text",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              success={this.state.cod_clienteState === "success"}
              error={this.state.cod_clienteState === "error"}
              labelText={<span>CÓDIGO OCASIONAL (Financeiro)</span>}
              id="cod_cliente"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "cod_cliente"),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.cod_cliente,
                type: "text",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              success={this.state.sapState === "success"}
              error={this.state.sapState === "error"}
              labelText={<span>CÓDIGO FORNECEDOR (Suprimentos)</span>}
              id="sap"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "sap", "length", 3),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.sap,
                type: "text",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              success={this.state.comissaoState === "success"}
              error={this.state.comissaoState === "error"}
              labelText={<span>Repasse (%)</span>}
              id="comissao"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "comissao", "length", 1),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.comissao,
                type: "text",
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={4}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                Banco
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={this.state.bank_id}
                onChange={this.handleSimple}
                inputProps={{
                  readOnly: this.me.profile === "maintenance_manager",
                  name: "bank_id",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                >
                  Selecione
                </MenuItem>
                {this.state.banks.map((bank) => {
                  return (
                    <MenuItem
                      key={bank.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={bank.id}
                    >
                      {bank.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              labelText={<span>Agência</span>}
              id="bank_agency"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "bank_agency", "length", 3),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.bank_agency,
                type: "text",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              labelText={<span>Conta</span>}
              id="bank_account"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "bank_account", "length", 3),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.bank_account,
                type: "text",
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={4}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                Tipo de Conta
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={this.state.bank_account_type}
                onChange={this.handleSimple}
                inputProps={{
                  readOnly: this.me.profile === "maintenance_manager",
                  name: "bank_account_type",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="corrente"
                >
                  Conta Corrente
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="poupanca"
                >
                  Conta Poupança
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>

          <GridItem xs={12} sm={4}>
            {this.renderRoutes()}
          </GridItem>
          <GridItem xs={12} sm={4}>
            <div className={classes.block}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.active_contract}
                    onChange={(event) => {
                      // if (this.me.profile !== "maintenance_manager") {
                      this.setState({
                        active_contract: !this.state.active_contract,
                      });
                      // }
                    }}
                    value="1"
                    classes={{
                      switchBase: classes.switchBase ? classes.switchBase : "",
                      checked: classes.switchChecked ? classes.switchChecked : "",
                      icon: "colorPrimary",
                      iconChecked: "colorPrimary",
                      bar: "colorPrimary",
                    }}
                  />
                }
                classes={{
                  label: "textColorPrimary",
                }}
                label="Contrato ativo"
              />
            </div>
          </GridItem>
          {this.state.installation_at &&
            this.state.installation_at !== "" && (
              <GridItem xs={12} sm={4}>
                <CustomInput
                  labelText={<span>Data da Instalação</span>}
                  id="installation_at"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.installation_at,
                    type: "text",
                    readOnly: true,
                  }}
                />
              </GridItem>
            )}
          {this.state.contract_canceled_at &&
            this.state.contract_canceled_at !== "" && (
              <GridItem xs={12} sm={4}>
                <CustomInput
                  labelText={<span>Data da Cancelamento</span>}
                  id="installation_at"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.contract_canceled_at,
                    type: "text",
                    readOnly: true,
                  }}
                />
              </GridItem>
            )}
        </GridContainer>
      </form>
    );
  }

  renderLocalizationFields() {
    const { classes } = this.props;
    return (
      <form>
        <GridContainer>
          <GridItem xs={12} sm={4}>
            <CustomInput
              success={this.state.localState === "success"}
              error={this.state.localState === "error"}
              labelText={<span>Descrição do local</span>}
              id="local"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "local", "length", 3),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.local,
                type: "text",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInputCep
              success={this.state.cepState === "success"}
              error={this.state.cepState === "error"}
              labelText={
                <span>
                  Cep <small>*</small>
                </span>
              }
              id="cep"
              value={this.state.cep}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => {
                  if (this.me.profile !== "maintenance_manager") {
                    this.change(event, "cep", "length", 10);
                  }
                },
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.cep,
                endAdornment: (
                  <InputAdornment position="end" className={classes.inputAdornment}>
                    <Button justIcon simple onClick={() => this.search()}>
                      <SearchIcon className={classes.inputAdornmentIcon} />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              success={this.state.logradouroState === "success"}
              error={this.state.logradouroState === "error"}
              labelText={
                <span>
                  Logradouro <small>*</small>
                </span>
              }
              id="logradouro"
              value={this.state.logradouro}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "logradouro", "length", 1),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.logradouro,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              success={this.state.complementoState === "success"}
              error={this.state.complementoState === "error"}
              labelText={
                <span>
                  Complemento <small>*</small>
                </span>
              }
              id="complemento"
              value={this.state.complemento}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "complemento", "length", 1),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.complemento,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              success={this.state.numeroState === "success"}
              error={this.state.numeroState === "error"}
              labelText={
                <span>
                  Número <small>*</small>
                </span>
              }
              id="numero"
              value={this.state.numero}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "numero", "length", 1),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.numero,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              success={this.state.localidadeState === "success"}
              error={this.state.localidadeState === "error"}
              labelText={
                <span>
                  Cidade <small>*</small>
                </span>
              }
              id="localidade"
              value={this.state.localidade}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "localidade", "length", 1),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.localidade,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              success={this.state.bairroState === "success"}
              error={this.state.bairroState === "error"}
              labelText={
                <span>
                  Bairro <small>*</small>
                </span>
              }
              id="bairro"
              value={this.state.bairro}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "bairro", "length", 1),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.bairro,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              success={this.state.latState === "success"}
              error={this.state.latState === "error"}
              labelText={<span>Latitude</span>}
              id="lat"
              value={this.state.lat}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "lat", "length", 1),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.lat,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              success={this.state.lngState === "success"}
              error={this.state.lngState === "error"}
              labelText={<span>Longitude</span>}
              id="lng"
              value={this.state.lng}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "lng", "length", 1),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.lng,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                Estado <small>*</small>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={this.state.uf}
                onChange={this.handleSimple}
                inputProps={{
                  readOnly: this.me.profile === "maintenance_manager",
                  name: "uf",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Estado
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="AC"
                >
                  Acre
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="AL"
                >
                  Alagoas
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="AP"
                >
                  Amapá
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="AM"
                >
                  Amazonas
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="BA"
                >
                  Bahia
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="CE"
                >
                  Ceará
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="DF"
                >
                  Distrito Federal
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="ES"
                >
                  Espírito Santo
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="GO"
                >
                  Goiás
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="MA"
                >
                  Maranhão
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="MT"
                >
                  Mato Grosso
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="MS"
                >
                  Mato Grosso do Sul
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="MG"
                >
                  Minas Gerais
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="PA"
                >
                  Pará
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="PB"
                >
                  Paraíba
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="PR"
                >
                  Paraná
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="PE"
                >
                  Pernambuco
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="PI"
                >
                  Piauí
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="RJ"
                >
                  Rio de Janeiro
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="RN"
                >
                  Rio Grande do Norte
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="RS"
                >
                  Rio Grande do Sul
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="RO"
                >
                  Rondônia
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="RR"
                >
                  Roraima
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="SC"
                >
                  Santa Catarina
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="SP"
                >
                  São Paulo
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="SE"
                >
                  Sergipe
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="TO"
                >
                  Tocantins
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              success={this.state.apts_qtyState === "success"}
              error={this.state.apts_qtyState === "error"}
              labelText={<span>Quantidade de apartamentos</span>}
              id="apts_qty"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "apts_qty", "number"),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.apts_qty,
                type: "number",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              success={this.state.apts_blocks_qtyState === "success"}
              error={this.state.apts_blocks_qtyState === "error"}
              labelText={<span>Quantidade de blocos</span>}
              id="apts_blocks_qty"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "apts_blocks_qty", "number"),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.apts_blocks_qty,
                type: "number",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <CustomInput
              success={this.state.residents_qtyState === "success"}
              error={this.state.residents_qtyState === "error"}
              labelText={<span>Quantidade de moradores</span>}
              id="residents_qty"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => this.change(event, "residents_qty", "number"),
                readOnly: this.me.profile === "maintenance_manager",
                value: this.state.residents_qty,
                type: "number",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                Local da Instalação
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={this.state.installation_site_id}
                onChange={this.handleSimple}
                inputProps={{
                  readOnly: this.me.profile === "maintenance_manager",
                  name: "installation_site_id",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                >
                  Selecione
                </MenuItem>
                {this.state.installationSites.map((installation_site) => {
                  return (
                    <MenuItem
                      key={installation_site.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={installation_site.id}
                    >
                      {installation_site.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={4}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                Mercado Autônomo
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={this.state.autonomous_market_id}
                onChange={this.handleSimple}
                inputProps={{
                  readOnly: this.me.profile === "maintenance_manager",
                  name: "autonomous_market_id",
                  id: "simple-select",
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                >
                  Selecione
                </MenuItem>
                {this.state.autonomousMarkets.map((autonomous_market) => {
                  return (
                    <MenuItem
                      key={autonomous_market.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={autonomous_market.id}
                    >
                      {autonomous_market.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={4}>
            <div className={classes.block}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.ambev}
                    onChange={(event) => {
                      if (this.me.profile !== "maintenance_manager") {
                        this.setState({
                          ambev: !this.state.ambev,
                        });
                      }
                    }}
                    value="1"
                    classes={{
                      switchBase: classes.switchBase ? classes.switchBase : "",
                      checked: classes.switchChecked ? classes.switchChecked : "",
                      icon: "colorPrimary",
                      iconChecked: "colorPrimary",
                      bar: "colorPrimary",
                    }}
                  />
                }
                classes={{
                  label: "textColorPrimary",
                }}
                label="AMBEV"
              />
            </div>
          </GridItem>
          <GridItem xs={12} sm={4}>
            <div className={classes.block}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.picole}
                    onChange={(event) => {
                      if (this.me.profile !== "maintenance_manager") {
                        this.setState({
                          picole: !this.state.picole,
                        });
                      }
                    }}
                    value="1"
                    classes={{
                      switchBase: classes.switchBase ? classes.switchBase : "",
                      checked: classes.switchChecked ? classes.switchChecked : "",
                      icon: "colorPrimary",
                      iconChecked: "colorPrimary",
                      bar: "colorPrimary",
                    }}
                  />
                }
                classes={{
                  label: "textColorPrimary",
                }}
                label="Picolé"
              />
            </div>
          </GridItem>
          <GridItem xs={12} sm={4}>
            <div className={classes.block}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.released_door_sound}
                    onChange={(event) => {
                      if (this.me.profile !== "maintenance_manager") {
                        this.setState({
                          released_door_sound: !this.state.released_door_sound,
                        });
                      }
                    }}
                    value="1"
                    classes={{
                      switchBase: classes.switchBase ? classes.switchBase : "",
                      checked: classes.switchChecked ? classes.switchChecked : "",
                      icon: "colorPrimary",
                      iconChecked: "colorPrimary",
                      bar: "colorPrimary",
                    }}
                  />
                }
                classes={{
                  label: "textColorPrimary",
                }}
                label="Sonorização de porta liberada"
              />
            </div>
          </GridItem>
        </GridContainer>
      </form>
    );
  }

  renderFormCustumer() {
    const { classes } = this.props;
    // console.log(classes);

    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <div className={classes.block}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.cpf_required}
                    onChange={(event) => {
                      if (this.me.profile !== "maintenance_manager") {
                        this.setState({
                          cpf_required: !this.state.cpf_required,
                          cpf_validation: this.state.cpf_required
                            ? false
                            : this.state.cpf_validation,
                        });
                      }
                    }}
                    value="1"
                    classes={{
                      switchBase: classes.switchBase ? classes.switchBase : "",
                      checked: classes.switchChecked ? classes.switchChecked : "",
                      icon: "colorPrimary",
                      iconChecked: "colorPrimary",
                      bar: "colorPrimary",
                    }}
                  />
                }
                classes={{
                  label: "textColorPrimary",
                }}
                label="CPF obrigatório"
              />
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <div className={classes.block}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.birthday_required}
                    onChange={(event) => {
                      if (this.me.profile !== "maintenance_manager") {
                        this.setState({
                          birthday_required: !this.state.birthday_required,
                          cpf_validation: this.state.birthday_required
                            ? false
                            : this.state.cpf_validation,
                          over_18: this.state.birthday_required ? false : this.state.over_18,
                        });
                      }
                    }}
                    value="1"
                    classes={{
                      switchBase: classes.switchBase ? classes.switchBase : "",
                      checked: classes.switchChecked ? classes.switchChecked : "",
                      icon: "colorPrimary",
                      iconChecked: "colorPrimary",
                      bar: "colorPrimary",
                    }}
                  />
                }
                classes={{
                  label: "textColorPrimary",
                }}
                label="Data de nascimento obrigatória"
              />
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <div className={classes.block}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.cpf_validation}
                    onChange={(event) => {
                      if (this.me.profile !== "maintenance_manager") {
                        this.setState({
                          cpf_validation: !this.state.cpf_validation,
                        });
                      }
                    }}
                    disabled={!this.state.cpf_required || !this.state.birthday_required}
                    value="1"
                    classes={{
                      switchBase: classes.switchBase ? classes.switchBase : "",
                      checked: classes.switchChecked ? classes.switchChecked : "",
                      icon: "colorPrimary",
                      iconChecked: "colorPrimary",
                      bar: "colorPrimary",
                    }}
                  />
                }
                classes={{
                  label: "textColorPrimary",
                }}
                label="Consultar CPF"
              />
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <div className={classes.block}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.over_18}
                    onChange={(event) => {
                      if (this.me.profile !== "maintenance_manager") {
                        this.setState({
                          over_18: !this.state.over_18,
                        });
                      }
                    }}
                    disabled={!this.state.birthday_required}
                    value="1"
                    classes={{
                      switchBase: classes.switchBase ? classes.switchBase : "",
                      checked: classes.switchChecked ? classes.switchChecked : "",
                      icon: "colorPrimary",
                      iconChecked: "colorPrimary",
                      bar: "colorPrimary",
                    }}
                  />
                }
                classes={{
                  label: "textColorPrimary",
                }}
                label="Restrição para menores de 18 anos"
              />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  renderFormPayment() {
    const { classes } = this.props;
    // console.log(classes);
    // console.log(this.state);

    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <div className={classes.block}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.debit}
                    onChange={(event) => {
                      if (this.me.profile !== "maintenance_manager") {
                        if (
                          this.state.debit === true &&
                          this.state.credit === false &&
                          this.state.voucher === false
                        ) {
                          this.setState({
                            debit: true,
                            credit: true,
                            voucher: true,
                          });
                        } else {
                          this.setState({
                            debit: !this.state.debit,
                          });
                        }
                      }
                    }}
                    value={this.state.debit}
                    classes={{
                      switchBase: classes.switchBase ? classes.switchBase : "",
                      checked: classes.switchChecked ? classes.switchChecked : "",
                      icon: "colorPrimary",
                      iconChecked: "colorPrimary",
                      bar: "colorPrimary",
                    }}
                  />
                }
                classes={{
                  label: "textColorPrimary",
                }}
                label="Débito"
              />
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <div className={classes.block}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.credit}
                    onChange={(event) => {
                      if (this.me.profile !== "maintenance_manager") {
                        if (
                          this.state.debit === false &&
                          this.state.credit === true &&
                          this.state.voucher === false
                        ) {
                          this.setState({
                            debit: true,
                            credit: true,
                            voucher: true,
                          });
                        } else {
                          this.setState({
                            credit: !this.state.credit,
                          });
                        }
                      }
                    }}
                    value={this.state.credit}
                    classes={{
                      switchBase: classes.switchBase ? classes.switchBase : "",
                      checked: classes.switchChecked ? classes.switchChecked : "",
                      icon: "colorPrimary",
                      iconChecked: "colorPrimary",
                      bar: "colorPrimary",
                    }}
                  />
                }
                classes={{
                  label: "textColorPrimary",
                }}
                label="Crédito"
              />
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <div className={classes.block}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.voucher}
                    onChange={(event) => {
                      if (this.me.profile !== "maintenance_manager") {
                        if (
                          this.state.debit === false &&
                          this.state.credit === false &&
                          this.state.voucher === true
                        ) {
                          this.setState({
                            debit: true,
                            credit: true,
                            voucher: true,
                          });
                        } else {
                          this.setState({
                            voucher: !this.state.voucher,
                          });
                        }
                      }
                    }}
                    value={this.state.voucher}
                    classes={{
                      switchBase: classes.switchBase ? classes.switchBase : "",
                      checked: classes.switchChecked ? classes.switchChecked : "",
                      icon: "colorPrimary",
                      iconChecked: "colorPrimary",
                      bar: "colorPrimary",
                    }}
                  />
                }
                classes={{
                  label: "textColorPrimary",
                }}
                label="Sodexo"
              />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  renderFormTabs() {
    return (
      <CustomTabs
        title=""
        headerColor="primary"
        tabs={[
          {
            tabName: "Cadastro",
            tabIcon: Settings,
            tabContent: (
              <div>
                {this.renderGeneralFields()}
                {this.renderFormButtons()}
              </div>
            ),
          },

          {
            tabName: "SAP",
            tabIcon: Dns,
            tabContent: (
              <div>
                {this.renderSapFields()}
                {this.renderFormButtons()}
              </div>
            ),
          },
          {
            tabName: "Localização",
            tabIcon: EditLocation,
            tabContent: (
              <div>
                {this.renderLocalizationFields()}
                {this.renderFormButtons()}
              </div>
            ),
          },
          {
            tabName: "Clientes",
            tabIcon: Group,
            tabContent: (
              <div>
                {this.renderFormCustumer()}
                {this.renderFormButtons()}
              </div>
            ),
          },
          {
            tabName: "Pagamento",
            tabIcon: Payment,
            tabContent: (
              <div>
                {this.renderFormPayment()}
                {this.renderFormButtons()}
              </div>
            ),
          },

          {
            tabName: "Contrato",
            tabIcon: ContractList,
            tabContent: <div>{this.renderContractCancelationButtons()}</div>,
          },
        ]}
      />
    );
  }

  render() {
    const { classes } = this.props;
    // console.log('render(): ', this.props)
    // console.log("render() state: ", this.state);

    return (
      <GridContainer justify="center">
        {this.state.loading ? <Loader /> : null}
        <GridItem xs={12} sm={12} md={12}>
          {/*this.renderForm()*/}
          {this.renderFormTabs()}
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('mapStateToProps: ', state)
  return {
    HideForm: state.fridges.HideForm,
    fridge: state.fridges.fridge,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      HideForm,
      save,
      showNotification,
      contractCancelation,
    },
    dispatch
  );
const ReturnClassDecorated = withStyles(styles)(ValidationForms);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnClassDecorated);
