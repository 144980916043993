import React from "react";
import socketIOClient from "socket.io-client";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import consts from "../../consts";
import { showNotification } from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInputCep from "components/CustomInput/CustomInputCep.jsx";

import PictureUpload from "components/CustomUpload/PictureUpload.jsx";

import Loader from "components/Loader/Loader.jsx";

// material ui icons
// import MailOutline from "@material-ui/icons/MailOutline";
// import Contacts from "@material-ui/icons/Contacts";
// import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
// import CategoryIcon from "@material-ui/icons/Category"
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomInputCPF from "components/CustomInput/CustomInputCPF.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import TableWBorder from "components/Table/TableWBorder.jsx";

import If from "../../common/operators/If";

// style for this view
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";

const styles = {
  ...validationFormsStyle,
  ...extendedTablesStyle,
};

class ValidationForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showSummary: false,
      transporte: "",
      // transporte: "0000443365",
      transportConference: null,
      driver_cod: "",
      drivers: [],
    };
    // this.startButtonClick();
  }

  getDrivers() {
    this.setState({ loading: true });

    axios
      .get(`${consts.API_URL}/transport-conferences/drivers`)
      .then((resp) => this.setState({ drivers: resp.data, loading: false }))
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // console.log(err);
      });
  }

  startButtonClick() {
    this.setState({ loading: true });
    const { transporte } = this.state;
    axios
      .post(`${consts.API_URL}/transport-conferences/cancel`, { transporte })
      .then((resp) => {
        this.setState({
          transportConference: resp.data,
          loading: false,
          driver_cod: resp.data.driver_cod,
        });
        this.getDrivers();
      })
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // console.log(err);
      });
  }

  finishButtonClick() {
    if (!this.state.showSummary) {
      this.setState({ showSummary: true });
      return;
    }

    const that = this;

    this.setState({ loading: true });
    const { transporte } = this.state;
    axios
      .put(`${consts.API_URL}/transport-conferences/${this.state.transportConference.id}/finish/cancel`)
      .then((resp) => {
        let configNotification = {
          message: "Conferêcia finalizada com sucesso.",
          color: "success",
        };
        this.props.showNotification(configNotification);
        this.setState({ loading: false, transportConference: null });
        that.cancel();
      })
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // console.log(err);
      });
  }

  cancel() {
    if (this.state.showSummary) {
      this.setState({ showSummary: false });
      return;
    }

    this.setState({
      loading: false,
      transporte: "",
      transportConference: null,
      driver_cod: "",
    });
  }

  addReturnedCX(item) {
    let { verified_cx_qtd } = item;

    verified_cx_qtd = verified_cx_qtd + 1;

    this.setState({ loading: true });
    axios
      .put(`${consts.API_URL}/transport-conferences/returned-products/${item.id}`, {
        verified_cx_qtd,
      })
      .then((resp) => this.setState({ transportConference: resp.data, loading: false }))
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // console.log(err);
      });
  }

  removeReturnedCX(item) {
    let { verified_cx_qtd } = item;

    if (verified_cx_qtd >= 1) {
      verified_cx_qtd = verified_cx_qtd - 1;
    } else {
      return;
    }

    this.setState({ loading: true });
    axios
      .put(`${consts.API_URL}/transport-conferences/returned-products/${item.id}`, {
        verified_cx_qtd,
      })
      .then((resp) => this.setState({ transportConference: resp.data, loading: false }))
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // console.log(err);
      });
  }

  addReturnedUN(item) {
    let { verified_un_qtd } = item;

    verified_un_qtd = verified_un_qtd + 1;

    this.setState({ loading: true });
    axios
      .put(`${consts.API_URL}/transport-conferences/returned-products/${item.id}`, {
        verified_un_qtd,
      })
      .then((resp) => this.setState({ transportConference: resp.data, loading: false }))
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // console.log(err);
      });
  }

  removeReturnedUN(item) {
    let { verified_un_qtd } = item;

    if (verified_un_qtd >= 1) {
      verified_un_qtd = verified_un_qtd - 1;
    } else {
      return;
    }

    this.setState({ loading: true });
    axios
      .put(`${consts.API_URL}/transport-conferences/returned-products/${item.id}`, {
        verified_un_qtd,
      })
      .then((resp) => this.setState({ transportConference: resp.data, loading: false }))
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // console.log(err);
      });
  }

  addCanceledCX(item) {
    let { verified_cx_qtd } = item;

    verified_cx_qtd = verified_cx_qtd + 1;

    this.setState({ loading: true });
    axios
      .put(`${consts.API_URL}/transport-conferences/canceled-products/${item.id}`, {
        verified_cx_qtd,
      })
      .then((resp) => this.setState({ transportConference: resp.data, loading: false }))
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // console.log(err);
      });
  }

  removeCanceledCX(item) {
    let { verified_cx_qtd } = item;

    if (verified_cx_qtd >= 1) {
      verified_cx_qtd = verified_cx_qtd - 1;
    } else {
      return;
    }

    this.setState({ loading: true });
    axios
      .put(`${consts.API_URL}/transport-conferences/canceled-products/${item.id}`, {
        verified_cx_qtd,
      })
      .then((resp) => this.setState({ transportConference: resp.data, loading: false }))
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // console.log(err);
      });
  }

  addCanceledUN(item) {
    let { verified_un_qtd } = item;

    verified_un_qtd = verified_un_qtd + 1;

    this.setState({ loading: true });
    axios
      .put(`${consts.API_URL}/transport-conferences/canceled-products/${item.id}`, {
        verified_un_qtd,
      })
      .then((resp) => this.setState({ transportConference: resp.data, loading: false }))
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // console.log(err);
      });
  }

  removeCanceledUN(item) {
    let { verified_un_qtd } = item;

    if (verified_un_qtd >= 1) {
      verified_un_qtd = verified_un_qtd - 1;
    } else {
      return;
    }

    this.setState({ loading: true });
    axios
      .put(`${consts.API_URL}/transport-conferences/canceled-products/${item.id}`, {
        verified_un_qtd,
      })
      .then((resp) => this.setState({ transportConference: resp.data, loading: false }))
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // console.log(err);
      });
  }

  handleDriver = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ loading: true });

    const data = {
      driver_cod: event.target.value,
      driver_name: this.state.drivers.filter(
        (driver) => driver.driver_cod === event.target.value
      )[0].driver_name,
    };

    axios
      .put(`${consts.API_URL}/transport-conferences/${this.state.transportConference.id}`, data)
      .then((resp) => {
        this.setState({ transportConference: resp.data, loading: false });
        let configNotification = {
          message: "Motorista alterado com sucesso.",
          color: "success",
        };
        this.props.showNotification(configNotification);
      })
      .catch((err) => {
        // console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message != ""
        ) {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        // console.log(err);
      });
  };

  renderDrivers() {
    const { classes } = this.props;

    return (
      <div>
        <GridItem xs={12} sm={12}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Motorista <small>*</small>
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={this.state.driver_cod}
              onChange={this.handleDriver}
              inputProps={{
                name: "driver_cod",
                id: "simple-select",
              }}
            >
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
              >
                Selecione o motorista
              </MenuItem>
              {this.state.drivers.map((route) => {
                return (
                  <MenuItem
                    key={route.driver_cod}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={route.driver_cod}
                  >
                    {route.driver_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>
      </div>
    );
  }

  renderInfo() {
    if (!this.state.transportConference) {
      return null;
    }
    const { classes } = this.props;
    const transportConferencePiclist = this.state.transportConference.TransportConferencePiclists.filter(
      (piclist) => piclist.Piclist && piclist.Piclist.finisher && piclist.Piclist.finisher.name
    );

    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>
            Transporte: {this.state.transportConference.transporte}
          </h4>
          <p>
            Este transporte foi executado por:{" "}
            {transportConferencePiclist.length > 0
              ? transportConferencePiclist[0].Piclist.finisher.name
              : ""}
          </p>
        </CardHeader>
        <CardBody>
          {this.state.transportConference.status === 0 &&
            !this.state.showSummary && (
              <GridContainer justify="center">
                <GridItem xs={12} sm={6}>
                  {this.renderDrivers()}
                </GridItem>
              </GridContainer>
            )}
          {(this.state.transportConference.status !== 0 || this.state.showSummary) && (
            <p>
              Motorista: {this.state.transportConference.driver_cod} -{" "}
              {this.state.transportConference.driver_name}
            </p>
          )}
        </CardBody>
      </Card>
    );
  }

  renderReturnedProducts() {
    if (
      !this.state.transportConference ||
      !this.state.transportConference.TransportConferenceReturnedProducts ||
      this.state.transportConference.TransportConferenceReturnedProducts.length == 0
    ) {
      return null;
    }

    const { classes } = this.props;
    // console.log(classes);
    const products = this.state.showSummary
      ? this.state.transportConference.TransportConferenceReturnedProducts.filter(
          (product) =>
            product.reported_cx_qtd !== product.verified_cx_qtd ||
            product.reported_un_qtd !== product.verified_un_qtd
        )
      : this.state.transportConference.TransportConferenceReturnedProducts;
    const tableData = products.map((product) => {
      const simpleButtonsCX =
        this.state.transportConference.status === 0 && !this.state.showSummary ? (
          <div>
            <Button
              color="danger"
              simple
              className={classes.actionButton}
              justIcon
              onClick={() => this.removeReturnedCX(product)}
            >
              <Remove className={classes.icon} />
            </Button>{" "}
            <Button
              color={
                product.verified_cx_qtd > product.reported_cx_qtd
                  ? "success"
                  : product.verified_cx_qtd < product.reported_cx_qtd
                    ? "danger"
                    : "primary"
              }
              simple={product.verified_cx_qtd === product.reported_cx_qtd}
              size="lg"
              className={classes.actionButton}
              onClick={() => {}}
            >
              {`${product.verified_cx_qtd}`}
            </Button>{" "}
            <Button
              color="success"
              simple
              className={classes.actionButton}
              justIcon
              onClick={() => this.addReturnedCX(product)}
            >
              <Add className={classes.icon} />
            </Button>
          </div>
        ) : (
          <Button
            color={
              product.verified_cx_qtd > product.reported_cx_qtd
                ? "success"
                : product.verified_cx_qtd < product.reported_cx_qtd
                  ? "danger"
                  : "primary"
            }
            simple={product.verified_cx_qtd === product.reported_cx_qtd}
            size="lg"
            className={classes.actionButton}
            onClick={() => {}}
          >
            {this.state.showSummary
              ? product.verified_cx_qtd - product.reported_cx_qtd
              : product.verified_cx_qtd}
          </Button>
        );

      const simpleButtonsUN =
        this.state.transportConference.status === 0 && !this.state.showSummary ? (
          <div>
            <Button
              color="danger"
              simple
              className={classes.actionButton}
              justIcon
              onClick={() => this.removeReturnedUN(product)}
              disabled={product.Product.only_pack === true}
            >
              <Remove className={classes.icon} />
            </Button>{" "}
            <Button
              color={
                product.verified_un_qtd > product.reported_un_qtd
                  ? "success"
                  : product.verified_un_qtd < product.reported_un_qtd
                    ? "danger"
                    : "primary"
              }
              simple={product.verified_un_qtd === product.reported_un_qtd}
              size="lg"
              className={classes.actionButton}
              onClick={() => {}}
              disabled={product.Product.only_pack === true}
            >
              {product.verified_un_qtd}
            </Button>{" "}
            <Button
              color="success"
              simple
              className={classes.actionButton}
              justIcon
              onClick={() => this.addReturnedUN(product)}
              disabled={product.Product.only_pack === true}
            >
              <Add className={classes.icon} />
            </Button>
          </div>
        ) : (
          <Button
            color={
              product.verified_un_qtd > product.reported_un_qtd
                ? "success"
                : product.verified_un_qtd < product.reported_un_qtd
                  ? "danger"
                  : "primary"
            }
            simple={product.verified_un_qtd === product.reported_un_qtd}
            size="lg"
            className={classes.actionButton}
            onClick={() => {}}
            disabled={product.Product.only_pack === true}
          >
            {this.state.showSummary
              ? product.verified_un_qtd - product.reported_un_qtd
              : product.verified_un_qtd}
          </Button>
        );
      return [
        product.Product.name,
        product.Product.sku,
        product.denominador,
        simpleButtonsCX,
        simpleButtonsUN,
      ];
    });
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>Retornos</h4>
          <p>
            Os retornos foram lançados pelo motorista e não possuem nota fiscal. Confira se as
            quantidades aqui descritas conferem com o físico e sinalize as divergências
          </p>
        </CardHeader>
        <CardBody>
          <TableWBorder
            tableHeaderColor="primary"
            tableHead={["Produto", "SKU", "Qnt CX", "Caixas", "Unidades"]}
            tableData={tableData}
            customHeadClassesForCells={[1, 2, 3, 4]}
            customHeadCellClasses={[classes.center, classes.center, classes.center, classes.center]}
            customClassesForCells={[1, 2, 3, 4]}
            customCellClasses={[classes.center, classes.center, classes.center, classes.center]}
            coloredColls={[20]}
            colorsColls={["info"]}
            tableShopping={true}
          />
        </CardBody>
      </Card>
    );
  }

  renderCanceledProducts() {
    if (
      !this.state.transportConference ||
      !this.state.transportConference.TransportConferenceCanceledProducts ||
      this.state.transportConference.TransportConferenceCanceledProducts.length == 0
    ) {
      return null;
    }

    const { classes } = this.props;
    // console.log(classes);

    const products = this.state.showSummary
      ? this.state.transportConference.TransportConferenceCanceledProducts.filter(
          (product) =>
            product.reported_cx_qtd !== product.verified_cx_qtd ||
            product.reported_un_qtd !== product.verified_un_qtd
        )
      : this.state.transportConference.TransportConferenceCanceledProducts;
    const tableData = products.map((product) => {
      const simpleButtonsCX =
        this.state.transportConference.status === 0 && !this.state.showSummary ? (
          <div>
            <Button
              color="danger"
              simple
              className={classes.actionButton}
              justIcon
              onClick={() => this.removeCanceledCX(product)}
            >
              <Remove className={classes.icon} />
            </Button>{" "}
            <Button
              color={
                product.verified_cx_qtd > product.reported_cx_qtd
                  ? "success"
                  : product.verified_cx_qtd < product.reported_cx_qtd
                    ? "danger"
                    : "primary"
              }
              simple={product.verified_cx_qtd === product.reported_cx_qtd}
              size="lg"
              className={classes.actionButton}
              onClick={() => {}}
            >
              {`${product.verified_cx_qtd}`}
            </Button>{" "}
            <Button
              color="success"
              simple
              className={classes.actionButton}
              justIcon
              onClick={() => this.addCanceledCX(product)}
            >
              <Add className={classes.icon} />
            </Button>
          </div>
        ) : (
          <Button
            color={
              product.verified_cx_qtd > product.reported_cx_qtd
                ? "success"
                : product.verified_cx_qtd < product.reported_cx_qtd
                  ? "danger"
                  : "primary"
            }
            simple={product.verified_cx_qtd === product.reported_cx_qtd}
            size="lg"
            className={classes.actionButton}
            onClick={() => {}}
          >
            {this.state.showSummary
              ? product.verified_cx_qtd - product.reported_cx_qtd
              : product.verified_cx_qtd}
          </Button>
        );
      const simpleButtonsUN =
        this.state.transportConference.status === 0 && !this.state.showSummary ? (
          <div>
            <Button
              color="danger"
              simple
              className={classes.actionButton}
              justIcon
              onClick={() => this.removeCanceledUN(product)}
              disabled={product.Product.only_pack === true}
            >
              <Remove className={classes.icon} />
            </Button>{" "}
            <Button
              color={
                product.verified_un_qtd > product.reported_un_qtd
                  ? "success"
                  : product.verified_un_qtd < product.reported_un_qtd
                    ? "danger"
                    : "primary"
              }
              simple={product.verified_un_qtd === product.reported_un_qtd}
              size="lg"
              className={classes.actionButton}
              onClick={() => {}}
              disabled={product.Product.only_pack === true}
            >
              {product.verified_un_qtd}
            </Button>{" "}
            <Button
              color="success"
              simple
              className={classes.actionButton}
              justIcon
              onClick={() => this.addCanceledUN(product)}
              disabled={product.Product.only_pack === true}
            >
              <Add className={classes.icon} />
            </Button>
          </div>
        ) : (
          <Button
            color={
              product.verified_un_qtd > product.reported_un_qtd
                ? "success"
                : product.verified_un_qtd < product.reported_un_qtd
                  ? "danger"
                  : "primary"
            }
            simple={product.verified_un_qtd === product.reported_un_qtd}
            size="lg"
            className={classes.actionButton}
            onClick={() => {}}
            disabled={product.Product.only_pack === true}
          >
            {this.state.showSummary
              ? product.verified_un_qtd - product.reported_un_qtd
              : product.verified_un_qtd}
          </Button>
        );

      return [
        product.Product.name,
        product.Product.sku,
        product.denominador,
        simpleButtonsCX,
        simpleButtonsUN,
      ];
    });
    return (
      <Card>
        <CardHeader>
          <h4 className={classes.cardIconTitle}>Cancelamentos</h4>
          <p>
            Os cancelamentos são referentes a geladeiras que não foram abastecidas. Recolha as notas
            fiscais{" "}
            {this.state.transportConference.TransportConferencePiclists.filter(
              (piclist) => piclist.Piclist.status === 2
            )
              .map((piclist) => piclist.Piclist.nf)
              .join(", ")}{" "}
            e confira as quantidades abaixo, sinalizando a divergencias.
          </p>
        </CardHeader>
        <CardBody>
          <TableWBorder
            tableHeaderColor="primary"
            tableHead={["Produto", "SKU", "Qnt CX", "Caixas", "Unidades"]}
            tableData={tableData}
            customHeadClassesForCells={[1, 2, 3, 4]}
            customHeadCellClasses={[classes.center, classes.center, classes.center, classes.center]}
            customClassesForCells={[1, 2, 3, 4]}
            customCellClasses={[classes.center, classes.center, classes.center, classes.center]}
            coloredColls={[20]}
            colorsColls={["info"]}
            tableShopping={true}
          />
        </CardBody>
      </Card>
    );
  }

  renderForm() {
    return (
      <GridItem xs={12} sm={12} md={8}>
        <Card>
          <CardHeader color="warning" icon>
            <CardIcon color="warning">
              <AssignmentIcon />
            </CardIcon>
            {/* <h4 className={classes.cardIconTitle}>{this.state.id ? "Editar" : "Cadastrar"}</h4> */}
          </CardHeader>
          <CardBody>
            <form>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <GridItem xs={12}>
                    <CustomInput
                      success={this.state.transporteState === "success"}
                      error={this.state.transporteState === "error"}
                      labelText={<span>Transporte</span>}
                      id="transporte"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) => this.setState({ transporte: event.target.value }),
                        value: this.state.transporte,
                        type: "text",
                        placeholder: "Transporte",
                      }}
                    />
                  </GridItem>
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
          <CardFooter>
            <div />
            <Button color="primary" onClick={() => this.startButtonClick()}>
              Iniciar
            </Button>
          </CardFooter>
        </Card>
      </GridItem>
    );
  }

  renderFormFinish() {
    return (
      <Card>
        <CardFooter>
          {/* <GridContainer justify="center"> */}
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Button fullWidth onClick={() => this.cancel()}>
                Voltar
              </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} />
            {this.state.transportConference.status === 0 && (
              <GridItem xs={12} sm={12} md={4}>
                <Button fullWidth color="primary" onClick={() => this.finishButtonClick()}>
                  Finalizar
                </Button>
              </GridItem>
            )}
          </GridContainer>
        </CardFooter>
      </Card>
    );
  }

  render() {
    const { classes } = this.props;
    // console.log('render(): ', this.props)
    // console.log("render(): ", this.state);

    return (
      <div>
        {this.state.loading && <Loader />}

        <GridContainer justify="center">
          {(!this.state.transportConference || !this.state.transportConference.id) &&
            this.renderForm()}

          {this.state.transportConference &&
            this.state.transportConference.id && (
              <GridItem xs={12} sm={12} md={12}>
                {this.renderInfo()}
                {/* {this.renderReturnedProducts()} */}
                {this.renderCanceledProducts()}
                {this.renderFormFinish()}
              </GridItem>
            )}
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('mapStateToProps: ', state)
  return {
    HideForm: state.piclists.HideForm,
    piclist: state.piclists.piclist,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showNotification,
    },
    dispatch
  );
const ReturnClassDecorated = withStyles(styles)(ValidationForms);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnClassDecorated);
