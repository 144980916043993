const INITIAL_PLANOGRAM = {
  name: '',
}
const INITIAL_STATE = {
    showForm: false,
    showProducts: false,
    planogram: INITIAL_PLANOGRAM
}

export default (state = INITIAL_STATE, action) => {
    // console.log(INITIAL_PLANOGRAM)
    // console.log(state)
    // console.log(action)
    switch (action.type) {
        case 'PLANOGRAMS_SHOW_FORM':
            return { ...state, showForm: true, planogram: (action.payload === undefined) ? INITIAL_PLANOGRAM : action.payload }
        case 'PLANOGRAMS_HIDE_FORM':
            return { ...state, showForm: false, planogram: INITIAL_PLANOGRAM }
        case 'PLANOGRAMS_SHOW_PRODUCTS':
            return { ...state, showProducts: true, planogram: (action.payload === undefined) ? INITIAL_PLANOGRAM : action.payload }
        case 'PLANOGRAMS_HIDE_PRODUCTS':
            return { ...state, showProducts: false, planogram: INITIAL_PLANOGRAM }
        default:
            return state
    }
}
