import React from "react";

// react component for creating dynamic tables
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import axios from "axios";
import socketIOClient from "socket.io-client";
import consts from "../../consts";

import If from "../../common/operators/If";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import Loader from "components/Loader/Loader.jsx";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import {
//   ShowForm,
//   showNotification,
//   save,
//   // get
// } from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import CategoryIcon from "@material-ui/icons/Category";
import DownloadIcon from "@material-ui/icons/GetApp";
import WarningIcon from "@material-ui/icons/Warning";
import NotaFistalIcon from "@material-ui/icons/InsertDriveFile";
import ViewIcon from "@material-ui/icons/Visibility";

import CheckIcon from "@material-ui/icons/Check";
import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import { dataTable } from "variables/general.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      data: [],
      originalData: [],
      pages: -1,
      loading: true,
      alert: null,
      show: false,
      piclist_id: null,
    };
  }

  componentDidMount() {
    this.socketConnect();
  }

  socketConnect() {
    const that = this;
    const socket = socketIOClient(consts.SOCKET_URL, {
      query: { app: "admin" },
    });
    // socket.on("connect", () => console.log("connected"));
    // socket.on("teste", (data) => console.log("data: ", data));
    socket.on("PiclistSapControllerCreated", (resp) => {
      // console.log("resp: ", resp);
      // console.log("state: ", that.state);
      if (resp.piclist_id == that.state.piclist_id) {
        const configNotification = {
          message: resp.message,
          color: resp.status == 200 ? "success" : "danger",
        };
        this.props.showNotification(configNotification);
        that.setState({
          loading: false,
          piclist_id: null,
        });
      }
    });
    this.setState({ socket });
  }

  execSap(piclist_id) {
    // console.log("sap");
    // console.log("execSap(): ", piclist_id);
    // return false;
    this.setState({ loading: true, piclist_id });
    axios
      .post(`${consts.API_URL}/piclists/${piclist_id}/sap`)
      .then((resp) => {
        // console.log("resp: ", resp);
        const configNotification = {
          message: resp.data.message,
          color: "info",
        };
        this.props.showNotification(configNotification);
        // this.getSapLogs();
        // const respFiltered = resp.data.result.filter((item => (item.status == 1)))
        // console.log('respFiltered: ', respFiltered)

        // this.setState({ reportData: resp.data });
      })
      .catch((err) => {
        console.log(err.response);
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (err.response && err.response.data && err.response.data.message && err.response.data.message != "") {
          configNotification = {
            message: err.response.data.message,
            color: "danger",
          };
        }
        this.props.showNotification(configNotification);
        this.setState({ loading: false });
        console.log(err);
      });
  }

  // componentWillMount(){
  // this.get();
  // }

  // componentDidMount(){
  // this.getReportData();
  // }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  add() {
    const that = this;
    axios
      .post(`${consts.API_URL}/fridges/${this.props.fridgeId}/piclists`)
      .then((resp) => {
        // console.log('then')
        // console.log(resp)
        let configNotification = {
          message: "PicList criada com sucesso.",
          color: "success",
        };
        that.props.showNotification(configNotification);
        that.get();
      })
      .catch((e) => {
        // console.log('catch')
        // console.log(e)
        // console.log(e.response)
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (e.response && e.response.data && e.response.data.errors && e.response.data.errors > 0) {
          e.response.data.errors.forEach((error) => {
            // toastr.error('Erro', error))
            configNotification = {
              message: error,
              color: "danger",
            };
          });
        } else if (e.response && e.response.data && e.response.data.message) {
          configNotification = {
            message: e.response.data.message,
            color: "danger",
          };
        } else {
          // toastr.error('Erro', 'Erro inesperado.')
          configNotification = {
            message: "Erro inesperado.",
            color: "danger",
          };
        }

        this.props.showNotification(configNotification);
      });
  }

  // renderDownloadXLS(){
  //   return (
  //       <Button
  //         justIcon
  //         round
  //         simple
  //         onClick={() => {}}
  //         color="info"
  //         className="edit"
  //         >
  //         <DownloadIcon />
  //       </Button>
  //   )
  // }

  supply(item) {
    // console.log(item)
    const that = this;
    axios
      .put(`${consts.API_URL}/fridges/${this.props.fridgeId}/piclists/${item.id}/supply`)
      .then((resp) => {
        // console.log('then')
        // console.log(resp)
        let configNotification = {
          message: "PicList executada com sucesso.",
          color: "success",
        };
        that.props.showNotification(configNotification);
        that.get();
      })
      .catch((e) => {
        // console.log('catch')
        // console.log(e)
        // console.log(e.response)
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (e.response && e.response.data && e.response.data.errors && e.response.data.errors > 0) {
          e.response.data.errors.forEach((error) => {
            // toastr.error('Erro', error))
            configNotification = {
              message: error,
              color: "danger",
            };
          });
        } else if (e.response && e.response.data && e.response.data.message) {
          configNotification = {
            message: e.response.data.message,
            color: "danger",
          };
        } else {
          // toastr.error('Erro', 'Erro inesperado.')
          configNotification = {
            message: "Erro inesperado.",
            color: "danger",
          };
        }

        this.props.showNotification(configNotification);
      });
  }

  cancel(item) {
    // console.log(item)
    const that = this;
    axios
      .put(`${consts.API_URL}/fridges/${this.props.fridgeId}/piclists/${item.id}/cancel`)
      .then((resp) => {
        // console.log('then')
        // console.log(resp)
        let configNotification = {
          message: "PicList cancelada com sucesso.",
          color: "success",
        };
        that.props.showNotification(configNotification);
        that.get();
      })
      .catch((e) => {
        // console.log('catch')
        // console.log(e)
        // console.log(e.response)
        let configNotification = {
          message: "Erro inesperado.",
          color: "danger",
        };
        if (e.response && e.response.data && e.response.data.errors && e.response.data.errors > 0) {
          e.response.data.errors.forEach((error) => {
            // toastr.error('Erro', error))
            configNotification = {
              message: error,
              color: "danger",
            };
          });
        } else if (e.response && e.response.data && e.response.data.message) {
          configNotification = {
            message: e.response.data.message,
            color: "danger",
          };
        } else {
          // toastr.error('Erro', 'Erro inesperado.')
          configNotification = {
            message: "Erro inesperado.",
            color: "danger",
          };
        }

        this.props.showNotification(configNotification);
      });
  }

  setData(result) {
    var data = result.map((item) => {
      // console.log(item);
      return {
        id: item.id,
        date: (
          <div>{item.createdAt ? new Date(item.createdAt).toLocaleString("pt-BR") : ""}</div>
          // item.createdAt
        ),
        status: (
          <div>
            {item.status == 0 ? (
              <div>
                <Button justIcon round simple onClick={() => {}} color="warning" className="edit" title="Aberto">
                  <WarningIcon />
                </Button>{" "}
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    if (item.nf == false) {
                      this.execSap(item.id);
                    }
                  }}
                  color={item.nf == true ? "success" : "danger"}
                  className="edit"
                  title={item.nf == true ? "Nota fiscal emitida" : "Nota fiscal não emitida"}
                >
                  <NotaFistalIcon />
                </Button>{" "}
              </div>
            ) : (
              ""
            )}
            {item.status == 1 ? (
              <div>
                <Button justIcon round simple onClick={() => {}} color="success" className="edit" title="Aprovado">
                  <CheckIcon />
                </Button>{" "}
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    if (item.nf == false) {
                      this.execSap(item.id);
                    }
                  }}
                  color={item.nf == true ? "success" : "danger"}
                  className="edit"
                  title={item.nf == true ? "Nota fiscal emitida" : "Nota fiscal não emitida"}
                >
                  <NotaFistalIcon />
                </Button>{" "}
              </div>
            ) : (
              ""
            )}
            {item.status == 2 ? (
              <div>
                <Button justIcon round simple onClick={() => {}} color="danger" className="remove" title="Cancelado">
                  <Close />
                </Button>{" "}
              </div>
            ) : (
              ""
            )}
          </div>
        ),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {item.status === 0 ? (
              <div>
                <Button justIcon round simple onClick={() => this.supply(item)} color="success" className="edit">
                  <CheckIcon />
                </Button>{" "}
                <Button justIcon round simple onClick={() => this.cancel(item)} color="danger" className="remove">
                  <Close />
                </Button>{" "}
                <Button justIcon round simple onClick={() => this.props.ShowForm(item)} color="primary" className="edit">
                  <ViewIcon />
                </Button>{" "}
                {this.renderDownloadXLS(item)}{" "}
              </div>
            ) : (
              <div>
                <Button justIcon round simple onClick={() => this.props.ShowForm(item)} color="primary" className="edit">
                  <ViewIcon />
                </Button>{" "}
                {this.renderDownloadXLS(item)}
              </div>
            )}
          </div>
        ),
      };
    });

    this.setState({ data });
  }

  // get(){
  // axios.get(`${consts.API_URL}/fridges`)
  // .then(resp => {
  // console.log(resp)
  // this.setData(resp.data.result)
  // })
  // }

  // getReportData(){
  // axios.get(`${consts.API_URL}/fridges/report`)
  // .then(resp => {
  // console.log(resp)
  // this.setState({reportData: resp.data})
  // })
  // }

  renderDownloadXLS(item) {
    // console.log(item)
    const { classes } = this.props;
    // console.log('renderDownloadXLS')
    // return Promise.all([
    // ])

    // console.log(report)
    return (
      <ExcelFile
        element={
          <Button justIcon round simple color="primary" className="edit">
            <DownloadIcon />
          </Button>
        }
      >
        <ExcelSheet data={item.piclistProducts} name="Produtos">
          <ExcelColumn
            label="Nome"
            value={(col) => {
              // console.log(col)
              return col.Product.name;
            }}
          />
          <ExcelColumn
            label="SKU"
            value={(col) => {
              // console.log(col)
              return col.Product.sku;
            }}
          />
          <ExcelColumn label="Canaleta" value="channel" />
          <ExcelColumn label="Quantidade" value="qtd" />
        </ExcelSheet>
      </ExcelFile>
    );
  }

  get() {
    const { sorted, order, filtered, page, pageSize } = this.state;
    // const sorted = (state.sorted.length === 0) ? '' : state.sorted[0].id
    // const order = (state.sorted.length > 0 && state.sorted[0].desc) ? 'desc' : 'asc'
    // const filtered = btoa(JSON.stringify(state.filtered))
    axios
      .get(`${consts.API_URL}/fridges/${this.props.fridgeId}/piclists?page=${page + 1}&qtd_per_page=${pageSize}&sorted=${sorted}&order=${order}&filtered=${filtered}`, {
        page,
        pageSize,
        sorted,
        filtered,
      })
      .then((res) => {
        // console.log(res)
        // console.log(state.sorted)
        // console.log(state.filtered)
        // Update react-table
        this.setState(
          {
            pages: res.data.pages,
            loading: false,
            originalData: res.data.result,
          },
          () => {
            this.setData(res.data.result);
          }
        );
      });
  }

  render() {
    const { classes } = this.props;
    console.log(this.state.originalData);
    return (
      <div>
        {this.state.loading ? <Loader /> : null}
        {this.state.alert}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <MaterialIconList />
                </CardIcon>
                <Button color="primary" className={classes.updateProfileButton} onClick={() => this.add()}>
                  Adicionar
                </Button>
                <h4
                  style={{
                    color: "#000",
                  }}
                  className={classes.cardIconTitle}
                >
                  {this.state.originalData && this.state.originalData.length > 0 && this.state.originalData[0].Fridge ? `Local: ${this.state.originalData[0].Fridge.local}` : ""}
                </h4>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={this.state.data}
                  pages={this.state.pages}
                  loading={this.state.loading}
                  filterable={false}
                  columns={[
                    {
                      Header: "Data",
                      accessor: "date",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Ações",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                  defaultPageSize={20}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  previousText="Anterior"
                  nextText="Próxima"
                  loadingText="Carregando"
                  noDataText="Nenhum registro encontrado"
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                  manual
                  onFetchData={(state, instance) => {
                    // show the loading overlay
                    const sorted = state.sorted.length === 0 ? "" : state.sorted[0].id;
                    const order = state.sorted.length > 0 && state.sorted[0].desc ? "desc" : "asc";
                    const filtered = btoa(JSON.stringify(state.filtered));
                    const page = state.page;
                    const pageSize = state.pageSize;
                    this.setState(
                      {
                        loading: true,
                        sorted,
                        order,
                        filtered,
                        page,
                        pageSize,
                      },
                      () => {
                        this.get();
                      }
                    );
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log(state)
//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = (state) => ({
  ShowForm: state.ShowForm,
  ShowPicList: state.ShowPicList,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // ShowForm,
      // showNotification,
      // save,
      // get
    },
    dispatch
  );
const MemberList = withStyles(sweetAlertStyle)(ReactTables);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberList);
