import axios from "axios";
import consts from "../../consts";

export function ShowForm(item) {
  // console.log(item)
  return { type: "FRIDGES_SHOW_FORM", payload: item };
}

export function HideForm() {
  return { type: "FRIDGES_HIDE_FORM" };
}

// export function ShowPicList(item){
//   // console.log(item)
//     return { type: 'FRIDGES_SHOW_PICLIST', payload: item  }
// }
//
// export function HidePicList(){
//     return { type: 'FRIDGES_HIDE_PICLIST' }
// }

// export function get(){
//   return dispatch => {
//       axios.get(`${consts.API_URL}/fridges`)
//       .then(resp => {
//         console.log(resp)
//           dispatch([
//             { type: 'FRIDGES_GET', payload: resp.data }
//           ])
//       })
//     }
// }

export function contractCancelation(fridge) {
  console.log(fridge);

  return (dispatch) => {
    if (fridge && fridge.id) {
      axios
        .post(`${consts.API_URL}/contract-cancellations`, {
          fridge_id: fridge.id,
        })
        .then((resp) => {
          const configNotification = {
            message: "Cancelamento de contrato adicionado com sucesso",
            color: "success",
          };
          dispatch([
            {
              type: "MAIN_SHOW_NOTIFICATION",
              showNotification: true,
              payload: configNotification,
            },
            { type: "FRIDGES_HIDE_FORM" },
          ]);
        })
        .catch((e) => {
          console.log(e);
          console.log(e.response);
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors > 0
          ) {
            e.response.data.errors.forEach((error) => {
              // toastr.error('Erro', error))
              const configNotification = {
                message: error,
                color: "danger",
              };
              dispatch([
                {
                  type: "MAIN_SHOW_NOTIFICATION",
                  showNotification: true,
                  payload: configNotification,
                },
              ]);
            });
          } else if (e.response && e.response.data && e.response.data.message) {
            // console.log('e.response.data.message: ', e.response.data.message)
            // toastr.error('Erro', e.response.data.message)

            const configNotification = {
              message: e.response.data.message,
              color: "danger",
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification,
              },
            ]);
          } else {
            // toastr.error('Erro', 'Erro inesperado.')
            const configNotification = {
              message: "Erro inesperado.",
              color: "danger",
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification,
              },
            ]);
          }
        });
    } else {
      const configNotification = {
        message: "Geladeira inválida",
        color: "danger",
      };
      dispatch([
        {
          type: "MAIN_SHOW_NOTIFICATION",
          showNotification: true,
          payload: configNotification,
        },
        { type: "FRIDGES_HIDE_FORM" },
      ]);
    }
  };
}
export function save(fridge) {
  // console.log(fridge)
  return (dispatch) => {
    if (fridge && fridge.id) {
      axios
        .put(`${consts.API_URL}/fridges/${fridge.id}`, fridge)
        .then((resp) => {
          const configNotification = {
            message: "Geladeira atualizada com sucesso",
            color: "success",
          };
          dispatch([
            {
              type: "MAIN_SHOW_NOTIFICATION",
              showNotification: true,
              payload: configNotification,
            },
            { type: "FRIDGES_HIDE_FORM" },
          ]);
        })
        .catch((e) => {
          console.log(e);
          console.log(e.response);
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors > 0
          ) {
            e.response.data.errors.forEach((error) => {
              // toastr.error('Erro', error))
              const configNotification = {
                message: error,
                color: "danger",
              };
              dispatch([
                {
                  type: "MAIN_SHOW_NOTIFICATION",
                  showNotification: true,
                  payload: configNotification,
                },
              ]);
            });
          } else if (e.response && e.response.data && e.response.data.message) {
            // console.log('e.response.data.message: ', e.response.data.message)
            // toastr.error('Erro', e.response.data.message)

            const configNotification = {
              message: e.response.data.message,
              color: "danger",
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification,
              },
            ]);
          } else {
            // toastr.error('Erro', 'Erro inesperado.')
            const configNotification = {
              message: "Erro inesperado.",
              color: "danger",
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification,
              },
            ]);
          }
        });
    } else {
      axios
        .post(`${consts.API_URL}/fridges`, fridge)
        .then((resp) => {
          // console.log(resp)
          const configNotification = {
            message: "Geladeira cadastrada com sucesso",
            color: "success",
          };
          dispatch([
            {
              type: "MAIN_SHOW_NOTIFICATION",
              showNotification: true,
              payload: configNotification,
            },
            { type: "FRIDGES_HIDE_FORM" },
          ]);
        })
        .catch((e) => {
          console.log(e);
          console.log(e.response);
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors > 0
          ) {
            e.response.data.errors.forEach((error) => {
              // toastr.error('Erro', error))
              const configNotification = {
                message: error,
                color: "danger",
              };
              dispatch([
                {
                  type: "MAIN_SHOW_NOTIFICATION",
                  showNotification: true,
                  payload: configNotification,
                },
              ]);
            });
          } else if (e.response && e.response.data && e.response.data.message) {
            // console.log('e.response.data.message: ', e.response.data.message)
            // toastr.error('Erro', e.response.data.message)

            const configNotification = {
              message: e.response.data.message,
              color: "danger",
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification,
              },
            ]);
          } else {
            // toastr.error('Erro', 'Erro inesperado.')
            const configNotification = {
              message: "Erro inesperado.",
              color: "danger",
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification,
              },
            ]);
          }
        });
    }
  };
}

export function showNotification(configNotification) {
  return {
    type: "MAIN_SHOW_NOTIFICATION",
    showNotification: true,
    payload: configNotification,
  };
}
