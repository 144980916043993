// import { toastr } from 'react-redux-toastr'
import axios from "axios";
import consts from "../../consts";

export function login(values) {
  // console.log(values)
  var newValues = { ...values };
  // newValues.grant_type = "password"
  // newValues.client_id = "2"
  // newValues.client_secret = "yzpJ4bd8TLh2LDVDSQJREASO1DdWQdFLzGsydp2i"
  return submit(newValues, `${consts.OAPI_URL}/auth`);
}

export function signup(values) {
  return submit(values, `${consts.OAPI_URL}/signup`);
}

export function recovery(values) {
  const url = `${consts.API_URL}/recovery`;
  return (dispatch) => {
    axios
      .post(url, values)
      .then((resp) => {
        // toastr.success('Sucesso', resp.data.message)
        const configNotification = {
          message: resp.data.message,
          color: "success",
        };
        dispatch([
          { type: "PASSWORD_RECOVERED" },
          {
            type: "MAIN_SHOW_NOTIFICATION",
            showNotification: true,
            payload: configNotification,
          },
        ]);
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors > 0
        ) {
          e.response.data.errors.forEach((error) => {
            // toastr.error('Erro', error))
            const configNotification = {
              message: error,
              color: "danger",
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification,
              },
            ]);
          });
        } else if (e.response && e.response.data && e.response.data.message) {
          // console.log('e.response.data.message: ', e.response.data.message)
          // toastr.error('Erro', e.response.data.message)

          const configNotification = {
            message: e.response.data.message,
            color: "danger",
          };
          dispatch([
            {
              type: "MAIN_SHOW_NOTIFICATION",
              showNotification: true,
              payload: configNotification,
            },
          ]);
        } else {
          // toastr.error('Erro', 'Erro inesperado.')
          const configNotification = {
            message: "Erro inesperado.",
            color: "danger",
          };
          dispatch([
            {
              type: "MAIN_SHOW_NOTIFICATION",
              showNotification: true,
              payload: configNotification,
            },
          ]);
        }
      });
  };
}

function submit(values, url) {
  // console.log('function submit values: ',values)
  // console.log('function submit url: ',url)

  return (dispatch) => {
    axios
      .post(url, values)
      .then((resp) => {
        // console.log(resp.data);
        var user = resp.data;

        var token = `Bearer ${resp.data.token}`;
        // console.log(token)
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.defaults.headers.common["Authorization"] = token;
        dispatch([
          { type: "USER_FETCHED", payload: { ...resp.data, user: resp.data } },
        ]);

        // axios.get(`${consts.API_URL}/user`)
        //     .then(resp => {
        //         console.log(resp)
        //         // user.token = token
        //     })
      })
      .catch((e) => {
        setTimeout(
          function() {
            dispatch([
              { type: "MAIN_HIDE_NOTIFICATION", showNotification: false },
            ]);
          }.bind(this),
          6000
        );

        // showNotification({
        //   message: "teste",
        //   color: "success"
        // })
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors > 0
        ) {
          e.response.data.errors.forEach((error) => {
            // toastr.error('Erro', error))
            const configNotification = {
              message: error,
              color: "danger",
            };
            dispatch([
              {
                type: "MAIN_SHOW_NOTIFICATION",
                showNotification: true,
                payload: configNotification,
              },
            ]);
          });
        } else if (e.response && e.response.data && e.response.data.message) {
          // console.log('e.response.data.message: ', e.response.data.message)
          // toastr.error('Erro', e.response.data.message)

          const configNotification = {
            message: e.response.data.message,
            color: "danger",
          };
          dispatch([
            {
              type: "MAIN_SHOW_NOTIFICATION",
              showNotification: true,
              payload: configNotification,
            },
          ]);
        } else {
          // toastr.error('Erro', 'Erro inesperado.')
          const configNotification = {
            message: "Erro inesperado.",
            color: "danger",
          };
          dispatch([
            {
              type: "MAIN_SHOW_NOTIFICATION",
              showNotification: true,
              payload: configNotification,
            },
          ]);
        }
      });
  };
}

export function logout() {
  return { type: "TOKEN_VALIDATED", payload: false };
}

export function validateToken(token) {
  return (dispatch) => {
    if (token) {
      dispatch({ type: "TOKEN_VALIDATED", payload: true });
      // axios.post(`${consts.OAPI_URL}/validateToken`, { token })
      //     .then(resp => {
      //         dispatch({ type: 'TOKEN_VALIDATED', payload: resp.data.valid })
      //     })
      //     .catch(e => dispatch({ type: 'TOKEN_VALIDATED', payload: false }))
    } else {
      dispatch({ type: "TOKEN_VALIDATED", payload: false });
    }
  };
}
