import axios from 'axios'
import consts from '../../consts'

export function ShowForm(item){
    return { type: 'PLANOGRAMS_SHOW_FORM', payload: item  }
}

export function HideForm(){
    return { type: 'PLANOGRAMS_HIDE_FORM' }
}

export function ShowProducts(item){
  // console.log(item)
    return { type: 'PLANOGRAMS_SHOW_PRODUCTS', payload: item  }
}

export function HideProducts(){
    return { type: 'PLANOGRAMS_HIDE_PRODUCTS' }
}

export function save(planogram){
  // console.log(planogram)
  return dispatch => {
    if(planogram && planogram.id){
      axios.put(`${consts.API_URL}/planograms/${planogram.id}`, planogram)
      .then(resp => {
        // console.log('resp: ', resp)
        const configNotification = {
          message: 'Planograma atualizado com sucesso',
          color: 'success'
        }
        dispatch([
          { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification },
          { type: 'PLANOGRAMS_HIDE_FORM' },
        ])
      })
      .catch(e => {
        console.log(e)
        console.log(e.response)
        if(e.response && e.response.data && e.response.data.errors && e.response.data.errors  > 0){
            e.response.data.errors.forEach(
                error => {
                  // toastr.error('Erro', error))
                  const configNotification = {
                        message: error,
                        color: 'danger'
                      }
                  dispatch([
                      { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification }
                  ])
            })
        }else if(e.response && e.response.data && e.response.data.message){
            // console.log('e.response.data.message: ', e.response.data.message)
            // toastr.error('Erro', e.response.data.message)

            const configNotification = {
                  message: e.response.data.message,
                  color: 'danger'
                }
            dispatch([
                { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification }
            ])
        }else{
            // toastr.error('Erro', 'Erro inesperado.')
            const configNotification = {
                  message: 'Erro inesperado.',
                  color: 'danger'
                }
            dispatch([
                { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification }
            ])
        }

      })
    }else{
      axios.post(`${consts.API_URL}/planograms`, planogram)
      .then(resp => {
        // console.log(resp)
        const configNotification = {
          message: 'Planograma cadastrado com sucesso',
          color: 'success'
        }
        dispatch([
          { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification },
          { type: 'PLANOGRAMS_HIDE_FORM' }
        ])
      })
      .catch(e => {
        console.log(e)
        console.log(e.response)
        if(e.response && e.response.data && e.response.data.errors && e.response.data.errors  > 0){
            e.response.data.errors.forEach(
                error => {
                  // toastr.error('Erro', error))
                  const configNotification = {
                        message: error,
                        color: 'danger'
                      }
                  dispatch([
                      { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification }
                  ])
            })
        }else if(e.response && e.response.data && e.response.data.message){
            // console.log('e.response.data.message: ', e.response.data.message)
            // toastr.error('Erro', e.response.data.message)

            const configNotification = {
                  message: e.response.data.message,
                  color: 'danger'
                }
            dispatch([
                { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification }
            ])
        }else{
            // toastr.error('Erro', 'Erro inesperado.')
            const configNotification = {
                  message: 'Erro inesperado.',
                  color: 'danger'
                }
            dispatch([
                { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification }
            ])
        }

      })
    }
  }
}

export function showNotification(configNotification){
  return {type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification }
}
