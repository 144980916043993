import React from "react";

// react component for creating dynamic tables
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import axios from "axios";
import socketIOClient from "socket.io-client";
import consts from "../../../consts";

// import Form from "./Form";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import Loader from "components/Loader/Loader.jsx";

import moment from "moment";
import "moment/locale/pt-br";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ShowForm,
  showNotification,
  // get
} from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import CategoryIcon from "@material-ui/icons/Category";
import DownloadIcon from "@material-ui/icons/GetApp";
import WarningIcon from "@material-ui/icons/Warning";
import NotaFistalIcon from "@material-ui/icons/InsertDriveFile";
import ViewIcon from "@material-ui/icons/Visibility";

import CheckIcon from "@material-ui/icons/Check";
import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInputDate from "components/CustomInput/CustomInputDate.jsx";

import { dataTable } from "variables/general.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      data: [],
      originalData: [],
      date: moment().format("L"),
      pages: -1,
      loading: true,
      alert: null,
      show: false,
      piclist_id: null,
      showFilters: false,
    };
  }

  componentDidMount() {
    this.socketConnect();
  }

  socketConnect() {
    const that = this;
    const socket = socketIOClient(consts.SOCKET_URL, {
      query: { app: "admin" },
    });
    // socket.on("connect", () => console.log("connected"));
    // socket.on("teste", (data) => console.log("data: ", data));
    socket.on("PiclistSapControllerCreated", (resp) => {
      // console.log("resp: ", resp);
      // console.log("state: ", that.state);
      if (resp.piclist_id == that.state.piclist_id) {
        const configNotification = {
          message: resp.message,
          color: resp.status == 200 ? "success" : "danger",
        };
        this.props.showNotification(configNotification);
        that.setState({
          loading: false,
          piclist_id: null,
        });
      }
    });
    this.setState({ socket });
  }

  setData(result) {
    const date = moment(this.state.date, "DD/MM/YYYY").format("YYYY-MM-DD");
    var data = result.map((item) => {
      // console.log(item);
      return {
        id: item.id,
        driver_name: item.driver_name,
        open: item.open,
        total: item.total,
        closed: item.closed,
        audits: item.audits,
        cancelled: item.cancelled,

        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            <Link to={`/dashboards/picking-lists-analytical/${item.driver_cod}/detail/${date}`}>
              <Button justIcon round simple onClick={() => {}} color="primary" className="edit">
                <ViewIcon />
              </Button>
            </Link>
          </div>
        ),
      };
    });

    this.setState({ data });
  }

  get() {
    const { sorted, order, filtered, page, pageSize } = this.state;
    const date = moment(this.state.date, "DD/MM/YYYY").format("YYYY-MM-DD");
    // const sorted = (state.sorted.length === 0) ? '' : state.sorted[0].id
    // const order = (state.sorted.length > 0 && state.sorted[0].desc) ? 'desc' : 'asc'
    // const filtered = btoa(JSON.stringify(state.filtered))
    axios.get(`${consts.API_URL}/dashboards/picking-lists/${date}`).then((res) => {
      // console.log(res)
      // console.log(state.sorted)
      // console.log(state.filtered)
      // Update react-table
      this.setState(
        {
          pages: res.data.pages,
          loading: false,
          originalData: res.data.result,
        },
        () => {
          this.setData(res.data.result);
        }
      );
    });
  }

  renderHeader() {
    const { classes } = this.props;
    return (
      <Card>
        <CardFooter>
          {/* <Button
            color="primary"
            onClick={() => this.setState({ showFilters: !this.state.showFilters })}
          >
            {this.state.showFilters ? "Fechar filtros" : "Mostrar filtros"}
          </Button> */}
          {/* <Link to={`/all-piclists/routines`}> */}
          <CustomInputDate
            success={this.state.dateState === "success"}
            error={this.state.dateState === "error"}
            id="date"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (event) => this.setState({ date: event.target.value }),
              value: this.state.date,
              type: "text",
              placeholder: "Data",
            }}
          />
          <Button color="primary" onClick={() => this.get()}>
            Filtrar
          </Button>
          {/* </Link> */}
        </CardFooter>
      </Card>
    );
  }

  render() {
    const { classes } = this.props;
    // console.log(this.state.originalData);
    return (
      <div>
        {this.state.loading ? <Loader /> : null}
        {this.state.alert}
        {this.renderHeader()}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <MaterialIconList />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={this.state.data}
                  pages={this.state.pages}
                  loading={this.state.loading}
                  filterable={false}
                  columns={[
                    {
                      Header: "Nome",
                      accessor: "driver_name",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Total",
                      accessor: "total",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Abertas",
                      accessor: "open",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Finalizadas",
                      accessor: "closed",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Auditadas",
                      accessor: "audits",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Canceladas",
                      accessor: "cancelled",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Ações",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                  defaultPageSize={50}
                  showPaginationTop={false}
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  previousText="Anterior"
                  nextText="Próxima"
                  loadingText="Carregando"
                  noDataText="Nenhum registro encontrado"
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                  manual
                  onFetchData={(state, instance) => {
                    // show the loading overlay
                    const sorted = state.sorted.length === 0 ? "" : state.sorted[0].id;
                    const order = state.sorted.length > 0 && state.sorted[0].desc ? "desc" : "asc";
                    const filtered = btoa(JSON.stringify(state.filtered));
                    const page = state.page;
                    const pageSize = state.pageSize;
                    this.setState(
                      {
                        loading: true,
                        sorted,
                        order,
                        filtered,
                        page,
                        pageSize,
                      },
                      () => {
                        this.get();
                      }
                    );
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log(state)
//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = (state) => ({
  ShowForm: state.ShowForm,
  ShowPicList: state.ShowPicList,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      showNotification,
    },
    dispatch
  );
const MemberList = withStyles(sweetAlertStyle)(ReactTables);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberList);
