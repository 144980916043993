const INITIAL_PICLIST = {
  gebra: "",
  cod_pinpad: "",
  cep: "",
  logradouro: "",
  complemento: "",
  numero: "",
  bairro: "",
  localidade: "",
  uf: "",
  planogram_id: "",
};
const INITIAL_STATE = {
  showForm: false,
  // showPicLists: false,
  piclist: INITIAL_PICLIST,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(INITIAL_PICLIST)
  // console.log(state)
  // console.log(action)
  switch (action.type) {
    case "PICLISTS_SHOW_FORM":
      return {
        ...state,
        showForm: true,
        piclist:
          action.payload === undefined ? INITIAL_PICLIST : action.payload,
      };
    case "PICLISTS_HIDE_FORM":
      return { ...state, showForm: false, piclist: INITIAL_PICLIST };
    // case 'PICLISTS_SHOW_PICLIST':
    //     return { ...state, showPicLists: true, piclist: (action.payload === undefined) ? INITIAL_PICLIST : action.payload }
    // case 'PICLISTS_HIDE_PICLIST':
    //     return { ...state, showPicLists: false, piclist: INITIAL_PICLIST }
    // case 'PICLISTS_GET':
    //     return { ...state, list: action.payload }
    // case 'PICLISTS_GET_CLIENT':
    //   return { ...state, cliente: action.payload }
    // case 'PICLISTS_GET_USER':
    //   return { ...state, piclist: {...state.piclist, name: action.payload.NOME, vendedor: action.payload.VENDEDOR}}
    default:
      return state;
  }
};
