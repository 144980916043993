import React from "react";

import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { Link } from "react-router-dom";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const items = [
  {
    to: "/equipments",
    label: "Equipamentos",
  },
  {
    to: "/equipments/statuses",
    label: "Status",
  },
  {
    to: "/equipments/return-reasons",
    label: "Motivos de retorno",
  },
  {
    to: "/equipments/confirm",
    label: "Confirmação de recebimento",
  },
];

export default class EquipmentMenu extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Card>
        <CardBody>
          {items.map((item) => (
            <Link key={item.to} to={item.to}>
              <Button color={item.to === this.props.to ? "primary" : null} size="sm">
                {item.label}
              </Button>
            </Link>
          ))}
        </CardBody>
      </Card>
    );
  }
}
