const INITIAL_PRODUCT_RETURN = {
  name: "",
  sku: "",
  image: "",
};
const INITIAL_STATE = {
  showForm: false,
  showFormReport: false,
  product_return: INITIAL_PRODUCT_RETURN,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(INITIAL_PRODUCT_RETURN)
  // console.log(state)
  // console.log(action)
  switch (action.type) {
    case "PRODUCT_RETURNS_SHOW_FORM":
      return {
        ...state,
        showForm: true,
        showFormReport: false,
        product_return: action.payload === undefined ? INITIAL_PRODUCT_RETURN : action.payload,
      };
    case "PRODUCT_RETURNS_HIDE_FORM":
      return {
        ...state,
        showForm: false,
        showFormReport: false,
        product_return: INITIAL_PRODUCT_RETURN,
      };
    case "PRODUCT_RETURNS_SHOW_FORM_REPORT":
      return {
        ...state,
        showForm: false,
        showFormReport: true,
        product_return: INITIAL_PRODUCT_RETURN,
      };
    case "PRODUCT_RETURNS_HIDE_FORM_REPORT":
      return {
        ...state,
        showForm: false,
        showFormReport: false,
        product_return: INITIAL_PRODUCT_RETURN,
      };
    case "PRODUCT_RETURNS_GET":
      return { ...state, list: action.payload };
    case "PRODUCT_RETURNS_GET_CLIENT":
      return { ...state, cliente: action.payload };
    case "PRODUCT_RETURNS_GET_PRODUCT_RETURN":
      return {
        ...state,
        product_return: {
          ...state.product_return,
          name: action.payload.NOME,
          vendedor: action.payload.VENDEDOR,
        },
      };
    default:
      return state;
  }
};
