const INITIAL_ORDER = {
  name: "",
  sku: "",
  image: ""
};
const INITIAL_STATE = {
  showForm: false,
  showFormReport: false,
  order: INITIAL_ORDER
};

export default (state = INITIAL_STATE, action) => {
  // console.log(INITIAL_ORDER)
  // console.log(state)
  // console.log(action)
  switch (action.type) {
    case "ORDERS_SHOW_FORM":
      return {
        ...state,
        showForm: true,
        showFormReport: false,
        order: action.payload === undefined ? INITIAL_ORDER : action.payload
      };
    case "ORDERS_HIDE_FORM":
      return {
        ...state,
        showForm: false,
        showFormReport: false,
        order: INITIAL_ORDER
      };
    case "ORDERS_SHOW_FORM_REPORT":
      return {
        ...state,
        showForm: false,
        showFormReport: true,
        order: INITIAL_ORDER
      };
    case "ORDERS_HIDE_FORM_REPORT":
      return {
        ...state,
        showForm: false,
        showFormReport: false,
        order: INITIAL_ORDER
      };
    case "ORDERS_GET":
      return { ...state, list: action.payload };
    case "ORDERS_GET_CLIENT":
      return { ...state, cliente: action.payload };
    case "ORDERS_GET_ORDER":
      return {
        ...state,
        order: {
          ...state.order,
          name: action.payload.NOME,
          vendedor: action.payload.VENDEDOR
        }
      };
    default:
      return state;
  }
};
