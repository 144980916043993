const INITIAL_OBJ = {
  name: "",
  recurrence_sun: "",
  recurrence_mon: "",
  recurrence_tue: "",
  recurrence_wed: "",
  recurrence_thu: "",
  recurrence_fri: "",
  recurrence_sat: "",
};
const INITIAL_STATE = {
  showForm: false,
  route: INITIAL_OBJ,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(INITIAL_OBJ)
  // console.log(state);
  // console.log(action);
  switch (action.type) {
    case "ROUTE_SHOW_FORM":
      return {
        ...state,
        showForm: true,
        route: action.payload === undefined ? INITIAL_OBJ : action.payload,
      };
    case "ROUTE_HIDE_FORM":
      return { ...state, showForm: false, route: INITIAL_OBJ };
    default:
      return state;
  }
};
