import React from "react";

import List from "./List";
import ListProductReturnToSapItem from "./ListProductReturnToSapItem";
import ListCanceledPiclist from "./ListCanceledPiclist";

import Form from "./Form";
import FormProductReturnToSapItem from "./FormProductReturnToSapItem";
import FormCanceledPiclist from "./FormCanceledPiclist";

import FormReport from "./FormReport";
import FormSyndic from "./FormSyndic";
import FormMarketing from "./FormMarketing";
import If from "../../common/operators/If";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ShowForm, HideForm, ShowFormReport, HideFormReport } from "./Actions";
import consts from "../../consts";

class Main extends React.Component {
  constructor(props) {
    super(props);
    props.HideForm();
    props.HideFormReport();
    // console.log(this.me);
    // props.ShowForm();
    // console.log(props)
    this.state = {
      menuPosition: 1,
    };
  }

  setMenu(menuPosition) {
    this.setState({ menuPosition });
  }

  render() {
    const me = JSON.parse(localStorage.getItem(consts.UserKey)).user;
    return (
      <div>
        <If test={!this.props.showForm}>
          <If test={!this.props.showFormReport}>
            {this.state.menuPosition === 1 && (
              <List
                parentProps={this.props}
                setMenu={this.setMenu.bind(this)}
                menuPosition={this.state.menuPosition}
              />
            )}
            {this.state.menuPosition === 2 && (
              <ListProductReturnToSapItem
                parentProps={this.props}
                setMenu={this.setMenu.bind(this)}
                menuPosition={this.state.menuPosition}
              />
            )}
            {this.state.menuPosition === 3 && (
              <ListCanceledPiclist
                parentProps={this.props}
                setMenu={this.setMenu.bind(this)}
                menuPosition={this.state.menuPosition}
              />
            )}
          </If>
          <If test={this.props.showFormReport}>
            <FormReport />
          </If>
        </If>
        <If test={this.props.showForm}>
          <If test={me.profile === "syndic"}>{this.state.menuPosition === 1 && <FormSyndic />}</If>
          <If test={me.profile === "marketing"}>
            {this.state.menuPosition === 1 && <FormMarketing />}
          </If>
          <If test={me.profile !== "syndic" && me.profile !== "marketing"}>
            {this.state.menuPosition === 1 && <Form />}
            {this.state.menuPosition === 2 && <FormProductReturnToSapItem />}
            {this.state.menuPosition === 3 && <FormCanceledPiclist />}
          </If>
        </If>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state)
  return {
    showForm: state.product_returns.showForm,
    showBookingForm: state.product_returns.showBookingForm,
    showFormReport: state.product_returns.showFormReport,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      HideForm,
      ShowFormReport,
      HideFormReport,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
