import React from "react";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import consts from "../../consts";
import { HideForm, save } from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "components/Table/Table.jsx";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInputCep from "components/CustomInput/CustomInputCep.jsx";

import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";

// material ui icons
// import MailOutline from "@material-ui/icons/MailOutline";
import Money from "@material-ui/icons/AttachMoney";
import Check from "@material-ui/icons/Check";
// import Close from "@material-ui/icons/Close";
// import CategoryIcon from "@material-ui/icons/Category"
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomInputCPF from "components/CustomInput/CustomInputCPF.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import If from "../../common/operators/If";

// style for this view
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";

class ValidationForms extends React.Component {
  constructor(props) {
    super(props);
    // console.log('props: ',props)

    const prices =
      props.product && props.product.sapProducts
        ? props.product.sapProducts.map((sapProduct) => {
            return [
              sapProduct.table,
              `R$${sapProduct.final_price.toFixed(2)}`,
              sapProduct.denominador,
              `R$${sapProduct.unit_price.toFixed(2)}`,
            ];
          })
        : [];
    this.state = {
      id: props.product ? props.product.id : "",
      name: props.product ? props.product.name : "",
      sku: props.product ? props.product.sku : "",
      image: props.product ? props.product.image : "",
      volume: props.product ? props.product.volume : "",
      multi_serve: props.product ? props.product.multi_serve : "",
      only_pack: props.product ? props.product.only_pack : "",
      prices,

      // profile: (props.product) ? props.product.profile : "",
      // name: (props.product) ? props.product.name : "",
      // vendedor: (props.product) ? props.product.vendedor : "",
      // registerEmail: (props.product) ? props.product.email : "",
      // registerCPF: (props.product) ? props.product.cpf : "",

      // category: "",
      // // register form
      // registerEmailState: "",
      // registerPassword: "",
      // registerPasswordState: "",
      // registerConfirmPassword: "",
      // registerConfirmPasswordState: "",
      // registerCheckbox: false,
      // registerCheckboxState: "",
      // // login form
      // loginEmail: "",
      // loginEmailState: "",
      // loginPassword: "",
      // loginPasswordState: "",
      // // type validation
      // required: "",
      // requiredState: "",
      // typeEmail: "",
      // typeEmailState: "",
      // number: "",
      // numberState: "",
      // url: "",
      // urlState: "",
      // equalTo: "",
      // whichEqualTo: "",
      // equalToState: "",
      // // range validation
      // minLength: "",
      // minLengthState: "",
      // maxLength: "",
      // maxLengthState: "",
      // range: "",
      // rangeState: "",
      // minValue: "",
      // minValueState: "",
      // maxValue: "",
      // maxValueState: ""
    };
    this.registerClick = this.registerClick.bind(this);
    this.loginClick = this.loginClick.bind(this);
    this.typeClick = this.typeClick.bind(this);
    this.rangeClick = this.rangeClick.bind(this);
  }

  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  cancelButtonClick() {
    this.props.HideForm();
  }

  saveButtonClick() {
    // console.log(this.props.product)
    var product = { ...this.props.product };

    product.name = this.state.name;
    product.sku = this.state.sku;
    // if (this.state.image.indexOf("data:image") != -1) {
    product.image = this.state.image;
    // }
    product.volume = this.state.volume;
    product.multi_serve = this.state.multi_serve;
    product.only_pack = this.state.only_pack;

    // console.log(product);
    this.props.save(product);
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  // function that verifies if two strings are equal
  compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }
  // function that verifies if value contains only numbers
  verifyNumber(value) {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }
  // verifies if value is a valid URL
  verifyUrl(value) {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  }
  change(event, stateName, type, stateNameEqualTo, maxValue) {
    // console.log(event)
    // console.log(this.state)
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "checkbox":
        if (event.target.checked) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-length":
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "min-value":
        if (this.verifyNumber(event.target.value) && event.target.value >= stateNameEqualTo) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "max-value":
        if (this.verifyNumber(event.target.value) && event.target.value <= stateNameEqualTo) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "range":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (type) {
      case "checkbox":
        this.setState({ [stateName]: event.target.checked });
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }
  registerClick() {
    if (this.state.registerEmailState === "") {
      this.setState({ registerEmailState: "error" });
    }
    if (this.state.registerPasswordState === "") {
      this.setState({ registerPasswordState: "error" });
    }
    if (this.state.registerConfirmPasswordState === "") {
      this.setState({ registerConfirmPasswordState: "error" });
    }
    if (this.state.registerCheckboxState === "") {
      this.setState({ registerCheckboxState: "error" });
    }
  }
  loginClick() {
    if (this.state.loginEmailState === "") {
      this.setState({ loginEmailState: "error" });
    }
    if (this.state.loginPasswordState === "") {
      this.setState({ loginPasswordState: "error" });
    }
  }
  typeClick() {
    if (this.state.requiredState === "") {
      this.setState({ requiredState: "error" });
    }
    if (this.state.typeEmailState === "") {
      this.setState({ typeEmailState: "error" });
    }
    if (this.state.numberState === "") {
      this.setState({ numberState: "error" });
    }
    if (this.state.urlState === "") {
      this.setState({ urlState: "error" });
    }
    if (this.state.equalToState === "") {
      this.setState({ equalToState: "error" });
    }
  }
  rangeClick() {
    if (this.state.minLengthState === "") {
      this.setState({ minLengthState: "error" });
    }
    if (this.state.maxLengthState === "") {
      this.setState({ maxLengthState: "error" });
    }
    if (this.state.rangeState === "") {
      this.setState({ rangeState: "error" });
    }
    if (this.state.minValueState === "") {
      this.setState({ minValueState: "error" });
    }
    if (this.state.maxValueState === "") {
      this.setState({ maxValueState: "error" });
    }
  }

  // getPicture(image){
  //   // console.log(image)
  //   if(image !== ''){
  //     this.setState({
  //       image,
  //       imageState: 'success'
  //     })
  //   }else{
  //     this.setState({
  //       image,
  //       imageState: 'error'
  //     })
  //   }
  //
  // }

  getPicture(image) {
    // console.log("getPicture(image): ", image);
    this.setState({ image });
  }

  render() {
    const { classes } = this.props;
    // console.log("render(): ", this.props);
    // console.log("render(): ", this.state);

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={5}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Cadastrar novo produto</h4>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={12}>
                    <GridItem xs={12}>
                      <CustomInput
                        success={this.state.nameState === "success"}
                        error={this.state.nameState === "error"}
                        labelText={<span>Nome</span>}
                        id="name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) => this.change(event, "name", "length", 3),
                          value: this.state.name,
                          type: "text",
                          placeholder: "Nome",
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        success={this.state.skuState === "success"}
                        error={this.state.skuState === "error"}
                        labelText={<span>SKU</span>}
                        id="sku"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) => this.change(event, "sku", "length", 3),
                          value: this.state.sku,
                          type: "text",
                          placeholder: "SKU",
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        success={this.state.volumeState === "success"}
                        error={this.state.volumeState === "error"}
                        labelText={<span>Volume</span>}
                        id="volume"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (event) => this.change(event, "volume", "length", 3),
                          value: this.state.volume,
                          type: "text",
                          placeholder: "Volume",
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            checked={this.state.multi_serve == 1 ? true : false}
                            onClick={(event) => this.change(event, "multi_serve", "checkbox")}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label:
                            classes.label +
                            (this.state.multi_serveState === "error"
                              ? " " + classes.labelError
                              : ""),
                        }}
                        label="Multi serve"
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            checked={this.state.only_pack == 1 ? true : false}
                            onClick={(event) => this.change(event, "only_pack", "checkbox")}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label:
                            classes.label +
                            (this.state.only_packState === "error" ? " " + classes.labelError : ""),
                        }}
                        label="Venda Pack"
                      />
                    </GridItem>

                    <GridItem xs={12} sm={4} md={4}>
                      <br />
                      <ImageUpload
                        addButtonProps={{
                          color: "rose",
                          round: true,
                        }}
                        changeButtonProps={{
                          color: "rose",
                          round: true,
                        }}
                        removeButtonProps={{
                          color: "danger",
                          round: true,
                        }}
                        getPicture={(image) => this.getPicture(image)}
                        defaultImage={this.state.image}
                      />
                    </GridItem>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
            <CardFooter>
              <Button onClick={() => this.cancelButtonClick()}>Cancelar</Button>
              <Button color="primary" onClick={() => this.saveButtonClick()}>
                Salvar
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={7}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <Money />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Preços</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["Tabela", "Preço", "Pack", "Preço unitário"]}
                tableData={this.state.prices}
                coloredColls={[1, 3]}
                colorsColls={["primary", "primary"]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log('mapStateToProps: ', state)
  return {
    HideForm: state.products.HideForm,
    product: state.products.product,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      HideForm,
      save,
    },
    dispatch
  );
const ReturnClassDecorated = withStyles(validationFormsStyle)(ValidationForms);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnClassDecorated);
