import React from "react";

// react component for creating dynamic tables
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import axios from "axios";
import socketIOClient from "socket.io-client";
import consts from "../../consts";

// import Form from "./Form";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import Loader from "components/Loader/Loader.jsx";

import moment from "moment";
import "moment/locale/pt-br";

// React Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ShowForm,
  showNotification,
  // get
} from "./Actions";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import MaterialIconList from "@material-ui/icons/List";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import CategoryIcon from "@material-ui/icons/Category";
import DownloadIcon from "@material-ui/icons/GetApp";
import WarningIcon from "@material-ui/icons/Warning";
import NotaFistalIcon from "@material-ui/icons/InsertDriveFile";
import ViewIcon from "@material-ui/icons/Visibility";
import Add from "@material-ui/icons/Add";

import CheckIcon from "@material-ui/icons/Check";
import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInputDate from "components/CustomInput/CustomInputDate.jsx";
import TableWBorder from "components/Table/TableWBorder.jsx";

import { dataTable } from "variables/general.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      data: [],
      tableData: [],
      date: moment().format("L"),
      pages: -1,
      loading: true,
      alert: null,
      show: false,
      piclist_id: null,
      showFilters: false,
    };
  }

  componentDidMount() {
    this.get();
  }

  tryAgain(transporte) {
    this.setState({ loading: true });
    axios
      .put(`${consts.API_URL}/transport-conferences-sap-retry/${transporte.id}`)
      .then((res) => {
        let configNotification = {
          message: "Nova tentativa de envio para o SAP realizada com sucesso.",
          color: "success",
        };
        this.props.showNotification(configNotification);
        this.get();
      })
      .catch((err) => {
        let configNotification = {
          message: "Erro ao tentar enviar para o SAP.",
          color: "danger",
        };
        this.props.showNotification(configNotification);
      });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  remove(transporte) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Tem certeza?"
          onConfirm={() => {
            // console.log(transporte);
            axios
              .delete(`${consts.API_URL}/transport-conferences-sap-retry/${transporte.id}`)
              .then((res) => {
                let configNotification = {
                  message: "Informação de inserção manual no SAP enviada com sucesso.",
                  color: "success",
                };
                this.props.showNotification(configNotification);
                this.get();
              })
              .catch((err) => {
                let configNotification = {
                  message: "Erro ao tentar enviar informação de inserção manual no SAP.",
                  color: "danger",
                };
                this.props.showNotification(configNotification);
              });
            this.hideAlert();
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
          cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
          confirmBtnText="Sim, excluir!"
          cancelBtnText="Cancelar"
          showCancel
        >
          Antes de encerrar esse transporte manualmente, tenha certeza que você finalizou no SAP.
          Essa ação não poderá ser desfeita.
        </SweetAlert>
      ),
    });
  }

  get() {
    this.setState({ loading: true });
    const { classes } = this.props;
    axios.get(`${consts.API_URL}/transport-conferences-sap-retry`).then((res) => {
      // console.log(res);
      // console.log(state.sorted)
      // console.log(state.filtered)
      // Update react-table
      const tableData = res.data.map((item) => {
        const simpleButtons = (
          <div>
            <Button
              fullWidth
              size="sm"
              color="primary"
              className={classes.actionButton}
              onClick={() => this.tryAgain(item)}
            >
              Tentar novamente
            </Button>
            <Button
              fullWidth
              size="sm"
              color="primary"
              className={classes.actionButton}
              onClick={() => this.remove(item)}
            >
              Inserido manualmente
            </Button>
          </div>
        );

        return [item.transporte, item.driver_cod, item.attempts, item.message, simpleButtons];
      });
      this.setState({
        loading: false,
        tableData,
      });
    });
  }

  render() {
    const { classes } = this.props;
    // console.log(this.state.originalData);
    return (
      <div>
        {this.state.loading ? <Loader /> : null}
        {this.state.alert}

        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <MaterialIconList />
                </CardIcon>
              </CardHeader>
              {this.state.tableData.length > 0 ? (
                <CardBody>
                  <TableWBorder
                    tableHeaderColor="primary"
                    tableHead={[
                      "Transporte",
                      "Motorista",
                      "Tentativas",
                      "Mensagem",
                      "Ações no SAP",
                    ]}
                    tableData={this.state.tableData}
                    customHeadClassesForCells={[1, 2, 3, 4]}
                    customHeadCellClasses={[
                      classes.center,
                      classes.center,
                      classes.center,
                      classes.center,
                    ]}
                    customClassesForCells={[1, 2, 3, 4]}
                    customCellClasses={[
                      classes.center,
                      classes.center,
                      classes.center,
                      classes.center,
                    ]}
                    coloredColls={[20]}
                    colorsColls={["info"]}
                    tableShopping={true}
                  />
                </CardBody>
              ) : (
                <CardBody>
                  <h4 className={classes.cardTitle}>Nenhum registro encontrado.</h4>
                </CardBody>
              )}
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   console.log(state)
//   return {
//     ShowForm: state.ShowForm
//   }
// }

const mapStateToProps = (state) => ({
  ShowForm: state.ShowForm,
  ShowPicList: state.ShowPicList,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ShowForm,
      showNotification,
    },
    dispatch
  );
const MemberList = withStyles(sweetAlertStyle)(ReactTables);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberList);
